//
// Textual form controls
//

.form-select {
  font-family: $font-family-base;

  // Alternative Style
  &.form-control-alt {
    border-color: $light;
    background-color: $light;
    transition: none;

    &:focus {
      border-color: $body-bg-dark;
      background-color: $body-bg-dark;
      box-shadow: none;
    }

    &.is-valid {
      border-color: $success-light;
      background-color: $success-light;

      &:focus {
        border-color: lighten($success-light, 2%);
        background-color: lighten($success-light, 2%);
      }
    }

    &.is-invalid {
      border-color: $danger-light;
      background-color: $danger-light;

      &:focus {
        border-color: lighten($danger-light, 2%);
        background-color: lighten($danger-light, 2%);
      }
    }
  }
}
