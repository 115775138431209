//
// Cards
// --------------------------------------------------

.card {
  &.card-borderless {
    box-shadow: 0 1px 2px rgba(shade-color($body-bg-dark, 3%), .5), 0 1px 2px rgba(shade-color($body-bg-dark, 3%), .5);
  }

  &.card-square {
    border-radius: 0;

    > .list-group:first-child,
    > .list-group:last-child,
    > .card-header:first-child,
    > .card-footer:last-child,
    .card-img-overlay,
    .card-img,
    .card-img-top,
    .card-img,
    .card-img-bottom {
      border-radius: 0;
    }
  }

  &.card-borderless {
    border: 0;

    > .card-header {
      border-bottom: 0;
    }
    
    > .card-footer {
      border-top: 0;
    }
  }
}