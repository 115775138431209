//
// Custom Main
//
// Add your own styles or override existing ones
//
// This file is included last, so you have access
// to all Bootstrap and OneUI functions/mixins/styles etc
// --------------------------------------------------
.iziToast-capsule {
    font-size: 0;
    height: 0;
    width: 100%;
    transform: translateZ(0);
    backface-visibility: hidden;
    transition: transform .5s cubic-bezier(.25, .8, .25, 1), height .5s cubic-bezier(.25, .8, .25, 1)
}

.iziToast-capsule,
.iziToast-capsule * {
    box-sizing: border-box
}

.iziToast-overlay {
    display: block;
    position: fixed;
    top: -100px;
    left: 0;
    right: 0;
    bottom: -100px;
    z-index: 997
}

.iziToast {
    display: inline-block;
    clear: both;
    position: relative;
    font-family: Lato, Tahoma, Arial;
    font-size: 14px;
    padding: 8px 45px 9px 0;
    background: rgba(238, 238, 238, .9);
    border-color: rgba(238, 238, 238, .9);
    width: 100%;
    pointer-events: all;
    cursor: default;
    transform: translateX(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 54px
}

.iziToast>.iziToast-progressbar {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, .2)
}

.iziToast>.iziToast-progressbar>div {
    height: 2px;
    width: 100%;
    background: rgba(0, 0, 0, .3);
    border-radius: 0 0 3px 3px
}

.iziToast.iziToast-balloon:before {
    content: '';
    position: absolute;
    right: 8px;
    left: auto;
    width: 0;
    height: 0;
    top: 100%;
    border-right: 0px solid transparent;
    border-left: 15px solid transparent;
    border-top: 10px solid #000;
    border-top-color: inherit;
    border-radius: 0
}

.iziToast.iziToast-balloon .iziToast-progressbar {
    top: 0;
    bottom: auto
}

.iziToast.iziToast-balloon>div {
    border-radius: 0 0 0 3px
}

.iziToast>.iziToast-cover {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    margin: 0;
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, .1)
}

.iziToast>.iziToast-close {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    padding: 0;
    opacity: .6;
    width: 42px;
    height: 100%;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAJPAAACTwBcGfW0QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAD3SURBVFiF1ZdtDoMgDEBfdi4PwAX8vLFn0qT7wxantojKupmQmCi8R4tSACpgjC2ICCUbEBa8ingjsU1AXRBeR8aLN64FiknswN8CYefBBDQ3whuFESy7WyQMeC0ipEI0A+0FeBvHUFN8xPaUhAH/iKoWsnXHGegy4J0yxialOfaHJAz4bhRzQzgDvdGnz4GbAonZbCQMuBm1K/kcFu8Mp1N2cFFpsxsMuJqqbIGExGl4loARajU1twskJLLhIsID7+tvUoDnIjTg5T9DPH9EBrz8rxjPzciAl9+O8SxI8CzJ8CxKFfh3ynK8Dyb8wNHM/XDqejx/AtNyPO87tNybAAAAAElFTkSuQmCC") 50% 50%/8px no-repeat;
    cursor: pointer;
    outline: 0
}

.iziToast>.iziToast-close:hover {
    opacity: 1
}

.iziToast>.iziToast-body {
    position: relative;
    padding: 0 0 0 10px;
    height: auto;
    min-height: 36px;
    margin: 0 0 0 15px;
    text-align: left
}

.iziToast>.iziToast-body:after {
    content: "";
    display: table;
    clear: both
}

.iziToast>.iziToast-body .iziToast-texts {
    margin: 10px 0 0;
    padding-right: 2px;
    display: inline-block;
    float: left;
}

.iziToast>.iziToast-body .iziToast-inputs {
    min-height: 19px;
    float: left;
    margin: 3px -2px
}

.iziToast>.iziToast-body .iziToast-inputs>input:not([type=checkbox]):not([type=radio]),
.iziToast>.iziToast-body .iziToast-inputs>select {
    position: relative;
    display: inline-block;
    margin: 2px;
    border-radius: 2px;
    border: 0;
    padding: 4px 7px;
    font-size: 13px;
    letter-spacing: .02em;
    background: rgba(0, 0, 0, .1);
    color: #000;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .2);
    min-height: 26px
}

.iziToast>.iziToast-body .iziToast-buttons>a:focus,
.iziToast>.iziToast-body .iziToast-buttons>button:focus,
.iziToast>.iziToast-body .iziToast-buttons>input:not([type=checkbox]):not([type=radio]):focus,
.iziToast>.iziToast-body .iziToast-inputs>input:not([type=checkbox]):not([type=radio]):focus,
.iziToast>.iziToast-body .iziToast-inputs>select:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .6)
}

.iziToast>.iziToast-body .iziToast-buttons {
    min-height: 17px;
    float: left;
    margin: 4px -2px
}

.iziToast>.iziToast-body .iziToast-buttons>a,
.iziToast>.iziToast-body .iziToast-buttons>button,
.iziToast>.iziToast-body .iziToast-buttons>input:not([type=checkbox]):not([type=radio]) {
    position: relative;
    display: inline-block;
    margin: 2px;
    border-radius: 2px;
    border: 0;
    padding: 5px 10px;
    font-size: 12px;
    letter-spacing: .02em;
    cursor: pointer;
    background: rgba(0, 0, 0, .1);
    color: #000
}

.iziToast>.iziToast-body .iziToast-buttons>a:hover,
.iziToast>.iziToast-body .iziToast-buttons>button:hover,
.iziToast>.iziToast-body .iziToast-buttons>input:not([type=checkbox]):not([type=radio]):hover {
    background: rgba(0, 0, 0, .2)
}

.iziToast>.iziToast-body .iziToast-buttons>a:active,
.iziToast>.iziToast-body .iziToast-buttons>button:active,
.iziToast>.iziToast-body .iziToast-buttons>input:not([type=checkbox]):not([type=radio]):active {
    top: 1px
}

.iziToast>.iziToast-body .iziToast-icon {
    position: absolute;
    left: 0;
    top: 50%;
    display: table;
    font-size: 23px;
    line-height: 24px;
    margin-top: -12px;
    color: #000;
    width: 24px;
    height: 24px
}

.iziToast>.iziToast-body .iziToast-message,
.iziToast>.iziToast-body .iziToast-title {
    padding: 0;
    font-size: 14px;
    line-height: 16px;
    white-space: normal;
    float: left;
    text-align: left
}

.iziToast>.iziToast-body .iziToast-icon.ico-info {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAflBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACCtoPsAAAAKXRSTlMA6PsIvDob+OapavVhWRYPrIry2MxGQ97czsOzpJaMcE0qJQOwVtKjfxCVFeIAAAI3SURBVFjDlJPZsoIwEETnCiGyb8q+qmjl/3/wFmGKwjBROS9QWbtnOqDDGPq4MdMkSc0m7gcDDhF4NRdv8NoL4EcMpzoJglPl/KTDz4WW3IdvXEvxkfIKn7BMZb1bFK4yZFqghZ03jk0nG8N5NBwzx9xU5cxAg8fXi20/hDdC316lcA8o7t16eRuQvW1XGd2d2P8QSHQDDbdIII/9CR3lUF+lbucfJy4WfMS64EJPORnrZxtfc2pjJdnbuags3l04TTtJMXrdTph4Pyg4XAjugAJqMDf5Rf+oXx2/qi4u6nipakIi7CsgiuMSEF9IGKg8heQJKkxIfFSUU/egWSwNrS1fPDtLfon8sZOcYUQml1Qv9a3kfwsEUyJEMgFBKzdV8o3Iw9yAjg1jdLQCV4qbd3no8yD2GugaC3oMbF0NYHCpJYSDhNI5N2DAWB4F4z9Aj/04Cna/x7eVAQ17vRjQZPh+G/kddYv0h49yY4NWNDWMMOMUIRYvlTECmrN8pUAjo5RCMn8KoPmbJ/+Appgnk//Sy90GYBCGgm7IAskQ7D9hFKW4ApB1ei3FSYD9PjGAKygAV+ARFYBH5BsVgG9kkBSAQWKUFYBRZpkUgGVinRWAdUZQDABBQdIcAElDVBUAUUXWHQBZx1gMAGMprM0AsLbVXHsA5trZe93/wp3svQ0YNb/jWV3AIOLsMtlznSNOH7JqjOpDVh7z8qCZR10ftvO4nxeOvPLkpSuvfXnxzKtvXr7j+v8C5ii0e71At7cAAAAASUVORK5CYII=") 50% 50%/85% no-repeat
}

.iziToast>.iziToast-body .iziToast-icon.ico-warning {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAkFBMVEUAAAAAAAABAAIAAAABAAIAAAMAAAABAAIBAAIBAAIAAAIAAAABAAIAAAABAAICAAICAAIAAAIAAAAAAAAAAAABAAIBAAIAAAMAAAABAAIBAAMBAAECAAIAAAIAAAIAAAABAAIBAAIBAAMBAAIBAAEAAAIAAAMAAAAAAAABAAECAAICAAIAAAIAAAMAAAQAAAE05yNAAAAAL3RSTlMAB+kD7V8Q+PXicwv7I9iYhkAzJxnx01IV5cmnk2xmHfzexsK4eEw5L7Gei39aRw640awAAAHQSURBVFjD7ZfJdoJAEEWJgCiI4oDiPM8m7///LidErRO7sHrY5u7YXLr7vKqu9kTC0HPmo9n8cJbEQOzqqAdAUHeUZACQuTkGDQBoDJwkHZR0XBz9FkpafXuHP0SJ09mGeJLZ5wwlTmcbA0THPmdEK7XPGTG1zxmInn3OiJ19zkB0jSVTKExMHT0wjAwlWzC0fSPHF1gWRpIhWMYm7fYTFcQGlbemf4dFfdTGg0B/KXM8qBU/3wntbq7rSGqvJ9kla6IpueFJet8fxfem5yhykjyOgNaWF1qSGd5JMNNxpNF7SZQaVh5JzLrTCZIEJ1GyEyVyd+pClMjdaSJK5O40giSRu5PfFiVyd1pAksjdKRnrSsbVdbiHrgT7yss315fkVQPLFQrL+4FHeOXKO5YRFEKv5AiFaMlKLlBpJuVCJlC5sJfvCgztru/3NmBYccPgGTxRAzxn1XGEMUf58pXZvjoOsOCgjL08+b53mtfAM/SVsZcjKLtysQZPqIy9HPP3m/3zKItRwT0LyQo8sTr26tcO83DIUMWIJjierHLsJda/tbNBFY0BP/bKtcM8HNIWCK3aYR4OMzgxo5w5EFLOLKDExXAm9gI4E3iAO94/Ct/lKWuM2LMGbgAAAABJRU5ErkJggg==") 50% 50%/85% no-repeat
}

.iziToast>.iziToast-body .iziToast-icon.ico-error {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAeFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAVyEiIAAAAJ3RSTlMA3BsB98QV8uSyWVUFz7+kcWMM2LuZioBpTUVBNcq2qaibj4d1azLZZYABAAACZElEQVRYw7WX25KCMAyGAxUoFDkpiohnV97/DXeGBtoOUprZ2dyo1K82fxKbwJJVp+KQZ7so2mX5oThVQLKwjDe9YZu4DF3ptAn6rxY0qQPOEq9fNC9ha3y77a22ba24v+9Xbe8v8x03dPOC2/NdvB6xeSreLfGJpnx0TyotKqLm2s7Jd/WO6ivXNp0tCy02R/aFz5VQ5wUPlUL5fIfj5KIlVGU0nWHm/5QtoTVMWY8mzIVu1K9O7XH2JiU/xnOOT39gnUfj+lFHddx4tFjL3/H8jjzaFCy2Rf0c/fdQyQszI8BDR973IyMSKa4krjxAiW/lkRvMP+bKK9WbYS1ASQg8dKjaUGlYPwRe/WoIkz8tiQchH5QAEMv6T0k8MD4mUyWr4E7jAWqZ+xWcMIYkXvlwggJ3IvFK+wIOcpXAo8n8P0COAaXyKH4OsjBuZB4ew0IGu+H1SebhNazsQBbWm8yj+hFuUJB5eMsN0IUXmYendAFFfJB5uEkRMYwxmcd6zDGRtmQePEykAgubymMRFmMxCSIPCRbTuFNN5OGORTjmNGc0Po0m8Uv0gcCry6xUhR2QeLii9tofbEfhz/qvNti+OfPqNm2Mq6105FUMvdT4GPmufMiV8PqBMkc+DdT1bjYYbjzU/ew23VP4n3mLAz4n8Jtv/Ui3ceTT2mzz5o1mZt0gnBpmsdjqRqVlmplcPdqa7X23kL9brdm2t/uBYDPn2+tyu48mtIGD10JTuUrukVrbCFiwDzcHrPjxKt7PW+AZQyT/WESO+1WL7f3o+WLHL2dYMSZsg6dg/z360ofvP4//v1NPzgs28WlWAAAAAElFTkSuQmCC") 50% 50%/80% no-repeat
}

.iziToast>.iziToast-body .iziToast-icon.ico-success {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAIVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABt0UjBAAAACnRSTlMApAPhIFn82wgGv8mVtwAAAKVJREFUSMft0LEJAkEARNFFFEw1NFJb8CKjAy1AEOzAxNw+bEEEg6nyFjbY4LOzcBwX7S/gwUxoTdIn+Jbv4Lv8bx446+kB6VsBtK0B+wbMCKxrwL33wOrVeeChX28n7KTOTjgoEu6DRSYAgAAAAkAmAIAAAAIACQIkMkACAAgAIACAyECBKAOJuCagTJwSUCaUAEMAABEBRwAAEQFLbCJgO4bW+AZKGnktR+jAFAAAAABJRU5ErkJggg==") 50% 50%/85% no-repeat
}

.iziToast>.iziToast-body .iziToast-icon.ico-question {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhCQkUEhFovxTxAAAEDklEQVRo3s2ZTWgTQRTHf03ipTRUqghNSgsRjHgQrFUQC6JgD1Kak3gQUUoPqRdBglf1oBehBws9Cn4cGk+1SOmh2upBxAYVoeJHrR9tgq0i1Cq0lqYeks7MbpPdmU00/c8hm9n33v/t7Nt5M2+qMEWQI0QIibZKRrQpHvLL2KI2wnQzzBKrDm2RIeKEy01dTYKUI7G1ZRknQXV5yP10kTYgly1NF/5S6duZ8ES+1iZodyaocrjXxE0OFeifYYgp0mRIkwFChAkRJsIxGgrIP+I0n82fvZW5dc/zkss0O2o1c5mX6/TmaDWl77RFe5YkUW3tKEmyFv0lOvXJ/fTYnmCEFuMRbGHEZqVHLyT9DFjUJmkzJl9DG5MWWwM6Llif/gF1nukB6nhgGwUXdFrE+wiURA8QoM9i0zEWWpXQW+ZsyeRrOMuyEo5Fv4gmy4dXPvqcC+pH2VRYaMwy+OWG+iLGCgm0W0Kv9HdvR8ASjmKCXpuK/bxiV/76A/v5UdDIZuKcJGjrnec5KZ7wwsWFOp6xPX/9mt2sqDe7FO+Kf/fXHBPPDWpdXGhTpLvUG9VKwh1xMDDjkvu+cNDFBTk7ptX1QkKZ850m3duu6fcrWxwdaFFyREJ2j4vOpKP6Du6z4uJCv8sYJIVkCnJBGGZaBONO3roY2EqNrSfIPi7SKP4fdXyNUd6I6wbSAHEl33tFLe+FlSsusnK90A0+oEPcuufZgXnOi+u9LrKSJQZQw6LwqBnv2CKsfHORbFbyQhA6xN/pEuihSdj56Co7LWRjPiKie6gkB2LiKuUqK5kiPkLiz1QJ9K1cNXBAMoUCigNpQ9IqDtMI1HKA4/jyvUsaoSyZLA5kjOjDPFZen8Ql5TsvBskUgjciIPSX3QAXC86DT7VWvlEh/xZ+ij9BDVWJ0QL0SbZq6QaFxoLPcXPmBLveLCc4wXdDK6s+6/vwhCSniFLPXW0NJe5UB8zKCsviqpc7vGPVQFcyZbyPwGD+d5ZnxmNWlhG4xSBZZjivjIWHEQgoDkSMjMwTo54569JSE5IpA7EyJSMTyGTUAUFlO1ZKOtaHTMeL1PhYYFTcihmY2cQ5+ullj7EDkiVfVez2sCTz8yiv84djhg7IJVk81xFWJlPdfHBG0flkRC/zQFZ+DSllNtfDdUsOMCliyGX5uOzU3ZhIXFDof4m1gDuKbEx0t2YS25gVGpcMnr/I1kx3c6piB8P8ZoqEwfMX3ZyCXynJTmq/U7NUXqfUzCbWL1wqVKBQUeESzQYoUlW8TAcVL1RCxUu1G6BYXfFyfQ4VPbDI4T8d2WzgQ6sc/vmxnTsqfHCZQzUJxm1h5dxS5Tu6lQgTZ0ipqRVqSwzTbbLHMt+c19iO76tsx/cLZub+Ali+tYC93olEAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA5LTA5VDIwOjE4OjE3KzAyOjAwjKtfjgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wOS0wOVQyMDoxODoxNyswMjowMP325zIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") 50% 50%/85% no-repeat
}

.iziToast>.iziToast-body .iziToast-title {
    margin: 0;
    color: #000
}

.iziToast>.iziToast-body .iziToast-message {
    margin: 0 0 10px;
    color: rgba(0, 0, 0, .6)
}

.iziToast.iziToast-animateInside .iziToast-buttons-child,
.iziToast.iziToast-animateInside .iziToast-icon,
.iziToast.iziToast-animateInside .iziToast-inputs-child,
.iziToast.iziToast-animateInside .iziToast-message,
.iziToast.iziToast-animateInside .iziToast-title {
    opacity: 0
}

.iziToast-target {
    position: relative;
    width: 100%;
    margin: 0 auto
}

.iziToast-target .iziToast-capsule {
    overflow: hidden
}

.iziToast-target .iziToast-capsule:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0
}

.iziToast-target .iziToast-capsule .iziToast {
    width: 100%;
    float: left
}

.iziToast-wrapper {
    z-index: 99999;
    position: fixed;
    width: 100%;
    pointer-events: none;
    display: flex;
    flex-direction: column
}

.iziToast-wrapper .iziToast.iziToast-balloon:before {
    border-right: 0 solid transparent;
    border-left: 15px solid transparent;
    border-top: 10px solid #000;
    border-top-color: inherit;
    right: 8px;
    left: auto
}

.iziToast-wrapper-bottomLeft {
    left: 0;
    bottom: 0;
    text-align: left
}

.iziToast-wrapper-bottomLeft .iziToast.iziToast-balloon:before,
.iziToast-wrapper-topLeft .iziToast.iziToast-balloon:before {
    border-right: 15px solid transparent;
    border-left: 0 solid transparent;
    right: auto;
    left: 8px
}

.iziToast-wrapper-bottomRight {
    right: 0;
    bottom: 0;
    text-align: right
}

.iziToast-wrapper-topLeft {
    left: 0;
    top: 0;
    text-align: left
}

.iziToast-wrapper-topRight {
    top: 0;
    right: 0;
    text-align: right
}

.iziToast-wrapper-topCenter {
    top: 0;
    left: 0;
    right: 0;
    text-align: center
}

.iziToast-wrapper-bottomCenter {
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center
}

.iziToast-wrapper-center {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    justify-content: center;
    flex-flow: column;
    align-items: center
}

.iziToast-rtl {
    direction: rtl;
    padding: 8px 0 9px 45px;
    font-family: Tahoma, Lato, Arial
}

.iziToast-rtl .iziToast-body .iziToast-icon,
.iziToast-rtl .iziToast-cover {
    left: auto;
    right: 0
}

.iziToast-rtl .iziToast-close {
    right: auto;
    left: 0
}

.iziToast-rtl .iziToast-body {
    padding: 0 10px 0 0;
    margin: 0 16px 0 0;
    text-align: right
}

.iziToast-rtl .iziToast-body .iziToast-buttons,
.iziToast-rtl .iziToast-body .iziToast-inputs,
.iziToast-rtl .iziToast-body .iziToast-message,
.iziToast-rtl .iziToast-body .iziToast-texts,
.iziToast-rtl .iziToast-body .iziToast-title {
    float: right;
    text-align: right
}

@media only screen and (min-width:568px) {
    .iziToast-wrapper {
        padding: 10px 15px
    }

    .iziToast {
        margin: 5px 0;
        border-radius: 3px;
        width: auto
    }

    .iziToast:after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        box-shadow: inset 0 -10px 20px -10px rgba(0, 0, 0, .2), inset 0 0 5px rgba(0, 0, 0, .1), 0 8px 8px -5px rgba(0, 0, 0, .25)
    }

    .iziToast:not(.iziToast-rtl) .iziToast-cover {
        border-radius: 3px 0 0 3px
    }

    .iziToast.iziToast-rtl .iziToast-cover {
        border-radius: 0 3px 3px 0
    }

    .iziToast.iziToast-color-dark:after {
        box-shadow: inset 0 -10px 20px -10px rgba(255, 255, 255, .3), 0 10px 10px -5px rgba(0, 0, 0, .25)
    }

    .iziToast.iziToast-balloon .iziToast-progressbar {
        background: 0 0
    }

    .iziToast.iziToast-balloon:after {
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .25), inset 0 10px 20px -5px rgba(0, 0, 0, .25)
    }

    .iziToast-target .iziToast:after {
        box-shadow: inset 0 -10px 20px -10px rgba(0, 0, 0, .2), inset 0 0 5px rgba(0, 0, 0, .1)
    }
}

.iziToast.iziToast-theme-dark {
    background: #565c70;
    border-color: #565c70
}

.iziToast.iziToast-theme-dark .iziToast-icon,
.iziToast.iziToast-theme-dark .iziToast-title {
    color: #fff
}

.iziToast.iziToast-theme-dark .iziToast-message {
    color: rgba(255, 255, 255, .7);
    font-weight: 300
}

.iziToast.iziToast-theme-dark .iziToast-close {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfgCR4OIQIPSao6AAAAwElEQVRIx72VUQ6EIAwFmz2XB+AConhjzqTJ7JeGKhLYlyx/BGdoBVpjIpMJNjgIZDKTkQHYmYfwmR2AfAqGFBcO2QjXZCd24bEggvd1KBx+xlwoDpYmvnBUUy68DYXD77ESr8WDtYqvxRex7a8oHP4Wo1Mkt5I68Mc+qYqv1h5OsZmZsQ3gj/02h6cO/KEYx29hu3R+VTTwz6D3TymIP1E8RvEiiVdZfEzicxYLiljSxKIqlnW5seitTW6uYnv/Aqh4whX3mEUrAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE2LTA5LTMwVDE0OjMzOjAyKzAyOjAwl6RMVgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNi0wOS0zMFQxNDozMzowMiswMjowMOb59OoAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") 50% 50%/8px no-repeat
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-info {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAflBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////vroaSAAAAKXRSTlMA6PsIvDob+OapavVhWRYPrIry2MxGQ97czsOzpJaMcE0qJQOwVtKjfxCVFeIAAAI3SURBVFjDlJPZsoIwEETnCiGyb8q+qmjl/3/wFmGKwjBROS9QWbtnOqDDGPq4MdMkSc0m7gcDDhF4NRdv8NoL4EcMpzoJglPl/KTDz4WW3IdvXEvxkfIKn7BMZb1bFK4yZFqghZ03jk0nG8N5NBwzx9xU5cxAg8fXi20/hDdC316lcA8o7t16eRuQvW1XGd2d2P8QSHQDDbdIII/9CR3lUF+lbucfJy4WfMS64EJPORnrZxtfc2pjJdnbuags3l04TTtJMXrdTph4Pyg4XAjugAJqMDf5Rf+oXx2/qi4u6nipakIi7CsgiuMSEF9IGKg8heQJKkxIfFSUU/egWSwNrS1fPDtLfon8sZOcYUQml1Qv9a3kfwsEUyJEMgFBKzdV8o3Iw9yAjg1jdLQCV4qbd3no8yD2GugaC3oMbF0NYHCpJYSDhNI5N2DAWB4F4z9Aj/04Cna/x7eVAQ17vRjQZPh+G/kddYv0h49yY4NWNDWMMOMUIRYvlTECmrN8pUAjo5RCMn8KoPmbJ/+Appgnk//Sy90GYBCGgm7IAskQ7D9hFKW4ApB1ei3FSYD9PjGAKygAV+ARFYBH5BsVgG9kkBSAQWKUFYBRZpkUgGVinRWAdUZQDABBQdIcAElDVBUAUUXWHQBZx1gMAGMprM0AsLbVXHsA5trZe93/wp3svQ0YNb/jWV3AIOLsMtlznSNOH7JqjOpDVh7z8qCZR10ftvO4nxeOvPLkpSuvfXnxzKtvXr7j+v8C5ii0e71At7cAAAAASUVORK5CYII=") 50% 50%/85% no-repeat
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-warning {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAllBMVEUAAAD////+//3+//3+//3///////z+//3+//3+//3////////////9//3////+//39//3///3////////////+//3+//39//3///z+//z+//7///3///3///3///3////////+//3+//3+//3+//z+//3+//7///3///z////////+//79//3///3///z///v+//3///+trXouAAAAMHRSTlMAB+j87RBf+PXiCwQClSPYhkAzJxnx05tSyadzcmxmHRbp5d7Gwrh4TDkvsYt/WkdQzCITAAAB1UlEQVRYw+3XaXKCQBCGYSIIighoxCVqNJrEPfly/8vFImKXduNsf/Mc4K1y7FnwlMLQc/bUbj85R6bA1LXRDICg6RjJcZa7NQYtnLUGTpERSiOXxrOPkv9s30iGKDmtbYir3H7OUHJa2ylAuvZzRvzUfs7Ii/2cgfTt54x82s8ZSM848gJmYtroQzA2jHwA+LkBIEuMGt+QIng1igzlyMrkuP2CyOi47axRaYTL5jhDJehoR+aovC29s3iIyly3Eb+hRCvZo2qsGTnhKr2cLDS+J73GsqBI9W80UCmWWpEuhIjh6ZRGjyNRarjzKGJ2Ou2himCvjHwqI+rTqQdlRH06TZQR9ek0hiqiPp06mV4ke7QPX6ERUZxO8Uo3sqrfhxvoRrCpvXwL/UjR9GRHMIvLgke4d5QbiwhM6JV2YKKF4vIl7XIBkwm4keryJVmvk/TfwcmPwQNkUQuyA2/sYGwnXL7GPu4bW1jYsmevrNj09/MGZMOEPXslQVqO8hqykD17JfPHP/bmo2yGGpdZiH3IZvzZa7B3+IdDjjpjesHJcvbs5dZ/e+cddVoDdvlq7x12Nac+iN7e4R8OXTjp0pw5CGnOLNDEzeBs5gVwFniAO+8f8wvfeXP2hyqnmwAAAABJRU5ErkJggg==") 50% 50%/85% no-repeat
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-error {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAeFBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////GqOSsAAAAJ3RSTlMA3BsB98QV8uSyWVUFz6RxYwzYvbupmYqAaU1FQTXKv7abj4d1azKNUit3AAACZElEQVRYw7WXaZOCMAyGw30UORRQBLxX/v8/3BkaWjrY2szO5otKfGrzJrEp6Kw6F8f8sI+i/SE/FucKSBaWiT8p5idlaEtnXTB9tKDLLHAvdSatOan3je93k9F2vRF36+mr1a6eH2NFNydoHq/ieU/UXcWjjk9XykdNWq2ywtp4tXL6Wb2T/MqtzzZutsrNyfvA51KoQROhVCjfrnASIRpSVUZiD5v4RbWExjRdJzSmOsZFvzYz59kRSr6V5zE+/QELHkNdb3VRx45HS1b1u+zfkkcbRAZ3qJ9l/A4qefHUDMShJe+6kZKJDD2pLQ9Q4lu+5Q7rz7Plperd7AtQEgIPI6o2dxr2D4GXvxqCiKcn8cD4gxIAEt7/GYkHL16KqeJd0NB4gJbXfgVnzCGJlzGcocCVSLzUvoAj9xJ4NF7/R8gxoVQexc/hgBpSebjPjgPs59cHmYfn7NkDb6wXmUf1I1ygIPPw4gtgCE8yDw8eAop4J/PQcBExjQmZx37MsZB2ZB4cLKQCG5vKYxMWSzMxIg8pNtOyUkvkocEmXGo69mh8FgnxS4yBwMvDrJSNHZB4uC3ayz/YkcIP4lflwVIT+OU07ZSjrbTkZQ6dTPkYubZ8GC/Cqxu6WvJZII93dcCw46GdNqdpTeF/tiMOuDGB9z/NI6NvyWetGPM0g+bVNeovBmamHXWj0nCbEaGeTMN2PWrqd6cM26ZxP2DeJvj+ph/30Zi/GmRbtlK5SptI+nwGGnvH6gUruT+L16MJHF+58rwNIifTV0vM8+hwMeOXAb6Yx0wXT+b999WXfvn+8/X/F7fWzjdTord5AAAAAElFTkSuQmCC") 50% 50%/80% no-repeat
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-success {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAIVBMVEUAAAD////////////////////////////////////////PIev5AAAACnRSTlMApAPhIFn82wgGv8mVtwAAAKVJREFUSMft0LEJAkEARNFFFEw1NFJb8CKjAy1AEOzAxNw+bEEEg6nyFjbY4LOzcBwX7S/gwUxoTdIn+Jbv4Lv8bx446+kB6VsBtK0B+wbMCKxrwL33wOrVeeChX28n7KTOTjgoEu6DRSYAgAAAAkAmAIAAAAIACQIkMkACAAgAIACAyECBKAOJuCagTJwSUCaUAEMAABEBRwAAEQFLbCJgO4bW+AZKGnktR+jAFAAAAABJRU5ErkJggg==") 50% 50%/85% no-repeat
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-question {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhCQkUEg18vki+AAAETUlEQVRo3s1ZTWhbRxD+VlIuxsLFCYVIIQYVopBDoK5bKDWUBupDMNbJ5FBKg/FBziUQdE9yaC+FHBrwsdCfQ9RTGoLxwWl+DqHEojUFFydxnB9bInZDqOsErBrr6yGvs/ueX97bldTKo4Pe7puZb3Z33s7srIIjMY1jyCEjP6ImvyX8pF64arSHznKC06wzijY5xSKz7YbuYokV2lODsyyxqz3gSY6z6gCuqcpxJluFH+Z8U+D/0jyHoxFUBHgfvsGHIS9WMIUlVFFDFTUAGWSRQRY5HMeBEP6b+Ew9dh/7INd2jGeO59kfKdXP85zbIbfGQVf4sYC3N1hm3lo6zzIbPvk6x+zBk7wQGMEMB5xncIAzAS0XrFySSV72iS1yyBVcdA1x0afrsoUJgdFfY2+z8ADAXl7zz0KcwJiPfZKpVuABgClO+nRG+QIHDdfb4qlWwUXvKW4Z7vi6L4J9vg+vbfCeCeZH2RfOdMOc/HbCA4BvIW6EMQz7XK/ltd+hP+VzR9mgva2YSfyGI17fA7ynnocqeQNFfIJ0oHsdv6CC2+rXGBN6cQdveY3fcVRtmy/HDete+93zy8jA8zV7YkwYMrjHzRddRsCdiVCwwmh6wg9iTNC7Y9XIF1iS7kbUpsvvGEdPuTfSgAEjRpR096x0liPFD/Eqt2NMuBQzB2XhrACAApjFsuQFh9XdGAX70B3oSuNdnMVBaX+sopYxjwVpHFBVACyKTXNoktjD+6Ll8xhenS9MAAkAI/Lux2YNUOs4I413Ypg1SgEAu7kpFvWjaeJe0fJHDGe/cNaZBkekudw8PMA+0fMwlndZeAsJ5KR/qhUDUJCnSiyvRsolkJHGUgvjH8QXDgZopEzKMKDqCKrwEQ4C6MH7GEXC665buLJG8hlQc4LP4paxfJrOqYVYYY2UARfEIazTbgDg2dB98GebzJd54b8L/iWNdLyooeR6CHyZ+6xk0yKxkYg6nEVSUG4VJ9QJ9cxRCxO+9WiOyvgUeexXP1hLGH5nGuBWVtiSp4vqe3VP0UFWI9Wan4Er3v8q7jjPWVtm4FtcQQMrOKO2nOQCM5AyDMi56FDrKHA/1nyppS1ppBpYaE8wciEjGI2AaeM41kI4doDX4XiT3Qm1gevyruCgZg9P8xIv8m1nCzTKq6oiJ9xTMiZ505P5m8cdZ0CnZMVXHVljM7WMBzxpyDxygtdxoCEFTaMIWbZU85UvBjgUMYy0fBaAF8V1Lj9qWQ1aMZ5f4k9r+AGMSkMP1vZoZih6k6sicc5h/OFHM9vDqU/VIU7zJZdYYsKGH4g4nAJMGiXZRds1pVMoZ69RM5vfkbh0qkBhsnS2RLMLilQdL9MBHS9UAh0v1e6CYnXHy/WeeCcvLDwl/9OVze69tPKM+M+v7eJN6OzFpWdEF0ucDbhVNFXadnVrmJFlkVNGTS2M6pzmhMvltfPhnN2B63sVuL7fcNP3D1TSk2ihosPrAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA5LTA5VDIwOjE4OjEzKzAyOjAweOR7nQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wOS0wOVQyMDoxODoxMyswMjowMAm5wyEAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") 50% 50%/85% no-repeat
}

.iziToast.iziToast-theme-dark .iziToast-buttons>a,
.iziToast.iziToast-theme-dark .iziToast-buttons>button,
.iziToast.iziToast-theme-dark .iziToast-buttons>input {
    color: #fff;
    background: rgba(255, 255, 255, .1)
}

.iziToast.iziToast-theme-dark .iziToast-buttons>a:hover,
.iziToast.iziToast-theme-dark .iziToast-buttons>button:hover,
.iziToast.iziToast-theme-dark .iziToast-buttons>input:hover {
    background: rgba(255, 255, 255, .2)
}

.iziToast.iziToast-theme-dark .iziToast-buttons>a:focus,
.iziToast.iziToast-theme-dark .iziToast-buttons>button:focus,
.iziToast.iziToast-theme-dark .iziToast-buttons>input:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, .6)
}

.iziToast.iziToast-color-red {
    background: rgba(255, 175, 180, .9);
    border-color: rgba(255, 175, 180, .9)
}

.iziToast.iziToast-color-orange {
    background: rgba(255, 207, 165, .9);
    border-color: rgba(255, 207, 165, .9)
}

.iziToast.iziToast-color-yellow {
    background: rgba(255, 249, 178, .9);
    border-color: rgba(255, 249, 178, .9)
}

.iziToast.iziToast-color-blue {
    background: rgba(157, 222, 255, .9);
    border-color: rgba(157, 222, 255, .9)
}

.iziToast.iziToast-color-green {
    background: rgba(166, 239, 184, .9);
    border-color: rgba(166, 239, 184, .9)
}

.iziToast.iziToast-layout2 .iziToast-body .iziToast-message,
.iziToast.iziToast-layout2 .iziToast-body .iziToast-texts {
    width: 100%
}

.iziToast.iziToast-layout3 {
    border-radius: 2px
}

.iziToast.iziToast-layout3::after {
    display: none
}

.iziToast .revealIn,
.iziToast.revealIn {
    -webkit-animation: 1s cubic-bezier(.25, 1.6, .25, 1) both iziT-revealIn;
    -moz-animation: 1s cubic-bezier(.25, 1.6, .25, 1) both iziT-revealIn;
    animation: 1s cubic-bezier(.25, 1.6, .25, 1) both iziT-revealIn
}

.iziToast .slideIn,
.iziToast.slideIn {
    -webkit-animation: 1s cubic-bezier(.16, .81, .32, 1) both iziT-slideIn;
    -moz-animation: 1s cubic-bezier(.16, .81, .32, 1) both iziT-slideIn;
    animation: 1s cubic-bezier(.16, .81, .32, 1) both iziT-slideIn
}

.iziToast.bounceInLeft {
    -webkit-animation: .7s ease-in-out both iziT-bounceInLeft;
    animation: .7s ease-in-out both iziT-bounceInLeft
}

.iziToast.bounceInRight {
    -webkit-animation: .85s ease-in-out both iziT-bounceInRight;
    animation: .85s ease-in-out both iziT-bounceInRight
}

.iziToast.bounceInDown {
    -webkit-animation: .7s ease-in-out both iziT-bounceInDown;
    animation: .7s ease-in-out both iziT-bounceInDown
}

.iziToast.bounceInUp {
    -webkit-animation: .7s ease-in-out both iziT-bounceInUp;
    animation: .7s ease-in-out both iziT-bounceInUp
}

.iziToast .fadeIn,
.iziToast-overlay.fadeIn,
.iziToast.fadeIn {
    -webkit-animation: .5s both iziT-fadeIn;
    animation: .5s both iziT-fadeIn
}

.iziToast.fadeInUp {
    -webkit-animation: .7s both iziT-fadeInUp;
    animation: .7s both iziT-fadeInUp
}

.iziToast.fadeInDown {
    -webkit-animation: .7s both iziT-fadeInDown;
    animation: .7s both iziT-fadeInDown
}

.iziToast.fadeInLeft {
    -webkit-animation: .85s cubic-bezier(.25, .8, .25, 1) both iziT-fadeInLeft;
    animation: .85s cubic-bezier(.25, .8, .25, 1) both iziT-fadeInLeft
}

.iziToast.fadeInRight {
    -webkit-animation: .85s cubic-bezier(.25, .8, .25, 1) both iziT-fadeInRight;
    animation: .85s cubic-bezier(.25, .8, .25, 1) both iziT-fadeInRight
}

.iziToast.flipInX {
    -webkit-animation: .85s cubic-bezier(.35, 0, .25, 1) both iziT-flipInX;
    animation: .85s cubic-bezier(.35, 0, .25, 1) both iziT-flipInX
}

.iziToast-overlay.fadeOut,
.iziToast.fadeOut {
    -webkit-animation: .7s both iziT-fadeOut;
    animation: .7s both iziT-fadeOut
}

.iziToast.fadeOutDown {
    -webkit-animation: .7s cubic-bezier(.4, .45, .15, .91) both iziT-fadeOutDown;
    animation: .7s cubic-bezier(.4, .45, .15, .91) both iziT-fadeOutDown
}

.iziToast.fadeOutUp {
    -webkit-animation: .7s cubic-bezier(.4, .45, .15, .91) both iziT-fadeOutUp;
    animation: .7s cubic-bezier(.4, .45, .15, .91) both iziT-fadeOutUp
}

.iziToast.fadeOutLeft {
    -webkit-animation: .5s both iziT-fadeOutLeft;
    animation: .5s both iziT-fadeOutLeft
}

.iziToast.fadeOutRight {
    -webkit-animation: .5s both iziT-fadeOutRight;
    animation: .5s both iziT-fadeOutRight
}

.iziToast.flipOutX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation: .7s cubic-bezier(.4, .45, .15, .91) both iziT-flipOutX;
    animation: .7s cubic-bezier(.4, .45, .15, .91) both iziT-flipOutX
}

@-webkit-keyframes iziT-revealIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, 1)
    }

    100% {
        opacity: 1
    }
}

@-moz-keyframes iziT-revealIn {
    0% {
        opacity: 0;
        -moz-transform: scale3d(.3, .3, 1)
    }

    100% {
        opacity: 1
    }
}

@-webkit-keyframes iziT-slideIn {
    0% {
        opacity: 0;
        -webkit-transform: translateX(50px)
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes iziT-slideIn {
    0% {
        opacity: 0;
        -moz-transform: translateX(50px)
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0)
    }
}

@-webkit-keyframes iziT-bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(280px)
    }

    50% {
        opacity: 1;
        -webkit-transform: translateX(-20px)
    }

    70% {
        -webkit-transform: translateX(10px)
    }

    100% {
        -webkit-transform: translateX(0)
    }
}

@-webkit-keyframes iziT-bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-280px)
    }

    50% {
        opacity: 1;
        -webkit-transform: translateX(20px)
    }

    70% {
        -webkit-transform: translateX(-10px)
    }

    100% {
        -webkit-transform: translateX(0)
    }
}

@-webkit-keyframes iziT-bounceInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-200px)
    }

    50% {
        opacity: 1;
        -webkit-transform: translateY(10px)
    }

    70% {
        -webkit-transform: translateY(-5px)
    }

    100% {
        -webkit-transform: translateY(0)
    }
}

@-webkit-keyframes iziT-bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(200px)
    }

    50% {
        opacity: 1;
        -webkit-transform: translateY(-10px)
    }

    70% {
        -webkit-transform: translateY(5px)
    }

    100% {
        -webkit-transform: translateY(0)
    }
}

@-moz-keyframes iziT-revealIn {
    0% {
        opacity: 0;
        transform: scale3d(.3, .3, 1)
    }

    100% {
        opacity: 1
    }
}

@-webkit-keyframes iziT-revealIn {
    0% {
        opacity: 0;
        transform: scale3d(.3, .3, 1)
    }

    100% {
        opacity: 1
    }
}

@-o-keyframes iziT-revealIn {
    0% {
        opacity: 0;
        transform: scale3d(.3, .3, 1)
    }

    100% {
        opacity: 1
    }
}

@keyframes iziT-revealIn {
    0% {
        opacity: 0;
        transform: scale3d(.3, .3, 1)
    }

    100% {
        opacity: 1
    }
}

@-moz-keyframes iziT-slideIn {
    0% {
        opacity: 0;
        transform: translateX(50px)
    }

    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@-webkit-keyframes iziT-slideIn {
    0% {
        opacity: 0;
        transform: translateX(50px)
    }

    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@-o-keyframes iziT-slideIn {
    0% {
        opacity: 0;
        transform: translateX(50px)
    }

    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes iziT-slideIn {
    0% {
        opacity: 0;
        transform: translateX(50px)
    }

    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@-moz-keyframes iziT-bounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(280px)
    }

    50% {
        opacity: 1;
        transform: translateX(-20px)
    }

    70% {
        transform: translateX(10px)
    }

    100% {
        transform: translateX(0)
    }
}

@-webkit-keyframes iziT-bounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(280px)
    }

    50% {
        opacity: 1;
        transform: translateX(-20px)
    }

    70% {
        transform: translateX(10px)
    }

    100% {
        transform: translateX(0)
    }
}

@-o-keyframes iziT-bounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(280px)
    }

    50% {
        opacity: 1;
        transform: translateX(-20px)
    }

    70% {
        transform: translateX(10px)
    }

    100% {
        transform: translateX(0)
    }
}

@keyframes iziT-bounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(280px)
    }

    50% {
        opacity: 1;
        transform: translateX(-20px)
    }

    70% {
        transform: translateX(10px)
    }

    100% {
        transform: translateX(0)
    }
}

@-moz-keyframes iziT-bounceInRight {
    0% {
        opacity: 0;
        transform: translateX(-280px)
    }

    50% {
        opacity: 1;
        transform: translateX(20px)
    }

    70% {
        transform: translateX(-10px)
    }

    100% {
        transform: translateX(0)
    }
}

@-webkit-keyframes iziT-bounceInRight {
    0% {
        opacity: 0;
        transform: translateX(-280px)
    }

    50% {
        opacity: 1;
        transform: translateX(20px)
    }

    70% {
        transform: translateX(-10px)
    }

    100% {
        transform: translateX(0)
    }
}

@-o-keyframes iziT-bounceInRight {
    0% {
        opacity: 0;
        transform: translateX(-280px)
    }

    50% {
        opacity: 1;
        transform: translateX(20px)
    }

    70% {
        transform: translateX(-10px)
    }

    100% {
        transform: translateX(0)
    }
}

@keyframes iziT-bounceInRight {
    0% {
        opacity: 0;
        transform: translateX(-280px)
    }

    50% {
        opacity: 1;
        transform: translateX(20px)
    }

    70% {
        transform: translateX(-10px)
    }

    100% {
        transform: translateX(0)
    }
}

@-moz-keyframes iziT-bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-200px)
    }

    50% {
        opacity: 1;
        transform: translateY(10px)
    }

    70% {
        transform: translateY(-5px)
    }

    100% {
        transform: translateY(0)
    }
}

@-webkit-keyframes iziT-bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-200px)
    }

    50% {
        opacity: 1;
        transform: translateY(10px)
    }

    70% {
        transform: translateY(-5px)
    }

    100% {
        transform: translateY(0)
    }
}

@-o-keyframes iziT-bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-200px)
    }

    50% {
        opacity: 1;
        transform: translateY(10px)
    }

    70% {
        transform: translateY(-5px)
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes iziT-bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-200px)
    }

    50% {
        opacity: 1;
        transform: translateY(10px)
    }

    70% {
        transform: translateY(-5px)
    }

    100% {
        transform: translateY(0)
    }
}

@-moz-keyframes iziT-bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(200px)
    }

    50% {
        opacity: 1;
        transform: translateY(-10px)
    }

    70% {
        transform: translateY(5px)
    }

    100% {
        transform: translateY(0)
    }
}

@-webkit-keyframes iziT-bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(200px)
    }

    50% {
        opacity: 1;
        transform: translateY(-10px)
    }

    70% {
        transform: translateY(5px)
    }

    100% {
        transform: translateY(0)
    }
}

@-o-keyframes iziT-bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(200px)
    }

    50% {
        opacity: 1;
        transform: translateY(-10px)
    }

    70% {
        transform: translateY(5px)
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes iziT-bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(200px)
    }

    50% {
        opacity: 1;
        transform: translateY(-10px)
    }

    70% {
        transform: translateY(5px)
    }

    100% {
        transform: translateY(0)
    }
}

@-moz-keyframes iziT-fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes iziT-fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-o-keyframes iziT-fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes iziT-fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-moz-keyframes iziT-fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-webkit-keyframes iziT-fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-o-keyframes iziT-fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes iziT-fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-moz-keyframes iziT-fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-webkit-keyframes iziT-fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-o-keyframes iziT-fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes iziT-fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-moz-keyframes iziT-fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-webkit-keyframes iziT-fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-o-keyframes iziT-fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes iziT-fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-moz-keyframes iziT-fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-webkit-keyframes iziT-fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-o-keyframes iziT-fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes iziT-fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-moz-keyframes iziT-flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg)
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@-webkit-keyframes iziT-flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg)
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@-o-keyframes iziT-flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg)
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@keyframes iziT-flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg)
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@-moz-keyframes iziT-fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes iziT-fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-o-keyframes iziT-fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes iziT-fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-moz-keyframes iziT-fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@-webkit-keyframes iziT-fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@-o-keyframes iziT-fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes iziT-fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@-moz-keyframes iziT-fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@-webkit-keyframes iziT-fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@-o-keyframes iziT-fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@keyframes iziT-fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@-moz-keyframes iziT-fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-200px, 0, 0);
        transform: translate3d(-200px, 0, 0)
    }
}

@-webkit-keyframes iziT-fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-200px, 0, 0);
        transform: translate3d(-200px, 0, 0)
    }
}

@-o-keyframes iziT-fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-200px, 0, 0);
        transform: translate3d(-200px, 0, 0)
    }
}

@keyframes iziT-fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-200px, 0, 0);
        transform: translate3d(-200px, 0, 0)
    }
}

@-moz-keyframes iziT-fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(200px, 0, 0);
        transform: translate3d(200px, 0, 0)
    }
}

@-webkit-keyframes iziT-fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(200px, 0, 0);
        transform: translate3d(200px, 0, 0)
    }
}

@-o-keyframes iziT-fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(200px, 0, 0);
        transform: translate3d(200px, 0, 0)
    }
}

@keyframes iziT-fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(200px, 0, 0);
        transform: translate3d(200px, 0, 0)
    }
}

@-moz-keyframes iziT-flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

@-webkit-keyframes iziT-flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

@-o-keyframes iziT-flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

@keyframes iziT-flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

/**
 *  Datepicker
 *
 */
// @import '~flatpickr/dist/flatpickr.min.css';
// @import '~flatpickr/dist/themes/airbnb.css';

// If you have many custom styles, you can create and import additional files
// For example you can create _component1.scss and include it as follows:
// @import 'component1';
.beta:after {
    content: "Ambiente de Teste";
    position: fixed;
    width: 100%;
    height: 25px;
    background: #EE8E4A;
    top: 0px;
    left: 0px;
    text-align: center;
    font-size: 13px;
    font-family: sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    line-height: 27px;
    display: block;
    // transform: rotate(-45deg);
    z-index: 99999;
}

[v-cloak]>* {
    display: none;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

[v-cloak]::before {
    /*content: "loading...";*/
    content: '';
    box-sizing: border-box;
    position: absolute;
    display: inline-flex;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-top: -15px;
    margin-left: -15px;
    margin-bottom: 50px;
    border-radius: 50%;
    /*border: 1px solid #ccc;
        border-top-color: #07d;*/
    border-top: 2px solid #07d;
    border-right: 2px solid transparent;
    animation: spinner .6s linear infinite;
}

.iziToast.iziToast-color-green {
    background: rgba(103, 143, 54, .9) !important;
    border-color: rgba(166, 239, 184, .9) !important;
}

.iziToast.iziToast-color-orange {
    background: rgba(255, 140, 66, .9) !important;
    border-color: rgba(255, 110, 46, .9) !important;
}

.iziToast.iziToast-color-red {
    background: rgba(247, 23, 53, .9) !important;
    border-color: rgba(255, 175, 180, .9) !important;
}

.iziToast.iziToast-color-blue {
    background: rgba(2, 128, 144, .9) !important;
    border-color: rgba(157, 222, 255, .9) !important;
}

// sweet alert
// Swal
// swal2-shown
// Blurred backdrop
body.swal2-shown>[aria-hidden="true"] {
    transition: 0.1s filter;
    filter: blur(10px);
}
/*
 * autocomplete
 */
.aa-input-container {
    position: relative;
}

.algolia-autocomplete {
    width: 100%;
}

.algolia-autocomplete .aa-input,
.algolia-autocomplete .aa-hint {
    width: 100%;
}

.aa-input-search {
    width: 100%;
    padding: 6px 28px 12px 12px;
    border: 2px solid #e4e4e4;
    border-radius: 4px;
    -webkit-transition: .2s;
    transition: .2s;
    box-shadow: 4px 4px 0 rgba(241, 241, 241, 0.35);
    font-size: 11px;
    box-sizing: border-box;
    color: #333;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.aa-input-search::-webkit-search-decoration,
.aa-input-search::-webkit-search-cancel-button,
.aa-input-search::-webkit-search-results-button,
.aa-input-search::-webkit-search-results-decoration {
    display: none;
}

.aa-input-search:focus {
    outline: 0;
    border-color: #3a96cf;
    box-shadow: 4px 4px 0 rgba(58, 150, 207, 0.1);
}

.aa-input-icon {
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    fill: #e4e4e4;
    pointer-events: none;
}

.aa-hint {
    color: #65728f;
}

.aa-dropdown-menu {
    background-color: #fff;
    border: 2px solid rgba(228, 228, 228, 0.6);
    border-top-width: 0;
    width: 100%;
    top: 1px;
    // margin-top: 10px;
    box-shadow: 4px 4px 0 rgba(241, 241, 241, 0.35);
    font-size: 10px;
    border-radius: 4px;
    box-sizing: border-box;
}

.aa-suggestion {
    text-transform: uppercase;
    padding: 6px 12px;
    cursor: pointer;
    -webkit-transition: .2s;
    transition: .2s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.375rem;
}

.aa-suggestion>.title {
    margin: 0;
    font-size: .675rem;
    font-weight: 600;
    flex-basis: 100%;
    text-transform: uppercase;
}

.aa-suggestion>.break {
    flex-basis: 100%;
    height: 0;
    width: 100%;
}

.aa-suggestion:hover,
.aa-suggestion.aa-cursor {
    background-color: #B2D7FF;
}

.aa-suggestion>span:first-child {
    color: #333;
}

.aa-suggestion>span:last-child {
    text-transform: uppercase;
    color: #a9a9a9;
}

.aa-suggestions-category {
    text-transform: uppercase;
    border-bottom: 2px solid rgba(228, 228, 228, 0.6);
    border-top: 2px solid rgba(228, 228, 228, 0.6);
    padding: 6px 12px;
    color: #a9a9a9;
}

.aa-suggestion>span:first-child em,
.aa-suggestion>span:last-child em {
    font-weight: 700;
    font-style: normal;
    background-color: rgba(58, 150, 207, 0.1);
    padding: 2px 0 2px 2px;
}

.nav-main-horizontal-workflow {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
}

.autocomplete {
    position: relative;
    width: 100%;
}

.autocomplete-results {
    padding: 0;
    margin: 0;
    overflow: auto;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 200px;
    position: absolute;
    top: 100%;
    z-index: 9999;
    left: 0px;
    right: auto;
    display: block;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    cursor: pointer;
    padding: 3px 2px;
}

.autocomplete-text {
    flex: 1 1 auto;
    min-height: 1.25rem;
    margin: 0;
    font-size: 0.6rem;
    text-transform: uppercase;
    /* letter-spacing: .0312rem; */
}

.autocomplete-result.is-active {
    background-color: #8bafd6;
    color: #000;
}

.autocomplete-result:hover {
    background-color: #B2D7FF;
    border-radius: 0.3rem;
    padding: 0.25rem !important;
    color: #000;
}



// .autocomplete
// ----------------
// 1.  CSS Variables
// 2.  Dark Mode
// 3.  Autocomplete
// 4.  Panel
// 5.  Sources
// 6.  Hit Layout
// 7.  Panel Header
// 8.  Panel Footer
// 9.  Detached Mode
// 10. Gradients
// 11. Utilities
// ----------------

// Note:
// This theme reflects the markup structure of autocomplete with SCSS indentation.
// We use the SASS `@at-root` function to keep specificity low.

// ----------------
// 1. CSS Variables
// ----------------
:root {
    // Input
    --aa-search-input-height: 44px;
    --aa-input-icon-size: 20px;

    // Size and spacing
    --aa-base-unit: 16;
    --aa-spacing-factor: 1;
    --aa-spacing: calc(var(--aa-base-unit) * var(--aa-spacing-factor) * 1px);
    --aa-spacing-half: calc(var(--aa-spacing) / 2);
    --aa-panel-max-height: 650px;

    // Z-index
    --aa-base-z-index: 9999;

    // Font
    --aa-font-size: calc(var(--aa-base-unit) * 1px);
    --aa-font-family: inherit;
    --aa-font-weight-medium: 500;
    --aa-font-weight-semibold: 600;
    --aa-font-weight-bold: 700;

    // Icons
    --aa-icon-size: 20px;
    --aa-icon-stroke-width: 1.6;
    --aa-icon-color-rgb: 119, 119, 163;
    --aa-icon-color-alpha: 1;
    --aa-action-icon-size: 20px;

    // Text colors
    --aa-text-color-rgb: 38, 38, 39;
    --aa-text-color-alpha: 1;
    --aa-primary-color-rgb: 62, 52, 211;
    --aa-primary-color-alpha: 0.2;
    --aa-muted-color-rgb: 128, 126, 163;
    --aa-muted-color-alpha: 0.6;

    // Border colors
    --aa-panel-border-color-rgb: 128, 126, 163;
    --aa-panel-border-color-alpha: 0.3;
    --aa-input-border-color-rgb: 128, 126, 163;
    --aa-input-border-color-alpha: 0.8;

    // Background colors
    --aa-background-color-rgb: 255, 255, 255;
    --aa-background-color-alpha: 1;
    --aa-input-background-color-rgb: 255, 255, 255;
    --aa-input-background-color-alpha: 1;
    --aa-selected-color-rgb: 179, 173, 214;
    --aa-selected-color-alpha: 0.205;
    --aa-description-highlight-background-color-rgb: 245, 223, 77;
    --aa-description-highlight-background-color-alpha: 0.5;

    // Detached mode
    --aa-detached-media-query: (max-width: 680px);
    --aa-detached-modal-media-query: (min-width: 680px);
    --aa-detached-modal-max-width: 680px;
    --aa-detached-modal-max-height: 500px;
    --aa-overlay-color-rgb: 115, 114, 129;
    --aa-overlay-color-alpha: 0.4;

    // Shadows
    --aa-panel-shadow: 0 0 0 1px rgba(35, 38, 59, 0.1),
        0 6px 16px -4px rgba(35, 38, 59, 0.15);

    // Scrollbar
    --aa-scrollbar-width: 13px;
    --aa-scrollbar-track-background-color-rgb: 234, 234, 234;
    --aa-scrollbar-track-background-color-alpha: 1;
    --aa-scrollbar-thumb-background-color-rgb: var(--aa-background-color-rgb);
    --aa-scrollbar-thumb-background-color-alpha: 1;

    // Touch screens
    @media (hover: none) and (pointer: coarse) {
        --aa-spacing-factor: 1.2;
        --aa-action-icon-size: 22px;
    }
}

// ----------------
// 2. Dark Mode
// ----------------
body {

    /* stylelint-disable selector-no-qualifying-type, selector-class-pattern */
    &[data-theme='dark'],
    &.dark {
        // Text colors
        --aa-text-color-rgb: 183, 192, 199;
        --aa-primary-color-rgb: 146, 138, 255;
        --aa-muted-color-rgb: 146, 138, 255;

        // Background colors
        --aa-input-background-color-rgb: 0, 3, 9;
        --aa-background-color-rgb: 21, 24, 42;
        --aa-selected-color-rgb: 146, 138, 255;
        --aa-selected-color-alpha: 0.25;
        --aa-description-highlight-background-color-rgb: 0 255 255;
        --aa-description-highlight-background-color-alpha: 0.25;

        // Icons
        --aa-icon-color-rgb: 119, 119, 163;

        // Shadows
        --aa-panel-shadow: inset 1px 1px 0 0 rgb(44, 46, 64),
            0 3px 8px 0 rgb(0, 3, 9);

        // Scrollbar
        --aa-scrollbar-track-background-color-rgb: 44, 46, 64;
        --aa-scrollbar-thumb-background-color-rgb: var(--aa-background-color-rgb);
    }

    /* stylelint-enable selector-no-qualifying-type, selector-class-pattern */
}

// Reset for `@extend`
%reset {
    box-sizing: border-box;
}

// Init for `@extend`
%init {
    color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
    font-family: var(--aa-font-family);
    font-size: var(--aa-font-size);
    font-weight: normal;
    line-height: 1em;
    margin: 0;
    padding: 0;
    text-align: left;
}

// ----------------
// 3. Autocomplete
// ----------------
.aa-Autocomplete,
.aa-DetachedFormContainer {
    @extend %init;

    * {
        @extend %reset;
    }

    // Search box
    @at-root .aa-Form {
        align-items: center;
        background-color: rgba(var(--aa-input-background-color-rgb),
                var(--aa-input-background-color-alpha));
        border: 1px solid rgba(var(--aa-input-border-color-rgb), var(--aa-input-border-color-alpha));
        border-radius: 3px;
        display: flex;
        line-height: 1em;
        margin: 0;
        position: relative;
        width: 100%;

        &:focus-within {
            border-color: rgba(var(--aa-primary-color-rgb), 1);
            box-shadow: rgba(var(--aa-primary-color-rgb),
                    var(--aa-primary-color-alpha)) 0 0 0 2px,
                inset rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha)) 0 0 0 2px;
            outline: currentColor none medium;
        }

        @at-root .aa-InputWrapperPrefix {
            align-items: center;
            display: flex;
            flex-shrink: 0;
            height: var(--aa-search-input-height);
            order: 1;

            // Container for search and loading icons
            @at-root .aa-Label,
            .aa-LoadingIndicator {
                cursor: initial;
                flex-shrink: 0;
                height: 100%;
                padding: 0;
                text-align: left;

                svg {
                    color: rgba(var(--aa-primary-color-rgb), 1);
                    height: auto;
                    max-height: var(--aa-input-icon-size);
                    stroke-width: var(--aa-icon-stroke-width);
                    width: var(--aa-input-icon-size);
                }
            }

            @at-root .aa-SubmitButton,
            .aa-LoadingIndicator {
                height: 100%;
                padding-left: calc(var(--aa-spacing) * 0.75 - 1px);
                padding-right: var(--aa-spacing-half);
                width: calc(var(--aa-spacing) * 1.75 + var(--aa-icon-size) - 1px);

                @media (hover: none) and (pointer: coarse) {
                    padding-left: calc(var(--aa-spacing-half) / 2 - 1px);
                    width: calc(var(--aa-icon-size) + (var(--aa-spacing) * 1.25) - 1px);
                }
            }

            @at-root .aa-SubmitButton {
                appearance: none;
                background: none;
                border: 0;
                margin: 0;
            }

            @at-root .aa-LoadingIndicator {
                align-items: center;
                display: flex;
                justify-content: center;

                &[hidden] {
                    display: none;
                }
            }
        }

        @at-root .aa-InputWrapper {
            order: 3;
            position: relative;
            width: 100%;

            // Search box input (with placeholder and query)
            @at-root .aa-Input {
                appearance: none;
                background: none;
                border: 0;
                color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
                font: inherit;
                height: var(--aa-search-input-height);
                padding: 0;
                width: 100%;

                &::placeholder {
                    color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
                    opacity: 1;
                }

                // Focus is set and styled on the parent, it isn't necessary here
                &:focus {
                    border-color: none;
                    box-shadow: none;
                    outline: none;
                }

                // Remove native appearence
                &::-webkit-search-decoration,
                &::-webkit-search-cancel-button,
                &::-webkit-search-results-button,
                &::-webkit-search-results-decoration {
                    appearance: none;
                }
            }
        }

        @at-root .aa-InputWrapperSuffix {
            align-items: center;
            display: flex;
            height: var(--aa-search-input-height);
            order: 4;

            // Accelerator to clear the query
            @at-root .aa-ClearButton {
                align-items: center;
                background: none;
                border: 0;
                color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
                cursor: pointer;
                display: flex;
                height: 100%;
                margin: 0;
                padding: 0 calc(var(--aa-spacing) * (5 / 6) - 0.5px);

                @media (hover: none) and (pointer: coarse) {
                    padding: 0 calc(var(--aa-spacing) * (2 / 3) - 0.5px);
                }

                &:hover,
                &:focus {
                    color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
                }

                &[hidden] {
                    display: none;
                }

                svg {
                    stroke-width: var(--aa-icon-stroke-width);
                    width: var(--aa-icon-size);
                }
            }
        }
    }
}

// ----------------
// 4. Panel
// ----------------
.aa-Panel {
    @extend %init;

    background-color: rgba(var(--aa-background-color-rgb),
            var(--aa-background-color-alpha));
    border-radius: calc(var(--aa-spacing) / 4);
    box-shadow: var(--aa-panel-shadow);
    margin: 8px 0 0;
    overflow: hidden;
    position: absolute;
    transition: opacity 200ms ease-in, filter 200ms ease-in;

    @media screen and (prefers-reduced-motion) {
        transition: none;
    }

    * {
        @extend %reset;
    }

    button {
        appearance: none;
        background: none;
        border: 0;
        margin: 0;
        padding: 0;
    }

    @at-root .aa-PanelLayout {
        height: 100%;
        margin: 0;
        max-height: var(--aa-panel-max-height);
        overflow-y: auto;
        padding: 0;
        position: relative;
        text-align: left;

        @at-root .aa-PanelLayoutColumns--twoGolden {
            display: grid;
            grid-template-columns: 39.2% auto;
            overflow: hidden;
            padding: 0;
        }

        @at-root .aa-PanelLayoutColumns--two {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            overflow: hidden;
            padding: 0;
        }

        @at-root .aa-PanelLayoutColumns--three {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            overflow: hidden;
            padding: 0;
        }
    }

    // When a request isn't resolved yet
    @at-root .aa-Panel--stalled {
        .aa-Source {
            filter: grayscale(1);
            opacity: 0.8;
        }
    }

    @at-root .aa-Panel--scrollable {
        margin: 0;
        max-height: var(--aa-panel-max-height);
        overflow-x: hidden;
        overflow-y: auto;
        padding: var(--aa-spacing-half);
        scrollbar-color: rgba(var(--aa-scrollbar-thumb-background-color-rgb),
                var(--aa-scrollbar-thumb-background-color-alpha)) rgba(var(--aa-scrollbar-track-background-color-rgb),
                var(--aa-scrollbar-track-background-color-alpha));
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: var(--aa-scrollbar-width);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(var(--aa-scrollbar-track-background-color-rgb),
                    var(--aa-scrollbar-track-background-color-alpha));
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--aa-scrollbar-thumb-background-color-rgb),
                    var(--aa-scrollbar-thumb-background-color-alpha));
            border-color: rgba(var(--aa-scrollbar-track-background-color-rgb),
                    var(--aa-scrollbar-track-background-color-alpha));
            border-radius: 9999px;
            border-style: solid;
            border-width: 3px 2px 3px 3px;
        }
    }
}

// ----------------
// 5. Sources
// Each source can be styled independently
// ----------------
.aa-Source {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;

    &:empty {
        // Hide empty section
        display: none;
    }

    @at-root .aa-SourceNoResults {
        font-size: 1em;
        margin: 0;
        padding: var(--aa-spacing);
    }

    // List of results inside the source
    @at-root .aa-List {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
    }

    // Source title
    @at-root .aa-SourceHeader {
        margin: var(--aa-spacing-half) 0.5em var(--aa-spacing-half) 0;
        padding: 0;
        position: relative;

        // Hide empty header
        &:empty {
            display: none;
        }

        // Title typography
        @at-root .aa-SourceHeaderTitle {
            background: rgba(var(--aa-background-color-rgb),
                    var(--aa-background-color-alpha));
            color: rgba(var(--aa-primary-color-rgb), 1);
            display: inline-block;
            font-size: 0.8em;
            font-weight: var(--aa-font-weight-semibold);
            margin: 0;
            padding: 0 var(--aa-spacing-half) 0 0;
            position: relative;
            z-index: var(--aa-base-z-index);
        }

        // Line separator
        @at-root .aa-SourceHeaderLine {
            border-bottom: solid 1px rgba(var(--aa-primary-color-rgb), 1);
            display: block;
            height: 2px;
            left: 0;
            margin: 0;
            opacity: 0.3;
            padding: 0;
            position: absolute;
            right: 0;
            top: var(--aa-spacing-half);
            z-index: calc(var(--aa-base-z-index) - 1);
        }
    }

    // See all button
    @at-root .aa-SourceFooterSeeAll {
        background: linear-gradient(180deg,
                rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha)),
                rgba(128, 126, 163, 0.14));
        border: 1px solid rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
        border-radius: 5px;
        box-shadow: inset 0 0 2px #fff, 0 2px 2px -1px rgba(76, 69, 88, 0.15);
        color: inherit;
        font-size: 0.95em;
        font-weight: var(--aa-font-weight-medium);
        padding: 0.475em 1em 0.6em;
        text-decoration: none;

        &:focus,
        &:hover {
            border: 1px solid rgba(var(--aa-primary-color-rgb), 1);
            color: rgba(var(--aa-primary-color-rgb), 1);
        }
    }
}

// ----------------
// 6. Hit Layout
// ----------------
.aa-Item {
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    display: grid;
    min-height: calc(var(--aa-spacing) * 2.5);
    padding: calc(var(--aa-spacing-half) / 2);

    // When the result is active
    &[aria-selected='true'] {
        background-color: rgba(var(--aa-selected-color-rgb),
                var(--aa-selected-color-alpha));

        .aa-ItemActionButton,
        .aa-ActiveOnly {
            visibility: visible;
        }
    }

    // The result type icon inlined SVG or image
    @at-root .aa-ItemIcon {
        align-items: center;
        background: rgba(var(--aa-background-color-rgb),
                var(--aa-background-color-alpha));
        border-radius: 3px;
        box-shadow: inset 0 0 0 1px rgba(var(--aa-panel-border-color-rgb), var(--aa-panel-border-color-alpha));
        color: rgba(var(--aa-icon-color-rgb), var(--aa-icon-color-alpha));
        display: flex;
        flex-shrink: 0;
        font-size: 0.7em;
        height: calc(var(--aa-icon-size) + var(--aa-spacing-half));
        justify-content: center;
        overflow: hidden;
        stroke-width: var(--aa-icon-stroke-width);
        text-align: center;
        width: calc(var(--aa-icon-size) + var(--aa-spacing-half));

        img {
            height: auto;
            max-height: calc(var(--aa-icon-size) + var(--aa-spacing-half) - 8px);
            max-width: calc(var(--aa-icon-size) + var(--aa-spacing-half) - 8px);
            width: auto;
        }

        svg {
            height: var(--aa-icon-size);
            width: var(--aa-icon-size);
        }

        @at-root .aa-ItemIcon--alignTop {
            align-self: flex-start;
        }

        @at-root .aa-ItemIcon--noBorder {
            background: none;
            box-shadow: none;
        }

        @at-root .aa-ItemIcon--picture {
            height: 96px;
            width: 96px;

            img {
                max-height: 100%;
                max-width: 100%;
                padding: var(--aa-spacing-half);
            }
        }
    }

    @at-root .aa-ItemContent {
        align-items: center;
        cursor: pointer;
        display: grid;
        gap: var(--aa-spacing-half);
        grid-auto-flow: column;
        line-height: 1.25em;
        overflow: hidden;

        &:empty {
            display: none;
        }

        mark {
            background: none;
            color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
            font-style: normal;
            font-weight: var(--aa-font-weight-bold);
        }

        @at-root .aa-ItemContent--dual {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;

            .aa-ItemContentTitle,
            .aa-ItemContentSubtitle {
                display: block;
            }
        }

        @at-root .aa-ItemContent--indented {
            padding-left: calc(var(--aa-icon-size) + var(--aa-spacing));
        }

        @at-root .aa-ItemContentBody {
            display: grid;
            gap: calc(var(--aa-spacing-half) / 2);
        }

        @at-root .aa-ItemContentTitle {
            display: inline-block;
            margin: 0 0.5em 0 0;
            max-width: 100%;
            overflow: hidden;
            padding: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        @at-root .aa-ItemContentSubtitle {
            font-size: 0.92em;

            @at-root .aa-ItemContentSubtitleIcon {
                &::before {
                    border-color: rgba(var(--aa-muted-color-rgb), 0.64);
                    border-style: solid;
                    content: '';
                    display: inline-block;
                    left: 1px;
                    position: relative;
                    top: -3px;
                }
            }

            @at-root .aa-ItemContentSubtitle--inline {
                .aa-ItemContentSubtitleIcon {
                    &::before {
                        border-width: 0 0 1.5px;
                        margin-left: var(--aa-spacing-half);
                        margin-right: calc(var(--aa-spacing-half) / 2);
                        width: calc(var(--aa-spacing-half) + 2px);
                    }
                }
            }

            @at-root .aa-ItemContentSubtitle--standalone {
                align-items: center;
                color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
                display: grid;
                gap: var(--aa-spacing-half);
                grid-auto-flow: column;
                justify-content: start;

                .aa-ItemContentSubtitleIcon {
                    &::before {
                        border-radius: 0 0 0 3px;
                        border-width: 0 0 1.5px 1.5px;
                        height: var(--aa-spacing-half);
                        width: var(--aa-spacing-half);
                    }
                }
            }

            @at-root .aa-ItemContentSubtitleCategory {
                color: rgba(var(--aa-muted-color-rgb), 1);
                font-weight: 500;
            }
        }

        @at-root .aa-ItemContentDescription {
            color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
            font-size: 0.85em;
            max-width: 100%;
            overflow-x: hidden;
            text-overflow: ellipsis;

            &:empty {
                display: none;
            }

            mark {
                background: rgba(var(--aa-description-highlight-background-color-rgb),
                        var(--aa-description-highlight-background-color-alpha));
                color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
                font-style: normal;
                font-weight: var(--aa-font-weight-medium);
            }
        }

        @at-root .aa-ItemContentDash {
            color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
            display: none;
            opacity: 0.4;
        }

        @at-root .aa-ItemContentTag {
            background-color: rgba(var(--aa-primary-color-rgb),
                    var(--aa-primary-color-alpha));
            border-radius: 3px;
            margin: 0 0.4em 0 0;
            padding: 0.08em 0.3em;
        }
    }

    // wrap hit with url but we don't need to see it
    @at-root .aa-ItemWrapper,
    .aa-ItemLink {
        align-items: center;
        color: inherit;
        display: grid;
        gap: calc(var(--aa-spacing-half) / 2);
        grid-auto-flow: column;
        justify-content: space-between;
        width: 100%;
    }

    @at-root .aa-ItemLink {
        color: inherit;
        text-decoration: none;
    }

    // Secondary click actions
    @at-root .aa-ItemActions {
        display: grid;
        grid-auto-flow: column;
        height: 100%;
        justify-self: end;
        margin: 0 calc(var(--aa-spacing) / -3);
        padding: 0 2px 0 0;
    }

    @at-root .aa-ItemActionButton {
        align-items: center;
        background: none;
        border: 0;
        color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
        cursor: pointer;
        display: flex;
        flex-shrink: 0;
        padding: 0;

        &:hover,
        &:focus {
            svg {
                color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));

                @media (hover: none) and (pointer: coarse) {
                    color: inherit;
                }
            }
        }

        svg {
            color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
            margin: 0;
            margin: calc(var(--aa-spacing) / 3);
            stroke-width: var(--aa-icon-stroke-width);
            width: var(--aa-action-icon-size);
        }
    }

    @at-root .aa-ActiveOnly {
        visibility: hidden;
    }
}

//----------------
// 7. Panel Header
//----------------
.aa-PanelHeader {
    align-items: center;
    background: rgba(var(--aa-primary-color-rgb), 1);
    color: #fff;
    display: grid;
    height: var(--aa-modal-header-height);
    margin: 0;
    padding: var(--aa-spacing-half) var(--aa-spacing);
    position: relative;

    &::after {
        background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 1),
                rgba(var(--aa-background-color-rgb), 0));
        bottom: calc(var(--aa-spacing-half) * -1);
        content: '';
        height: var(--aa-spacing-half);
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        z-index: var(--aa-base-z-index);
    }
}

//----------------
// 8. Panel Footer
//----------------
.aa-PanelFooter {
    background-color: rgba(var(--aa-background-color-rgb),
            var(--aa-background-color-alpha));
    box-shadow: inset 0 1px 0 rgba(var(--aa-panel-border-color-rgb), var(--aa-panel-border-color-alpha));
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: var(--aa-spacing);
    position: relative;
    z-index: var(--aa-base-z-index);

    &::after {
        background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 0),
                rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha)));
        content: '';
        height: var(--aa-spacing);
        left: 0;
        opacity: 0.12;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: calc(var(--aa-spacing) * -1);
        z-index: calc(var(--aa-base-z-index) - 1);
    }
}

//----------------
// 9. Detached Mode
//----------------
.aa-DetachedContainer {
    background: rgba(var(--aa-background-color-rgb),
            var(--aa-background-color-alpha));
    bottom: 0;
    box-shadow: var(--aa-panel-shadow);
    display: flex;
    flex-direction: column;
    left: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: var(--aa-base-z-index);

    &::after {
        height: 32px;
    }

    .aa-SourceHeader {
        margin: var(--aa-spacing-half) 0 var(--aa-spacing-half) 2px;
    }

    .aa-Panel {
        background-color: rgba(var(--aa-background-color-rgb),
                var(--aa-background-color-alpha));
        border-radius: 0;
        box-shadow: none;
        flex-grow: 1;
        margin: 0;
        padding: 0;
        position: relative;
    }

    .aa-PanelLayout {
        bottom: 0;
        box-shadow: none;
        left: 0;
        margin: 0;
        max-height: none;
        overflow-y: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

    @at-root .aa-DetachedFormContainer {
        border-bottom: solid 1px rgba(var(--aa-panel-border-color-rgb), var(--aa-panel-border-color-alpha));
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        padding: var(--aa-spacing-half);

        @at-root .aa-DetachedCancelButton {
            background: none;
            border: 0;
            border-radius: 3px;
            color: inherit;
            color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
            cursor: pointer;
            font: inherit;
            margin: 0 0 0 var(--aa-spacing-half);
            padding: 0 var(--aa-spacing-half);

            &:hover,
            &:focus {
                box-shadow: inset 0 0 0 1px rgba(var(--aa-panel-border-color-rgb),
                        var(--aa-panel-border-color-alpha));
            }
        }
    }

    @at-root .aa-DetachedContainer--modal {
        border-radius: 6px;
        bottom: inherit;
        height: auto;
        margin: 0 auto;
        max-width: var(--aa-detached-modal-max-width);
        position: absolute;
        top: 3%;

        .aa-PanelLayout {
            max-height: var(--aa-detached-modal-max-height);
            padding-bottom: var(--aa-spacing-half);
            position: static;
        }
    }
}

// Search Button
.aa-DetachedSearchButton {
    align-items: center;
    background-color: rgba(var(--aa-input-background-color-rgb),
            var(--aa-input-background-color-alpha));
    border: 1px solid rgba(var(--aa-input-border-color-rgb), var(--aa-input-border-color-alpha));
    border-radius: 3px;
    color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
    cursor: pointer;
    display: flex;
    font: inherit;
    font-family: var(--aa-font-family);
    font-size: var(--aa-font-size);
    height: var(--aa-search-input-height);
    margin: 0;
    padding: 0 calc(var(--aa-search-input-height) / 8);
    position: relative;
    text-align: left;
    width: 100%;

    &:focus {
        border-color: rgba(var(--aa-primary-color-rgb), 1);
        box-shadow: rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha)) 0 0 0 3px,
            inset rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha)) 0 0 0 2px;
        outline: currentColor none medium;
    }

    @at-root .aa-DetachedSearchButtonIcon {
        align-items: center;
        color: rgba(var(--aa-primary-color-rgb), 1);
        cursor: initial;
        display: flex;
        height: 100%;
        justify-content: center;
        width: calc(var(--aa-icon-size) + var(--aa-spacing));
    }
}

// Remove scroll on `body`
.aa-Detached {
    height: 100vh;
    overflow: hidden;
}

.aa-DetachedOverlay {
    background-color: rgba(var(--aa-overlay-color-rgb),
            var(--aa-overlay-color-alpha));
    height: 100vh;
    left: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: calc(var(--aa-base-z-index) - 1);
}

//----------------
// 10. Gradients
//----------------
.aa-GradientTop,
.aa-GradientBottom {
    height: var(--aa-spacing-half);
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: var(--aa-base-z-index);
}

.aa-GradientTop {
    background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 1),
            rgba(var(--aa-background-color-rgb), 0));
    top: 0;
}

.aa-GradientBottom {
    background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 0),
            rgba(var(--aa-background-color-rgb), 1));
    border-bottom-left-radius: calc(var(--aa-spacing) / 4);
    border-bottom-right-radius: calc(var(--aa-spacing) / 4);
    bottom: 0;
}

//----------------
// 11. Utilities
//----------------
.aa-DesktopOnly {
    @media (hover: none) and (pointer: coarse) {
        display: none;
    }
}

.aa-TouchOnly {
    @media (hover: hover) {
        display: none;
    }
}


/**
 *
 * tailwind ripoff
 *
 */
.bg-transparent {
    background-color: transparent;
}

.bg-black {
    background-color: rgb(0, 0, 0);
}

.bg-white {
    background-color: rgb(255, 255, 255);
}

.bg-slate-50 {
    background-color: rgb(248, 250, 252);
}

.bg-slate-100 {
    background-color: rgb(241, 245, 249);
}

.bg-slate-200 {
    background-color: rgb(226, 232, 240);
}

.bg-slate-300 {
    background-color: rgb(203, 213, 225);
}

.bg-slate-400 {
    background-color: rgb(148, 163, 184);
}

.bg-slate-500 {
    background-color: rgb(100, 116, 139);
}

.bg-slate-600 {
    background-color: rgb(71, 85, 105);
}

.bg-slate-700 {
    background-color: rgb(51, 65, 85);
}

.bg-slate-800 {
    background-color: rgb(30, 41, 59);
}

.bg-slate-900 {
    background-color: rgb(15, 23, 42);
}

.bg-gray-50 {
    background-color: rgb(249, 250, 251);
}

.bg-gray-100 {
    background-color: rgb(243, 244, 246);
}

.bg-gray-200 {
    background-color: rgb(229, 231, 235);
}

.bg-gray-300 {
    background-color: rgb(209, 213, 219);
}

.bg-gray-400 {
    background-color: rgb(156, 163, 175);
}

.bg-gray-500 {
    background-color: rgb(107, 114, 128);
}

.bg-gray-600 {
    background-color: rgb(75, 85, 99);
}

.bg-gray-700 {
    background-color: rgb(55, 65, 81);
}

.bg-gray-800 {
    background-color: rgb(31, 41, 55);
}

.bg-gray-900 {
    background-color: rgb(17, 24, 39);
}

.bg-zinc-50 {
    background-color: rgb(250, 250, 250);
}

.bg-zinc-100 {
    background-color: rgb(244, 244, 245);
}

.bg-zinc-200 {
    background-color: rgb(228, 228, 231);
}

.bg-zinc-300 {
    background-color: rgb(212, 212, 216);
}

.bg-zinc-400 {
    background-color: rgb(161, 161, 170);
}

.bg-zinc-500 {
    background-color: rgb(113, 113, 122);
}

.bg-zinc-600 {
    background-color: rgb(82, 82, 91);
}

.bg-zinc-700 {
    background-color: rgb(63, 63, 70);
}

.bg-zinc-800 {
    background-color: rgb(39, 39, 42);
}

.bg-zinc-900 {
    background-color: rgb(24, 24, 27);
}

.bg-neutral-50 {
    background-color: rgb(250, 250, 250);
}

.bg-neutral-100 {
    background-color: rgb(245, 245, 245);
}

.bg-neutral-200 {
    background-color: rgb(229, 229, 229);
}

.bg-neutral-300 {
    background-color: rgb(212, 212, 212);
}

.bg-neutral-400 {
    background-color: rgb(163, 163, 163);
}

.bg-neutral-500 {
    background-color: rgb(115, 115, 115);
}

.bg-neutral-600 {
    background-color: rgb(82, 82, 82);
}

.bg-neutral-700 {
    background-color: rgb(64, 64, 64);
}

.bg-neutral-800 {
    background-color: rgb(38, 38, 38);
}

.bg-neutral-900 {
    background-color: rgb(23, 23, 23);
}

.bg-stone-50 {
    background-color: rgb(250, 250, 249);
}

.bg-stone-100 {
    background-color: rgb(245, 245, 244);
}

.bg-stone-200 {
    background-color: rgb(231, 229, 228);
}

.bg-stone-300 {
    background-color: rgb(214, 211, 209);
}

.bg-stone-400 {
    background-color: rgb(168, 162, 158);
}

.bg-stone-500 {
    background-color: rgb(120, 113, 108);
}

.bg-stone-600 {
    background-color: rgb(87, 83, 78);
}

.bg-stone-700 {
    background-color: rgb(68, 64, 60);
}

.bg-stone-800 {
    background-color: rgb(41, 37, 36);
}

.bg-stone-900 {
    background-color: rgb(28, 25, 23);
}

.bg-red-50 {
    background-color: rgb(254, 242, 242);
}

.bg-red-100 {
    background-color: rgb(254, 226, 226);
}

.bg-red-200 {
    background-color: rgb(254, 202, 202);
}

.bg-red-300 {
    background-color: rgb(252, 165, 165);
}

.bg-red-400 {
    background-color: rgb(248, 113, 113);
}

.bg-red-500 {
    background-color: rgb(239, 68, 68);
}

.bg-red-600 {
    background-color: rgb(220, 38, 38);
}

.bg-red-700 {
    background-color: rgb(185, 28, 28);
}

.bg-red-800 {
    background-color: rgb(153, 27, 27);
}

.bg-red-900 {
    background-color: rgb(127, 29, 29);
}

.bg-orange-50 {
    background-color: rgb(255, 247, 237);
}

.bg-orange-100 {
    background-color: rgb(255, 237, 213);
}

.bg-orange-200 {
    background-color: rgb(254, 215, 170);
}

.bg-orange-300 {
    background-color: rgb(253, 186, 116);
}

.bg-orange-400 {
    background-color: rgb(251, 146, 60);
}

.bg-orange-500 {
    background-color: rgb(249, 115, 22);
}

.bg-orange-600 {
    background-color: rgb(234, 88, 12);
}

.bg-orange-700 {
    background-color: rgb(194, 65, 12);
}

.bg-orange-800 {
    background-color: rgb(154, 52, 18);
}

.bg-orange-900 {
    background-color: rgb(124, 45, 18);
}

.bg-amber-50 {
    background-color: rgb(255, 251, 235);
}

.bg-amber-100 {
    background-color: rgb(254, 243, 199);
}

.bg-amber-200 {
    background-color: rgb(253, 230, 138);
}

.bg-amber-300 {
    background-color: rgb(252, 211, 77);
}

.bg-amber-400 {
    background-color: rgb(251, 191, 36);
}

.bg-amber-500 {
    background-color: rgb(245, 158, 11);
}

.bg-amber-600 {
    background-color: rgb(217, 119, 6);
}

.bg-amber-700 {
    background-color: rgb(180, 83, 9);
}

.bg-amber-800 {
    background-color: rgb(146, 64, 14);
}

.bg-amber-900 {
    background-color: rgb(120, 53, 15);
}

.bg-yellow-50 {
    background-color: rgb(254, 252, 232);
}

.bg-yellow-100 {
    background-color: rgb(254, 249, 195);
}

.bg-yellow-200 {
    background-color: rgb(254, 240, 138);
}

.bg-yellow-300 {
    background-color: rgb(253, 224, 71);
}

.bg-yellow-400 {
    background-color: rgb(250, 204, 21);
}

.bg-yellow-500 {
    background-color: rgb(234, 179, 8);
}

.bg-yellow-600 {
    background-color: rgb(202, 138, 4);
}

.bg-yellow-700 {
    background-color: rgb(161, 98, 7);
}

.bg-yellow-800 {
    background-color: rgb(133, 77, 14);
}

.bg-yellow-900 {
    background-color: rgb(113, 63, 18);
}

.bg-lime-50 {
    background-color: rgb(247, 254, 231);
}

.bg-lime-100 {
    background-color: rgb(236, 252, 203);
}

.bg-lime-200 {
    background-color: rgb(217, 249, 157);
}

.bg-lime-300 {
    background-color: rgb(190, 242, 100);
}

.bg-lime-400 {
    background-color: rgb(163, 230, 53);
}

.bg-lime-500 {
    background-color: rgb(132, 204, 22);
}

.bg-lime-600 {
    background-color: rgb(101, 163, 13);
}

.bg-lime-700 {
    background-color: rgb(77, 124, 15);
}

.bg-lime-800 {
    background-color: rgb(63, 98, 18);
}

.bg-lime-900 {
    background-color: rgb(54, 83, 20);
}

.bg-green-50 {
    background-color: rgb(240, 253, 244);
}

.bg-green-100 {
    background-color: rgb(220, 252, 231);
}

.bg-green-200 {
    background-color: rgb(187, 247, 208);
}

.bg-green-300 {
    background-color: rgb(134, 239, 172);
}

.bg-green-400 {
    background-color: rgb(74, 222, 128);
}

.bg-green-500 {
    background-color: rgb(34, 197, 94);
}

.bg-green-600 {
    background-color: rgb(22, 163, 74);
}

.bg-green-700 {
    background-color: rgb(21, 128, 61);
}

.bg-green-800 {
    background-color: rgb(22, 101, 52);
}

.bg-green-900 {
    background-color: rgb(20, 83, 45);
}

.bg-emerald-50 {
    background-color: rgb(236, 253, 245);
}

.bg-emerald-100 {
    background-color: rgb(209, 250, 229);
}

.bg-emerald-200 {
    background-color: rgb(167, 243, 208);
}

.bg-emerald-300 {
    background-color: rgb(110, 231, 183);
}

.bg-emerald-400 {
    background-color: rgb(52, 211, 153);
}

.bg-emerald-500 {
    background-color: rgb(16, 185, 129);
}

.bg-emerald-600 {
    background-color: rgb(5, 150, 105);
}

.bg-emerald-700 {
    background-color: rgb(4, 120, 87);
}

.bg-emerald-800 {
    background-color: rgb(6, 95, 70);
}

.bg-emerald-900 {
    background-color: rgb(6, 78, 59);
}

.bg-teal-50 {
    background-color: rgb(240, 253, 250);
}

.bg-teal-100 {
    background-color: rgb(204, 251, 241);
}

.bg-teal-200 {
    background-color: rgb(153, 246, 228);
}

.bg-teal-300 {
    background-color: rgb(94, 234, 212);
}

.bg-teal-400 {
    background-color: rgb(45, 212, 191);
}

.bg-teal-500 {
    background-color: rgb(20, 184, 166);
}

.bg-teal-600 {
    background-color: rgb(13, 148, 136);
}

.bg-teal-700 {
    background-color: rgb(15, 118, 110);
}

.bg-teal-800 {
    background-color: rgb(17, 94, 89);
}

.bg-teal-900 {
    background-color: rgb(19, 78, 74);
}

.bg-cyan-50 {
    background-color: rgb(236, 254, 255);
}

.bg-cyan-100 {
    background-color: rgb(207, 250, 254);
}

.bg-cyan-200 {
    background-color: rgb(165, 243, 252);
}

.bg-cyan-300 {
    background-color: rgb(103, 232, 249);
}

.bg-cyan-400 {
    background-color: rgb(34, 211, 238);
}

.bg-cyan-500 {
    background-color: rgb(6, 182, 212);
}

.bg-cyan-600 {
    background-color: rgb(8, 145, 178);
}

.bg-cyan-700 {
    background-color: rgb(14, 116, 144);
}

.bg-cyan-800 {
    background-color: rgb(21, 94, 117);
}

.bg-cyan-900 {
    background-color: rgb(22, 78, 99);
}

.bg-sky-50 {
    background-color: rgb(240, 249, 255);
}

.bg-sky-100 {
    background-color: rgb(224, 242, 254);
}

.bg-sky-200 {
    background-color: rgb(186, 230, 253);
}

.bg-sky-300 {
    background-color: rgb(125, 211, 252);
}

.bg-sky-400 {
    background-color: rgb(56, 189, 248);
}

.bg-sky-500 {
    background-color: rgb(14, 165, 233);
}

.bg-sky-600 {
    background-color: rgb(2, 132, 199);
}

.bg-sky-700 {
    background-color: rgb(3, 105, 161);
}

.bg-sky-800 {
    background-color: rgb(7, 89, 133);
}

.bg-sky-900 {
    background-color: rgb(12, 74, 110);
}

.bg-blue-50 {
    background-color: rgb(239, 246, 255);
}

.bg-blue-100 {
    background-color: rgb(219, 234, 254);
}

.bg-blue-200 {
    background-color: rgb(191, 219, 254);
}

.bg-blue-300 {
    background-color: rgb(147, 197, 253);
}

.bg-blue-400 {
    background-color: rgb(96, 165, 250);
}

.bg-blue-500 {
    background-color: rgb(59, 130, 246);
}

.bg-blue-600 {
    background-color: rgb(37, 99, 235);
}

.bg-blue-700 {
    background-color: rgb(29, 78, 216);
}

.bg-blue-800 {
    background-color: rgb(30, 64, 175);
}

.bg-blue-900 {
    background-color: rgb(30, 58, 138);
}

.bg-indigo-50 {
    background-color: rgb(238, 242, 255);
}

.bg-indigo-100 {
    background-color: rgb(224, 231, 255);
}

.bg-indigo-200 {
    background-color: rgb(199, 210, 254);
}

.bg-indigo-300 {
    background-color: rgb(165, 180, 252);
}

.bg-indigo-400 {
    background-color: rgb(129, 140, 248);
}

.bg-indigo-500 {
    background-color: rgb(99, 102, 241);
}

.bg-indigo-600 {
    background-color: rgb(79, 70, 229);
}

.bg-indigo-700 {
    background-color: rgb(67, 56, 202);
}

.bg-indigo-800 {
    background-color: rgb(55, 48, 163);
}

.bg-indigo-900 {
    background-color: rgb(49, 46, 129);
}

.bg-violet-50 {
    background-color: rgb(245, 243, 255);
}

.bg-violet-100 {
    background-color: rgb(237, 233, 254);
}

.bg-violet-200 {
    background-color: rgb(221, 214, 254);
}

.bg-violet-300 {
    background-color: rgb(196, 181, 253);
}

.bg-violet-400 {
    background-color: rgb(167, 139, 250);
}

.bg-violet-500 {
    background-color: rgb(139, 92, 246);
}

.bg-violet-600 {
    background-color: rgb(124, 58, 237);
}

.bg-violet-700 {
    background-color: rgb(109, 40, 217);
}

.bg-violet-800 {
    background-color: rgb(91, 33, 182);
}

.bg-violet-900 {
    background-color: rgb(76, 29, 149);
}

.bg-purple-50 {
    background-color: rgb(250, 245, 255);
}

.bg-purple-100 {
    background-color: rgb(243, 232, 255);
}

.bg-purple-200 {
    background-color: rgb(233, 213, 255);
}

.bg-purple-300 {
    background-color: rgb(216, 180, 254);
}

.bg-purple-400 {
    background-color: rgb(192, 132, 252);
}

.bg-purple-500 {
    background-color: rgb(168, 85, 247);
}

.bg-purple-600 {
    background-color: rgb(147, 51, 234);
}

.bg-purple-700 {
    background-color: rgb(126, 34, 206);
}

.bg-purple-800 {
    background-color: rgb(107, 33, 168);
}

.bg-purple-900 {
    background-color: rgb(88, 28, 135);
}

.bg-fuchsia-50 {
    background-color: rgb(253, 244, 255);
}

.bg-fuchsia-100 {
    background-color: rgb(250, 232, 255);
}

.bg-fuchsia-200 {
    background-color: rgb(245, 208, 254);
}

.bg-fuchsia-300 {
    background-color: rgb(240, 171, 252);
}

.bg-fuchsia-400 {
    background-color: rgb(232, 121, 249);
}

.bg-fuchsia-500 {
    background-color: rgb(217, 70, 239);
}

.bg-fuchsia-600 {
    background-color: rgb(192, 38, 211);
}

.bg-fuchsia-700 {
    background-color: rgb(162, 28, 175);
}

.bg-fuchsia-800 {
    background-color: rgb(134, 25, 143);
}

.bg-fuchsia-900 {
    background-color: rgb(112, 26, 117);
}

.bg-pink-50 {
    background-color: rgb(253, 242, 248);
}

.bg-pink-100 {
    background-color: rgb(252, 231, 243);
}

.bg-pink-200 {
    background-color: rgb(251, 207, 232);
}

.bg-pink-300 {
    background-color: rgb(249, 168, 212);
}

.bg-pink-400 {
    background-color: rgb(244, 114, 182);
}

.bg-pink-500 {
    background-color: rgb(236, 72, 153);
}

.bg-pink-600 {
    background-color: rgb(219, 39, 119);
}

.bg-pink-700 {
    background-color: rgb(190, 24, 93);
}

.bg-pink-800 {
    background-color: rgb(157, 23, 77);
}

.bg-pink-900 {
    background-color: rgb(131, 24, 67);
}

.bg-rose-50 {
    background-color: rgb(255, 241, 242);
}

.bg-rose-100 {
    background-color: rgb(255, 228, 230);
}

.bg-rose-200 {
    background-color: rgb(254, 205, 211);
}

.bg-rose-300 {
    background-color: rgb(253, 164, 175);
}

.bg-rose-400 {
    background-color: rgb(251, 113, 133);
}

.bg-rose-500 {
    background-color: rgb(244, 63, 94);
}

.bg-rose-600 {
    background-color: rgb(225, 29, 72);
}

.bg-rose-700 {
    background-color: rgb(190, 18, 60);
}

.bg-rose-800 {
    background-color: rgb(159, 18, 57);
}

.bg-rose-900 {
    background-color: rgb(136, 19, 55);
}

.font-thin { font-weight: 100 };
.font-extralight { font-weight: 200 };
.font-light { font-weight: 300 };
.font-normal { font-weight: 400 };
.font-medium { font-weight: 500 };
.font-semibold { font-weight: 600 };
.font-bold { font-weight: 700 };
.font-extrabold { font-weight: 800 };
.font-black { font-weight: 900 };

.text-slate-50 {
    color: rgb(248, 250, 252)
}

.text-slate-100 {
    color: rgb(241, 245, 249)
}

.text-slate-200 {
    color: rgb(226, 232, 240)
}

.text-slate-300 {
    color: rgb(203, 213, 225)
}

.text-slate-400 {
    color: rgb(148, 163, 184)
}

.text-slate-500 {
    color: rgb(100, 116, 139)
}

.text-slate-600 {
    color: rgb(71, 85, 105)
}

.text-slate-700 {
    color: rgb(51, 65, 85)
}

.text-slate-800 {
    color: rgb(30, 41, 59)
}

.text-slate-900 {
    color: rgb(15, 23, 42)
}

.text-gray-50 {
    color: rgb(249, 250, 251)
}

.text-gray-100 {
    color: rgb(243, 244, 246)
}

.text-gray-200 {
    color: rgb(229, 231, 235)
}

.text-gray-300 {
    color: rgb(209, 213, 219)
}

.text-gray-400 {
    color: rgb(156, 163, 175)
}

.text-gray-500 {
    color: rgb(107, 114, 128)
}

.text-gray-600 {
    color: rgb(75, 85, 99)
}

.text-gray-700 {
    color: rgb(55, 65, 81)
}

.text-gray-800 {
    color: rgb(31, 41, 55)
}

.text-gray-900 {
    color: rgb(17, 24, 39)
}

.text-zinc-50 {
    color: rgb(250, 250, 250)
}

.text-zinc-100 {
    color: rgb(244, 244, 245)
}

.text-zinc-200 {
    color: rgb(228, 228, 231)
}

.text-zinc-300 {
    color: rgb(212, 212, 216)
}

.text-zinc-400 {
    color: rgb(161, 161, 170)
}

.text-zinc-500 {
    color: rgb(113, 113, 122)
}

.text-zinc-600 {
    color: rgb(82, 82, 91)
}

.text-zinc-700 {
    color: rgb(63, 63, 70)
}

.text-zinc-800 {
    color: rgb(39, 39, 42)
}

.text-zinc-900 {
    color: rgb(24, 24, 27)
}

.text-neutral-50 {
    color: rgb(250, 250, 250)
}

.text-neutral-100 {
    color: rgb(245, 245, 245)
}

.text-neutral-200 {
    color: rgb(229, 229, 229)
}

.text-neutral-300 {
    color: rgb(212, 212, 212)
}

.text-neutral-400 {
    color: rgb(163, 163, 163)
}

.text-neutral-500 {
    color: rgb(115, 115, 115)
}

.text-neutral-600 {
    color: rgb(82, 82, 82)
}

.text-neutral-700 {
    color: rgb(64, 64, 64)
}

.text-neutral-800 {
    color: rgb(38, 38, 38)
}

.text-neutral-900 {
    color: rgb(23, 23, 23)
}

.text-stone-50 {
    color: rgb(250, 250, 249)
}

.text-stone-100 {
    color: rgb(245, 245, 244)
}

.text-stone-200 {
    color: rgb(231, 229, 228)
}

.text-stone-300 {
    color: rgb(214, 211, 209)
}

.text-stone-400 {
    color: rgb(168, 162, 158)
}

.text-stone-500 {
    color: rgb(120, 113, 108)
}

.text-stone-600 {
    color: rgb(87, 83, 78)
}

.text-stone-700 {
    color: rgb(68, 64, 60)
}

.text-stone-800 {
    color: rgb(41, 37, 36)
}

.text-stone-900 {
    color: rgb(28, 25, 23)
}

.text-red-50 {
    color: rgb(254, 242, 242)
}

.text-red-100 {
    color: rgb(254, 226, 226)
}

.text-red-200 {
    color: rgb(254, 202, 202)
}

.text-red-300 {
    color: rgb(252, 165, 165)
}

.text-red-400 {
    color: rgb(248, 113, 113)
}

.text-red-500 {
    color: rgb(239, 68, 68)
}

.text-red-600 {
    color: rgb(220, 38, 38)
}

.text-red-700 {
    color: rgb(185, 28, 28)
}

.text-red-800 {
    color: rgb(153, 27, 27)
}

.text-red-900 {
    color: rgb(127, 29, 29)
}

.text-orange-50 {
    color: rgb(255, 247, 237)
}

.text-orange-100 {
    color: rgb(255, 237, 213)
}

.text-orange-200 {
    color: rgb(254, 215, 170)
}

.text-orange-300 {
    color: rgb(253, 186, 116)
}

.text-orange-400 {
    color: rgb(251, 146, 60)
}

.text-orange-500 {
    color: rgb(249, 115, 22)
}

.text-orange-600 {
    color: rgb(234, 88, 12)
}

.text-orange-700 {
    color: rgb(194, 65, 12)
}

.text-orange-800 {
    color: rgb(154, 52, 18)
}

.text-orange-900 {
    color: rgb(124, 45, 18)
}

.text-amber-50 {
    color: rgb(255, 251, 235)
}

.text-amber-100 {
    color: rgb(254, 243, 199)
}

.text-amber-200 {
    color: rgb(253, 230, 138)
}

.text-amber-300 {
    color: rgb(252, 211, 77)
}

.text-amber-400 {
    color: rgb(251, 191, 36)
}

.text-amber-500 {
    color: rgb(245, 158, 11)
}

.text-amber-600 {
    color: rgb(217, 119, 6)
}

.text-amber-700 {
    color: rgb(180, 83, 9)
}

.text-amber-800 {
    color: rgb(146, 64, 14)
}

.text-amber-900 {
    color: rgb(120, 53, 15)
}

.text-yellow-50 {
    color: rgb(254, 252, 232)
}

.text-yellow-100 {
    color: rgb(254, 249, 195)
}

.text-yellow-200 {
    color: rgb(254, 240, 138)
}

.text-yellow-300 {
    color: rgb(253, 224, 71)
}

.text-yellow-400 {
    color: rgb(250, 204, 21)
}

.text-yellow-500 {
    color: rgb(234, 179, 8)
}

.text-yellow-600 {
    color: rgb(202, 138, 4)
}

.text-yellow-700 {
    color: rgb(161, 98, 7)
}

.text-yellow-800 {
    color: rgb(133, 77, 14)
}

.text-yellow-900 {
    color: rgb(113, 63, 18)
}

.text-lime-50 {
    color: rgb(247, 254, 231)
}

.text-lime-100 {
    color: rgb(236, 252, 203)
}

.text-lime-200 {
    color: rgb(217, 249, 157)
}

.text-lime-300 {
    color: rgb(190, 242, 100)
}

.text-lime-400 {
    color: rgb(163, 230, 53)
}

.text-lime-500 {
    color: rgb(132, 204, 22)
}

.text-lime-600 {
    color: rgb(101, 163, 13)
}

.text-lime-700 {
    color: rgb(77, 124, 15)
}

.text-lime-800 {
    color: rgb(63, 98, 18)
}

.text-lime-900 {
    color: rgb(54, 83, 20)
}

.text-green-50 {
    color: rgb(240, 253, 244)
}

.text-green-100 {
    color: rgb(220, 252, 231)
}

.text-green-200 {
    color: rgb(187, 247, 208)
}

.text-green-300 {
    color: rgb(134, 239, 172)
}

.text-green-400 {
    color: rgb(74, 222, 128)
}

.text-green-500 {
    color: rgb(34, 197, 94)
}

.text-green-600 {
    color: rgb(22, 163, 74)
}

.text-green-700 {
    color: rgb(21, 128, 61)
}

.text-green-800 {
    color: rgb(22, 101, 52)
}

.text-green-900 {
    color: rgb(20, 83, 45)
}

.text-emerald-50 {
    color: rgb(236, 253, 245)
}

.text-emerald-100 {
    color: rgb(209, 250, 229)
}

.text-emerald-200 {
    color: rgb(167, 243, 208)
}

.text-emerald-300 {
    color: rgb(110, 231, 183)
}

.text-emerald-400 {
    color: rgb(52, 211, 153)
}

.text-emerald-500 {
    color: rgb(16, 185, 129)
}

.text-emerald-600 {
    color: rgb(5, 150, 105)
}

.text-emerald-700 {
    color: rgb(4, 120, 87)
}

.text-emerald-800 {
    color: rgb(6, 95, 70)
}

.text-emerald-900 {
    color: rgb(6, 78, 59)
}

.text-teal-50 {
    color: rgb(240, 253, 250)
}

.text-teal-100 {
    color: rgb(204, 251, 241)
}

.text-teal-200 {
    color: rgb(153, 246, 228)
}

.text-teal-300 {
    color: rgb(94, 234, 212)
}

.text-teal-400 {
    color: rgb(45, 212, 191)
}

.text-teal-500 {
    color: rgb(20, 184, 166)
}

.text-teal-600 {
    color: rgb(13, 148, 136)
}

.text-teal-700 {
    color: rgb(15, 118, 110)
}

.text-teal-800 {
    color: rgb(17, 94, 89)
}

.text-teal-900 {
    color: rgb(19, 78, 74)
}

.text-cyan-50 {
    color: rgb(236, 254, 255)
}

.text-cyan-100 {
    color: rgb(207, 250, 254)
}

.text-cyan-200 {
    color: rgb(165, 243, 252)
}

.text-cyan-300 {
    color: rgb(103, 232, 249)
}

.text-cyan-400 {
    color: rgb(34, 211, 238)
}

.text-cyan-500 {
    color: rgb(6, 182, 212)
}

.text-cyan-600 {
    color: rgb(8, 145, 178)
}

.text-cyan-700 {
    color: rgb(14, 116, 144)
}

.text-cyan-800 {
    color: rgb(21, 94, 117)
}

.text-cyan-900 {
    color: rgb(22, 78, 99)
}

.text-sky-50 {
    color: rgb(240, 249, 255)
}

.text-sky-100 {
    color: rgb(224, 242, 254)
}

.text-sky-200 {
    color: rgb(186, 230, 253)
}

.text-sky-300 {
    color: rgb(125, 211, 252)
}

.text-sky-400 {
    color: rgb(56, 189, 248)
}

.text-sky-500 {
    color: rgb(14, 165, 233)
}

.text-sky-600 {
    color: rgb(2, 132, 199)
}

.text-sky-700 {
    color: rgb(3, 105, 161)
}

.text-sky-800 {
    color: rgb(7, 89, 133)
}

.text-sky-900 {
    color: rgb(12, 74, 110)
}

.text-blue-50 {
    color: rgb(239, 246, 255)
}

.text-blue-100 {
    color: rgb(219, 234, 254)
}

.text-blue-200 {
    color: rgb(191, 219, 254)
}

.text-blue-300 {
    color: rgb(147, 197, 253)
}

.text-blue-400 {
    color: rgb(96, 165, 250)
}

.text-blue-500 {
    color: rgb(59, 130, 246)
}

.text-blue-600 {
    color: rgb(37, 99, 235)
}

.text-blue-700 {
    color: rgb(29, 78, 216)
}

.text-blue-800 {
    color: rgb(30, 64, 175)
}

.text-blue-900 {
    color: rgb(30, 58, 138)
}

.text-indigo-50 {
    color: rgb(238, 242, 255)
}

.text-indigo-100 {
    color: rgb(224, 231, 255)
}

.text-indigo-200 {
    color: rgb(199, 210, 254)
}

.text-indigo-300 {
    color: rgb(165, 180, 252)
}

.text-indigo-400 {
    color: rgb(129, 140, 248)
}

.text-indigo-500 {
    color: rgb(99, 102, 241)
}

.text-indigo-600 {
    color: rgb(79, 70, 229)
}

.text-indigo-700 {
    color: rgb(67, 56, 202)
}

.text-indigo-800 {
    color: rgb(55, 48, 163)
}

.text-indigo-900 {
    color: rgb(49, 46, 129)
}

.text-violet-50 {
    color: rgb(245, 243, 255)
}

.text-violet-100 {
    color: rgb(237, 233, 254)
}

.text-violet-200 {
    color: rgb(221, 214, 254)
}

.text-violet-300 {
    color: rgb(196, 181, 253)
}

.text-violet-400 {
    color: rgb(167, 139, 250)
}

.text-violet-500 {
    color: rgb(139, 92, 246)
}

.text-violet-600 {
    color: rgb(124, 58, 237)
}

.text-violet-700 {
    color: rgb(109, 40, 217)
}

.text-violet-800 {
    color: rgb(91, 33, 182)
}

.text-violet-900 {
    color: rgb(76, 29, 149)
}

.text-purple-50 {
    color: rgb(250, 245, 255)
}

.text-purple-100 {
    color: rgb(243, 232, 255)
}

.text-purple-200 {
    color: rgb(233, 213, 255)
}

.text-purple-300 {
    color: rgb(216, 180, 254)
}

.text-purple-400 {
    color: rgb(192, 132, 252)
}

.text-purple-500 {
    color: rgb(168, 85, 247)
}

.text-purple-600 {
    color: rgb(147, 51, 234)
}

.text-purple-700 {
    color: rgb(126, 34, 206)
}

.text-purple-800 {
    color: rgb(107, 33, 168)
}

.text-purple-900 {
    color: rgb(88, 28, 135)
}

.text-fuchsia-50 {
    color: rgb(253, 244, 255)
}

.text-fuchsia-100 {
    color: rgb(250, 232, 255)
}

.text-fuchsia-200 {
    color: rgb(245, 208, 254)
}

.text-fuchsia-300 {
    color: rgb(240, 171, 252)
}

.text-fuchsia-400 {
    color: rgb(232, 121, 249)
}

.text-fuchsia-500 {
    color: rgb(217, 70, 239)
}

.text-fuchsia-600 {
    color: rgb(192, 38, 211)
}

.text-fuchsia-700 {
    color: rgb(162, 28, 175)
}

.text-fuchsia-800 {
    color: rgb(134, 25, 143)
}

.text-fuchsia-900 {
    color: rgb(112, 26, 117)
}

.text-pink-50 {
    color: rgb(253, 242, 248)
}

.text-pink-100 {
    color: rgb(252, 231, 243)
}

.text-pink-200 {
    color: rgb(251, 207, 232)
}

.text-pink-300 {
    color: rgb(249, 168, 212)
}

.text-pink-400 {
    color: rgb(244, 114, 182)
}

.text-pink-500 {
    color: rgb(236, 72, 153)
}

.text-pink-600 {
    color: rgb(219, 39, 119)
}

.text-pink-700 {
    color: rgb(190, 24, 93)
}

.text-pink-800 {
    color: rgb(157, 23, 77)
}

.text-pink-900 {
    color: rgb(131, 24, 67)
}

.text-rose-50 {
    color: rgb(255, 241, 242)
}

.text-rose-100 {
    color: rgb(255, 228, 230)
}

.text-rose-200 {
    color: rgb(254, 205, 211)
}

.text-rose-300 {
    color: rgb(253, 164, 175)
}

.text-rose-400 {
    color: rgb(251, 113, 133)
}

.text-rose-500 {
    color: rgb(244, 63, 94)
}

.text-rose-600 {
    color: rgb(225, 29, 72)
}

.text-rose-700 {
    color: rgb(190, 18, 60)
}

.text-rose-800 {
    color: rgb(159, 18, 57)
}

.text-rose-900 {
    color: rgb(136, 19, 55)
}

.w-0 {
    width: 0px
}

.w-px {
    width: 1px
}

.w-1 {
    width: 0.25rem
}

.w-2 {
    width: 0.5rem
}

.w-3 {
    width: 0.75rem
}

.w-4 {
    width: 1rem
}

.w-5 {
    width: 1.25rem
}

.w-6 {
    width: 1.5rem
}

.w-7 {
    width: 1.75rem
}

.w-8 {
    width: 2rem
}

.w-9 {
    width: 2.25rem
}

.w-10 {
    width: 2.5rem
}

.w-11 {
    width: 2.75rem
}

.w-12 {
    width: 3rem
}

.w-14 {
    width: 3.5rem
}

.w-16 {
    width: 4rem
}

.w-20 {
    width: 5rem
}

.w-24 {
    width: 6rem
}

.w-28 {
    width: 7rem
}

.w-32 {
    width: 8rem
}

.w-36 {
    width: 9rem
}

.w-40 {
    width: 10rem
}

.w-44 {
    width: 11rem
}

.w-48 {
    width: 12rem
}

.w-52 {
    width: 13rem
}

.w-56 {
    width: 14rem
}

.w-60 {
    width: 15rem
}

.w-64 {
    width: 16rem
}

.w-72 {
    width: 18rem
}

.w-80 {
    width: 20rem
}

.w-96 {
    width: 24rem
}

.w-auto {
    width: auto
}

.w-full {
    width: 100%
}

.w-screen {
    width: 100vw
}

.w-min {
    width: min-content
}

.w-max {
    width: max-content
}

.w-fit {
    width: fit-content
}

.cursor-auto {
    cursor: auto
}

.cursor-default {
    cursor: default
}

.cursor-pointer {
    cursor: pointer
}

.cursor-wait {
    cursor: wait
}

.cursor-text {
    cursor: text
}

.cursor-move {
    cursor: move
}

.cursor-help {
    cursor: help
}

.cursor-not-allowed {
    cursor: not-allowed
}

.cursor-none {
    cursor: none
}

.cursor-context-menu {
    cursor: context-menu
}

.cursor-progress {
    cursor: progress
}

.cursor-cell {
    cursor: cell
}

.cursor-crosshair {
    cursor: crosshair
}

.cursor-vertical-text {
    cursor: vertical-text
}

.cursor-alias {
    cursor: alias
}

.cursor-copy {
    cursor: copy
}

.cursor-no-drop {
    cursor: no-drop
}

.cursor-grab {
    cursor: grab
}

.cursor-grabbing {
    cursor: grabbing
}

.cursor-all-scroll {
    cursor: all-scroll
}

.cursor-col-resize {
    cursor: col-resize
}

.cursor-row-resize {
    cursor: row-resize
}

.cursor-n-resize {
    cursor: n-resize
}

.cursor-e-resize {
    cursor: e-resize
}

.cursor-s-resize {
    cursor: s-resize
}

.cursor-w-resize {
    cursor: w-resize
}

.cursor-ne-resize {
    cursor: ne-resize
}

.cursor-nw-resize {
    cursor: nw-resize
}

.cursor-se-resize {
    cursor: se-resize
}

.cursor-sw-resize {
    cursor: sw-resize
}

.cursor-ew-resize {
    cursor: ew-resize
}

.cursor-ns-resize {
    cursor: ns-resize
}

.cursor-nesw-resize {
    cursor: nesw-resize
}

.cursor-nwse-resize {
    cursor: nwse-resize
}

.cursor-zoom-in {
    cursor: zoom-in
}

.cursor-zoom-out {
    cursor: zoom-out
}

.clearButton {
    display: block;
    width: 2rem;
    right: 0px;
    top: 0px;
    bottom: 0px;
    position: absolute;
    color: #a8a8a8;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
}


/*form generator*/
