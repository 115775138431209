//
// Background
// --------------------------------------------------

// Black/White with opacity
.bg-black {
  &-5 {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
  &-10 {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  &-25 {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
  &-50 {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  &-75 {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
  &-90 {
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
  &-95 {
    background-color: rgba(0, 0, 0, 0.95) !important;
  }
}

.bg-white {
  &-5 {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
  &-10 {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  &-25 {
    background-color: rgba(255, 255, 255, 0.25) !important;
  }
  &-50 {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  &-75 {
    background-color: rgba(255, 255, 255, 0.75) !important;
  }
  &-90 {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }
  &-95 {
    background-color: rgba(255, 255, 255, 0.95) !important;
  }
}

// Image Background
.bg-image {
  background-position: 0% 50%;
  background-size: cover;

  &-top {
    background-position-y: 0%;
  }
  &-center {
    background-position-x: 50%;
  }
  &-bottom {
    background-position-y: 100%;
  }

  @include media-breakpoint-up(xl) {
    &-fixed {
      background-attachment: fixed;
    }
  }
}

// Pattern Image
.bg-pattern {
  background-repeat: repeat;
}

// Video Background
.bg-video {
  width: 100%;
  transform: translateZ(0);
}

// Contextual colors
@include bg-variant(".bg-primary-op", rgba($primary, 0.75));
@include bg-variant(".bg-primary", $primary);
@include bg-variant(".bg-primary-dark", $primary-dark);
@include bg-variant(".bg-primary-dark-op", rgba($primary-dark, 0.8));
@include bg-variant(".bg-primary-darker", $primary-darker);
@include bg-variant(".bg-primary-light", $primary-light);
@include bg-variant(".bg-primary-lighter", $primary-lighter);

@include bg-variant(".bg-success", $success);
@include bg-variant(".bg-success-light", $success-light);
@include bg-variant(".bg-warning", $warning);
@include bg-variant(".bg-warning-light", $warning-light);
@include bg-variant(".bg-info", $info);
@include bg-variant(".bg-info-light", $info-light);
@include bg-variant(".bg-danger", $danger);
@include bg-variant(".bg-danger-light", $danger-light);

@include bg-variant(".bg-success-op", rgba($success-light, 0.95));
@include bg-variant(".bg-warning-op", rgba($warning-light, 0.95));
@include bg-variant(".bg-info-op", rgba($info-light, 0.95));
@include bg-variant(".bg-danger-op ", rgba($danger-light, 0.95));

@include bg-variant(".bg-body", $light);
@include bg-variant(".bg-body-light", $body-bg-light);
@include bg-variant(".bg-body-dark", $body-bg-dark);
@include bg-variant(".bg-body-extra-light", $white);
@include bg-variant(".bg-muted", $gray-600);

@include bg-variant(".bg-white", $white);
@include bg-variant(".bg-black", $black);

@include bg-variant(".bg-gray", $gray-400);
@include bg-variant(".bg-gray-dark", $gray-600);
@include bg-variant(".bg-gray-darker", $gray-800);
@include bg-variant(".bg-gray-light", $gray-200);
@include bg-variant(".bg-gray-lighter", $gray-100);

// Elements
@include bg-variant(".bg-header-light", $header-bg);
@include bg-variant(".bg-header-dark", $header-dark-bg);

@include bg-variant(".bg-sidebar-light", $sidebar-bg);
@include bg-variant(".bg-sidebar-dark", $sidebar-dark-bg);

// Default Color Theme
@include bg-variant(".bg-default", $primary);
@include bg-variant(".bg-default-op", rgba($primary, 0.75));
@include bg-variant(".bg-default-dark", $primary-dark);
@include bg-variant(".bg-default-dark-op", rgba($primary-dark, 0.8));
@include bg-variant(".bg-default-darker", $primary-darker);
@include bg-variant(".bg-default-light", $primary-light);
@include bg-variant(".bg-default-lighter", $primary-lighter);

// Amethyst Color Theme
@include bg-variant(".bg-amethyst", $theme-amethyst-primary);
@include bg-variant(".bg-amethyst-op", rgba($theme-amethyst-primary, 0.75));
@include bg-variant(".bg-amethyst-dark", $theme-amethyst-dark);
@include bg-variant(".bg-amethyst-dark-op", rgba($theme-amethyst-dark, 0.8));
@include bg-variant(".bg-amethyst-darker", $theme-amethyst-darker);
@include bg-variant(".bg-amethyst-light", $theme-amethyst-light);
@include bg-variant(".bg-amethyst-lighter", $theme-amethyst-lighter);

// City Color Theme
@include bg-variant(".bg-city", $theme-city-primary);
@include bg-variant(".bg-city-op", rgba($theme-city-primary, 0.75));
@include bg-variant(".bg-city-dark", $theme-city-dark);
@include bg-variant(".bg-city-dark-op", rgba($theme-city-dark, 0.8));
@include bg-variant(".bg-city-darker", $theme-city-darker);
@include bg-variant(".bg-city-light", $theme-city-light);
@include bg-variant(".bg-city-lighter", $theme-city-lighter);

// Flat Color Theme
@include bg-variant(".bg-flat", $theme-flat-primary);
@include bg-variant(".bg-flat-op", rgba($theme-flat-primary, 0.75));
@include bg-variant(".bg-flat-dark", $theme-flat-dark);
@include bg-variant(".bg-flat-dark-op", rgba($theme-flat-dark, 0.8));
@include bg-variant(".bg-flat-darker", $theme-flat-darker);
@include bg-variant(".bg-flat-light", $theme-flat-light);
@include bg-variant(".bg-flat-lighter", $theme-flat-lighter);

// Modern Color Theme
@include bg-variant(".bg-modern", $theme-modern-primary);
@include bg-variant(".bg-modern-op", rgba($theme-modern-primary, 0.75));
@include bg-variant(".bg-modern-dark", $theme-modern-dark);
@include bg-variant(".bg-modern-dark-op", rgba($theme-modern-dark, 0.8));
@include bg-variant(".bg-modern-darker", $theme-modern-darker);
@include bg-variant(".bg-modern-light", $theme-modern-light);
@include bg-variant(".bg-modern-lighter", $theme-modern-lighter);

// Smooth Color Theme
@include bg-variant(".bg-smooth", $theme-smooth-primary);
@include bg-variant(".bg-smooth-op", rgba($theme-smooth-primary, 0.75));
@include bg-variant(".bg-smooth-dark", $theme-smooth-dark);
@include bg-variant(".bg-smooth-dark-op", rgba($theme-smooth-dark, 0.8));
@include bg-variant(".bg-smooth-darker", $theme-smooth-darker);
@include bg-variant(".bg-smooth-light", $theme-smooth-light);
@include bg-variant(".bg-smooth-lighter", $theme-smooth-lighter);