@charset "UTF-8";
/* font */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #2356d7;
  --bs-secondary: #475569;
  --bs-success: #139a52;
  --bs-info: #1391aa;
  --bs-warning: #ea580c;
  --bs-danger: #d61f47;
  --bs-light: #ebeef2;
  --bs-dark: #334155;
  --bs-primary-rgb: 35, 86, 215;
  --bs-secondary-rgb: 71, 85, 105;
  --bs-success-rgb: 19, 154, 82;
  --bs-info-rgb: 19, 145, 170;
  --bs-warning-rgb: 234, 88, 12;
  --bs-danger-rgb: 214, 31, 71;
  --bs-light-rgb: 235, 238, 242;
  --bs-dark-rgb: 51, 65, 85;
  --bs-primary-text-emphasis: #0e2256;
  --bs-secondary-text-emphasis: #1c222a;
  --bs-success-text-emphasis: #083e21;
  --bs-info-text-emphasis: #083a44;
  --bs-warning-text-emphasis: #5e2305;
  --bs-danger-text-emphasis: #560c1c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #d3ddf7;
  --bs-secondary-bg-subtle: #dadde1;
  --bs-success-bg-subtle: #d0ebdc;
  --bs-info-bg-subtle: #d0e9ee;
  --bs-warning-bg-subtle: #fbdece;
  --bs-danger-bg-subtle: #f7d2da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #a7bbef;
  --bs-secondary-border-subtle: #b5bbc3;
  --bs-success-border-subtle: #a1d7ba;
  --bs-info-border-subtle: #a1d3dd;
  --bs-warning-border-subtle: #f7bc9e;
  --bs-danger-border-subtle: #efa5b5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Inter", "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-root-font-size: 16px;
  --bs-body-font-family: "Inter", "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-body-font-size: 0.773rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #2356d7;
  --bs-link-color-rgb: 35, 86, 215;
  --bs-link-decoration: none;
  --bs-link-hover-color: #15337f;
  --bs-link-hover-color-rgb: 21, 51, 127;
  --bs-link-hover-decoration: none;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dfe3ea;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(35, 86, 215, 0.25);
  --bs-form-valid-color: #139a52;
  --bs-form-valid-border-color: #139a52;
  --bs-form-invalid-color: #d61f47;
  --bs-form-invalid-border-color: #d61f47;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--bs-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1.375rem;
  font-weight: 600;
  line-height: 1.25;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.298925rem + 0.5871vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 1.73925rem;
  }
}

h2, .h2 {
  font-size: calc(1.2699375rem + 0.23925vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.449375rem;
  }
}

h3, .h3 {
  font-size: 1.1595rem;
}

h4, .h4 {
  font-size: 0.96625rem;
}

h5, .h5 {
  font-size: 0.869625rem;
}

h6, .h6 {
  font-size: 0.773rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.875rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  font-weight: 600;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 0.96625rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 0.96625rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.75rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.75rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 6rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 6rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 9rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 9rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 12rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 12rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 18rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 18rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 9rem;
  }
  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 9rem;
  }
  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 12rem;
  }
  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 12rem;
  }
  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 18rem;
  }
  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 18rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 6rem;
  }
  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 6rem;
  }
  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 9rem;
  }
  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 9rem;
  }
  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 12rem;
  }
  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 12rem;
  }
  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 18rem;
  }
  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 18rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 9rem;
  }
  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 9rem;
  }
  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 12rem;
  }
  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 12rem;
  }
  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 18rem;
  }
  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 18rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 9rem;
  }
  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 9rem;
  }
  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 12rem;
  }
  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 12rem;
  }
  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 18rem;
  }
  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 18rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 9rem;
  }
  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 9rem;
  }
  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 12rem;
  }
  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 12rem;
  }
  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 18rem;
  }
  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 18rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: #212529;
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: #ebeef2;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: #f6f7f9;
  --bs-table-active-color: #212529;
  --bs-table-active-bg: #ebeef2;
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(235, 238, 242, 0.75);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid #ebeef2;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d3ddf7;
  --bs-table-border-color: #bec7de;
  --bs-table-striped-bg: #c8d2eb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bec7de;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c3cce4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #dadde1;
  --bs-table-border-color: #c4c7cb;
  --bs-table-striped-bg: #cfd2d6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c4c7cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #caccd0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d0ebdc;
  --bs-table-border-color: #bbd4c6;
  --bs-table-striped-bg: #c6dfd1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbd4c6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c0d9cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #d0e9ee;
  --bs-table-border-color: #bbd2d6;
  --bs-table-striped-bg: #c6dde2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbd2d6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c0d8dc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fbdece;
  --bs-table-border-color: #e2c8b9;
  --bs-table-striped-bg: #eed3c4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2c8b9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8cdbf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f7d2da;
  --bs-table-border-color: #debdc4;
  --bs-table-striped-bg: #ebc8cf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #debdc4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4c2ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #ebeef2;
  --bs-table-border-color: #d4d6da;
  --bs-table-striped-bg: #dfe2e6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d4d6da;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d9dce0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #334155;
  --bs-table-border-color: #475466;
  --bs-table-striped-bg: #3d4b5e;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #475466;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #424f62;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.375rem;
  font-weight: 500;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 0.773rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.676375rem;
}

.form-text {
  margin-top: 0.375rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.773rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: var(--bs-border-width) solid #dfe3ea;
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #91abeb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(35, 86, 215, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.676375rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 0.773rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.773rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #dfe3ea;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #91abeb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(35, 86, 215, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.676375rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 0.773rem;
  border-radius: var(--bs-border-radius-lg);
}

.form-check {
  display: block;
  min-height: 1.1595rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: #fff;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #ccd4de;
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #91abeb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(35, 86, 215, 0.25);
}
.form-check-input:checked {
  background-color: #2356d7;
  border-color: #2356d7;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #2356d7;
  border-color: #2356d7;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 3em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ccd4de'/%3e%3c/svg%3e");
  width: 2.5em;
  margin-left: -3em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2.5em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2391abeb'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 3em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -3em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(35, 86, 215, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(35, 86, 215, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2356d7;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #bdccf3;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2356d7;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #bdccf3;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #fff;
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.773rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #ebeef2;
  border: var(--bs-border-width) solid #dfe3ea;
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.773rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.676375rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.676375rem;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.676375rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.676375rem;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.676375rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.773rem;
  --bs-btn-font-weight: 600;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2356d7;
  --bs-btn-border-color: #2356d7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1c45ac;
  --bs-btn-hover-border-color: #1a41a1;
  --bs-btn-focus-shadow-rgb: 68, 111, 221;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1c45ac;
  --bs-btn-active-border-color: #1a41a1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2356d7;
  --bs-btn-disabled-border-color: #2356d7;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #475569;
  --bs-btn-border-color: #475569;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #394454;
  --bs-btn-hover-border-color: #35404f;
  --bs-btn-focus-shadow-rgb: 99, 111, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #394454;
  --bs-btn-active-border-color: #35404f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #475569;
  --bs-btn-disabled-border-color: #475569;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #139a52;
  --bs-btn-border-color: #139a52;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0f7b42;
  --bs-btn-hover-border-color: #0e743e;
  --bs-btn-focus-shadow-rgb: 54, 169, 108;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0f7b42;
  --bs-btn-active-border-color: #0e743e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #139a52;
  --bs-btn-disabled-border-color: #139a52;
}

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1391aa;
  --bs-btn-border-color: #1391aa;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0f7488;
  --bs-btn-hover-border-color: #0e6d80;
  --bs-btn-focus-shadow-rgb: 54, 162, 183;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0f7488;
  --bs-btn-active-border-color: #0e6d80;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1391aa;
  --bs-btn-disabled-border-color: #1391aa;
}

.btn-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ea580c;
  --bs-btn-border-color: #ea580c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb460a;
  --bs-btn-hover-border-color: #b04209;
  --bs-btn-focus-shadow-rgb: 237, 113, 48;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #bb460a;
  --bs-btn-active-border-color: #b04209;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ea580c;
  --bs-btn-disabled-border-color: #ea580c;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #d61f47;
  --bs-btn-border-color: #d61f47;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ab1939;
  --bs-btn-hover-border-color: #a11735;
  --bs-btn-focus-shadow-rgb: 220, 65, 99;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ab1939;
  --bs-btn-active-border-color: #a11735;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d61f47;
  --bs-btn-disabled-border-color: #d61f47;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #ebeef2;
  --bs-btn-border-color: #ebeef2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #bcbec2;
  --bs-btn-hover-border-color: #b0b3b6;
  --bs-btn-focus-shadow-rgb: 200, 202, 206;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #bcbec2;
  --bs-btn-active-border-color: #b0b3b6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ebeef2;
  --bs-btn-disabled-border-color: #ebeef2;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #334155;
  --bs-btn-border-color: #334155;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c6777;
  --bs-btn-hover-border-color: #525e6f;
  --bs-btn-focus-shadow-rgb: 82, 94, 111;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5c6777;
  --bs-btn-active-border-color: #475466;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #334155;
  --bs-btn-disabled-border-color: #334155;
}

.btn-outline-primary {
  --bs-btn-color: #2356d7;
  --bs-btn-border-color: #2356d7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2356d7;
  --bs-btn-hover-border-color: #2356d7;
  --bs-btn-focus-shadow-rgb: 35, 86, 215;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2356d7;
  --bs-btn-active-border-color: #2356d7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2356d7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2356d7;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #475569;
  --bs-btn-border-color: #475569;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #475569;
  --bs-btn-hover-border-color: #475569;
  --bs-btn-focus-shadow-rgb: 71, 85, 105;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #475569;
  --bs-btn-active-border-color: #475569;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #475569;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #475569;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #139a52;
  --bs-btn-border-color: #139a52;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #139a52;
  --bs-btn-hover-border-color: #139a52;
  --bs-btn-focus-shadow-rgb: 19, 154, 82;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #139a52;
  --bs-btn-active-border-color: #139a52;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #139a52;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #139a52;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #1391aa;
  --bs-btn-border-color: #1391aa;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1391aa;
  --bs-btn-hover-border-color: #1391aa;
  --bs-btn-focus-shadow-rgb: 19, 145, 170;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1391aa;
  --bs-btn-active-border-color: #1391aa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1391aa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1391aa;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ea580c;
  --bs-btn-border-color: #ea580c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ea580c;
  --bs-btn-hover-border-color: #ea580c;
  --bs-btn-focus-shadow-rgb: 234, 88, 12;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ea580c;
  --bs-btn-active-border-color: #ea580c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ea580c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ea580c;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #d61f47;
  --bs-btn-border-color: #d61f47;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d61f47;
  --bs-btn-hover-border-color: #d61f47;
  --bs-btn-focus-shadow-rgb: 214, 31, 71;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #d61f47;
  --bs-btn-active-border-color: #d61f47;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d61f47;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d61f47;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #ebeef2;
  --bs-btn-border-color: #ebeef2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ebeef2;
  --bs-btn-hover-border-color: #ebeef2;
  --bs-btn-focus-shadow-rgb: 235, 238, 242;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ebeef2;
  --bs-btn-active-border-color: #ebeef2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ebeef2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ebeef2;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #334155;
  --bs-btn-border-color: #334155;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #334155;
  --bs-btn-hover-border-color: #334155;
  --bs-btn-focus-shadow-rgb: 51, 65, 85;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #334155;
  --bs-btn-active-border-color: #334155;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #334155;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #334155;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 68, 111, 221;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 0.773rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.676375rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.25s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 11.25rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.773rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: #d2d9e2;
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: #dfe3ea;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.08);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #212529;
  --bs-dropdown-link-hover-bg: #ebeef2;
  --bs-dropdown-link-active-color: #212529;
  --bs-dropdown-link-active-bg: #dde2e9;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 0.75rem;
  --bs-dropdown-item-padding-y: 0.5rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 0.75rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.676375rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: #d2d9e2;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #dfe3ea;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #212529;
  --bs-dropdown-link-active-bg: #dde2e9;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: 600;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(35, 86, 215, 0.25);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: #dfe3ea;
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: #dfe3ea #dfe3ea #dfe3ea;
  --bs-nav-tabs-link-active-color: #212529;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dfe3ea #dfe3ea #dfe3ea;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #2356d7;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3550625rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 0.96625rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 0.96625rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: 600;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-title-spacer-y: 0.625rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: #dde2e9;
  --bs-card-border-radius: 0.3rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.3rem - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.625rem;
  --bs-card-cap-padding-x: 1.25rem;
  --bs-card-cap-bg: #f6f7f9;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1.25rem;
  --bs-card-group-margin: 1.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230e2256'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #91abeb;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(35, 86, 215, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.773rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 0;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.773rem;
  --bs-pagination-color: #212529;
  --bs-pagination-bg: transparent;
  --bs-pagination-border-width: 2px;
  --bs-pagination-border-color: transparent;
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: #2356d7;
  --bs-pagination-hover-bg: transparent;
  --bs-pagination-hover-border-color: #2356d7;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #ebeef2;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: #2356d7;
  --bs-pagination-active-bg: #f0f2f5;
  --bs-pagination-active-border-color: #2356d7;
  --bs-pagination-disabled-color: #adb5bd;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #fff;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0.25rem;
}
.page-item .page-link {
  border-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 0.96625rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.676375rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 600;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 0 solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1.25rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1.25rem;
  --bs-progress-font-size: 1rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #2356d7;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: #dfe3ea;
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1.25rem;
  --bs-list-group-item-padding-y: 0.75rem;
  --bs-list-group-action-color: #212529;
  --bs-list-group-action-hover-color: #212529;
  --bs-list-group-action-hover-bg: #f0f2f5;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #dfe3ea;
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #2356d7;
  --bs-list-group-active-border-color: #2356d7;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(35, 86, 215, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.75rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: none;
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - 0);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: #dfe3ea;
  --bs-modal-header-border-width: 0;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #dfe3ea;
  --bs-modal-footer-border-width: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: none;
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.676375rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "Inter", "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.676375rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: #dfe3ea;
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.05);
  --bs-popover-header-padding-x: 0.625rem;
  --bs-popover-header-padding-y: 0.75rem;
  --bs-popover-header-font-size: 0.773rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: #fff;
  --bs-popover-body-padding-x: 0.625rem;
  --bs-popover-body-padding-y: 0.625rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Inter", "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .dark-mode .bs-popover-auto[x-placement^=top] > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=top] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=top] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=top] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=top] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .dark-mode .bs-popover-auto[x-placement^=right] > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=right] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=right] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=right] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=right] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .dark-mode .bs-popover-auto[x-placement^=bottom] > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=bottom] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=bottom] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=bottom] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=bottom] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .dark-mode .bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .dark-mode .bs-popover-auto[x-placement^=left] > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=left] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=left] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=left] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=left] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: 0;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: none;
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(35, 86, 215, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(71, 85, 105, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(19, 154, 82, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(19, 145, 170, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(234, 88, 12, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(214, 31, 71, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(235, 238, 242, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(51, 65, 85, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(28, 69, 172, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(28, 69, 172, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(57, 68, 84, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(57, 68, 84, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(15, 123, 66, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(15, 123, 66, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(15, 116, 136, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(15, 116, 136, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(187, 70, 10, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(187, 70, 10, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(171, 25, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(171, 25, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(239, 241, 245, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(239, 241, 245, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(41, 52, 68, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 52, 68, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.m-8 {
  margin: 9rem !important;
}

.m-9 {
  margin: 12rem !important;
}

.m-10 {
  margin: 18rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-7 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-8 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-9 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-10 {
  margin-right: 18rem !important;
  margin-left: 18rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-8 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-9 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-10 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4.5rem !important;
}

.mt-7 {
  margin-top: 6rem !important;
}

.mt-8 {
  margin-top: 9rem !important;
}

.mt-9 {
  margin-top: 12rem !important;
}

.mt-10 {
  margin-top: 18rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 4.5rem !important;
}

.me-7 {
  margin-right: 6rem !important;
}

.me-8 {
  margin-right: 9rem !important;
}

.me-9 {
  margin-right: 12rem !important;
}

.me-10 {
  margin-right: 18rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4.5rem !important;
}

.mb-7 {
  margin-bottom: 6rem !important;
}

.mb-8 {
  margin-bottom: 9rem !important;
}

.mb-9 {
  margin-bottom: 12rem !important;
}

.mb-10 {
  margin-bottom: 18rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 4.5rem !important;
}

.ms-7 {
  margin-left: 6rem !important;
}

.ms-8 {
  margin-left: 9rem !important;
}

.ms-9 {
  margin-left: 12rem !important;
}

.ms-10 {
  margin-left: 18rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.m-n8 {
  margin: -9rem !important;
}

.m-n9 {
  margin: -12rem !important;
}

.m-n10 {
  margin: -18rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n7 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n8 {
  margin-right: -9rem !important;
  margin-left: -9rem !important;
}

.mx-n9 {
  margin-right: -12rem !important;
  margin-left: -12rem !important;
}

.mx-n10 {
  margin-right: -18rem !important;
  margin-left: -18rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n7 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n8 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.my-n9 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.my-n10 {
  margin-top: -18rem !important;
  margin-bottom: -18rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -4.5rem !important;
}

.mt-n7 {
  margin-top: -6rem !important;
}

.mt-n8 {
  margin-top: -9rem !important;
}

.mt-n9 {
  margin-top: -12rem !important;
}

.mt-n10 {
  margin-top: -18rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -4.5rem !important;
}

.me-n7 {
  margin-right: -6rem !important;
}

.me-n8 {
  margin-right: -9rem !important;
}

.me-n9 {
  margin-right: -12rem !important;
}

.me-n10 {
  margin-right: -18rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -4.5rem !important;
}

.mb-n7 {
  margin-bottom: -6rem !important;
}

.mb-n8 {
  margin-bottom: -9rem !important;
}

.mb-n9 {
  margin-bottom: -12rem !important;
}

.mb-n10 {
  margin-bottom: -18rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -4.5rem !important;
}

.ms-n7 {
  margin-left: -6rem !important;
}

.ms-n8 {
  margin-left: -9rem !important;
}

.ms-n9 {
  margin-left: -12rem !important;
}

.ms-n10 {
  margin-left: -18rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.p-8 {
  padding: 9rem !important;
}

.p-9 {
  padding: 12rem !important;
}

.p-10 {
  padding: 18rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-7 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-8 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-9 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.px-10 {
  padding-right: 18rem !important;
  padding-left: 18rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-8 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-9 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-10 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4.5rem !important;
}

.pt-7 {
  padding-top: 6rem !important;
}

.pt-8 {
  padding-top: 9rem !important;
}

.pt-9 {
  padding-top: 12rem !important;
}

.pt-10 {
  padding-top: 18rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 4.5rem !important;
}

.pe-7 {
  padding-right: 6rem !important;
}

.pe-8 {
  padding-right: 9rem !important;
}

.pe-9 {
  padding-right: 12rem !important;
}

.pe-10 {
  padding-right: 18rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4.5rem !important;
}

.pb-7 {
  padding-bottom: 6rem !important;
}

.pb-8 {
  padding-bottom: 9rem !important;
}

.pb-9 {
  padding-bottom: 12rem !important;
}

.pb-10 {
  padding-bottom: 18rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 4.5rem !important;
}

.ps-7 {
  padding-left: 6rem !important;
}

.ps-8 {
  padding-left: 9rem !important;
}

.ps-9 {
  padding-left: 12rem !important;
}

.ps-10 {
  padding-left: 18rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 4.5rem !important;
}

.gap-7 {
  gap: 6rem !important;
}

.gap-8 {
  gap: 9rem !important;
}

.gap-9 {
  gap: 12rem !important;
}

.gap-10 {
  gap: 18rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 4.5rem !important;
}

.row-gap-7 {
  row-gap: 6rem !important;
}

.row-gap-8 {
  row-gap: 9rem !important;
}

.row-gap-9 {
  row-gap: 12rem !important;
}

.row-gap-10 {
  row-gap: 18rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-6 {
  column-gap: 4.5rem !important;
}

.column-gap-7 {
  column-gap: 6rem !important;
}

.column-gap-8 {
  column-gap: 9rem !important;
}

.column-gap-9 {
  column-gap: 12rem !important;
}

.column-gap-10 {
  column-gap: 18rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.298925rem + 0.5871vw) !important;
}

.fs-2 {
  font-size: calc(1.2699375rem + 0.23925vw) !important;
}

.fs-3 {
  font-size: 1.1595rem !important;
}

.fs-4 {
  font-size: 0.96625rem !important;
}

.fs-5 {
  font-size: 0.869625rem !important;
}

.fs-6 {
  font-size: 0.773rem !important;
}

.fs-base {
  font-size: 0.773rem !important;
}

.fs-xs {
  font-size: 0.57975rem !important;
}

.fs-sm {
  font-size: 0.676375rem !important;
}

.fs-lg {
  font-size: 0.96625rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-extrabold {
  font-weight: 800 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.tracking-tighter {
  letter-spacing: -0.05em !important;
}

.tracking-tight {
  letter-spacing: -0.025em !important;
}

.tracking-normal {
  letter-spacing: 0 !important;
}

.tracking-wide {
  letter-spacing: 0.025em !important;
}

.tracking-wider {
  letter-spacing: 0.05em !important;
}

.tracking-widest {
  letter-spacing: 0.1em !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .m-sm-8 {
    margin: 9rem !important;
  }
  .m-sm-9 {
    margin: 12rem !important;
  }
  .m-sm-10 {
    margin: 18rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-sm-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-sm-10 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-sm-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-sm-10 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 6rem !important;
  }
  .mt-sm-8 {
    margin-top: 9rem !important;
  }
  .mt-sm-9 {
    margin-top: 12rem !important;
  }
  .mt-sm-10 {
    margin-top: 18rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 4.5rem !important;
  }
  .me-sm-7 {
    margin-right: 6rem !important;
  }
  .me-sm-8 {
    margin-right: 9rem !important;
  }
  .me-sm-9 {
    margin-right: 12rem !important;
  }
  .me-sm-10 {
    margin-right: 18rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 9rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 18rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 6rem !important;
  }
  .ms-sm-8 {
    margin-left: 9rem !important;
  }
  .ms-sm-9 {
    margin-left: 12rem !important;
  }
  .ms-sm-10 {
    margin-left: 18rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4.5rem !important;
  }
  .m-sm-n7 {
    margin: -6rem !important;
  }
  .m-sm-n8 {
    margin: -9rem !important;
  }
  .m-sm-n9 {
    margin: -12rem !important;
  }
  .m-sm-n10 {
    margin: -18rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n8 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-sm-n9 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-sm-n10 {
    margin-right: -18rem !important;
    margin-left: -18rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-sm-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n8 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-sm-n9 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-sm-n10 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -6rem !important;
  }
  .mt-sm-n8 {
    margin-top: -9rem !important;
  }
  .mt-sm-n9 {
    margin-top: -12rem !important;
  }
  .mt-sm-n10 {
    margin-top: -18rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -6rem !important;
  }
  .me-sm-n8 {
    margin-right: -9rem !important;
  }
  .me-sm-n9 {
    margin-right: -12rem !important;
  }
  .me-sm-n10 {
    margin-right: -18rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -9rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -12rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -18rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -6rem !important;
  }
  .ms-sm-n8 {
    margin-left: -9rem !important;
  }
  .ms-sm-n9 {
    margin-left: -12rem !important;
  }
  .ms-sm-n10 {
    margin-left: -18rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .p-sm-8 {
    padding: 9rem !important;
  }
  .p-sm-9 {
    padding: 12rem !important;
  }
  .p-sm-10 {
    padding: 18rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-sm-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-sm-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-sm-10 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-sm-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-sm-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-sm-10 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 6rem !important;
  }
  .pt-sm-8 {
    padding-top: 9rem !important;
  }
  .pt-sm-9 {
    padding-top: 12rem !important;
  }
  .pt-sm-10 {
    padding-top: 18rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 6rem !important;
  }
  .pe-sm-8 {
    padding-right: 9rem !important;
  }
  .pe-sm-9 {
    padding-right: 12rem !important;
  }
  .pe-sm-10 {
    padding-right: 18rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 9rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 12rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 18rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 6rem !important;
  }
  .ps-sm-8 {
    padding-left: 9rem !important;
  }
  .ps-sm-9 {
    padding-left: 12rem !important;
  }
  .ps-sm-10 {
    padding-left: 18rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 4.5rem !important;
  }
  .gap-sm-7 {
    gap: 6rem !important;
  }
  .gap-sm-8 {
    gap: 9rem !important;
  }
  .gap-sm-9 {
    gap: 12rem !important;
  }
  .gap-sm-10 {
    gap: 18rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 4.5rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 6rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 9rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 12rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 18rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 4.5rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 6rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 9rem !important;
  }
  .column-gap-sm-9 {
    column-gap: 12rem !important;
  }
  .column-gap-sm-10 {
    column-gap: 18rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .m-md-8 {
    margin: 9rem !important;
  }
  .m-md-9 {
    margin: 12rem !important;
  }
  .m-md-10 {
    margin: 18rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-md-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-md-10 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-md-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-md-10 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 4.5rem !important;
  }
  .mt-md-7 {
    margin-top: 6rem !important;
  }
  .mt-md-8 {
    margin-top: 9rem !important;
  }
  .mt-md-9 {
    margin-top: 12rem !important;
  }
  .mt-md-10 {
    margin-top: 18rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 4.5rem !important;
  }
  .me-md-7 {
    margin-right: 6rem !important;
  }
  .me-md-8 {
    margin-right: 9rem !important;
  }
  .me-md-9 {
    margin-right: 12rem !important;
  }
  .me-md-10 {
    margin-right: 18rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 6rem !important;
  }
  .mb-md-8 {
    margin-bottom: 9rem !important;
  }
  .mb-md-9 {
    margin-bottom: 12rem !important;
  }
  .mb-md-10 {
    margin-bottom: 18rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 4.5rem !important;
  }
  .ms-md-7 {
    margin-left: 6rem !important;
  }
  .ms-md-8 {
    margin-left: 9rem !important;
  }
  .ms-md-9 {
    margin-left: 12rem !important;
  }
  .ms-md-10 {
    margin-left: 18rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n6 {
    margin: -4.5rem !important;
  }
  .m-md-n7 {
    margin: -6rem !important;
  }
  .m-md-n8 {
    margin: -9rem !important;
  }
  .m-md-n9 {
    margin: -12rem !important;
  }
  .m-md-n10 {
    margin: -18rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-md-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n8 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-md-n9 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-md-n10 {
    margin-right: -18rem !important;
    margin-left: -18rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-md-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n8 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-md-n9 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-md-n10 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -6rem !important;
  }
  .mt-md-n8 {
    margin-top: -9rem !important;
  }
  .mt-md-n9 {
    margin-top: -12rem !important;
  }
  .mt-md-n10 {
    margin-top: -18rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n6 {
    margin-right: -4.5rem !important;
  }
  .me-md-n7 {
    margin-right: -6rem !important;
  }
  .me-md-n8 {
    margin-right: -9rem !important;
  }
  .me-md-n9 {
    margin-right: -12rem !important;
  }
  .me-md-n10 {
    margin-right: -18rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -9rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -12rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -18rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -6rem !important;
  }
  .ms-md-n8 {
    margin-left: -9rem !important;
  }
  .ms-md-n9 {
    margin-left: -12rem !important;
  }
  .ms-md-n10 {
    margin-left: -18rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .p-md-8 {
    padding: 9rem !important;
  }
  .p-md-9 {
    padding: 12rem !important;
  }
  .p-md-10 {
    padding: 18rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-md-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-md-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-md-10 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-md-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-md-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-md-10 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 4.5rem !important;
  }
  .pt-md-7 {
    padding-top: 6rem !important;
  }
  .pt-md-8 {
    padding-top: 9rem !important;
  }
  .pt-md-9 {
    padding-top: 12rem !important;
  }
  .pt-md-10 {
    padding-top: 18rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 4.5rem !important;
  }
  .pe-md-7 {
    padding-right: 6rem !important;
  }
  .pe-md-8 {
    padding-right: 9rem !important;
  }
  .pe-md-9 {
    padding-right: 12rem !important;
  }
  .pe-md-10 {
    padding-right: 18rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 6rem !important;
  }
  .pb-md-8 {
    padding-bottom: 9rem !important;
  }
  .pb-md-9 {
    padding-bottom: 12rem !important;
  }
  .pb-md-10 {
    padding-bottom: 18rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 4.5rem !important;
  }
  .ps-md-7 {
    padding-left: 6rem !important;
  }
  .ps-md-8 {
    padding-left: 9rem !important;
  }
  .ps-md-9 {
    padding-left: 12rem !important;
  }
  .ps-md-10 {
    padding-left: 18rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 4.5rem !important;
  }
  .gap-md-7 {
    gap: 6rem !important;
  }
  .gap-md-8 {
    gap: 9rem !important;
  }
  .gap-md-9 {
    gap: 12rem !important;
  }
  .gap-md-10 {
    gap: 18rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .row-gap-md-6 {
    row-gap: 4.5rem !important;
  }
  .row-gap-md-7 {
    row-gap: 6rem !important;
  }
  .row-gap-md-8 {
    row-gap: 9rem !important;
  }
  .row-gap-md-9 {
    row-gap: 12rem !important;
  }
  .row-gap-md-10 {
    row-gap: 18rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .column-gap-md-6 {
    column-gap: 4.5rem !important;
  }
  .column-gap-md-7 {
    column-gap: 6rem !important;
  }
  .column-gap-md-8 {
    column-gap: 9rem !important;
  }
  .column-gap-md-9 {
    column-gap: 12rem !important;
  }
  .column-gap-md-10 {
    column-gap: 18rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .m-lg-8 {
    margin: 9rem !important;
  }
  .m-lg-9 {
    margin: 12rem !important;
  }
  .m-lg-10 {
    margin: 18rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-lg-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-lg-10 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-lg-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-lg-10 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 6rem !important;
  }
  .mt-lg-8 {
    margin-top: 9rem !important;
  }
  .mt-lg-9 {
    margin-top: 12rem !important;
  }
  .mt-lg-10 {
    margin-top: 18rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 4.5rem !important;
  }
  .me-lg-7 {
    margin-right: 6rem !important;
  }
  .me-lg-8 {
    margin-right: 9rem !important;
  }
  .me-lg-9 {
    margin-right: 12rem !important;
  }
  .me-lg-10 {
    margin-right: 18rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 9rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 18rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 6rem !important;
  }
  .ms-lg-8 {
    margin-left: 9rem !important;
  }
  .ms-lg-9 {
    margin-left: 12rem !important;
  }
  .ms-lg-10 {
    margin-left: 18rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4.5rem !important;
  }
  .m-lg-n7 {
    margin: -6rem !important;
  }
  .m-lg-n8 {
    margin: -9rem !important;
  }
  .m-lg-n9 {
    margin: -12rem !important;
  }
  .m-lg-n10 {
    margin: -18rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n8 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-lg-n9 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-lg-n10 {
    margin-right: -18rem !important;
    margin-left: -18rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-lg-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n8 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-lg-n9 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-lg-n10 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -6rem !important;
  }
  .mt-lg-n8 {
    margin-top: -9rem !important;
  }
  .mt-lg-n9 {
    margin-top: -12rem !important;
  }
  .mt-lg-n10 {
    margin-top: -18rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -6rem !important;
  }
  .me-lg-n8 {
    margin-right: -9rem !important;
  }
  .me-lg-n9 {
    margin-right: -12rem !important;
  }
  .me-lg-n10 {
    margin-right: -18rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -9rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -12rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -18rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -6rem !important;
  }
  .ms-lg-n8 {
    margin-left: -9rem !important;
  }
  .ms-lg-n9 {
    margin-left: -12rem !important;
  }
  .ms-lg-n10 {
    margin-left: -18rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .p-lg-8 {
    padding: 9rem !important;
  }
  .p-lg-9 {
    padding: 12rem !important;
  }
  .p-lg-10 {
    padding: 18rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-lg-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-lg-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-lg-10 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-lg-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-lg-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-lg-10 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 6rem !important;
  }
  .pt-lg-8 {
    padding-top: 9rem !important;
  }
  .pt-lg-9 {
    padding-top: 12rem !important;
  }
  .pt-lg-10 {
    padding-top: 18rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 6rem !important;
  }
  .pe-lg-8 {
    padding-right: 9rem !important;
  }
  .pe-lg-9 {
    padding-right: 12rem !important;
  }
  .pe-lg-10 {
    padding-right: 18rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 9rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 12rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 18rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 6rem !important;
  }
  .ps-lg-8 {
    padding-left: 9rem !important;
  }
  .ps-lg-9 {
    padding-left: 12rem !important;
  }
  .ps-lg-10 {
    padding-left: 18rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 4.5rem !important;
  }
  .gap-lg-7 {
    gap: 6rem !important;
  }
  .gap-lg-8 {
    gap: 9rem !important;
  }
  .gap-lg-9 {
    gap: 12rem !important;
  }
  .gap-lg-10 {
    gap: 18rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 4.5rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 6rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 9rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 12rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 18rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 4.5rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 6rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 9rem !important;
  }
  .column-gap-lg-9 {
    column-gap: 12rem !important;
  }
  .column-gap-lg-10 {
    column-gap: 18rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .m-xl-8 {
    margin: 9rem !important;
  }
  .m-xl-9 {
    margin: 12rem !important;
  }
  .m-xl-10 {
    margin: 18rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xl-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xl-10 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xl-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xl-10 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 6rem !important;
  }
  .mt-xl-8 {
    margin-top: 9rem !important;
  }
  .mt-xl-9 {
    margin-top: 12rem !important;
  }
  .mt-xl-10 {
    margin-top: 18rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xl-7 {
    margin-right: 6rem !important;
  }
  .me-xl-8 {
    margin-right: 9rem !important;
  }
  .me-xl-9 {
    margin-right: 12rem !important;
  }
  .me-xl-10 {
    margin-right: 18rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 9rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 18rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 6rem !important;
  }
  .ms-xl-8 {
    margin-left: 9rem !important;
  }
  .ms-xl-9 {
    margin-left: 12rem !important;
  }
  .ms-xl-10 {
    margin-left: 18rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4.5rem !important;
  }
  .m-xl-n7 {
    margin: -6rem !important;
  }
  .m-xl-n8 {
    margin: -9rem !important;
  }
  .m-xl-n9 {
    margin: -12rem !important;
  }
  .m-xl-n10 {
    margin: -18rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n8 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-xl-n9 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-xl-n10 {
    margin-right: -18rem !important;
    margin-left: -18rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n8 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-xl-n9 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-xl-n10 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -6rem !important;
  }
  .mt-xl-n8 {
    margin-top: -9rem !important;
  }
  .mt-xl-n9 {
    margin-top: -12rem !important;
  }
  .mt-xl-n10 {
    margin-top: -18rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -6rem !important;
  }
  .me-xl-n8 {
    margin-right: -9rem !important;
  }
  .me-xl-n9 {
    margin-right: -12rem !important;
  }
  .me-xl-n10 {
    margin-right: -18rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -9rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -12rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -18rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -6rem !important;
  }
  .ms-xl-n8 {
    margin-left: -9rem !important;
  }
  .ms-xl-n9 {
    margin-left: -12rem !important;
  }
  .ms-xl-n10 {
    margin-left: -18rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .p-xl-8 {
    padding: 9rem !important;
  }
  .p-xl-9 {
    padding: 12rem !important;
  }
  .p-xl-10 {
    padding: 18rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xl-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xl-10 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xl-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xl-10 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 6rem !important;
  }
  .pt-xl-8 {
    padding-top: 9rem !important;
  }
  .pt-xl-9 {
    padding-top: 12rem !important;
  }
  .pt-xl-10 {
    padding-top: 18rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 6rem !important;
  }
  .pe-xl-8 {
    padding-right: 9rem !important;
  }
  .pe-xl-9 {
    padding-right: 12rem !important;
  }
  .pe-xl-10 {
    padding-right: 18rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 9rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 12rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 18rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 6rem !important;
  }
  .ps-xl-8 {
    padding-left: 9rem !important;
  }
  .ps-xl-9 {
    padding-left: 12rem !important;
  }
  .ps-xl-10 {
    padding-left: 18rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 4.5rem !important;
  }
  .gap-xl-7 {
    gap: 6rem !important;
  }
  .gap-xl-8 {
    gap: 9rem !important;
  }
  .gap-xl-9 {
    gap: 12rem !important;
  }
  .gap-xl-10 {
    gap: 18rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 6rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 9rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 12rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 18rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 6rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 9rem !important;
  }
  .column-gap-xl-9 {
    column-gap: 12rem !important;
  }
  .column-gap-xl-10 {
    column-gap: 18rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 4.5rem !important;
  }
  .m-xxl-7 {
    margin: 6rem !important;
  }
  .m-xxl-8 {
    margin: 9rem !important;
  }
  .m-xxl-9 {
    margin: 12rem !important;
  }
  .m-xxl-10 {
    margin: 18rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xxl-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xxl-10 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xxl-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xxl-10 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 6rem !important;
  }
  .mt-xxl-8 {
    margin-top: 9rem !important;
  }
  .mt-xxl-9 {
    margin-top: 12rem !important;
  }
  .mt-xxl-10 {
    margin-top: 18rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 6rem !important;
  }
  .me-xxl-8 {
    margin-right: 9rem !important;
  }
  .me-xxl-9 {
    margin-right: 12rem !important;
  }
  .me-xxl-10 {
    margin-right: 18rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 18rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 6rem !important;
  }
  .ms-xxl-8 {
    margin-left: 9rem !important;
  }
  .ms-xxl-9 {
    margin-left: 12rem !important;
  }
  .ms-xxl-10 {
    margin-left: 18rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -4.5rem !important;
  }
  .m-xxl-n7 {
    margin: -6rem !important;
  }
  .m-xxl-n8 {
    margin: -9rem !important;
  }
  .m-xxl-n9 {
    margin: -12rem !important;
  }
  .m-xxl-n10 {
    margin: -18rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -18rem !important;
    margin-left: -18rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxl-n8 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-xxl-n9 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-xxl-n10 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -9rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -12rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -18rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -6rem !important;
  }
  .me-xxl-n8 {
    margin-right: -9rem !important;
  }
  .me-xxl-n9 {
    margin-right: -12rem !important;
  }
  .me-xxl-n10 {
    margin-right: -18rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -9rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -12rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -18rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -9rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -12rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -18rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4.5rem !important;
  }
  .p-xxl-7 {
    padding: 6rem !important;
  }
  .p-xxl-8 {
    padding: 9rem !important;
  }
  .p-xxl-9 {
    padding: 12rem !important;
  }
  .p-xxl-10 {
    padding: 18rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xxl-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xxl-10 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xxl-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xxl-10 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 6rem !important;
  }
  .pt-xxl-8 {
    padding-top: 9rem !important;
  }
  .pt-xxl-9 {
    padding-top: 12rem !important;
  }
  .pt-xxl-10 {
    padding-top: 18rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 6rem !important;
  }
  .pe-xxl-8 {
    padding-right: 9rem !important;
  }
  .pe-xxl-9 {
    padding-right: 12rem !important;
  }
  .pe-xxl-10 {
    padding-right: 18rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 9rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 12rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 18rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 6rem !important;
  }
  .ps-xxl-8 {
    padding-left: 9rem !important;
  }
  .ps-xxl-9 {
    padding-left: 12rem !important;
  }
  .ps-xxl-10 {
    padding-left: 18rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 4.5rem !important;
  }
  .gap-xxl-7 {
    gap: 6rem !important;
  }
  .gap-xxl-8 {
    gap: 9rem !important;
  }
  .gap-xxl-9 {
    gap: 12rem !important;
  }
  .gap-xxl-10 {
    gap: 18rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 6rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 9rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 12rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 18rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 6rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 9rem !important;
  }
  .column-gap-xxl-9 {
    column-gap: 12rem !important;
  }
  .column-gap-xxl-10 {
    column-gap: 18rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.73925rem !important;
  }
  .fs-2 {
    font-size: 1.449375rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/inter/inter-v12-latin-300.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/inter/inter-v12-latin-regular.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/inter/inter-v12-latin-500.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/inter/inter-v12-latin-600.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/inter/inter-v12-latin-700.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/inter/inter-v12-latin-800.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../fonts/inter/inter-v12-latin-900.woff2") format("woff2");
}
b,
strong {
  font-weight: 700;
}

a {
  transition: color 0.12s ease-out;
}
@media (min-width: 576px) {
  a.link-fx {
    position: relative;
  }
  a.link-fx::before {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    content: "";
    background-color: #2356d7;
    visibility: hidden;
    transform: scaleX(0);
    transition: transform 0.12s ease-out;
  }
}
a.link-fx:hover::before, a.link-fx:focus::before {
  visibility: visible;
  transform: scaleX(1);
}

p {
  line-height: 1.6;
}

.story p,
p.story {
  line-height: 1.8;
  font-size: 1.125rem;
  color: #495057;
}

.story h2, .story .h2,
.story h3,
.story .h3,
.story h4,
.story .h4 {
  margin-top: 3rem;
}

.content-heading {
  margin-bottom: 1rem;
  padding-top: 1rem;
  font-size: 0.676375rem;
  font-weight: 600;
  color: #6c757d;
  line-height: 1.75;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
}
.content-heading small, .content-heading .small {
  margin-top: 0.25rem;
  font-size: 0.676375rem;
  font-weight: 400;
  color: #6c757d;
  text-transform: none;
  letter-spacing: normal;
}
@media (min-width: 768px) {
  .content-heading {
    padding-top: 1.5rem;
  }
  .content-heading small, .content-heading .small {
    margin-top: 0;
  }
}
.block-content > .content-heading:first-child, .content > .content-heading:first-child {
  padding-top: 0 !important;
}
.content-heading .dropdown {
  line-height: 1.5;
}

small,
.small {
  font-weight: inherit;
}

.text-uppercase {
  letter-spacing: 0.0625em;
}

.row.g-sm {
  --bs-gutter-x: 0.375rem;
}
.row.g-sm > .col,
.row.g-sm > [class*=col-] {
  padding-left: 0.1875rem;
  padding-right: 0.1875rem;
}
.row.g-sm .push,
.row.g-sm .block, .row.g-sm.items-push > div {
  margin-bottom: 0.375rem;
}

@media (max-width: 767.98px) {
  .row:not(.g-sm):not(.g-0) {
    --bs-gutter-x: 1rem;
  }
  .row:not(.g-sm):not(.g-0) > .col,
.row:not(.g-sm):not(.g-0) > [class*=col-] {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.table thead th {
  font-size: 0.676375rem;
  text-transform: uppercase;
  letter-spacing: 0.0625em;
}

.table:not(.table-borderless) > :not(:last-child) > :last-child > * {
  border-bottom-width: 2px;
}

.table-vcenter th,
.table-vcenter td {
  vertical-align: middle;
}

.js-table-checkable tbody tr,
.js-table-sections-header > tr {
  cursor: pointer;
}

.js-table-sections-header > tr > td:first-child > i {
  transition: transform 0.15s ease-out;
}
.js-table-sections-header + tbody {
  display: none;
}
.js-table-sections-header.show > tr > td:first-child > i {
  transform: rotate(90deg);
}
.js-table-sections-header.show + tbody {
  display: table-row-group;
}

.form-switch {
  min-height: 2em;
  line-height: 2em;
}
.form-switch .form-check-input {
  height: 1.5em;
}

.form-block {
  position: relative;
  padding: 0;
  z-index: 1;
}
.form-block .form-check-input {
  position: absolute;
  left: 0;
  z-index: -1;
  opacity: 0;
}
.form-block .form-check-label {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #dde2e9;
  border-radius: 0.25rem;
  cursor: pointer;
}
.form-block .form-check-label:hover {
  border-color: #ced5df;
}
.form-block .form-check-label::before {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  display: none;
  line-height: 1rem;
  color: #fff;
  font-size: 0.625rem;
  text-align: center;
  background-color: #2356d7;
  border-radius: 1rem;
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f00c";
  z-index: 1;
}
.form-block .form-check-input:checked ~ .form-check-label {
  border-color: #2356d7;
}
.form-block .form-check-input:focus ~ .form-check-label {
  border-color: #2356d7;
  box-shadow: 0 0 0 0.25rem rgba(35, 86, 215, 0.25);
}
.form-block .form-check-input:checked ~ .form-check-label::before {
  display: block;
}
.form-block .form-check-input:disabled + .form-check-label,
.form-block .form-check-input[readonly] + .form-check-label {
  opacity: 0.5;
  cursor: not-allowed;
}
.form-block .form-check-input:disabled:not([checked]) + .form-check-label:hover,
.form-block .form-check-input[readonly]:not([checked]) + .form-check-label:hover {
  border-color: #dde2e9;
}

.form-control {
  font-family: "Inter", "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-control.form-control-alt {
  border-color: #ebeef2;
  background-color: #ebeef2;
  transition: none;
}
.form-control.form-control-alt:focus {
  border-color: #dde2e9;
  background-color: #dde2e9;
  box-shadow: none;
}
.form-control.form-control-alt.is-valid {
  border-color: #d0ebdc;
  background-color: #d0ebdc;
}
.form-control.form-control-alt.is-valid:focus {
  border-color: #d7eee1;
  background-color: #d7eee1;
}
.form-control.form-control-alt.is-invalid {
  border-color: #f7d2da;
  background-color: #f7d2da;
}
.form-control.form-control-alt.is-invalid:focus {
  border-color: #f9dbe1;
  background-color: #f9dbe1;
}

.form-select {
  font-family: "Inter", "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-select.form-control-alt {
  border-color: #ebeef2;
  background-color: #ebeef2;
  transition: none;
}
.form-select.form-control-alt:focus {
  border-color: #dde2e9;
  background-color: #dde2e9;
  box-shadow: none;
}
.form-select.form-control-alt.is-valid {
  border-color: #d0ebdc;
  background-color: #d0ebdc;
}
.form-select.form-control-alt.is-valid:focus {
  border-color: #d7eee1;
  background-color: #d7eee1;
}
.form-select.form-control-alt.is-invalid {
  border-color: #f7d2da;
  background-color: #f7d2da;
}
.form-select.form-control-alt.is-invalid:focus {
  border-color: #f9dbe1;
  background-color: #f9dbe1;
}

.input-group-text.input-group-text-alt {
  background-color: #dde2e9;
  border-color: #dde2e9;
}

.btn .si {
  position: relative;
  top: 1px;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4B5563;
  --bs-btn-border-color: #4B5563;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3c444f;
  --bs-btn-hover-border-color: #38404a;
  --bs-btn-focus-shadow-rgb: 102, 111, 122;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3c444f;
  --bs-btn-active-border-color: #38404a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4B5563;
  --bs-btn-disabled-border-color: #4B5563;
}

.btn-alt-primary {
  --bs-btn-color: #153481;
  --bs-btn-bg: #c8d5f5;
  --bs-btn-border-color: #c8d5f5;
  --bs-btn-hover-color: #0e2256;
  --bs-btn-hover-bg: #91abeb;
  --bs-btn-hover-border-color: #91abeb;
  --bs-btn-focus-shadow-rgb: 173, 189, 228;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d3ddf7;
  --bs-btn-active-border-color: #ced9f6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c8d5f5;
  --bs-btn-disabled-border-color: #c8d5f5;
}

.btn-alt-secondary {
  --bs-btn-color: #1e2226;
  --bs-btn-bg: #ebeef2;
  --bs-btn-border-color: #ebeef2;
  --bs-btn-hover-color: #1a1d20;
  --bs-btn-hover-bg: #d4d6da;
  --bs-btn-hover-border-color: #d4d6da;
  --bs-btn-focus-shadow-rgb: 204, 207, 211;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #eff1f5;
  --bs-btn-active-border-color: #edf0f3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ebeef2;
  --bs-btn-disabled-border-color: #ebeef2;
}

.btn-alt-success {
  --bs-btn-color: #0b5c31;
  --bs-btn-bg: #c4e6d4;
  --bs-btn-border-color: #c4e6d4;
  --bs-btn-hover-color: #083e21;
  --bs-btn-hover-bg: #89cda9;
  --bs-btn-hover-border-color: #89cda9;
  --bs-btn-focus-shadow-rgb: 168, 209, 188;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d0ebdd;
  --bs-btn-active-border-color: #cae9d8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c4e6d4;
  --bs-btn-disabled-border-color: #c4e6d4;
}

.btn-alt-info {
  --bs-btn-color: #0b5766;
  --bs-btn-bg: #c4e4ea;
  --bs-btn-border-color: #c4e4ea;
  --bs-btn-hover-color: #083a44;
  --bs-btn-hover-bg: #89c8d5;
  --bs-btn-hover-border-color: #89c8d5;
  --bs-btn-focus-shadow-rgb: 168, 207, 214;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d0e9ee;
  --bs-btn-active-border-color: #cae7ec;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c4e4ea;
  --bs-btn-disabled-border-color: #c4e4ea;
}

.btn-alt-warning {
  --bs-btn-color: #8c3507;
  --bs-btn-bg: #fad5c2;
  --bs-btn-border-color: #fad5c2;
  --bs-btn-hover-color: #5e2305;
  --bs-btn-hover-bg: #f5ac86;
  --bs-btn-hover-border-color: #f5ac86;
  --bs-btn-focus-shadow-rgb: 234, 189, 166;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fbddce;
  --bs-btn-active-border-color: #fbd9c8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fad5c2;
  --bs-btn-disabled-border-color: #fad5c2;
}

.btn-alt-danger {
  --bs-btn-color: #80132b;
  --bs-btn-bg: #f5c7d1;
  --bs-btn-border-color: #f5c7d1;
  --bs-btn-hover-color: #560c1c;
  --bs-btn-hover-bg: #eb8fa3;
  --bs-btn-hover-border-color: #eb8fa3;
  --bs-btn-focus-shadow-rgb: 227, 172, 184;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f7d2da;
  --bs-btn-active-border-color: #f6cdd6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f5c7d1;
  --bs-btn-disabled-border-color: #f5c7d1;
}

.page-header-dark #page-header .btn-alt-secondary,
.sidebar-dark #sidebar .btn-alt-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #283547;
  --bs-btn-border-color: #283547;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #161d27;
  --bs-btn-hover-border-color: #161d27;
  --bs-btn-focus-shadow-rgb: 72, 83, 99;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #202a39;
  --bs-btn-active-border-color: #1e2835;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #283547;
  --bs-btn-disabled-border-color: #283547;
}

.fade {
  transition: opacity 0.25s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade.fade-up, .fade.fade-right, .fade.fade-left {
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .fade.fade-up, .fade.fade-right, .fade.fade-left {
    transition: none;
  }
}
.fade.fade-up:not(.show) {
  transform: translateY(50px);
}
.fade.fade-right:not(.show) {
  transform: translateX(-50px);
}
.fade.fade-left:not(.show) {
  transform: translateX(50px);
}

.dropdown-menu {
  box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.08);
}
.dropdown-menu.dropdown-menu-md {
  min-width: 200px;
}
.dropdown-menu.dropdown-menu-lg, .dropdown-menu.dropdown-menu-xl, .dropdown-menu.dropdown-menu-xxl {
  min-width: 300px;
}
.dropdown-menu.dropdown-menu-mega {
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;
  opacity: 0;
  display: block;
  visibility: hidden;
  transform: translateY(50px);
  transform-origin: top left;
}
.dropdown-menu.dropdown-menu-mega.show {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1200px) {
  .dropdown-menu.dropdown-menu-xl {
    min-width: 450px;
  }
  .dropdown-menu.dropdown-menu-xxl {
    min-width: 600px;
  }
}

.form-switch {
  min-height: 2em;
  line-height: 2em;
}
.form-switch .form-check-input {
  height: 1.5em;
}

.form-block {
  position: relative;
  padding: 0;
  z-index: 1;
}
.form-block .form-check-input {
  position: absolute;
  left: 0;
  z-index: -1;
  opacity: 0;
}
.form-block .form-check-label {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #dde2e9;
  border-radius: 0.25rem;
  cursor: pointer;
}
.form-block .form-check-label:hover {
  border-color: #ced5df;
}
.form-block .form-check-label::before {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  display: none;
  line-height: 1rem;
  color: #fff;
  font-size: 0.625rem;
  text-align: center;
  background-color: #2356d7;
  border-radius: 1rem;
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f00c";
  z-index: 1;
}
.form-block .form-check-input:checked ~ .form-check-label {
  border-color: #2356d7;
}
.form-block .form-check-input:focus ~ .form-check-label {
  border-color: #2356d7;
  box-shadow: 0 0 0 0.25rem rgba(35, 86, 215, 0.25);
}
.form-block .form-check-input:checked ~ .form-check-label::before {
  display: block;
}
.form-block .form-check-input:disabled + .form-check-label,
.form-block .form-check-input[readonly] + .form-check-label {
  opacity: 0.5;
  cursor: not-allowed;
}
.form-block .form-check-input:disabled:not([checked]) + .form-check-label:hover,
.form-block .form-check-input[readonly]:not([checked]) + .form-check-label:hover {
  border-color: #dde2e9;
}

.form-control {
  font-family: "Inter", "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-control.form-control-alt {
  border-color: #ebeef2;
  background-color: #ebeef2;
  transition: none;
}
.form-control.form-control-alt:focus {
  border-color: #dde2e9;
  background-color: #dde2e9;
  box-shadow: none;
}
.form-control.form-control-alt.is-valid {
  border-color: #d0ebdc;
  background-color: #d0ebdc;
}
.form-control.form-control-alt.is-valid:focus {
  border-color: #d7eee1;
  background-color: #d7eee1;
}
.form-control.form-control-alt.is-invalid {
  border-color: #f7d2da;
  background-color: #f7d2da;
}
.form-control.form-control-alt.is-invalid:focus {
  border-color: #f9dbe1;
  background-color: #f9dbe1;
}

.form-select {
  font-family: "Inter", "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-select.form-control-alt {
  border-color: #ebeef2;
  background-color: #ebeef2;
  transition: none;
}
.form-select.form-control-alt:focus {
  border-color: #dde2e9;
  background-color: #dde2e9;
  box-shadow: none;
}
.form-select.form-control-alt.is-valid {
  border-color: #d0ebdc;
  background-color: #d0ebdc;
}
.form-select.form-control-alt.is-valid:focus {
  border-color: #d7eee1;
  background-color: #d7eee1;
}
.form-select.form-control-alt.is-invalid {
  border-color: #f7d2da;
  background-color: #f7d2da;
}
.form-select.form-control-alt.is-invalid:focus {
  border-color: #f9dbe1;
  background-color: #f9dbe1;
}

.input-group-text.input-group-text-alt {
  background-color: #dde2e9;
  border-color: #dde2e9;
}

.nav-pills .nav-link {
  color: #212529;
}
.nav-pills .nav-link:hover, .nav-pills .nav-link:focus {
  background-color: #ebeef2;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2356d7;
}

.nav-tabs-block {
  background-color: #f6f7f9;
  border-bottom: none;
}
.nav-tabs-block .nav-item {
  margin-bottom: 0;
}
.nav-tabs-block .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-color: transparent;
  border-radius: 0;
  color: #212529;
  font-size: 0.676375rem;
}
.nav-tabs-block .nav-link:hover, .nav-tabs-block .nav-link:focus {
  color: #2356d7;
  background-color: #f6f7f9;
  border-color: transparent;
}
.nav-tabs-block .nav-link.active,
.nav-tabs-block .nav-item.show .nav-link {
  color: #212529;
  background-color: #fff;
  border-color: transparent;
}

.nav-tabs-alt {
  background-color: transparent;
  border-bottom-color: #dde2e9;
}
.nav-tabs-alt .nav-item {
  margin-bottom: -1px;
}
.nav-tabs-alt .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #212529;
  font-size: 0.676375rem;
  background-color: transparent;
  border: none;
}
.nav-tabs-alt .nav-link:hover, .nav-tabs-alt .nav-link:focus {
  color: #2356d7;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -2px #2356d7;
}
.nav-tabs-alt .nav-link.active,
.nav-tabs-alt .nav-item.show .nav-link {
  color: #212529;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -2px #2356d7;
}

.nav-items {
  padding-left: 0;
  list-style: none;
}
.nav-items a:hover {
  background-color: #f6f7f9;
}
.nav-items a:active {
  background-color: #ebeef2;
}
.nav-items > li:not(:last-child) > a {
  border-bottom: 1px solid #ebeef2;
}

.mini-nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  color: #afbbcb;
  font-size: 1rem;
}
.mini-nav-item i {
  transition: transform 0.2s ease-out;
}
.mini-nav-item.active {
  background-color: #2a384b;
  color: #fff;
}
.mini-nav-item:hover {
  color: #fff;
  background-color: #2a384b;
}
.mini-nav-item:active {
  color: #afbbcb;
}
.mini-nav-item:active i {
  transform: scale(0.9);
}

.card.card-borderless {
  box-shadow: 0 1px 2px rgba(214, 219, 226, 0.5), 0 1px 2px rgba(214, 219, 226, 0.5);
}
.card.card-square {
  border-radius: 0;
}
.card.card-square > .list-group:first-child,
.card.card-square > .list-group:last-child,
.card.card-square > .card-header:first-child,
.card.card-square > .card-footer:last-child,
.card.card-square .card-img-overlay,
.card.card-square .card-img,
.card.card-square .card-img-top,
.card.card-square .card-img,
.card.card-square .card-img-bottom {
  border-radius: 0;
}
.card.card-borderless {
  border: 0;
}
.card.card-borderless > .card-header {
  border-bottom: 0;
}
.card.card-borderless > .card-footer {
  border-top: 0;
}

.breadcrumb.breadcrumb-alt .breadcrumb-item {
  font-size: 0.676375rem;
  font-weight: 600;
}
.breadcrumb.breadcrumb-alt .breadcrumb-item + .breadcrumb-item::before {
  position: relative;
  top: 1px;
  width: 1.25rem;
  font-family: "simple-line-icons";
  font-size: 0.676375rem;
  color: #ced4da;
  content: "\e606";
}

.page-link {
  font-weight: 600;
  border-width: 0 0 2px 0;
  border-radius: 0 !important;
}

.modal-dialog.modal-dialog-top {
  margin-top: 0 !important;
}

.modal.fade .modal-dialog {
  transition-duration: 0.12s;
}
.modal.fade .modal-dialog.modal-dialog-popin {
  transform: scale(1.1);
}
.modal.fade .modal-dialog.modal-dialog-popout {
  transform: scale(0.9);
}
.modal.fade .modal-dialog.modal-dialog-slideup {
  transform: translate(0, 10%);
}
.modal.fade .modal-dialog.modal-dialog-slideright {
  transform: translate(-10%, 0);
}
.modal.fade .modal-dialog.modal-dialog-slideleft {
  transform: translate(10%, 0);
}
.modal.fade .modal-dialog.modal-dialog-fromright {
  transform: translateX(25%) rotate(10deg) scale(0.9);
}
.modal.fade .modal-dialog.modal-dialog-fromleft {
  transform: translateX(-25%) rotate(-10deg) scale(0.9);
}
.modal.show .modal-dialog.modal-dialog-popin, .modal.show .modal-dialog.modal-dialog-popout {
  transform: scale(1);
}
.modal.show .modal-dialog.modal-dialog-slideup, .modal.show .modal-dialog.modal-dialog-slideright, .modal.show .modal-dialog.modal-dialog-slideleft {
  transform: translate(0, 0);
}
.modal.show .modal-dialog.modal-dialog-fromright, .modal.show .modal-dialog.modal-dialog-fromleft {
  transform: translateX(0) rotate(0) scale(1);
}

@media print {
  #page-container,
#main-container {
    padding: 0 !important;
  }
  #page-overlay,
#page-loader,
#page-header,
#page-footer,
#sidebar,
#side-overlay,
.block-options {
    display: none !important;
  }
  .block {
    box-shadow: none !important;
  }
}
#page-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  background-color: #ebeef2;
}

#page-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1033;
  opacity: 0;
  transition: opacity 0.28s ease-out;
  transform: translateY(-100%);
}
@media (min-width: 992px) {
  .side-overlay-o.enable-page-overlay #page-overlay {
    transform: translateY(0);
    opacity: 1;
  }
}

#main-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 100%;
}

.content {
  width: 100%;
  margin: 0 auto;
  padding: 1rem 1rem 1px;
  overflow-x: visible;
}
.content > .pull-t,
.content > .pull-y {
  margin-top: -1rem;
}
.content > .pull-b,
.content > .pull-y {
  margin-bottom: -1px;
}
.content > .pull-r,
.content > .pull-x {
  margin-right: -1rem;
}
.content > .pull-r,
.content > .pull-x {
  margin-left: -1rem;
}
.content > .pull {
  margin: -1rem -1rem -1px;
}
.content.content-full {
  padding-bottom: 1rem;
}
.content.content-full > .pull-b,
.content.content-full > .pull-y,
.content.content-full > .pull {
  margin-bottom: -1rem;
}

.content p,
.content .push,
.content .block,
.content .items-push > div {
  margin-bottom: 1rem;
}
.content .items-push-2x > div {
  margin-bottom: 2rem;
}
.content .items-push-3x > div {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .content {
    width: 100%;
    margin: 0 auto;
    padding: 1.875rem 1.875rem 1px;
    overflow-x: visible;
  }
  .content > .pull-t,
.content > .pull-y {
    margin-top: -1.875rem;
  }
  .content > .pull-b,
.content > .pull-y {
    margin-bottom: -1px;
  }
  .content > .pull-r,
.content > .pull-x {
    margin-right: -1.875rem;
  }
  .content > .pull-r,
.content > .pull-x {
    margin-left: -1.875rem;
  }
  .content > .pull {
    margin: -1.875rem -1.875rem -1px;
  }
  .content.content-full {
    padding-bottom: 1.875rem;
  }
  .content.content-full > .pull-b,
.content.content-full > .pull-y,
.content.content-full > .pull {
    margin-bottom: -1.875rem;
  }
  .content p,
.content .push,
.content .block,
.content .items-push > div {
    margin-bottom: 1.875rem;
  }
  .content .items-push-2x > div {
    margin-bottom: 3.75rem;
  }
  .content .items-push-3x > div {
    margin-bottom: 5.625rem;
  }
}
@media (min-width: 1200px) {
  .content.content-narrow {
    width: 90% !important;
  }
}
.content.content-boxed {
  max-width: 1280px !important;
}
.content.content-top {
  padding-top: 5rem;
}
@media (min-width: 992px) {
  .content.content-top {
    padding-top: 5.875rem;
  }
}

.content-section-top {
  padding-top: 4rem;
}

.content-side {
  width: 100%;
  margin: 0 auto;
  padding: 1.25rem 1.25rem 1px;
  overflow-x: hidden;
}
.content-side > .pull-t,
.content-side > .pull-y {
  margin-top: -1.25rem;
}
.content-side > .pull-b,
.content-side > .pull-y {
  margin-bottom: -1px;
}
.content-side > .pull-r,
.content-side > .pull-x {
  margin-right: -1.25rem;
}
.content-side > .pull-r,
.content-side > .pull-x {
  margin-left: -1.25rem;
}
.content-side > .pull {
  margin: -1.25rem -1.25rem -1px;
}
.content-side.content-side-full {
  padding-bottom: 1.25rem;
}
.content-side.content-side-full > .pull-b,
.content-side.content-side-full > .pull-y,
.content-side.content-side-full > .pull {
  margin-bottom: -1.25rem;
}

.content-side p,
.content-side .push,
.content-side .block,
.content-side .items-push > div {
  margin-bottom: 1.25rem;
}
.content-side .items-push-2x > div {
  margin-bottom: 2.5rem;
}
.content-side .items-push-3x > div {
  margin-bottom: 3.75rem;
}

#page-header {
  position: relative;
  margin: 0 auto;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

.overlay-header {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  transform: translateY(-100%);
  will-change: opacity;
  z-index: 1;
}
.overlay-header.show {
  opacity: 1;
  transform: translateY(0);
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 4rem;
}
#page-header .content-header {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  #page-header .content-header {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

#sidebar .content-header, #side-overlay .content-header {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.smini-visible,
.smini-visible-block {
  display: none;
}

.smini-show {
  opacity: 0;
}

.smini-show,
.smini-hide {
  transition: opacity 0.28s ease-out;
}

#sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1032;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(-100%) translateY(0) translateZ(0);
  -webkit-overflow-scrolling: touch;
  will-change: transform;
}
.side-trans-enabled #sidebar {
  transition: transform 0.28s ease-out;
}
#sidebar.with-mini-nav {
  padding-left: 46px;
}
#sidebar.with-mini-nav .sidebar-mini-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 46px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #e9ecef;
  background-color: #253041;
}
.sidebar-r #sidebar {
  right: 0;
  left: auto;
  transform: translateX(100%);
}
.sidebar-r #sidebar.with-mini-nav {
  padding-right: 46px;
  padding-left: 0;
}
.sidebar-r #sidebar .sidebar-mini-nav {
  left: auto;
  right: 0;
}
#sidebar .js-sidebar-scroll {
  height: calc(100% - 4rem);
  overflow-y: auto;
}
#sidebar .js-sidebar-scroll.full-height {
  height: 100%;
}
@media (max-width: 991.98px) {
  .sidebar-o-xs #sidebar {
    transform: translateX(0) translateY(0) translateZ(0);
  }
}
@media (min-width: 992px) {
  #sidebar {
    width: 250px;
  }
  .sidebar-o #sidebar {
    transform: translateX(0) translateY(0) translateZ(0);
  }
  .sidebar-mini.sidebar-o #sidebar {
    overflow-x: hidden;
    transform: translateX(-190px) translateY(0) translateZ(0);
    transition: transform 0.28s ease-out;
  }
  .sidebar-mini.sidebar-o.sidebar-r #sidebar {
    transform: translateX(190px) translateY(0) translateZ(0);
  }
  .sidebar-mini.sidebar-o #sidebar .content-side, .sidebar-mini.sidebar-o #sidebar .content-header {
    width: 250px;
    transform: translateX(190px) translateY(0) translateZ(0);
    transition: transform 0.28s ease-out;
    will-change: transform;
  }
  .sidebar-mini.sidebar-o #sidebar .content-header {
    position: relative;
    z-index: 1;
  }
  .sidebar-mini.sidebar-o #sidebar:hover, .sidebar-mini.sidebar-o #sidebar:hover .content-side, .sidebar-mini.sidebar-o.sidebar-r #sidebar .content-side, .sidebar-mini.sidebar-o #sidebar:hover .content-header, .sidebar-mini.sidebar-o.sidebar-r #sidebar .content-header {
    transform: translateX(0);
  }
  .sidebar-mini.sidebar-o #sidebar .nav-main .nav-main-heading,
.sidebar-mini.sidebar-o #sidebar .nav-main .nav-main-link-name,
.sidebar-mini.sidebar-o #sidebar .nav-main .nav-main-link-badge {
    transition: opacity 0.28s ease-out;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-hide {
    opacity: 0;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-show {
    opacity: 1;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-hidden {
    display: none;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-visible {
    display: inline-block;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-visible-block {
    display: block;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main > .nav-main-item > .nav-main-submenu {
    display: none;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-heading,
.sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-link-name,
.sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-link-badge {
    opacity: 0;
  }
}

#side-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1034;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  transform: translateX(100%) translateY(0) translateZ(0);
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  will-change: transform;
}
.side-trans-enabled #side-overlay {
  transition: transform 0.28s ease-out, opacity 0.28s ease-out;
}
.sidebar-r #side-overlay {
  right: auto;
  left: 0;
  transform: translateX(-100%) translateY(0) translateZ(0);
}
.side-overlay-o #side-overlay {
  transform: translateX(0) translateY(0) translateZ(0);
  opacity: 1;
}
@media (min-width: 992px) {
  #side-overlay {
    width: 320px;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
    transform: translateX(110%) translateY(0) translateZ(0);
  }
  .sidebar-r #side-overlay {
    transform: translateX(-110%) translateY(0) translateZ(0);
  }
  .side-overlay-hover #side-overlay {
    transform: translateX(305px) translateY(0) translateZ(0);
    opacity: 1;
  }
  .sidebar-r.side-overlay-hover #side-overlay {
    transform: translateX(-305px) translateY(0) translateZ(0);
    opacity: 1;
  }
  .side-overlay-hover #side-overlay:hover, .side-overlay-o #side-overlay, .side-overlay-o.side-overlay-hover #side-overlay {
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.3);
    transform: translateX(0) translateY(0) translateZ(0);
    opacity: 1;
  }
}

#page-container > #page-header .content-header,
#page-container > #page-header .content,
#page-container > #main-container .content,
#page-container > #page-footer .content {
  max-width: 1920px;
}
@media (min-width: 1200px) {
  #page-container.main-content-narrow > #page-header .content-header,
#page-container.main-content-narrow > #page-header .content,
#page-container.main-content-narrow > #main-container .content,
#page-container.main-content-narrow > #page-footer .content {
    width: 90%;
  }
}
#page-container.main-content-boxed > #page-header .content-header,
#page-container.main-content-boxed > #page-header .content,
#page-container.main-content-boxed > #main-container .content,
#page-container.main-content-boxed > #page-footer .content {
  max-width: 1280px;
}
#page-container.page-header-dark #page-header {
  color: #d1d8de;
  background-color: #1f2937;
}
#page-container.page-header-fixed #page-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  min-width: 320px;
  max-width: 100%;
  width: auto;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(5px);
}
#page-container.page-header-fixed.page-header-dark #page-header {
  box-shadow: none;
}
#page-container.page-header-fixed #main-container {
  padding-top: 4rem;
}
@media (min-width: 992px) {
  #page-container.page-header-fixed.sidebar-o #page-header {
    padding-left: 250px;
  }
  #page-container.page-header-fixed.sidebar-o #page-header .overlay-header {
    left: 250px;
  }
  #page-container.page-header-fixed.sidebar-r.sidebar-o #page-header {
    padding-right: 250px;
    padding-left: 0;
  }
  #page-container.page-header-fixed.sidebar-r.sidebar-o #page-header .overlay-header {
    right: 250px;
    left: 0;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-o #page-header {
    padding-left: 60px;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-o #page-header .overlay-header {
    left: 60px;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-r.sidebar-o #page-header {
    padding-right: 60px;
    padding-left: 0;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-r.sidebar-o #page-header .overlay-header {
    right: 60px;
    left: 0;
  }
}
#page-container #sidebar .content-header {
  background-color: #f6f7f9;
}
#page-container.sidebar-dark #sidebar {
  color: #e9ecef;
  background-color: #1F2937;
}
#page-container.sidebar-dark #sidebar .content-header {
  background-color: #232e3e;
}
#page-container.side-scroll #sidebar .js-sidebar-scroll,
#page-container.side-scroll #side-overlay {
  overflow-y: visible;
}
@media (min-width: 992px) {
  #page-container.side-scroll #sidebar .content-header,
#page-container.side-scroll #sidebar .content-side {
    width: 250px !important;
  }
  #page-container.side-scroll #sidebar.with-mini-nav .content-header,
#page-container.side-scroll #sidebar.with-mini-nav .content-side {
    width: 204px !important;
  }
  #page-container.side-scroll #side-overlay .content-header,
#page-container.side-scroll #side-overlay .content-side {
    width: 320px !important;
  }
}
@media (min-width: 992px) {
  #page-container.sidebar-o {
    padding-left: 250px;
  }
  #page-container.sidebar-o.sidebar-r {
    padding-right: 250px;
    padding-left: 0;
  }
  #page-container.sidebar-mini.sidebar-o {
    padding-left: 60px;
  }
  #page-container.sidebar-mini.sidebar-o.sidebar-r {
    padding-right: 60px;
    padding-left: 0;
  }
}

.hero {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 500px;
}
.hero.hero-sm {
  min-height: 300px;
}
.hero.hero-lg {
  min-height: 800px;
}
.hero-inner {
  flex: 0 0 auto;
  width: 100%;
}
.hero-meta {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem 0;
  text-align: center;
}

.hero-static {
  min-height: 100vh;
}

.block {
  margin-bottom: 1.875rem;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(214, 219, 226, 0.5), 0 1px 2px rgba(214, 219, 226, 0.5);
}
.block .block, .content-side .block {
  box-shadow: none;
}

.block-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1.25rem;
  transition: opacity 0.25s ease-out;
}
.block-header.block-header-rtl {
  flex-direction: row-reverse;
}
.block-header.block-header-rtl .block-title {
  text-align: right;
}
.block-header.block-header-rtl .block-options {
  padding-right: 1.25rem;
  padding-left: 0;
}
.block-header-default {
  background-color: #f6f7f9;
}

.block-title {
  flex: 1 1 auto;
  min-height: 1.75rem;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.75rem;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
}
.block-title small, .block-title .small {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  color: #6c757d;
  text-transform: none;
  letter-spacing: normal;
}

.block-content {
  transition: opacity 0.25s ease-out;
  width: 100%;
  margin: 0 auto;
  padding: 1.25rem 1.25rem 1px;
  overflow-x: visible;
}
.block-content > .pull-t,
.block-content > .pull-y {
  margin-top: -1.25rem;
}
.block-content > .pull-b,
.block-content > .pull-y {
  margin-bottom: -1px;
}
.block-content > .pull-r,
.block-content > .pull-x {
  margin-right: -1.25rem;
}
.block-content > .pull-r,
.block-content > .pull-x {
  margin-left: -1.25rem;
}
.block-content > .pull {
  margin: -1.25rem -1.25rem -1px;
}
.block-content.block-content-full {
  padding-bottom: 1.25rem;
}
.block-content.block-content-full > .pull-b,
.block-content.block-content-full > .pull-y,
.block-content.block-content-full > .pull {
  margin-bottom: -1.25rem;
}

.block-content p,
.block-content .push,
.block-content .block,
.block-content .items-push > div {
  margin-bottom: 1.25rem;
}
.block-content .items-push-2x > div {
  margin-bottom: 2.5rem;
}
.block-content .items-push-3x > div {
  margin-bottom: 3.75rem;
}
.block-content.block-content-sm {
  padding-top: 0.625rem;
}
.block-content.block-content-sm > .pull-t,
.block-content.block-content-sm > .pull-y,
.block-content.block-content-sm > .pull {
  margin-top: -0.625rem;
}
.block-content.block-content-sm.block-content-full {
  padding-bottom: 0.625rem;
}
.block-content.block-content-sm.block-content-full > .pull-b,
.block-content.block-content-sm.block-content-full > .pull-y,
.block-content.block-content-sm.block-content-full > .pull {
  margin-bottom: -0.625rem;
}

.block.block-bordered {
  border: 1px solid #dde2e9;
  box-shadow: none;
}
.block.block-rounded {
  border-radius: 0.5rem;
}
.block.block-rounded > .block-header,
.block.block-rounded > .nav-tabs {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.block.block-rounded > .block-header:last-child,
.block.block-rounded > .nav-tabs:last-child {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.block.block-rounded.block-mode-hidden > .block-header.block-header-default {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.block.block-rounded > .block-content:first-child {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.block.block-rounded > .block-content:last-child {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.block.block-rounded > .nav-tabs > .nav-item:first-child > .nav-link {
  border-top-left-radius: 0.375rem;
}
.block.block-rounded > .nav-tabs > .nav-item:last-child > .nav-link {
  border-top-right-radius: 0.375rem;
}
.block.block-themed > .block-header {
  border-bottom: none;
  color: #fff;
  background-color: #2356d7;
}
.block.block-themed > .block-header > .block-title {
  color: rgba(255, 255, 255, 0.9);
}
.block.block-themed > .block-header > .block-title small, .block.block-themed > .block-header > .block-title .small {
  color: rgba(255, 255, 255, 0.7);
}
.block.block-transparent {
  background-color: transparent;
  box-shadow: none;
}

.block.block-mode-hidden.block-bordered > .block-header {
  border-bottom: none;
}
.block.block-mode-hidden > .block-content {
  display: none;
}
.block.block-mode-loading {
  position: relative;
  overflow: hidden;
}
.block.block-mode-loading > .block-header,
.block.block-mode-loading > .block-content,
.block.block-mode-loading > .nav-tabs {
  opacity: 0.05;
}
.block.block-mode-loading::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 1;
  content: " ";
}
.block.block-mode-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1.5rem 0 0 -1.5rem;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  color: #4B5563;
  font-family: Simple-Line-Icons;
  font-size: 1.125rem;
  text-align: center;
  z-index: 2;
  content: "\e09a";
  animation: fa-spin 1.75s infinite linear;
  border-radius: 1.5rem;
}
.block.block-mode-loading.block-mode-hidden::after {
  margin: -1rem 0 0 -1rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}
.block.block-mode-loading.block-mode-loading-dark::after {
  background-color: #4B5563;
  color: #fff;
}
.block.block-mode-loading.block-mode-loading-location::after {
  content: "\e06e";
}
.block.block-mode-loading.block-mode-loading-energy::after {
  content: "\e020";
}
.block.block-mode-loading.block-mode-loading-refresh::after {
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f021";
}
.block.block-mode-loading.block-mode-loading-sun::after {
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f185";
}
.block.block-mode-loading.block-mode-loading-repeat::after {
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f01e";
}
.block.block-mode-loading.block-mode-loading-hourglass::after {
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f254";
}
.block.block-mode-loading.block-mode-loading-oneui::after {
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f1ce";
}
.block.block-mode-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1035;
  margin-bottom: 0;
  overflow-y: auto;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
}
.block.block-mode-fullscreen.block-bordered {
  border: none;
}
.block.block-mode-fullscreen.block-rounded {
  border-radius: 0;
}
.block.block-mode-fullscreen.block-transparent {
  background-color: #fff;
}
.block.block-mode-pinned {
  position: fixed;
  right: 0.625rem;
  bottom: 0;
  margin-bottom: 0 !important;
  width: 100%;
  max-width: 300px;
  z-index: 1031;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
}
.block.block-mode-pinned > .block-content {
  max-height: 250px;
  overflow-y: auto;
}
@media (min-width: 576px) {
  .block.block-mode-pinned {
    max-width: 400px;
  }
}

a.block {
  display: block;
  color: #212529;
  font-weight: normal;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out, box-shadow 0.15s ease-out;
}
a.block:hover {
  color: #212529;
  opacity: 0.75;
}
a.block:active {
  opacity: 1;
}
a.block.block-link-rotate:hover {
  transform: rotate(1.5deg);
  opacity: 1;
}
a.block.block-link-rotate:active {
  transform: rotate(0deg);
}
a.block.block-link-pop:hover {
  box-shadow: 0 0.5rem 2.5rem #d8dee6;
  transform: translateY(-2px);
  opacity: 1;
}
a.block.block-link-pop:active {
  box-shadow: 0 0.375rem 0.55rem #e8ebf0;
  transform: translateY(0);
}
a.block.block-link-shadow:hover {
  box-shadow: 0 0 1.5rem #d8dee6;
  opacity: 1;
}
a.block.block-link-shadow:active {
  box-shadow: 0 0 0.75rem #dfe3ea;
}

.block.block-fx-shadow {
  box-shadow: 0 0 1.5rem #d8dee6;
  opacity: 1;
}
.block.block-fx-pop {
  box-shadow: 0 0.5rem 2.5rem #d8dee6;
  opacity: 1;
}
.block.block-fx-rotate-right {
  transform: rotate(1.5deg);
  opacity: 1;
}
.block.block-fx-rotate-left {
  transform: rotate(-1.5deg);
  opacity: 1;
}

.block-options {
  flex: 0 0 auto;
  padding-left: 1.25rem;
}
.block-options .block-options-item {
  padding: 0 0.25rem;
  line-height: 1.2;
}
.block.block-themed .block-options .block-options-item {
  color: #fff;
}
.block-options .block-options-item,
.block-options .dropdown {
  display: inline-block;
}

.block-sticky-options {
  position: relative;
}
.block-sticky-options .block-options {
  position: absolute;
  top: 0.875rem;
  right: 1.25rem;
}
.block-sticky-options .block-options.block-options-left {
  right: auto;
  left: 1.25rem;
  padding-right: 10px;
  padding-left: 0;
}

.btn-block-option {
  display: inline-block;
  padding: 0.375rem 0.25rem;
  line-height: 1;
  color: #adb5bd;
  background: none;
  border: none;
  cursor: pointer;
}
.block-header-default .btn-block-option {
  color: #6c757d;
}
.btn-block-option.dropdown-toggle:after {
  position: relative;
  top: 2px;
}
.btn-block-option .si {
  position: relative;
  top: 1px;
}
.btn-block-option:hover {
  text-decoration: none;
  color: #495057;
}
a.btn-block-option:focus,
.active > a.btn-block-option,
.show > button.btn-block-option {
  text-decoration: none;
  color: #495057;
}

.btn-block-option:focus {
  outline: none !important;
  color: #495057;
}
.btn-block-option:active {
  color: #adb5bd;
}
.block.block-themed .btn-block-option {
  color: #fff;
  opacity: 0.7;
}
.block.block-themed .btn-block-option:hover {
  color: #fff;
  opacity: 1;
}
a.block.block-themed .btn-block-option:focus,
.active > a.block.block-themed .btn-block-option,
.show > button.block.block-themed .btn-block-option {
  color: #fff;
  opacity: 1;
}

.block.block-themed .btn-block-option:focus {
  color: #fff;
  opacity: 1;
}
.block.block-themed .btn-block-option:active {
  color: #fff;
  opacity: 0.6;
}

#page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 999998;
  transform: translateY(-100%);
}
#page-loader.show {
  transform: translateY(0);
}
#page-loader::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  margin-top: -30px;
  margin-left: -30px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #2356d7;
  content: "";
  z-index: 999999;
  animation: page-loader 0.9s infinite ease-in-out;
}

@keyframes page-loader {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.nav-main {
  padding-left: 0;
  list-style: none;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.nav-main-heading {
  padding: 1.375rem 1.25rem 0.375rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  color: #5a6570;
}

.nav-main-link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  min-height: 2.5rem;
  font-size: 0.676375rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #212529;
}
.nav-main-link .nav-main-link-icon {
  flex: 0 0 auto;
  display: inline-block;
  margin-right: 0.625rem;
  min-width: 1rem;
  text-align: center;
  color: #717e8c;
}
.nav-main-link .nav-main-link-name {
  flex: 1 1 auto;
  display: inline-block;
  max-width: 100%;
}
.nav-main-link .nav-main-link-badge {
  flex: 0 0 auto;
  display: inline-block;
  margin-left: 0.625rem;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  font-size: 0.75rem;
}
.nav-main-link:hover, .nav-main-link:focus {
  color: #212529;
  background-color: #f6f7f9;
  outline: 0 !important;
}
.nav-main-link:hover > .nav-main-link-icon, .nav-main-link:focus > .nav-main-link-icon {
  color: #000;
}
.nav-main-link.active {
  color: #000;
}
.nav-main-link.active > .nav-main-link-icon {
  color: #000;
}
.nav-main-link.nav-main-link-submenu {
  padding-right: 2rem;
}
.nav-main-link.nav-main-link-submenu::before {
  position: absolute;
  top: 50%;
  right: 0.625rem;
  display: block;
  margin-top: -0.5rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 0.75rem;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
}
.nav-main-link.nav-main-link-submenu::before {
  content: "\f104";
  opacity: 0.4;
}

.nav-main-submenu {
  padding-left: 2.875rem;
  list-style: none;
  height: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.02);
}
.nav-main-submenu .nav-main-item {
  opacity: 0;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
  transform: translateX(-0.75rem);
}
.nav-main-submenu .nav-main-heading {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0;
}
.nav-main-submenu .nav-main-link {
  margin: 0;
  padding-left: 0;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  min-height: 2.125rem;
  font-size: 0.8125rem;
  color: #383f45;
}
.nav-main-submenu .nav-main-link:hover, .nav-main-submenu .nav-main-link:focus, .nav-main-submenu .nav-main-link.active {
  color: #000;
  background-color: transparent;
}
.nav-main-submenu .nav-main-submenu {
  padding-left: 0.75rem;
}

.nav-main-item.open > .nav-main-link-submenu {
  color: #000;
}
.nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon {
  color: #000;
}
.nav-main-item.open > .nav-main-link-submenu::before {
  transform: rotate(-90deg);
}
.nav-main-item.open > .nav-main-submenu {
  height: auto;
}
.nav-main-item.open > .nav-main-submenu > .nav-main-item {
  opacity: 1;
  transform: translateX(0);
}

.nav-main-submenu .nav-main-item.open .nav-main-link {
  background-color: transparent;
}

.nav-main-horizontal {
  margin: 0;
}

@media (min-width: 992px) {
  .nav-main-horizontal {
    display: flex;
    flex-wrap: wrap;
  }
  .nav-main-horizontal .nav-main-heading {
    display: none;
  }
  .nav-main-horizontal > .nav-main-item {
    position: relative;
    display: inline-block;
  }
  .nav-main-horizontal > .nav-main-item:not(:last-child) {
    margin-right: 0.25rem;
  }
  .nav-main-horizontal .nav-main-link-submenu::before {
    content: "\f107";
  }
  .nav-main-horizontal .nav-main-submenu {
    position: absolute;
    left: 0;
    width: 230px;
    padding-left: 0;
    z-index: 995;
    background-color: #ebeef2;
    box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.08);
  }
  .nav-main-horizontal .nav-main-submenu.nav-main-submenu-right {
    left: auto;
    right: 0;
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-link {
    padding-left: 1rem;
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-link-submenu::before {
    content: "\f105";
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-item.open > .nav-main-link-submenu::before {
    transform: scaleX(-1);
  }
  .nav-main-horizontal .nav-main-item.open > .nav-main-link-submenu::before {
    transform: scaleY(-1);
  }
  .nav-main-horizontal .nav-main-item.open > .nav-main-submenu {
    height: auto;
    margin-top: -1px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    overflow: visible;
  }
  .nav-main-horizontal .nav-main-item.open > .nav-main-submenu > .nav-main-item {
    transform: translateX(0);
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-submenu {
    top: -0.5rem;
    left: auto;
    right: -100%;
    margin-top: 0;
  }
  .nav-main-horizontal.nav-main-horizontal-center {
    justify-content: center;
  }
  .nav-main-horizontal.nav-main-horizontal-justify > .nav-main-item {
    flex: 1 1 auto;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu {
    opacity: 1;
    color: #000;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu > .nav-main-link-icon {
    color: #000;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu::before {
    transform: scaleY(-1);
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu {
    height: auto;
    margin-top: -1px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    overflow: visible;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu .nav-main-submenu {
    margin-top: 0;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu > .nav-main-item {
    opacity: 1;
    transform: translateX(0);
  }
  .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover > .nav-main-link-submenu::before {
    transform: scaleX(-1);
  }
}
.nav-main-dark .nav-main-heading,
.sidebar-dark #sidebar .nav-main-heading,
.page-header-dark #page-header .nav-main-heading,
.dark-mode #side-overlay .nav-main-heading,
.dark-mode #main-container .nav-main-heading {
  color: #7186a3;
}
.nav-main-dark .nav-main-link,
.sidebar-dark #sidebar .nav-main-link,
.page-header-dark #page-header .nav-main-link,
.dark-mode #side-overlay .nav-main-link,
.dark-mode #main-container .nav-main-link {
  color: #9faec1;
}
.nav-main-dark .nav-main-link > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link > .nav-main-link-icon,
.dark-mode #side-overlay .nav-main-link > .nav-main-link-icon,
.dark-mode #main-container .nav-main-link > .nav-main-link-icon {
  color: rgba(159, 174, 193, 0.5);
}
.nav-main-dark .nav-main-link:hover, .nav-main-dark .nav-main-link:focus,
.sidebar-dark #sidebar .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-link:focus,
.page-header-dark #page-header .nav-main-link:hover,
.page-header-dark #page-header .nav-main-link:focus,
.dark-mode #side-overlay .nav-main-link:hover,
.dark-mode #side-overlay .nav-main-link:focus,
.dark-mode #main-container .nav-main-link:hover,
.dark-mode #main-container .nav-main-link:focus {
  color: #90a0b7;
  background-color: rgba(0, 0, 0, 0.2);
}
.nav-main-dark .nav-main-link:hover > .nav-main-link-icon, .nav-main-dark .nav-main-link:focus > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link:hover > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link:focus > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link:hover > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link:focus > .nav-main-link-icon,
.dark-mode #side-overlay .nav-main-link:hover > .nav-main-link-icon,
.dark-mode #side-overlay .nav-main-link:focus > .nav-main-link-icon,
.dark-mode #main-container .nav-main-link:hover > .nav-main-link-icon,
.dark-mode #main-container .nav-main-link:focus > .nav-main-link-icon {
  color: #fff;
}
.nav-main-dark .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-link.active,
.page-header-dark #page-header .nav-main-link.active,
.dark-mode #side-overlay .nav-main-link.active,
.dark-mode #main-container .nav-main-link.active {
  color: #fff;
}
.nav-main-dark .nav-main-link.active > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link.active > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link.active > .nav-main-link-icon,
.dark-mode #side-overlay .nav-main-link.active > .nav-main-link-icon,
.dark-mode #main-container .nav-main-link.active > .nav-main-link-icon {
  color: #fff;
}
.nav-main-dark .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-submenu,
.page-header-dark #page-header .nav-main-submenu,
.dark-mode #side-overlay .nav-main-submenu,
.dark-mode #main-container .nav-main-submenu {
  background-color: rgba(0, 0, 0, 0.15);
}
.nav-main-dark .nav-main-submenu .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-link,
.dark-mode #side-overlay .nav-main-submenu .nav-main-link,
.dark-mode #main-container .nav-main-submenu .nav-main-link {
  color: #8093ad;
}
.nav-main-dark .nav-main-submenu .nav-main-link:hover, .nav-main-dark .nav-main-submenu .nav-main-link:focus, .nav-main-dark .nav-main-submenu .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link:focus,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link.active,
.page-header-dark #page-header .nav-main-submenu .nav-main-link:hover,
.page-header-dark #page-header .nav-main-submenu .nav-main-link:focus,
.page-header-dark #page-header .nav-main-submenu .nav-main-link.active,
.dark-mode #side-overlay .nav-main-submenu .nav-main-link:hover,
.dark-mode #side-overlay .nav-main-submenu .nav-main-link:focus,
.dark-mode #side-overlay .nav-main-submenu .nav-main-link.active,
.dark-mode #main-container .nav-main-submenu .nav-main-link:hover,
.dark-mode #main-container .nav-main-submenu .nav-main-link:focus,
.dark-mode #main-container .nav-main-submenu .nav-main-link.active {
  color: #fff;
  background-color: transparent;
}
.nav-main-dark .nav-main-item.open > .nav-main-link-submenu,
.nav-main-dark .nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-link-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-item.open > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon,
.dark-mode #side-overlay .nav-main-item.open > .nav-main-link-submenu,
.dark-mode #side-overlay .nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon,
.dark-mode #main-container .nav-main-item.open > .nav-main-link-submenu,
.dark-mode #main-container .nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon {
  color: #fff;
}
.nav-main-dark .nav-main-item.open > .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-submenu,
.dark-mode #side-overlay .nav-main-item.open > .nav-main-submenu,
.dark-mode #main-container .nav-main-item.open > .nav-main-submenu {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-main-dark .nav-main-submenu .nav-main-item.open .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-item.open .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-item.open .nav-main-link,
.dark-mode #side-overlay .nav-main-submenu .nav-main-item.open .nav-main-link,
.dark-mode #main-container .nav-main-submenu .nav-main-item.open .nav-main-link {
  background-color: transparent;
}

@media (min-width: 992px) {
  .nav-main-dark.nav-main-horizontal .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-horizontal .nav-main-submenu,
.page-header-dark #page-header .nav-main-horizontal .nav-main-submenu,
.dark-mode #main-container .nav-main-horizontal .nav-main-submenu {
    background-color: #19222d !important;
    box-shadow: none;
  }
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu,
.sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu,
.dark-mode #main-container .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu {
    color: #fff;
  }
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu > .nav-main-link-icon,
.dark-mode #main-container .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu > .nav-main-link-icon {
    color: #fff;
  }
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link,
.sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link,
.page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link,
.dark-mode #main-container .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link {
    background-color: transparent;
  }
}
.img-avatar {
  display: inline-block !important;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.img-avatar.img-avatar16 {
  width: 16px;
  height: 16px;
}
.img-avatar.img-avatar20 {
  width: 20px;
  height: 20px;
}
.img-avatar.img-avatar32 {
  width: 32px;
  height: 32px;
}
.img-avatar.img-avatar48 {
  width: 48px;
  height: 48px;
}
.img-avatar.img-avatar96 {
  width: 96px;
  height: 96px;
}
.img-avatar.img-avatar128 {
  width: 128px;
  height: 128px;
}
.img-avatar-thumb {
  margin: 0.25rem;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.3);
}
.img-avatar.img-avatar-rounded {
  border-radius: 0.25rem;
}

.img-thumb {
  padding: 0.375rem;
  background-color: #fff;
  border-radius: 0.25rem;
}

.img-link {
  display: inline-block;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;
}
.img-link:hover {
  transform: scale(1.02);
  opacity: 0.75;
}
.img-link:active {
  transform: scale(1);
  opacity: 1;
}
.img-link.img-link-simple:hover {
  transform: scale(1);
}

.img-link-zoom-in {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.img-fluid.img-fluid-100,
.img-fluid-100 .img-fluid {
  width: 100%;
}

.list {
  padding-left: 0;
  list-style: none;
}
.list > li {
  position: relative;
}
.list.list-simple > li, .list-li-push > li {
  margin-bottom: 1.25rem;
}
.list.list-simple-mini > li, .list-li-push-sm > li {
  margin-bottom: 0.625rem;
}

.list-activity > li {
  margin-bottom: 0.75rem;
  padding-bottom: 0.375rem;
  padding-left: 2rem;
}
.list-activity > li > i:first-child {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
}
.list-activity > li:not(:last-child) {
  border-bottom: 1px solid #ebeef2;
}

.list-events > li {
  margin-bottom: 0.25rem;
}
.list-events .js-event {
  background-color: #1391aa;
  transition: transform 0.3s ease-out;
}
.list-events .js-event:hover {
  cursor: move;
  transform: translateX(-0.25rem);
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
}
a.item {
  will-change: opacity;
}
a.item:hover, a.item:focus {
  opacity: 0.6;
}
a.item:active {
  opacity: 1;
}
a.item.item-link-pop {
  will-change: transform;
}
a.item.item-link-pop:hover, a.item.item-link-pop:focus {
  opacity: 1;
  transform: scale(1.1);
}
a.item.item-link-pop:active {
  transform: scale(1);
}

.item.item-tiny {
  width: 1rem;
  height: 1rem;
}
.item.item-2x {
  width: 6rem;
  height: 6rem;
}
.item.item-3x {
  width: 8rem;
  height: 8rem;
}
.item.item-circle {
  border-radius: 50%;
}
.item.item-rounded {
  border-radius: 0.375rem;
}
.item.item-rounded-lg {
  border-radius: 1.5rem;
}
.item.item-rounded-lg.item-2x {
  border-radius: 2rem;
}
.item.item-rounded-lg.item-3x {
  border-radius: 3rem;
}

.overlay-container {
  position: relative;
}

.overlay-item {
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  line-height: 1;
}
.overlay-left .overlay-item {
  right: auto;
  left: 0;
}
.overlay-bottom .overlay-item {
  top: auto;
  bottom: 0;
}
.overlay-center .overlay-item {
  top: 50%;
  right: 50%;
  transform: translateX(50%) translateY(-50%);
}

.options-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: block;
}
.options-container .options-item {
  transition: transform 0.25s ease-out;
  will-change: transform;
}
.options-container .options-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: 2;
  content: "";
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in;
  will-change: opacity, transform;
}
.options-container .options-overlay-content {
  text-align: center;
}
.options-container:hover .options-overlay {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767.98px) {
  .options-container .options-overlay {
    display: none;
  }
  .options-container .options-overlay:hover .options-overlay {
    display: block;
  }
}

.fx-item-zoom-in:hover .options-item {
  transform: scale(1.2, 1.2);
}

.fx-item-rotate-r:hover .options-item {
  transform: scale(1.4) rotate(8deg);
}

.fx-item-rotate-l:hover .options-item {
  transform: scale(1.4) rotate(-8deg);
}

.fx-overlay-slide-top .options-overlay {
  transform: translateY(100%);
}
.fx-overlay-slide-top:hover .options-overlay {
  transform: translateY(0);
}

.fx-overlay-slide-right .options-overlay {
  transform: translateX(-100%);
}
.fx-overlay-slide-right:hover .options-overlay {
  transform: translateX(0);
}

.fx-overlay-slide-down .options-overlay {
  transform: translateY(-100%);
}
.fx-overlay-slide-down:hover .options-overlay {
  transform: translateY(0);
}

.fx-overlay-slide-left .options-overlay {
  transform: translateX(100%);
}
.fx-overlay-slide-left:hover .options-overlay {
  transform: translateX(0);
}

.fx-overlay-zoom-in .options-overlay {
  transform: scale(0, 0);
}
.fx-overlay-zoom-in:hover .options-overlay {
  transform: scale(1, 1);
}

.fx-overlay-zoom-out .options-overlay {
  transform: scale(2, 2);
}
.fx-overlay-zoom-out:hover .options-overlay {
  transform: scale(1, 1);
}

.timeline {
  position: relative;
  padding: 1rem 0;
  padding-left: 0;
  list-style: none;
}
.timeline::before {
  position: absolute;
  top: 0;
  left: 1.25rem;
  height: 100%;
  width: 0.25rem;
  background-color: #dde2e9;
  content: "";
  border-radius: 0.375rem;
}

.timeline-event {
  position: relative;
}
.timeline-event:not(:last-child) {
  margin-bottom: 1.875rem;
}

.timeline-event-icon {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  color: #fff;
  border-radius: 0.375rem;
  z-index: 5;
}

.timeline-event-block {
  margin-left: 3.25rem;
  margin-bottom: 0 !important;
}

@media (min-width: 1200px) {
  .timeline-centered::before {
    left: 50%;
    margin-left: -0.125rem;
  }
  .timeline-centered .timeline-event-icon {
    left: 50%;
    margin-left: -1.25rem;
  }
  .timeline-centered .timeline-event-icon::before {
    right: auto;
    left: -0.625rem;
    border-right: 0.625rem solid #fff;
    border-left: none;
  }
  .timeline-centered .timeline-event-time {
    position: absolute;
    display: inline-block;
    top: 1.25rem;
    left: 50%;
    padding: 0.5rem 0.5rem 0.5rem 1.75rem;
    width: auto;
    border-radius: 0.375rem;
    background-color: #f8f9fa;
    transform: translateY(-50%);
  }
  .timeline-centered .timeline-event-block {
    width: 46%;
    margin-left: 0;
  }
  .timeline-centered .timeline-event:not(:last-child) {
    margin-bottom: 3.75rem;
  }
  .timeline-centered.timeline-alt .timeline-event:nth-child(even) .timeline-event-icon,
.timeline-centered .timeline-event.timeline-event-alt .timeline-event-icon {
    left: 50%;
    margin-left: -1.25rem;
  }
  .timeline-centered.timeline-alt .timeline-event:nth-child(even) .timeline-event-block,
.timeline-centered .timeline-event.timeline-event-alt .timeline-event-block {
    margin-left: auto;
  }
  .timeline-centered.timeline-alt .timeline-event:nth-child(even) .timeline-event-time,
.timeline-centered .timeline-event.timeline-event-alt .timeline-event-time {
    padding: 0.5rem 1.75rem 0.5rem 0.5rem;
    right: 50%;
    left: auto;
    text-align: right;
  }
}
.ribbon {
  position: relative;
  min-height: 3.25rem;
}

.ribbon-box {
  position: absolute;
  top: 0.75rem;
  right: 0;
  padding: 0 0.75rem;
  height: 2.25rem;
  line-height: 2.25rem;
  z-index: 5;
  font-weight: 700;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.ribbon-box::before {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  content: "";
}

.ribbon-bookmark .ribbon-box {
  padding-left: 0.625rem;
  border-radius: 0 !important;
}
.ribbon-bookmark .ribbon-box::before {
  top: 0;
  right: 100%;
  height: 2.25rem;
  border: 1rem solid;
  border-left-width: 0.625rem;
  border-right-width: 0;
}

.ribbon-modern .ribbon-box {
  top: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0 !important;
}
.ribbon-modern .ribbon-box::before {
  right: 100%;
  border: 1.125rem solid;
}

.ribbon-left .ribbon-box {
  right: auto;
  left: 0;
  border-radius: 0;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.ribbon-left.ribbon-bookmark .ribbon-box {
  padding-left: 0.75rem;
  padding-right: 0.625rem;
}
.ribbon-left.ribbon-bookmark .ribbon-box::before {
  right: auto;
  left: 100%;
  border-left-width: 0;
  border-right-width: 0.625rem;
}
.ribbon-left.ribbon-modern .ribbon-box::before {
  left: 100%;
  right: auto;
}

.ribbon-bottom .ribbon-box {
  top: auto;
  bottom: 0.75rem;
}
.ribbon-bottom.ribbon-modern .ribbon-box {
  bottom: 0;
}

.ribbon-light .ribbon-box {
  color: #212529;
  background-color: #dde2e9;
}
.ribbon-light.ribbon-bookmark .ribbon-box::before {
  border-color: #dde2e9;
  border-left-color: transparent;
}
.ribbon-light.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #dde2e9;
  border-right-color: transparent;
}
.ribbon-light.ribbon-modern .ribbon-box::before {
  border-color: #dde2e9;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-light.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #dde2e9;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-light.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #dde2e9;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-light.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #dde2e9;
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-dark .ribbon-box {
  color: #fff;
  background-color: #334155;
}
.ribbon-dark.ribbon-bookmark .ribbon-box::before {
  border-color: #334155;
  border-left-color: transparent;
}
.ribbon-dark.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #334155;
  border-right-color: transparent;
}
.ribbon-dark.ribbon-modern .ribbon-box::before {
  border-color: #334155;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-dark.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #334155;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-dark.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #334155;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-dark.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #334155;
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-primary .ribbon-box {
  color: #fff;
  background-color: #2356d7;
}
.ribbon-primary.ribbon-bookmark .ribbon-box::before {
  border-color: #2356d7;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #2356d7;
  border-right-color: transparent;
}
.ribbon-primary.ribbon-modern .ribbon-box::before {
  border-color: #2356d7;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #2356d7;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #2356d7;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #2356d7;
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-success .ribbon-box {
  color: #fff;
  background-color: #139a52;
}
.ribbon-success.ribbon-bookmark .ribbon-box::before {
  border-color: #139a52;
  border-left-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #139a52;
  border-right-color: transparent;
}
.ribbon-success.ribbon-modern .ribbon-box::before {
  border-color: #139a52;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-success.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #139a52;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-success.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #139a52;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-success.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #139a52;
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-info .ribbon-box {
  color: #fff;
  background-color: #1391aa;
}
.ribbon-info.ribbon-bookmark .ribbon-box::before {
  border-color: #1391aa;
  border-left-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #1391aa;
  border-right-color: transparent;
}
.ribbon-info.ribbon-modern .ribbon-box::before {
  border-color: #1391aa;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-info.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #1391aa;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-info.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #1391aa;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-info.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #1391aa;
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-warning .ribbon-box {
  color: #fff;
  background-color: #ea580c;
}
.ribbon-warning.ribbon-bookmark .ribbon-box::before {
  border-color: #ea580c;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #ea580c;
  border-right-color: transparent;
}
.ribbon-warning.ribbon-modern .ribbon-box::before {
  border-color: #ea580c;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-warning.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #ea580c;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #ea580c;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-warning.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #ea580c;
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-danger .ribbon-box {
  color: #fff;
  background-color: #d61f47;
}
.ribbon-danger.ribbon-bookmark .ribbon-box::before {
  border-color: #d61f47;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #d61f47;
  border-right-color: transparent;
}
.ribbon-danger.ribbon-modern .ribbon-box::before {
  border-color: #d61f47;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-danger.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #d61f47;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #d61f47;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-danger.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #d61f47;
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-glass .ribbon-box {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.4);
}
.ribbon-glass.ribbon-bookmark .ribbon-box::before {
  border-color: rgba(255, 255, 255, 0.4);
  border-left-color: transparent;
}
.ribbon-glass.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: rgba(255, 255, 255, 0.4);
  border-right-color: transparent;
}
.ribbon-glass.ribbon-modern .ribbon-box::before {
  border-color: rgba(255, 255, 255, 0.4);
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-glass.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: rgba(255, 255, 255, 0.4);
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-glass.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: rgba(255, 255, 255, 0.4);
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-glass.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: rgba(255, 255, 255, 0.4);
  border-top-color: transparent;
  border-right-color: transparent;
}

.bg-black-5 {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.bg-black-10 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.bg-black-25 {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.bg-black-50 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.bg-black-75 {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.bg-black-90 {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.bg-black-95 {
  background-color: rgba(0, 0, 0, 0.95) !important;
}

.bg-white-5 {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.bg-white-10 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.bg-white-25 {
  background-color: rgba(255, 255, 255, 0.25) !important;
}
.bg-white-50 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.bg-white-75 {
  background-color: rgba(255, 255, 255, 0.75) !important;
}
.bg-white-90 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.bg-white-95 {
  background-color: rgba(255, 255, 255, 0.95) !important;
}

.bg-image {
  background-position: 0% 50%;
  background-size: cover;
}
.bg-image-top {
  background-position-y: 0%;
}
.bg-image-center {
  background-position-x: 50%;
}
.bg-image-bottom {
  background-position-y: 100%;
}
@media (min-width: 1200px) {
  .bg-image-fixed {
    background-attachment: fixed;
  }
}

.bg-pattern {
  background-repeat: repeat;
}

.bg-video {
  width: 100%;
  transform: translateZ(0);
}

.bg-primary-op {
  background-color: rgba(35, 86, 215, 0.75) !important;
}

a.bg-primary-op:hover, a.bg-primary-op:focus,
button.bg-primary-op:hover,
button.bg-primary-op:focus {
  background-color: rgba(28, 68, 171, 0.75) !important;
}

.bg-primary {
  background-color: #2356d7 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1c44ab !important;
}

.bg-primary-dark {
  background-color: #4B5563 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #353c46 !important;
}

.bg-primary-dark-op {
  background-color: rgba(75, 85, 99, 0.8) !important;
}

a.bg-primary-dark-op:hover, a.bg-primary-dark-op:focus,
button.bg-primary-dark-op:hover,
button.bg-primary-dark-op:focus {
  background-color: rgba(53, 60, 70, 0.8) !important;
}

.bg-primary-darker {
  background-color: #1F2937 !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
  background-color: #0d1116 !important;
}

.bg-primary-light {
  background-color: #7091e5 !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #4570dd !important;
}

.bg-primary-lighter {
  background-color: #d3ddf7 !important;
}

a.bg-primary-lighter:hover, a.bg-primary-lighter:focus,
button.bg-primary-lighter:hover,
button.bg-primary-lighter:focus {
  background-color: #a8bcef !important;
}

.bg-success {
  background-color: #139a52 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0d6d3a !important;
}

.bg-success-light {
  background-color: #d0ebdc !important;
}

a.bg-success-light:hover, a.bg-success-light:focus,
button.bg-success-light:hover,
button.bg-success-light:focus {
  background-color: #acdcc1 !important;
}

.bg-warning {
  background-color: #ea580c !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #b9460a !important;
}

.bg-warning-light {
  background-color: #fbdece !important;
}

a.bg-warning-light:hover, a.bg-warning-light:focus,
button.bg-warning-light:hover,
button.bg-warning-light:focus {
  background-color: #f7be9f !important;
}

.bg-info {
  background-color: #1391aa !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0e6a7c !important;
}

.bg-info-light {
  background-color: #d0e9ee !important;
}

a.bg-info-light:hover, a.bg-info-light:focus,
button.bg-info-light:hover,
button.bg-info-light:focus {
  background-color: #abd7e0 !important;
}

.bg-danger {
  background-color: #d61f47 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #a91938 !important;
}

.bg-danger-light {
  background-color: #f7d2da !important;
}

a.bg-danger-light:hover, a.bg-danger-light:focus,
button.bg-danger-light:hover,
button.bg-danger-light:focus {
  background-color: #efa7b6 !important;
}

.bg-success-op {
  background-color: rgba(208, 235, 220, 0.95) !important;
}

a.bg-success-op:hover, a.bg-success-op:focus,
button.bg-success-op:hover,
button.bg-success-op:focus {
  background-color: rgba(172, 220, 193, 0.95) !important;
}

.bg-warning-op {
  background-color: rgba(251, 222, 206, 0.95) !important;
}

a.bg-warning-op:hover, a.bg-warning-op:focus,
button.bg-warning-op:hover,
button.bg-warning-op:focus {
  background-color: rgba(247, 190, 159, 0.95) !important;
}

.bg-info-op {
  background-color: rgba(208, 233, 238, 0.95) !important;
}

a.bg-info-op:hover, a.bg-info-op:focus,
button.bg-info-op:hover,
button.bg-info-op:focus {
  background-color: rgba(171, 215, 224, 0.95) !important;
}

.bg-danger-op {
  background-color: rgba(247, 210, 218, 0.95) !important;
}

a.bg-danger-op:hover, a.bg-danger-op:focus,
button.bg-danger-op:hover,
button.bg-danger-op:focus {
  background-color: rgba(239, 167, 182, 0.95) !important;
}

.bg-body {
  background-color: #ebeef2 !important;
}

a.bg-body:hover, a.bg-body:focus,
button.bg-body:hover,
button.bg-body:focus {
  background-color: #ccd4de !important;
}

.bg-body-light {
  background-color: #f6f7f9 !important;
}

a.bg-body-light:hover, a.bg-body-light:focus,
button.bg-body-light:hover,
button.bg-body-light:focus {
  background-color: #d7dde5 !important;
}

.bg-body-dark {
  background-color: #dde2e9 !important;
}

a.bg-body-dark:hover, a.bg-body-dark:focus,
button.bg-body-dark:hover,
button.bg-body-dark:focus {
  background-color: #bec8d5 !important;
}

.bg-body-extra-light {
  background-color: #fff !important;
}

a.bg-body-extra-light:hover, a.bg-body-extra-light:focus,
button.bg-body-extra-light:hover,
button.bg-body-extra-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-muted {
  background-color: #6c757d !important;
}

a.bg-muted:hover, a.bg-muted:focus,
button.bg-muted:hover,
button.bg-muted:focus {
  background-color: #545b62 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-gray {
  background-color: #ced4da !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #b1bbc4 !important;
}

.bg-gray-dark {
  background-color: #6c757d !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #545b62 !important;
}

.bg-gray-darker {
  background-color: #343a40 !important;
}

a.bg-gray-darker:hover, a.bg-gray-darker:focus,
button.bg-gray-darker:hover,
button.bg-gray-darker:focus {
  background-color: #1d2124 !important;
}

.bg-gray-light {
  background-color: #e9ecef !important;
}

a.bg-gray-light:hover, a.bg-gray-light:focus,
button.bg-gray-light:hover,
button.bg-gray-light:focus {
  background-color: #cbd3da !important;
}

.bg-gray-lighter {
  background-color: #f8f9fa !important;
}

a.bg-gray-lighter:hover, a.bg-gray-lighter:focus,
button.bg-gray-lighter:hover,
button.bg-gray-lighter:focus {
  background-color: #dae0e5 !important;
}

.bg-header-light {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

a.bg-header-light:hover, a.bg-header-light:focus,
button.bg-header-light:hover,
button.bg-header-light:focus {
  background-color: rgba(230, 230, 230, 0.75) !important;
}

.bg-header-dark {
  background-color: #1f2937 !important;
}

a.bg-header-dark:hover, a.bg-header-dark:focus,
button.bg-header-dark:hover,
button.bg-header-dark:focus {
  background-color: #0d1116 !important;
}

.bg-sidebar-light {
  background-color: #fff !important;
}

a.bg-sidebar-light:hover, a.bg-sidebar-light:focus,
button.bg-sidebar-light:hover,
button.bg-sidebar-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-sidebar-dark {
  background-color: #1F2937 !important;
}

a.bg-sidebar-dark:hover, a.bg-sidebar-dark:focus,
button.bg-sidebar-dark:hover,
button.bg-sidebar-dark:focus {
  background-color: #0d1116 !important;
}

.bg-default {
  background-color: #2356d7 !important;
}

a.bg-default:hover, a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #1c44ab !important;
}

.bg-default-op {
  background-color: rgba(35, 86, 215, 0.75) !important;
}

a.bg-default-op:hover, a.bg-default-op:focus,
button.bg-default-op:hover,
button.bg-default-op:focus {
  background-color: rgba(28, 68, 171, 0.75) !important;
}

.bg-default-dark {
  background-color: #4B5563 !important;
}

a.bg-default-dark:hover, a.bg-default-dark:focus,
button.bg-default-dark:hover,
button.bg-default-dark:focus {
  background-color: #353c46 !important;
}

.bg-default-dark-op {
  background-color: rgba(75, 85, 99, 0.8) !important;
}

a.bg-default-dark-op:hover, a.bg-default-dark-op:focus,
button.bg-default-dark-op:hover,
button.bg-default-dark-op:focus {
  background-color: rgba(53, 60, 70, 0.8) !important;
}

.bg-default-darker {
  background-color: #1F2937 !important;
}

a.bg-default-darker:hover, a.bg-default-darker:focus,
button.bg-default-darker:hover,
button.bg-default-darker:focus {
  background-color: #0d1116 !important;
}

.bg-default-light {
  background-color: #7091e5 !important;
}

a.bg-default-light:hover, a.bg-default-light:focus,
button.bg-default-light:hover,
button.bg-default-light:focus {
  background-color: #4570dd !important;
}

.bg-default-lighter {
  background-color: #d3ddf7 !important;
}

a.bg-default-lighter:hover, a.bg-default-lighter:focus,
button.bg-default-lighter:hover,
button.bg-default-lighter:focus {
  background-color: #a8bcef !important;
}

.bg-amethyst {
  background-color: #6366f1 !important;
}

a.bg-amethyst:hover, a.bg-amethyst:focus,
button.bg-amethyst:hover,
button.bg-amethyst:focus {
  background-color: #3438ed !important;
}

.bg-amethyst-op {
  background-color: rgba(99, 102, 241, 0.75) !important;
}

a.bg-amethyst-op:hover, a.bg-amethyst-op:focus,
button.bg-amethyst-op:hover,
button.bg-amethyst-op:focus {
  background-color: rgba(52, 56, 237, 0.75) !important;
}

.bg-amethyst-dark {
  background-color: #3e4254 !important;
}

a.bg-amethyst-dark:hover, a.bg-amethyst-dark:focus,
button.bg-amethyst-dark:hover,
button.bg-amethyst-dark:focus {
  background-color: #282b37 !important;
}

.bg-amethyst-dark-op {
  background-color: rgba(62, 66, 84, 0.8) !important;
}

a.bg-amethyst-dark-op:hover, a.bg-amethyst-dark-op:focus,
button.bg-amethyst-dark-op:hover,
button.bg-amethyst-dark-op:focus {
  background-color: rgba(40, 43, 55, 0.8) !important;
}

.bg-amethyst-darker {
  background-color: #2c2e3b !important;
}

a.bg-amethyst-darker:hover, a.bg-amethyst-darker:focus,
button.bg-amethyst-darker:hover,
button.bg-amethyst-darker:focus {
  background-color: #16171e !important;
}

.bg-amethyst-light {
  background-color: #a9abf7 !important;
}

a.bg-amethyst-light:hover, a.bg-amethyst-light:focus,
button.bg-amethyst-light:hover,
button.bg-amethyst-light:focus {
  background-color: #7a7df3 !important;
}

.bg-amethyst-lighter {
  background-color: #e6e7fd !important;
}

a.bg-amethyst-lighter:hover, a.bg-amethyst-lighter:focus,
button.bg-amethyst-lighter:hover,
button.bg-amethyst-lighter:focus {
  background-color: #b7b9f9 !important;
}

.bg-city {
  background-color: #db3954 !important;
}

a.bg-city:hover, a.bg-city:focus,
button.bg-city:hover,
button.bg-city:focus {
  background-color: #be233d !important;
}

.bg-city-op {
  background-color: rgba(219, 57, 84, 0.75) !important;
}

a.bg-city-op:hover, a.bg-city-op:focus,
button.bg-city-op:hover,
button.bg-city-op:focus {
  background-color: rgba(190, 35, 61, 0.75) !important;
}

.bg-city-dark {
  background-color: #4b4b4b !important;
}

a.bg-city-dark:hover, a.bg-city-dark:focus,
button.bg-city-dark:hover,
button.bg-city-dark:focus {
  background-color: #323232 !important;
}

.bg-city-dark-op {
  background-color: rgba(75, 75, 75, 0.8) !important;
}

a.bg-city-dark-op:hover, a.bg-city-dark-op:focus,
button.bg-city-dark-op:hover,
button.bg-city-dark-op:focus {
  background-color: rgba(50, 50, 50, 0.8) !important;
}

.bg-city-darker {
  background-color: #2b2b2b !important;
}

a.bg-city-darker:hover, a.bg-city-darker:focus,
button.bg-city-darker:hover,
button.bg-city-darker:focus {
  background-color: #121212 !important;
}

.bg-city-light {
  background-color: #e98797 !important;
}

a.bg-city-light:hover, a.bg-city-light:focus,
button.bg-city-light:hover,
button.bg-city-light:focus {
  background-color: #e15c72 !important;
}

.bg-city-lighter {
  background-color: #f4c3cb !important;
}

a.bg-city-lighter:hover, a.bg-city-lighter:focus,
button.bg-city-lighter:hover,
button.bg-city-lighter:focus {
  background-color: #ec98a6 !important;
}

.bg-flat {
  background-color: #069fba !important;
}

a.bg-flat:hover, a.bg-flat:focus,
button.bg-flat:hover,
button.bg-flat:focus {
  background-color: #047589 !important;
}

.bg-flat-op {
  background-color: rgba(6, 159, 186, 0.75) !important;
}

a.bg-flat-op:hover, a.bg-flat-op:focus,
button.bg-flat-op:hover,
button.bg-flat-op:focus {
  background-color: rgba(4, 117, 137, 0.75) !important;
}

.bg-flat-dark {
  background-color: #36474d !important;
}

a.bg-flat-dark:hover, a.bg-flat-dark:focus,
button.bg-flat-dark:hover,
button.bg-flat-dark:focus {
  background-color: #212b2f !important;
}

.bg-flat-dark-op {
  background-color: rgba(54, 71, 77, 0.8) !important;
}

a.bg-flat-dark-op:hover, a.bg-flat-dark-op:focus,
button.bg-flat-dark-op:hover,
button.bg-flat-dark-op:focus {
  background-color: rgba(33, 43, 47, 0.8) !important;
}

.bg-flat-darker {
  background-color: #2a363b !important;
}

a.bg-flat-darker:hover, a.bg-flat-darker:focus,
button.bg-flat-darker:hover,
button.bg-flat-darker:focus {
  background-color: #151b1d !important;
}

.bg-flat-light {
  background-color: #83cfdd !important;
}

a.bg-flat-light:hover, a.bg-flat-light:focus,
button.bg-flat-light:hover,
button.bg-flat-light:focus {
  background-color: #5bc0d2 !important;
}

.bg-flat-lighter {
  background-color: #c1e7ee !important;
}

a.bg-flat-lighter:hover, a.bg-flat-lighter:focus,
button.bg-flat-lighter:hover,
button.bg-flat-lighter:focus {
  background-color: #99d8e3 !important;
}

.bg-modern {
  background-color: #0c91cf !important;
}

a.bg-modern:hover, a.bg-modern:focus,
button.bg-modern:hover,
button.bg-modern:focus {
  background-color: #096f9f !important;
}

.bg-modern-op {
  background-color: rgba(12, 145, 207, 0.75) !important;
}

a.bg-modern-op:hover, a.bg-modern-op:focus,
button.bg-modern-op:hover,
button.bg-modern-op:focus {
  background-color: rgba(9, 111, 159, 0.75) !important;
}

.bg-modern-dark {
  background-color: #323a47 !important;
}

a.bg-modern-dark:hover, a.bg-modern-dark:focus,
button.bg-modern-dark:hover,
button.bg-modern-dark:focus {
  background-color: #1d2229 !important;
}

.bg-modern-dark-op {
  background-color: rgba(50, 58, 71, 0.8) !important;
}

a.bg-modern-dark-op:hover, a.bg-modern-dark-op:focus,
button.bg-modern-dark-op:hover,
button.bg-modern-dark-op:focus {
  background-color: rgba(29, 34, 41, 0.8) !important;
}

.bg-modern-darker {
  background-color: #262d3b !important;
}

a.bg-modern-darker:hover, a.bg-modern-darker:focus,
button.bg-modern-darker:hover,
button.bg-modern-darker:focus {
  background-color: #12151c !important;
}

.bg-modern-light {
  background-color: #4cbff5 !important;
}

a.bg-modern-light:hover, a.bg-modern-light:focus,
button.bg-modern-light:hover,
button.bg-modern-light:focus {
  background-color: #1caef2 !important;
}

.bg-modern-lighter {
  background-color: #ade2fa !important;
}

a.bg-modern-lighter:hover, a.bg-modern-lighter:focus,
button.bg-modern-lighter:hover,
button.bg-modern-lighter:focus {
  background-color: #7dd0f7 !important;
}

.bg-smooth {
  background-color: #d4428b !important;
}

a.bg-smooth:hover, a.bg-smooth:focus,
button.bg-smooth:hover,
button.bg-smooth:focus {
  background-color: #b92a72 !important;
}

.bg-smooth-op {
  background-color: rgba(212, 66, 139, 0.75) !important;
}

a.bg-smooth-op:hover, a.bg-smooth-op:focus,
button.bg-smooth-op:hover,
button.bg-smooth-op:focus {
  background-color: rgba(185, 42, 114, 0.75) !important;
}

.bg-smooth-dark {
  background-color: #3c4554 !important;
}

a.bg-smooth-dark:hover, a.bg-smooth-dark:focus,
button.bg-smooth-dark:hover,
button.bg-smooth-dark:focus {
  background-color: #272d36 !important;
}

.bg-smooth-dark-op {
  background-color: rgba(60, 69, 84, 0.8) !important;
}

a.bg-smooth-dark-op:hover, a.bg-smooth-dark-op:focus,
button.bg-smooth-dark-op:hover,
button.bg-smooth-dark-op:focus {
  background-color: rgba(39, 45, 54, 0.8) !important;
}

.bg-smooth-darker {
  background-color: #2a303b !important;
}

a.bg-smooth-darker:hover, a.bg-smooth-darker:focus,
button.bg-smooth-darker:hover,
button.bg-smooth-darker:focus {
  background-color: #15181d !important;
}

.bg-smooth-light {
  background-color: #e58db9 !important;
}

a.bg-smooth-light:hover, a.bg-smooth-light:focus,
button.bg-smooth-light:hover,
button.bg-smooth-light:focus {
  background-color: #dc639f !important;
}

.bg-smooth-lighter {
  background-color: #f2c7dd !important;
}

a.bg-smooth-lighter:hover, a.bg-smooth-lighter:focus,
button.bg-smooth-lighter:hover,
button.bg-smooth-lighter:focus {
  background-color: #e99dc3 !important;
}

.border-white-op {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-black-op {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.h-header {
  height: 4rem !important;
}

.overflow-y-auto {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.no-transition {
  transition: none !important;
}

.click-ripple {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  transform: scale(0);
}
.click-ripple.animate {
  animation: click-ripple 0.6s ease-out;
}

@keyframes click-ripple {
  50% {
    opacity: 0.6;
    transform: scale(2);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}
.space-x-0 > * + * {
  margin-left: 0;
}

.space-x-1 > * + * {
  margin-left: 0.25rem;
}

.space-x-2 > * + * {
  margin-left: 0.5rem;
}

.space-x-3 > * + * {
  margin-left: 1rem;
}

.space-x-4 > * + * {
  margin-left: 1.5rem;
}

.space-x-5 > * + * {
  margin-left: 3rem;
}

.space-x-6 > * + * {
  margin-left: 4.5rem;
}

.space-x-7 > * + * {
  margin-left: 6rem;
}

.space-x-8 > * + * {
  margin-left: 9rem;
}

.space-x-9 > * + * {
  margin-left: 12rem;
}

.space-x-10 > * + * {
  margin-left: 18rem;
}

.space-y-0 > * + * {
  margin-top: 0;
}

.space-y-1 > * + * {
  margin-top: 0.25rem;
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

.space-y-3 > * + * {
  margin-top: 1rem;
}

.space-y-4 > * + * {
  margin-top: 1.5rem;
}

.space-y-5 > * + * {
  margin-top: 3rem;
}

.space-y-6 > * + * {
  margin-top: 4.5rem;
}

.space-y-7 > * + * {
  margin-top: 6rem;
}

.space-y-8 > * + * {
  margin-top: 9rem;
}

.space-y-9 > * + * {
  margin-top: 12rem;
}

.space-y-10 > * + * {
  margin-top: 18rem;
}

.text-normal {
  text-transform: none !important;
}

.text-primary {
  color: #2356d7 !important;
}

a.text-primary.link-fx::before {
  background-color: #2356d7 !important;
}
a.text-primary:hover, a.text-primary:focus {
  color: #1c44ab !important;
}

.text-primary-dark {
  color: #4B5563 !important;
}

a.text-primary-dark.link-fx::before {
  background-color: #4B5563 !important;
}
a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #353c46 !important;
}

.text-primary-darker {
  color: #1F2937 !important;
}

a.text-primary-darker.link-fx::before {
  background-color: #1F2937 !important;
}
a.text-primary-darker:hover, a.text-primary-darker:focus {
  color: #0d1116 !important;
}

.text-primary-light {
  color: #7091e5 !important;
}

a.text-primary-light.link-fx::before {
  background-color: #7091e5 !important;
}
a.text-primary-light:hover, a.text-primary-light:focus {
  color: #4570dd !important;
}

.text-primary-lighter {
  color: #d3ddf7 !important;
}

a.text-primary-lighter.link-fx::before {
  background-color: #d3ddf7 !important;
}
a.text-primary-lighter:hover, a.text-primary-lighter:focus {
  color: #a8bcef !important;
}

.text-success {
  color: #139a52 !important;
}

a.text-success.link-fx::before {
  background-color: #139a52 !important;
}
a.text-success:hover, a.text-success:focus {
  color: #0d6d3a !important;
}

.text-success-light {
  color: #d0ebdc !important;
}

a.text-success-light.link-fx::before {
  background-color: #d0ebdc !important;
}
a.text-success-light:hover, a.text-success-light:focus {
  color: #acdcc1 !important;
}

.text-warning {
  color: #ea580c !important;
}

a.text-warning.link-fx::before {
  background-color: #ea580c !important;
}
a.text-warning:hover, a.text-warning:focus {
  color: #b9460a !important;
}

.text-warning-light {
  color: #fbdece !important;
}

a.text-warning-light.link-fx::before {
  background-color: #fbdece !important;
}
a.text-warning-light:hover, a.text-warning-light:focus {
  color: #f7be9f !important;
}

.text-info {
  color: #1391aa !important;
}

a.text-info.link-fx::before {
  background-color: #1391aa !important;
}
a.text-info:hover, a.text-info:focus {
  color: #0e6a7c !important;
}

.text-info-light {
  color: #d0e9ee !important;
}

a.text-info-light.link-fx::before {
  background-color: #d0e9ee !important;
}
a.text-info-light:hover, a.text-info-light:focus {
  color: #abd7e0 !important;
}

.text-danger {
  color: #d61f47 !important;
}

a.text-danger.link-fx::before {
  background-color: #d61f47 !important;
}
a.text-danger:hover, a.text-danger:focus {
  color: #a91938 !important;
}

.text-danger-light {
  color: #f7d2da !important;
}

a.text-danger-light.link-fx::before {
  background-color: #f7d2da !important;
}
a.text-danger-light:hover, a.text-danger-light:focus {
  color: #efa7b6 !important;
}

.text-body-bg {
  color: #ebeef2 !important;
}

a.text-body-bg.link-fx::before {
  background-color: #ebeef2 !important;
}
a.text-body-bg:hover, a.text-body-bg:focus {
  color: #ccd4de !important;
}

.text-body-bg-dark {
  color: #dde2e9 !important;
}

a.text-body-bg-dark.link-fx::before {
  background-color: #dde2e9 !important;
}
a.text-body-bg-dark:hover, a.text-body-bg-dark:focus {
  color: #bec8d5 !important;
}

.text-body-bg-light {
  color: #f6f7f9 !important;
}

a.text-body-bg-light.link-fx::before {
  background-color: #f6f7f9 !important;
}
a.text-body-bg-light:hover, a.text-body-bg-light:focus {
  color: #d7dde5 !important;
}

.text-body-color {
  color: #212529 !important;
}

a.text-body-color.link-fx::before {
  background-color: #212529 !important;
}
a.text-body-color:hover, a.text-body-color:focus {
  color: #0a0c0d !important;
}

.text-body-color-dark {
  color: #1e2226 !important;
}

a.text-body-color-dark.link-fx::before {
  background-color: #1e2226 !important;
}
a.text-body-color-dark:hover, a.text-body-color-dark:focus {
  color: #08090a !important;
}

.text-body-color-light {
  color: #e9ecef !important;
}

a.text-body-color-light.link-fx::before {
  background-color: #e9ecef !important;
}
a.text-body-color-light:hover, a.text-body-color-light:focus {
  color: #cbd3da !important;
}

.text-muted {
  color: #6c757d !important;
}

a.text-muted.link-fx::before {
  background-color: #6c757d !important;
}
a.text-muted:hover, a.text-muted:focus {
  color: #545b62 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white.link-fx::before {
  background-color: #fff !important;
}
a.text-white:hover, a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

a.text-white-50.link-fx::before {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
a.text-white-50:hover, a.text-white-50:focus {
  color: rgba(230, 230, 230, 0.5) !important;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

a.text-white-75.link-fx::before {
  background-color: rgba(255, 255, 255, 0.75) !important;
}
a.text-white-75:hover, a.text-white-75:focus {
  color: rgba(230, 230, 230, 0.75) !important;
}

.text-black {
  color: #000 !important;
}

a.text-black.link-fx::before {
  background-color: #000 !important;
}
a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

a.text-black-50.link-fx::before {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
a.text-black-50:hover, a.text-black-50:focus {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-black-75 {
  color: rgba(0, 0, 0, 0.75) !important;
}

a.text-black-75.link-fx::before {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
a.text-black-75:hover, a.text-black-75:focus {
  color: rgba(0, 0, 0, 0.75) !important;
}

.text-gray {
  color: #ced4da !important;
}

a.text-gray.link-fx::before {
  background-color: #ced4da !important;
}
a.text-gray:hover, a.text-gray:focus {
  color: #b1bbc4 !important;
}

.text-gray-dark {
  color: #6c757d !important;
}

a.text-gray-dark.link-fx::before {
  background-color: #6c757d !important;
}
a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #545b62 !important;
}

.text-gray-darker {
  color: #343a40 !important;
}

a.text-gray-darker.link-fx::before {
  background-color: #343a40 !important;
}
a.text-gray-darker:hover, a.text-gray-darker:focus {
  color: #1d2124 !important;
}

.text-gray-light {
  color: #e9ecef !important;
}

a.text-gray-light.link-fx::before {
  background-color: #e9ecef !important;
}
a.text-gray-light:hover, a.text-gray-light:focus {
  color: #cbd3da !important;
}

.text-gray-lighter {
  color: #f8f9fa !important;
}

a.text-gray-lighter.link-fx::before {
  background-color: #f8f9fa !important;
}
a.text-gray-lighter:hover, a.text-gray-lighter:focus {
  color: #dae0e5 !important;
}

.text-dual {
  color: #4B5563 !important;
}

a.text-dual.link-fx::before {
  background-color: #4B5563 !important;
}
a.text-dual:hover, a.text-dual:focus {
  color: #353c46 !important;
}

.page-header-dark #page-header .text-dual,
.sidebar-dark #sidebar .text-dual {
  color: #e9ecef !important;
}
.page-header-dark #page-header a.text-dual.link-fx::before,
.sidebar-dark #sidebar a.text-dual.link-fx::before {
  background-color: #e9ecef !important;
}
.page-header-dark #page-header a.text-dual:hover, .page-header-dark #page-header a.text-dual:focus,
.sidebar-dark #sidebar a.text-dual:hover,
.sidebar-dark #sidebar a.text-dual:focus {
  color: #cbd3da !important;
}

.text-default {
  color: #2356d7 !important;
}

a.text-default.link-fx::before {
  background-color: #2356d7 !important;
}
a.text-default:hover, a.text-default:focus {
  color: #1c44ab !important;
}

.text-default-dark {
  color: #4B5563 !important;
}

a.text-default-dark.link-fx::before {
  background-color: #4B5563 !important;
}
a.text-default-dark:hover, a.text-default-dark:focus {
  color: #353c46 !important;
}

.text-default-darker {
  color: #1F2937 !important;
}

a.text-default-darker.link-fx::before {
  background-color: #1F2937 !important;
}
a.text-default-darker:hover, a.text-default-darker:focus {
  color: #0d1116 !important;
}

.text-default-light {
  color: #7091e5 !important;
}

a.text-default-light.link-fx::before {
  background-color: #7091e5 !important;
}
a.text-default-light:hover, a.text-default-light:focus {
  color: #4570dd !important;
}

.text-default-lighter {
  color: #d3ddf7 !important;
}

a.text-default-lighter.link-fx::before {
  background-color: #d3ddf7 !important;
}
a.text-default-lighter:hover, a.text-default-lighter:focus {
  color: #a8bcef !important;
}

.text-amethyst {
  color: #6366f1 !important;
}

a.text-amethyst.link-fx::before {
  background-color: #6366f1 !important;
}
a.text-amethyst:hover, a.text-amethyst:focus {
  color: #3438ed !important;
}

.text-amethyst-dark {
  color: #3e4254 !important;
}

a.text-amethyst-dark.link-fx::before {
  background-color: #3e4254 !important;
}
a.text-amethyst-dark:hover, a.text-amethyst-dark:focus {
  color: #282b37 !important;
}

.text-amethyst-darker {
  color: #2c2e3b !important;
}

a.text-amethyst-darker.link-fx::before {
  background-color: #2c2e3b !important;
}
a.text-amethyst-darker:hover, a.text-amethyst-darker:focus {
  color: #16171e !important;
}

.text-amethyst-light {
  color: #a9abf7 !important;
}

a.text-amethyst-light.link-fx::before {
  background-color: #a9abf7 !important;
}
a.text-amethyst-light:hover, a.text-amethyst-light:focus {
  color: #7a7df3 !important;
}

.text-amethyst-lighter {
  color: #e6e7fd !important;
}

a.text-amethyst-lighter.link-fx::before {
  background-color: #e6e7fd !important;
}
a.text-amethyst-lighter:hover, a.text-amethyst-lighter:focus {
  color: #b7b9f9 !important;
}

.text-city {
  color: #db3954 !important;
}

a.text-city.link-fx::before {
  background-color: #db3954 !important;
}
a.text-city:hover, a.text-city:focus {
  color: #be233d !important;
}

.text-city-dark {
  color: #4b4b4b !important;
}

a.text-city-dark.link-fx::before {
  background-color: #4b4b4b !important;
}
a.text-city-dark:hover, a.text-city-dark:focus {
  color: #323232 !important;
}

.text-city-darker {
  color: #2b2b2b !important;
}

a.text-city-darker.link-fx::before {
  background-color: #2b2b2b !important;
}
a.text-city-darker:hover, a.text-city-darker:focus {
  color: #121212 !important;
}

.text-city-light {
  color: #e98797 !important;
}

a.text-city-light.link-fx::before {
  background-color: #e98797 !important;
}
a.text-city-light:hover, a.text-city-light:focus {
  color: #e15c72 !important;
}

.text-city-lighter {
  color: #f4c3cb !important;
}

a.text-city-lighter.link-fx::before {
  background-color: #f4c3cb !important;
}
a.text-city-lighter:hover, a.text-city-lighter:focus {
  color: #ec98a6 !important;
}

.text-flat {
  color: #069fba !important;
}

a.text-flat.link-fx::before {
  background-color: #069fba !important;
}
a.text-flat:hover, a.text-flat:focus {
  color: #047589 !important;
}

.text-flat-dark {
  color: #36474d !important;
}

a.text-flat-dark.link-fx::before {
  background-color: #36474d !important;
}
a.text-flat-dark:hover, a.text-flat-dark:focus {
  color: #212b2f !important;
}

.text-flat-darker {
  color: #2a363b !important;
}

a.text-flat-darker.link-fx::before {
  background-color: #2a363b !important;
}
a.text-flat-darker:hover, a.text-flat-darker:focus {
  color: #151b1d !important;
}

.text-flat-light {
  color: #83cfdd !important;
}

a.text-flat-light.link-fx::before {
  background-color: #83cfdd !important;
}
a.text-flat-light:hover, a.text-flat-light:focus {
  color: #5bc0d2 !important;
}

.text-flat-lighter {
  color: #c1e7ee !important;
}

a.text-flat-lighter.link-fx::before {
  background-color: #c1e7ee !important;
}
a.text-flat-lighter:hover, a.text-flat-lighter:focus {
  color: #99d8e3 !important;
}

.text-modern {
  color: #0c91cf !important;
}

a.text-modern.link-fx::before {
  background-color: #0c91cf !important;
}
a.text-modern:hover, a.text-modern:focus {
  color: #096f9f !important;
}

.text-modern-dark {
  color: #323a47 !important;
}

a.text-modern-dark.link-fx::before {
  background-color: #323a47 !important;
}
a.text-modern-dark:hover, a.text-modern-dark:focus {
  color: #1d2229 !important;
}

.text-modern-darker {
  color: #262d3b !important;
}

a.text-modern-darker.link-fx::before {
  background-color: #262d3b !important;
}
a.text-modern-darker:hover, a.text-modern-darker:focus {
  color: #12151c !important;
}

.text-modern-light {
  color: #4cbff5 !important;
}

a.text-modern-light.link-fx::before {
  background-color: #4cbff5 !important;
}
a.text-modern-light:hover, a.text-modern-light:focus {
  color: #1caef2 !important;
}

.text-modern-lighter {
  color: #ade2fa !important;
}

a.text-modern-lighter.link-fx::before {
  background-color: #ade2fa !important;
}
a.text-modern-lighter:hover, a.text-modern-lighter:focus {
  color: #7dd0f7 !important;
}

.text-smooth {
  color: #d4428b !important;
}

a.text-smooth.link-fx::before {
  background-color: #d4428b !important;
}
a.text-smooth:hover, a.text-smooth:focus {
  color: #b92a72 !important;
}

.text-smooth-dark {
  color: #3c4554 !important;
}

a.text-smooth-dark.link-fx::before {
  background-color: #3c4554 !important;
}
a.text-smooth-dark:hover, a.text-smooth-dark:focus {
  color: #272d36 !important;
}

.text-smooth-darker {
  color: #2a303b !important;
}

a.text-smooth-darker.link-fx::before {
  background-color: #2a303b !important;
}
a.text-smooth-darker:hover, a.text-smooth-darker:focus {
  color: #15181d !important;
}

.text-smooth-light {
  color: #e58db9 !important;
}

a.text-smooth-light.link-fx::before {
  background-color: #e58db9 !important;
}
a.text-smooth-light:hover, a.text-smooth-light:focus {
  color: #dc639f !important;
}

.text-smooth-lighter {
  color: #f2c7dd !important;
}

a.text-smooth-lighter.link-fx::before {
  background-color: #f2c7dd !important;
}
a.text-smooth-lighter:hover, a.text-smooth-lighter:focus {
  color: #e99dc3 !important;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.bounceIn,
.animated.bounceOut {
  animation-duration: 0.75s;
}

.animated.flipOutX,
.animated.flipOutY {
  animation-duration: 0.75s;
}

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
.flash {
  animation-name: flash;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  animation-name: pulse;
}

@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.rubberBand {
  animation-name: rubberBand;
}

@keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  animation-name: shake;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  transform-origin: top center;
  animation-name: swing;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.tada {
  animation-name: tada;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes wobble {
  0% {
    transform: none;
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    transform: none;
  }
}
.wobble {
  animation-name: wobble;
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.bounceOut {
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px);
    animation-timing-function: ease-in;
  }
}
.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
.flipInX {
  backface-visibility: visible !important;
  animation-name: flipInX;
}

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
.flipInY {
  backface-visibility: visible !important;
  animation-name: flipInY;
}

@keyframes flipOutX {
  0% {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  0% {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
.flipOutY {
  backface-visibility: visible !important;
  animation-name: flipOutY;
}

@keyframes lightSpeedIn {
  0% {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-5deg);
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  0% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  100% {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}
.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1;
  }
  100% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  animation-name: hinge;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  }
}
.rollIn {
  animation-name: rollIn;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
.rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
.zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutDown {
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform-origin: left center;
  }
}
.zoomOutLeft {
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.1) translate3d(2000px, 0, 0);
    transform-origin: right center;
  }
}
.zoomOutRight {
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutUp {
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  0% {
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}
.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}
.slideInLeft {
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}
.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  0% {
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}
.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  0% {
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    transform: translateY(100%);
  }
}
.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    transform: translateX(-100%);
  }
}
.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    transform: translateX(100%);
  }
}
.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    transform: translateY(-100%);
  }
}
.slideOutUp {
  animation-name: slideOutUp;
}

/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}

.fa-abacus:before {
  content: "\f640";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acorn:before {
  content: "\f6ae";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-ad:before {
  content: "\f641";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-air-conditioner:before {
  content: "\f8f4";
}

.fa-air-freshener:before {
  content: "\f5d0";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-alarm-clock:before {
  content: "\f34e";
}

.fa-alarm-exclamation:before {
  content: "\f843";
}

.fa-alarm-plus:before {
  content: "\f844";
}

.fa-alarm-snooze:before {
  content: "\f845";
}

.fa-album:before {
  content: "\f89f";
}

.fa-album-collection:before {
  content: "\f8a0";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-alicorn:before {
  content: "\f6b0";
}

.fa-alien:before {
  content: "\f8f5";
}

.fa-alien-monster:before {
  content: "\f8f6";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-align-slash:before {
  content: "\f846";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-amp-guitar:before {
  content: "\f8a1";
}

.fa-analytics:before {
  content: "\f643";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angel:before {
  content: "\f779";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angry:before {
  content: "\f556";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-ankh:before {
  content: "\f644";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-alt:before {
  content: "\f5d1";
}

.fa-apple-crate:before {
  content: "\f6b1";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-archway:before {
  content: "\f557";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-alt-down:before {
  content: "\f354";
}

.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}

.fa-arrow-alt-from-left:before {
  content: "\f347";
}

.fa-arrow-alt-from-right:before {
  content: "\f348";
}

.fa-arrow-alt-from-top:before {
  content: "\f349";
}

.fa-arrow-alt-left:before {
  content: "\f355";
}

.fa-arrow-alt-right:before {
  content: "\f356";
}

.fa-arrow-alt-square-down:before {
  content: "\f350";
}

.fa-arrow-alt-square-left:before {
  content: "\f351";
}

.fa-arrow-alt-square-right:before {
  content: "\f352";
}

.fa-arrow-alt-square-up:before {
  content: "\f353";
}

.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}

.fa-arrow-alt-to-left:before {
  content: "\f34b";
}

.fa-arrow-alt-to-right:before {
  content: "\f34c";
}

.fa-arrow-alt-to-top:before {
  content: "\f34d";
}

.fa-arrow-alt-up:before {
  content: "\f357";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-from-bottom:before {
  content: "\f342";
}

.fa-arrow-from-left:before {
  content: "\f343";
}

.fa-arrow-from-right:before {
  content: "\f344";
}

.fa-arrow-from-top:before {
  content: "\f345";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-square-down:before {
  content: "\f339";
}

.fa-arrow-square-left:before {
  content: "\f33a";
}

.fa-arrow-square-right:before {
  content: "\f33b";
}

.fa-arrow-square-up:before {
  content: "\f33c";
}

.fa-arrow-to-bottom:before {
  content: "\f33d";
}

.fa-arrow-to-left:before {
  content: "\f33e";
}

.fa-arrow-to-right:before {
  content: "\f340";
}

.fa-arrow-to-top:before {
  content: "\f341";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-atlas:before {
  content: "\f558";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-atom:before {
  content: "\f5d2";
}

.fa-atom-alt:before {
  content: "\f5d3";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-award:before {
  content: "\f559";
}

.fa-aws:before {
  content: "\f375";
}

.fa-axe:before {
  content: "\f6b2";
}

.fa-axe-battle:before {
  content: "\f6b3";
}

.fa-baby:before {
  content: "\f77c";
}

.fa-baby-carriage:before {
  content: "\f77d";
}

.fa-backpack:before {
  content: "\f5d4";
}

.fa-backspace:before {
  content: "\f55a";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-bacon:before {
  content: "\f7e5";
}

.fa-bacteria:before {
  content: "\e059";
}

.fa-bacterium:before {
  content: "\e05a";
}

.fa-badge:before {
  content: "\f335";
}

.fa-badge-check:before {
  content: "\f336";
}

.fa-badge-dollar:before {
  content: "\f645";
}

.fa-badge-percent:before {
  content: "\f646";
}

.fa-badge-sheriff:before {
  content: "\f8a2";
}

.fa-badger-honey:before {
  content: "\f6b4";
}

.fa-bags-shopping:before {
  content: "\f847";
}

.fa-bahai:before {
  content: "\f666";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-balance-scale-left:before {
  content: "\f515";
}

.fa-balance-scale-right:before {
  content: "\f516";
}

.fa-ball-pile:before {
  content: "\f77e";
}

.fa-ballot:before {
  content: "\f732";
}

.fa-ballot-check:before {
  content: "\f733";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-banjo:before {
  content: "\f8a3";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-barcode-alt:before {
  content: "\f463";
}

.fa-barcode-read:before {
  content: "\f464";
}

.fa-barcode-scan:before {
  content: "\f465";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball:before {
  content: "\f432";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-basketball-hoop:before {
  content: "\f435";
}

.fa-bat:before {
  content: "\f6b5";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-bolt:before {
  content: "\f376";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-slash:before {
  content: "\f377";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-bed:before {
  content: "\f236";
}

.fa-bed-alt:before {
  content: "\f8f7";
}

.fa-bed-bunk:before {
  content: "\f8f8";
}

.fa-bed-empty:before {
  content: "\f8f9";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-exclamation:before {
  content: "\f848";
}

.fa-bell-on:before {
  content: "\f8fa";
}

.fa-bell-plus:before {
  content: "\f849";
}

.fa-bell-school:before {
  content: "\f5d5";
}

.fa-bell-school-slash:before {
  content: "\f5d6";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bells:before {
  content: "\f77f";
}

.fa-betamax:before {
  content: "\f8a4";
}

.fa-bezier-curve:before {
  content: "\f55b";
}

.fa-bible:before {
  content: "\f647";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-biking:before {
  content: "\f84a";
}

.fa-biking-mountain:before {
  content: "\f84b";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-biohazard:before {
  content: "\f780";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blanket:before {
  content: "\f498";
}

.fa-blender:before {
  content: "\f517";
}

.fa-blender-phone:before {
  content: "\f6b6";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blinds:before {
  content: "\f8fb";
}

.fa-blinds-open:before {
  content: "\f8fc";
}

.fa-blinds-raised:before {
  content: "\f8fd";
}

.fa-blog:before {
  content: "\f781";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-bone:before {
  content: "\f5d7";
}

.fa-bone-break:before {
  content: "\f5d8";
}

.fa-bong:before {
  content: "\f55c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-book-alt:before {
  content: "\f5d9";
}

.fa-book-dead:before {
  content: "\f6b7";
}

.fa-book-heart:before {
  content: "\f499";
}

.fa-book-medical:before {
  content: "\f7e6";
}

.fa-book-open:before {
  content: "\f518";
}

.fa-book-reader:before {
  content: "\f5da";
}

.fa-book-spells:before {
  content: "\f6b8";
}

.fa-book-user:before {
  content: "\f7e7";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-books:before {
  content: "\f5db";
}

.fa-books-medical:before {
  content: "\f7e8";
}

.fa-boombox:before {
  content: "\f8a5";
}

.fa-boot:before {
  content: "\f782";
}

.fa-booth-curtain:before {
  content: "\f734";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-border-all:before {
  content: "\f84c";
}

.fa-border-bottom:before {
  content: "\f84d";
}

.fa-border-center-h:before {
  content: "\f89c";
}

.fa-border-center-v:before {
  content: "\f89d";
}

.fa-border-inner:before {
  content: "\f84e";
}

.fa-border-left:before {
  content: "\f84f";
}

.fa-border-none:before {
  content: "\f850";
}

.fa-border-outer:before {
  content: "\f851";
}

.fa-border-right:before {
  content: "\f852";
}

.fa-border-style:before {
  content: "\f853";
}

.fa-border-style-alt:before {
  content: "\f854";
}

.fa-border-top:before {
  content: "\f855";
}

.fa-bow-arrow:before {
  content: "\f6b9";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-bowling-pins:before {
  content: "\f437";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-alt:before {
  content: "\f49a";
}

.fa-box-ballot:before {
  content: "\f735";
}

.fa-box-check:before {
  content: "\f467";
}

.fa-box-fragile:before {
  content: "\f49b";
}

.fa-box-full:before {
  content: "\f49c";
}

.fa-box-heart:before {
  content: "\f49d";
}

.fa-box-open:before {
  content: "\f49e";
}

.fa-box-tissue:before {
  content: "\e05b";
}

.fa-box-up:before {
  content: "\f49f";
}

.fa-box-usd:before {
  content: "\f4a0";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-boxes-alt:before {
  content: "\f4a1";
}

.fa-boxing-glove:before {
  content: "\f438";
}

.fa-brackets:before {
  content: "\f7e9";
}

.fa-brackets-curly:before {
  content: "\f7ea";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-brain:before {
  content: "\f5dc";
}

.fa-bread-loaf:before {
  content: "\f7eb";
}

.fa-bread-slice:before {
  content: "\f7ec";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-bring-forward:before {
  content: "\f856";
}

.fa-bring-front:before {
  content: "\f857";
}

.fa-broadcast-tower:before {
  content: "\f519";
}

.fa-broom:before {
  content: "\f51a";
}

.fa-browser:before {
  content: "\f37e";
}

.fa-brush:before {
  content: "\f55d";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-bullseye-arrow:before {
  content: "\f648";
}

.fa-bullseye-pointer:before {
  content: "\f649";
}

.fa-burger-soda:before {
  content: "\f858";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-burrito:before {
  content: "\f7ed";
}

.fa-bus:before {
  content: "\f207";
}

.fa-bus-alt:before {
  content: "\f55e";
}

.fa-bus-school:before {
  content: "\f5dd";
}

.fa-business-time:before {
  content: "\f64a";
}

.fa-buy-n-large:before {
  content: "\f8a6";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-cabinet-filing:before {
  content: "\f64b";
}

.fa-cactus:before {
  content: "\f8a7";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calculator-alt:before {
  content: "\f64c";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-day:before {
  content: "\f783";
}

.fa-calendar-edit:before {
  content: "\f333";
}

.fa-calendar-exclamation:before {
  content: "\f334";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-star:before {
  content: "\f736";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-calendar-week:before {
  content: "\f784";
}

.fa-camcorder:before {
  content: "\f8a8";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-alt:before {
  content: "\f332";
}

.fa-camera-home:before {
  content: "\f8fe";
}

.fa-camera-movie:before {
  content: "\f8a9";
}

.fa-camera-polaroid:before {
  content: "\f8aa";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-campfire:before {
  content: "\f6ba";
}

.fa-campground:before {
  content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-candle-holder:before {
  content: "\f6bc";
}

.fa-candy-cane:before {
  content: "\f786";
}

.fa-candy-corn:before {
  content: "\f6bd";
}

.fa-cannabis:before {
  content: "\f55f";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-car-alt:before {
  content: "\f5de";
}

.fa-car-battery:before {
  content: "\f5df";
}

.fa-car-building:before {
  content: "\f859";
}

.fa-car-bump:before {
  content: "\f5e0";
}

.fa-car-bus:before {
  content: "\f85a";
}

.fa-car-crash:before {
  content: "\f5e1";
}

.fa-car-garage:before {
  content: "\f5e2";
}

.fa-car-mechanic:before {
  content: "\f5e3";
}

.fa-car-side:before {
  content: "\f5e4";
}

.fa-car-tilt:before {
  content: "\f5e5";
}

.fa-car-wash:before {
  content: "\f5e6";
}

.fa-caravan:before {
  content: "\f8ff";
}

.fa-caravan-alt:before {
  content: "\e000";
}

.fa-caret-circle-down:before {
  content: "\f32d";
}

.fa-caret-circle-left:before {
  content: "\f32e";
}

.fa-caret-circle-right:before {
  content: "\f330";
}

.fa-caret-circle-up:before {
  content: "\f331";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-carrot:before {
  content: "\f787";
}

.fa-cars:before {
  content: "\f85b";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cash-register:before {
  content: "\f788";
}

.fa-cassette-tape:before {
  content: "\f8ab";
}

.fa-cat:before {
  content: "\f6be";
}

.fa-cat-space:before {
  content: "\e001";
}

.fa-cauldron:before {
  content: "\f6bf";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-cctv:before {
  content: "\f8ac";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chair:before {
  content: "\f6c0";
}

.fa-chair-office:before {
  content: "\f6c1";
}

.fa-chalkboard:before {
  content: "\f51b";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-charging-station:before {
  content: "\f5e7";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-line-down:before {
  content: "\f64d";
}

.fa-chart-network:before {
  content: "\f78a";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-chart-pie-alt:before {
  content: "\f64e";
}

.fa-chart-scatter:before {
  content: "\f7ee";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-double:before {
  content: "\f560";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-cheese:before {
  content: "\f7ef";
}

.fa-cheese-swiss:before {
  content: "\f7f0";
}

.fa-cheeseburger:before {
  content: "\f7f1";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-bishop-alt:before {
  content: "\f43b";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-clock:before {
  content: "\f43d";
}

.fa-chess-clock-alt:before {
  content: "\f43e";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-king-alt:before {
  content: "\f440";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-knight-alt:before {
  content: "\f442";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-pawn-alt:before {
  content: "\f444";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-queen-alt:before {
  content: "\f446";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chess-rook-alt:before {
  content: "\f448";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-double-down:before {
  content: "\f322";
}

.fa-chevron-double-left:before {
  content: "\f323";
}

.fa-chevron-double-right:before {
  content: "\f324";
}

.fa-chevron-double-up:before {
  content: "\f325";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-square-down:before {
  content: "\f329";
}

.fa-chevron-square-left:before {
  content: "\f32a";
}

.fa-chevron-square-right:before {
  content: "\f32b";
}

.fa-chevron-square-up:before {
  content: "\f32c";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chimney:before {
  content: "\f78b";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-church:before {
  content: "\f51d";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-city:before {
  content: "\f64f";
}

.fa-clarinet:before {
  content: "\f8ad";
}

.fa-claw-marks:before {
  content: "\f6c2";
}

.fa-clinic-medical:before {
  content: "\f7f2";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clipboard-list-check:before {
  content: "\f737";
}

.fa-clipboard-prescription:before {
  content: "\f5e8";
}

.fa-clipboard-user:before {
  content: "\f7f3";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-drizzle:before {
  content: "\f738";
}

.fa-cloud-hail:before {
  content: "\f739";
}

.fa-cloud-hail-mixed:before {
  content: "\f73a";
}

.fa-cloud-meatball:before {
  content: "\f73b";
}

.fa-cloud-moon:before {
  content: "\f6c3";
}

.fa-cloud-moon-rain:before {
  content: "\f73c";
}

.fa-cloud-music:before {
  content: "\f8ae";
}

.fa-cloud-rain:before {
  content: "\f73d";
}

.fa-cloud-rainbow:before {
  content: "\f73e";
}

.fa-cloud-showers:before {
  content: "\f73f";
}

.fa-cloud-showers-heavy:before {
  content: "\f740";
}

.fa-cloud-sleet:before {
  content: "\f741";
}

.fa-cloud-snow:before {
  content: "\f742";
}

.fa-cloud-sun:before {
  content: "\f6c4";
}

.fa-cloud-sun-rain:before {
  content: "\f743";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudflare:before {
  content: "\e07d";
}

.fa-clouds:before {
  content: "\f744";
}

.fa-clouds-moon:before {
  content: "\f745";
}

.fa-clouds-sun:before {
  content: "\f746";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-club:before {
  content: "\f327";
}

.fa-cocktail:before {
  content: "\f561";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-code-commit:before {
  content: "\f386";
}

.fa-code-merge:before {
  content: "\f387";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-coffee-pot:before {
  content: "\e002";
}

.fa-coffee-togo:before {
  content: "\f6c5";
}

.fa-coffin:before {
  content: "\f6c6";
}

.fa-coffin-cross:before {
  content: "\e051";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-coin:before {
  content: "\f85c";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comet:before {
  content: "\e003";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-alt-check:before {
  content: "\f4a2";
}

.fa-comment-alt-dollar:before {
  content: "\f650";
}

.fa-comment-alt-dots:before {
  content: "\f4a3";
}

.fa-comment-alt-edit:before {
  content: "\f4a4";
}

.fa-comment-alt-exclamation:before {
  content: "\f4a5";
}

.fa-comment-alt-lines:before {
  content: "\f4a6";
}

.fa-comment-alt-medical:before {
  content: "\f7f4";
}

.fa-comment-alt-minus:before {
  content: "\f4a7";
}

.fa-comment-alt-music:before {
  content: "\f8af";
}

.fa-comment-alt-plus:before {
  content: "\f4a8";
}

.fa-comment-alt-slash:before {
  content: "\f4a9";
}

.fa-comment-alt-smile:before {
  content: "\f4aa";
}

.fa-comment-alt-times:before {
  content: "\f4ab";
}

.fa-comment-check:before {
  content: "\f4ac";
}

.fa-comment-dollar:before {
  content: "\f651";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comment-edit:before {
  content: "\f4ae";
}

.fa-comment-exclamation:before {
  content: "\f4af";
}

.fa-comment-lines:before {
  content: "\f4b0";
}

.fa-comment-medical:before {
  content: "\f7f5";
}

.fa-comment-minus:before {
  content: "\f4b1";
}

.fa-comment-music:before {
  content: "\f8b0";
}

.fa-comment-plus:before {
  content: "\f4b2";
}

.fa-comment-slash:before {
  content: "\f4b3";
}

.fa-comment-smile:before {
  content: "\f4b4";
}

.fa-comment-times:before {
  content: "\f4b5";
}

.fa-comments:before {
  content: "\f086";
}

.fa-comments-alt:before {
  content: "\f4b6";
}

.fa-comments-alt-dollar:before {
  content: "\f652";
}

.fa-comments-dollar:before {
  content: "\f653";
}

.fa-compact-disc:before {
  content: "\f51f";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compass-slash:before {
  content: "\f5e9";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-alt:before {
  content: "\f422";
}

.fa-compress-arrows-alt:before {
  content: "\f78c";
}

.fa-compress-wide:before {
  content: "\f326";
}

.fa-computer-classic:before {
  content: "\f8b1";
}

.fa-computer-speaker:before {
  content: "\f8b2";
}

.fa-concierge-bell:before {
  content: "\f562";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-construction:before {
  content: "\f85d";
}

.fa-container-storage:before {
  content: "\f4b7";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-conveyor-belt:before {
  content: "\f46e";
}

.fa-conveyor-belt-alt:before {
  content: "\f46f";
}

.fa-cookie:before {
  content: "\f563";
}

.fa-cookie-bite:before {
  content: "\f564";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-corn:before {
  content: "\f6c7";
}

.fa-cotton-bureau:before {
  content: "\f89e";
}

.fa-couch:before {
  content: "\f4b8";
}

.fa-cow:before {
  content: "\f6c8";
}

.fa-cowbell:before {
  content: "\f8b3";
}

.fa-cowbell-more:before {
  content: "\f8b4";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-credit-card-blank:before {
  content: "\f389";
}

.fa-credit-card-front:before {
  content: "\f38a";
}

.fa-cricket:before {
  content: "\f449";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-croissant:before {
  content: "\f7f6";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crop-alt:before {
  content: "\f565";
}

.fa-cross:before {
  content: "\f654";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-crow:before {
  content: "\f520";
}

.fa-crown:before {
  content: "\f521";
}

.fa-crutch:before {
  content: "\f7f7";
}

.fa-crutches:before {
  content: "\f7f8";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-curling:before {
  content: "\f44a";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dagger:before {
  content: "\f6cb";
}

.fa-dailymotion:before {
  content: "\e052";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-debug:before {
  content: "\f7f9";
}

.fa-deer:before {
  content: "\f78e";
}

.fa-deer-rudolph:before {
  content: "\f78f";
}

.fa-deezer:before {
  content: "\e077";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-democrat:before {
  content: "\f747";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-desktop-alt:before {
  content: "\f390";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dewpoint:before {
  content: "\f748";
}

.fa-dharmachakra:before {
  content: "\f655";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diamond:before {
  content: "\f219";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-dice:before {
  content: "\f522";
}

.fa-dice-d10:before {
  content: "\f6cd";
}

.fa-dice-d12:before {
  content: "\f6ce";
}

.fa-dice-d20:before {
  content: "\f6cf";
}

.fa-dice-d4:before {
  content: "\f6d0";
}

.fa-dice-d6:before {
  content: "\f6d1";
}

.fa-dice-d8:before {
  content: "\f6d2";
}

.fa-dice-five:before {
  content: "\f523";
}

.fa-dice-four:before {
  content: "\f524";
}

.fa-dice-one:before {
  content: "\f525";
}

.fa-dice-six:before {
  content: "\f526";
}

.fa-dice-three:before {
  content: "\f527";
}

.fa-dice-two:before {
  content: "\f528";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digging:before {
  content: "\f85e";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-digital-tachograph:before {
  content: "\f566";
}

.fa-diploma:before {
  content: "\f5ea";
}

.fa-directions:before {
  content: "\f5eb";
}

.fa-disc-drive:before {
  content: "\f8b5";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-disease:before {
  content: "\f7fa";
}

.fa-divide:before {
  content: "\f529";
}

.fa-dizzy:before {
  content: "\f567";
}

.fa-dna:before {
  content: "\f471";
}

.fa-do-not-enter:before {
  content: "\f5ec";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dog:before {
  content: "\f6d3";
}

.fa-dog-leashed:before {
  content: "\f6d4";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-empty:before {
  content: "\f473";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-dolly-flatbed-alt:before {
  content: "\f475";
}

.fa-dolly-flatbed-empty:before {
  content: "\f476";
}

.fa-donate:before {
  content: "\f4b9";
}

.fa-door-closed:before {
  content: "\f52a";
}

.fa-door-open:before {
  content: "\f52b";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-dove:before {
  content: "\f4ba";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-drafting-compass:before {
  content: "\f568";
}

.fa-dragon:before {
  content: "\f6d5";
}

.fa-draw-circle:before {
  content: "\f5ed";
}

.fa-draw-polygon:before {
  content: "\f5ee";
}

.fa-draw-square:before {
  content: "\f5ef";
}

.fa-dreidel:before {
  content: "\f792";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-drone:before {
  content: "\f85f";
}

.fa-drone-alt:before {
  content: "\f860";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drum:before {
  content: "\f569";
}

.fa-drum-steelpan:before {
  content: "\f56a";
}

.fa-drumstick:before {
  content: "\f6d6";
}

.fa-drumstick-bite:before {
  content: "\f6d7";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dryer:before {
  content: "\f861";
}

.fa-dryer-alt:before {
  content: "\f862";
}

.fa-duck:before {
  content: "\f6d8";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dumpster:before {
  content: "\f793";
}

.fa-dumpster-fire:before {
  content: "\f794";
}

.fa-dungeon:before {
  content: "\f6d9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-ear:before {
  content: "\f5f0";
}

.fa-ear-muffs:before {
  content: "\f795";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-eclipse:before {
  content: "\f749";
}

.fa-eclipse-alt:before {
  content: "\f74a";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edge-legacy:before {
  content: "\e078";
}

.fa-edit:before {
  content: "\f044";
}

.fa-egg:before {
  content: "\f7fb";
}

.fa-egg-fried:before {
  content: "\f7fc";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-elephant:before {
  content: "\f6da";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-h-alt:before {
  content: "\f39b";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ellipsis-v-alt:before {
  content: "\f39c";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-empty-set:before {
  content: "\f656";
}

.fa-engine-warning:before {
  content: "\f5f2";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-dollar:before {
  content: "\f657";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-equals:before {
  content: "\f52c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-ethernet:before {
  content: "\f796";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-square:before {
  content: "\f321";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-alt:before {
  content: "\f424";
}

.fa-expand-arrows:before {
  content: "\f31d";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expand-wide:before {
  content: "\f320";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-evil:before {
  content: "\f6db";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fan:before {
  content: "\f863";
}

.fa-fan-table:before {
  content: "\e004";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-farm:before {
  content: "\f864";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-faucet:before {
  content: "\e005";
}

.fa-faucet-drip:before {
  content: "\e006";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-feather:before {
  content: "\f52d";
}

.fa-feather-alt:before {
  content: "\f56b";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-female:before {
  content: "\f182";
}

.fa-field-hockey:before {
  content: "\f44c";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-figma:before {
  content: "\f799";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-certificate:before {
  content: "\f5f3";
}

.fa-file-chart-line:before {
  content: "\f659";
}

.fa-file-chart-pie:before {
  content: "\f65a";
}

.fa-file-check:before {
  content: "\f316";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-contract:before {
  content: "\f56c";
}

.fa-file-csv:before {
  content: "\f6dd";
}

.fa-file-download:before {
  content: "\f56d";
}

.fa-file-edit:before {
  content: "\f31c";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-exclamation:before {
  content: "\f31a";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-file-invoice:before {
  content: "\f570";
}

.fa-file-invoice-dollar:before {
  content: "\f571";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-minus:before {
  content: "\f318";
}

.fa-file-music:before {
  content: "\f8b6";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-plus:before {
  content: "\f319";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-prescription:before {
  content: "\f572";
}

.fa-file-search:before {
  content: "\f865";
}

.fa-file-signature:before {
  content: "\f573";
}

.fa-file-spreadsheet:before {
  content: "\f65b";
}

.fa-file-times:before {
  content: "\f317";
}

.fa-file-upload:before {
  content: "\f574";
}

.fa-file-user:before {
  content: "\f65c";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-files-medical:before {
  content: "\f7fd";
}

.fa-fill:before {
  content: "\f575";
}

.fa-fill-drip:before {
  content: "\f576";
}

.fa-film:before {
  content: "\f008";
}

.fa-film-alt:before {
  content: "\f3a0";
}

.fa-film-canister:before {
  content: "\f8b7";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fingerprint:before {
  content: "\f577";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-alt:before {
  content: "\f7e4";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-fire-smoke:before {
  content: "\f74b";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-firefox-browser:before {
  content: "\e007";
}

.fa-fireplace:before {
  content: "\f79a";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-fish:before {
  content: "\f578";
}

.fa-fish-cooked:before {
  content: "\f7fe";
}

.fa-fist-raised:before {
  content: "\f6de";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-alt:before {
  content: "\f74c";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flag-usa:before {
  content: "\f74d";
}

.fa-flame:before {
  content: "\f6df";
}

.fa-flashlight:before {
  content: "\f8b8";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flask-poison:before {
  content: "\f6e0";
}

.fa-flask-potion:before {
  content: "\f6e1";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-flower:before {
  content: "\f7ff";
}

.fa-flower-daffodil:before {
  content: "\f800";
}

.fa-flower-tulip:before {
  content: "\f801";
}

.fa-flushed:before {
  content: "\f579";
}

.fa-flute:before {
  content: "\f8b9";
}

.fa-flux-capacitor:before {
  content: "\f8ba";
}

.fa-fly:before {
  content: "\f417";
}

.fa-fog:before {
  content: "\f74e";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-download:before {
  content: "\e053";
}

.fa-folder-minus:before {
  content: "\f65d";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-folder-plus:before {
  content: "\f65e";
}

.fa-folder-times:before {
  content: "\f65f";
}

.fa-folder-tree:before {
  content: "\f802";
}

.fa-folder-upload:before {
  content: "\e054";
}

.fa-folders:before {
  content: "\f660";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

.fa-font-case:before {
  content: "\f866";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-football-helmet:before {
  content: "\f44f";
}

.fa-forklift:before {
  content: "\f47a";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-fragile:before {
  content: "\f4bb";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-french-fries:before {
  content: "\f803";
}

.fa-frog:before {
  content: "\f52e";
}

.fa-frosty-head:before {
  content: "\f79b";
}

.fa-frown:before {
  content: "\f119";
}

.fa-frown-open:before {
  content: "\f57a";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-function:before {
  content: "\f661";
}

.fa-funnel-dollar:before {
  content: "\f662";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-galaxy:before {
  content: "\e008";
}

.fa-game-board:before {
  content: "\f867";
}

.fa-game-board-alt:before {
  content: "\f868";
}

.fa-game-console-handheld:before {
  content: "\f8bb";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gamepad-alt:before {
  content: "\f8bc";
}

.fa-garage:before {
  content: "\e009";
}

.fa-garage-car:before {
  content: "\e00a";
}

.fa-garage-open:before {
  content: "\e00b";
}

.fa-gas-pump:before {
  content: "\f52f";
}

.fa-gas-pump-slash:before {
  content: "\f5f4";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-ghost:before {
  content: "\f6e2";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-gift-card:before {
  content: "\f663";
}

.fa-gifts:before {
  content: "\f79c";
}

.fa-gingerbread-man:before {
  content: "\f79d";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-alt:before {
  content: "\f841";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass:before {
  content: "\f804";
}

.fa-glass-champagne:before {
  content: "\f79e";
}

.fa-glass-cheers:before {
  content: "\f79f";
}

.fa-glass-citrus:before {
  content: "\f869";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glass-martini-alt:before {
  content: "\f57b";
}

.fa-glass-whiskey:before {
  content: "\f7a0";
}

.fa-glass-whiskey-rocks:before {
  content: "\f7a1";
}

.fa-glasses:before {
  content: "\f530";
}

.fa-glasses-alt:before {
  content: "\f5f5";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-globe-africa:before {
  content: "\f57c";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-globe-asia:before {
  content: "\f57e";
}

.fa-globe-europe:before {
  content: "\f7a2";
}

.fa-globe-snow:before {
  content: "\f7a3";
}

.fa-globe-stand:before {
  content: "\f5f6";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-golf-club:before {
  content: "\f451";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-pay:before {
  content: "\e079";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gopuram:before {
  content: "\f664";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gramophone:before {
  content: "\f8bd";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-greater-than:before {
  content: "\f531";
}

.fa-greater-than-equal:before {
  content: "\f532";
}

.fa-grimace:before {
  content: "\f57f";
}

.fa-grin:before {
  content: "\f580";
}

.fa-grin-alt:before {
  content: "\f581";
}

.fa-grin-beam:before {
  content: "\f582";
}

.fa-grin-beam-sweat:before {
  content: "\f583";
}

.fa-grin-hearts:before {
  content: "\f584";
}

.fa-grin-squint:before {
  content: "\f585";
}

.fa-grin-squint-tears:before {
  content: "\f586";
}

.fa-grin-stars:before {
  content: "\f587";
}

.fa-grin-tears:before {
  content: "\f588";
}

.fa-grin-tongue:before {
  content: "\f589";
}

.fa-grin-tongue-squint:before {
  content: "\f58a";
}

.fa-grin-tongue-wink:before {
  content: "\f58b";
}

.fa-grin-wink:before {
  content: "\f58c";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-grip-lines:before {
  content: "\f7a4";
}

.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

.fa-grip-vertical:before {
  content: "\f58e";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guilded:before {
  content: "\e07e";
}

.fa-guitar:before {
  content: "\f7a6";
}

.fa-guitar-electric:before {
  content: "\f8be";
}

.fa-guitars:before {
  content: "\f8bf";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-h1:before {
  content: "\f313";
}

.fa-h2:before {
  content: "\f314";
}

.fa-h3:before {
  content: "\f315";
}

.fa-h4:before {
  content: "\f86a";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hamburger:before {
  content: "\f805";
}

.fa-hammer:before {
  content: "\f6e3";
}

.fa-hammer-war:before {
  content: "\f6e4";
}

.fa-hamsa:before {
  content: "\f665";
}

.fa-hand-heart:before {
  content: "\f4bc";
}

.fa-hand-holding:before {
  content: "\f4bd";
}

.fa-hand-holding-box:before {
  content: "\f47b";
}

.fa-hand-holding-heart:before {
  content: "\f4be";
}

.fa-hand-holding-magic:before {
  content: "\f6e5";
}

.fa-hand-holding-medical:before {
  content: "\e05c";
}

.fa-hand-holding-seedling:before {
  content: "\f4bf";
}

.fa-hand-holding-usd:before {
  content: "\f4c0";
}

.fa-hand-holding-water:before {
  content: "\f4c1";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-middle-finger:before {
  content: "\f806";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-receiving:before {
  content: "\f47c";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-sparkles:before {
  content: "\e05d";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-hands:before {
  content: "\f4c2";
}

.fa-hands-heart:before {
  content: "\f4c3";
}

.fa-hands-helping:before {
  content: "\f4c4";
}

.fa-hands-usd:before {
  content: "\f4c5";
}

.fa-hands-wash:before {
  content: "\e05e";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-handshake-alt:before {
  content: "\f4c6";
}

.fa-handshake-alt-slash:before {
  content: "\e05f";
}

.fa-handshake-slash:before {
  content: "\e060";
}

.fa-hanukiah:before {
  content: "\f6e6";
}

.fa-hard-hat:before {
  content: "\f807";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hat-chef:before {
  content: "\f86b";
}

.fa-hat-cowboy:before {
  content: "\f8c0";
}

.fa-hat-cowboy-side:before {
  content: "\f8c1";
}

.fa-hat-santa:before {
  content: "\f7a7";
}

.fa-hat-winter:before {
  content: "\f7a8";
}

.fa-hat-witch:before {
  content: "\f6e7";
}

.fa-hat-wizard:before {
  content: "\f6e8";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-head-side:before {
  content: "\f6e9";
}

.fa-head-side-brain:before {
  content: "\f808";
}

.fa-head-side-cough:before {
  content: "\e061";
}

.fa-head-side-cough-slash:before {
  content: "\e062";
}

.fa-head-side-headphones:before {
  content: "\f8c2";
}

.fa-head-side-mask:before {
  content: "\e063";
}

.fa-head-side-medical:before {
  content: "\f809";
}

.fa-head-side-virus:before {
  content: "\e064";
}

.fa-head-vr:before {
  content: "\f6ea";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-headphones-alt:before {
  content: "\f58f";
}

.fa-headset:before {
  content: "\f590";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heart-broken:before {
  content: "\f7a9";
}

.fa-heart-circle:before {
  content: "\f4c7";
}

.fa-heart-rate:before {
  content: "\f5f8";
}

.fa-heart-square:before {
  content: "\f4c8";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-heat:before {
  content: "\e00c";
}

.fa-helicopter:before {
  content: "\f533";
}

.fa-helmet-battle:before {
  content: "\f6eb";
}

.fa-hexagon:before {
  content: "\f312";
}

.fa-highlighter:before {
  content: "\f591";
}

.fa-hiking:before {
  content: "\f6ec";
}

.fa-hippo:before {
  content: "\f6ed";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hive:before {
  content: "\e07f";
}

.fa-hockey-mask:before {
  content: "\f6ee";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-hockey-sticks:before {
  content: "\f454";
}

.fa-holly-berry:before {
  content: "\f7aa";
}

.fa-home:before {
  content: "\f015";
}

.fa-home-alt:before {
  content: "\f80a";
}

.fa-home-heart:before {
  content: "\f4c9";
}

.fa-home-lg:before {
  content: "\f80b";
}

.fa-home-lg-alt:before {
  content: "\f80c";
}

.fa-hood-cloak:before {
  content: "\f6ef";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-horizontal-rule:before {
  content: "\f86c";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-horse:before {
  content: "\f6f0";
}

.fa-horse-head:before {
  content: "\f7ab";
}

.fa-horse-saddle:before {
  content: "\f8c3";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hospital-user:before {
  content: "\f80d";
}

.fa-hospitals:before {
  content: "\f80e";
}

.fa-hot-tub:before {
  content: "\f593";
}

.fa-hotdog:before {
  content: "\f80f";
}

.fa-hotel:before {
  content: "\f594";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-house:before {
  content: "\e00d";
}

.fa-house-damage:before {
  content: "\f6f1";
}

.fa-house-day:before {
  content: "\e00e";
}

.fa-house-flood:before {
  content: "\f74f";
}

.fa-house-leave:before {
  content: "\e00f";
}

.fa-house-night:before {
  content: "\e010";
}

.fa-house-return:before {
  content: "\e011";
}

.fa-house-signal:before {
  content: "\e012";
}

.fa-house-user:before {
  content: "\e065";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-hryvnia:before {
  content: "\f6f2";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-humidity:before {
  content: "\f750";
}

.fa-hurricane:before {
  content: "\f751";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-ice-cream:before {
  content: "\f810";
}

.fa-ice-skate:before {
  content: "\f7ac";
}

.fa-icicles:before {
  content: "\f7ad";
}

.fa-icons:before {
  content: "\f86d";
}

.fa-icons-alt:before {
  content: "\f86e";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-ideal:before {
  content: "\e013";
}

.fa-igloo:before {
  content: "\f7ae";
}

.fa-image:before {
  content: "\f03e";
}

.fa-image-polaroid:before {
  content: "\f8c4";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-inbox-in:before {
  content: "\f310";
}

.fa-inbox-out:before {
  content: "\f311";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-industry-alt:before {
  content: "\f3b3";
}

.fa-infinity:before {
  content: "\f534";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-info-square:before {
  content: "\f30f";
}

.fa-inhaler:before {
  content: "\f5f9";
}

.fa-innosoft:before {
  content: "\e080";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-instagram-square:before {
  content: "\e055";
}

.fa-instalod:before {
  content: "\e081";
}

.fa-integral:before {
  content: "\f667";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-intersection:before {
  content: "\f668";
}

.fa-inventory:before {
  content: "\f480";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-island-tropical:before {
  content: "\f811";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-jack-o-lantern:before {
  content: "\f30e";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi:before {
  content: "\f669";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joint:before {
  content: "\f595";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-journal-whills:before {
  content: "\f66a";
}

.fa-joystick:before {
  content: "\f8c5";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-jug:before {
  content: "\f8c6";
}

.fa-kaaba:before {
  content: "\f66b";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-kazoo:before {
  content: "\f8c7";
}

.fa-kerning:before {
  content: "\f86f";
}

.fa-key:before {
  content: "\f084";
}

.fa-key-skeleton:before {
  content: "\f6f3";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-keynote:before {
  content: "\f66c";
}

.fa-khanda:before {
  content: "\f66d";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-kidneys:before {
  content: "\f5fb";
}

.fa-kiss:before {
  content: "\f596";
}

.fa-kiss-beam:before {
  content: "\f597";
}

.fa-kiss-wink-heart:before {
  content: "\f598";
}

.fa-kite:before {
  content: "\f6f4";
}

.fa-kiwi-bird:before {
  content: "\f535";
}

.fa-knife-kitchen:before {
  content: "\f6f5";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-lambda:before {
  content: "\f66e";
}

.fa-lamp:before {
  content: "\f4ca";
}

.fa-lamp-desk:before {
  content: "\e014";
}

.fa-lamp-floor:before {
  content: "\e015";
}

.fa-landmark:before {
  content: "\f66f";
}

.fa-landmark-alt:before {
  content: "\f752";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laptop-code:before {
  content: "\f5fc";
}

.fa-laptop-house:before {
  content: "\e066";
}

.fa-laptop-medical:before {
  content: "\f812";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lasso:before {
  content: "\f8c8";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-laugh:before {
  content: "\f599";
}

.fa-laugh-beam:before {
  content: "\f59a";
}

.fa-laugh-squint:before {
  content: "\f59b";
}

.fa-laugh-wink:before {
  content: "\f59c";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-layer-minus:before {
  content: "\f5fe";
}

.fa-layer-plus:before {
  content: "\f5ff";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leaf-heart:before {
  content: "\f4cb";
}

.fa-leaf-maple:before {
  content: "\f6f6";
}

.fa-leaf-oak:before {
  content: "\f6f7";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-less-than:before {
  content: "\f536";
}

.fa-less-than-equal:before {
  content: "\f537";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-light-ceiling:before {
  content: "\e016";
}

.fa-light-switch:before {
  content: "\e017";
}

.fa-light-switch-off:before {
  content: "\e018";
}

.fa-light-switch-on:before {
  content: "\e019";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-lightbulb-dollar:before {
  content: "\f670";
}

.fa-lightbulb-exclamation:before {
  content: "\f671";
}

.fa-lightbulb-on:before {
  content: "\f672";
}

.fa-lightbulb-slash:before {
  content: "\f673";
}

.fa-lights-holiday:before {
  content: "\f7b2";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-line-columns:before {
  content: "\f870";
}

.fa-line-height:before {
  content: "\f871";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lips:before {
  content: "\f600";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-music:before {
  content: "\f8c9";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location:before {
  content: "\f601";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-location-circle:before {
  content: "\f602";
}

.fa-location-slash:before {
  content: "\f603";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-alt:before {
  content: "\f30d";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-lock-open-alt:before {
  content: "\f3c2";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-loveseat:before {
  content: "\f4cc";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luchador:before {
  content: "\f455";
}

.fa-luggage-cart:before {
  content: "\f59d";
}

.fa-lungs:before {
  content: "\f604";
}

.fa-lungs-virus:before {
  content: "\e067";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-mace:before {
  content: "\f6f8";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-mail-bulk:before {
  content: "\f674";
}

.fa-mailbox:before {
  content: "\f813";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-male:before {
  content: "\f183";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-mandolin:before {
  content: "\f6f9";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marked:before {
  content: "\f59f";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-marker-alt-slash:before {
  content: "\f605";
}

.fa-map-marker-check:before {
  content: "\f606";
}

.fa-map-marker-edit:before {
  content: "\f607";
}

.fa-map-marker-exclamation:before {
  content: "\f608";
}

.fa-map-marker-minus:before {
  content: "\f609";
}

.fa-map-marker-plus:before {
  content: "\f60a";
}

.fa-map-marker-question:before {
  content: "\f60b";
}

.fa-map-marker-slash:before {
  content: "\f60c";
}

.fa-map-marker-smile:before {
  content: "\f60d";
}

.fa-map-marker-times:before {
  content: "\f60e";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-marker:before {
  content: "\f5a1";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mask:before {
  content: "\f6fa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-mdb:before {
  content: "\f8ca";
}

.fa-meat:before {
  content: "\f814";
}

.fa-medal:before {
  content: "\f5a2";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaphone:before {
  content: "\f675";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-meh-blank:before {
  content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

.fa-memory:before {
  content: "\f538";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-menorah:before {
  content: "\f676";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-meteor:before {
  content: "\f753";
}

.fa-microblog:before {
  content: "\e01a";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-alt-slash:before {
  content: "\f539";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microphone-stand:before {
  content: "\f8cb";
}

.fa-microscope:before {
  content: "\f610";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-microwave:before {
  content: "\e01b";
}

.fa-mind-share:before {
  content: "\f677";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-hexagon:before {
  content: "\f307";
}

.fa-minus-octagon:before {
  content: "\f308";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mistletoe:before {
  content: "\f7b4";
}

.fa-mitten:before {
  content: "\f7b5";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mixer:before {
  content: "\e056";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-mobile-android:before {
  content: "\f3ce";
}

.fa-mobile-android-alt:before {
  content: "\f3cf";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-money-bill-wave:before {
  content: "\f53a";
}

.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

.fa-money-check:before {
  content: "\f53c";
}

.fa-money-check-alt:before {
  content: "\f53d";
}

.fa-money-check-edit:before {
  content: "\f872";
}

.fa-money-check-edit-alt:before {
  content: "\f873";
}

.fa-monitor-heart-rate:before {
  content: "\f611";
}

.fa-monkey:before {
  content: "\f6fb";
}

.fa-monument:before {
  content: "\f5a6";
}

.fa-moon:before {
  content: "\f186";
}

.fa-moon-cloud:before {
  content: "\f754";
}

.fa-moon-stars:before {
  content: "\f755";
}

.fa-mortar-pestle:before {
  content: "\f5a7";
}

.fa-mosque:before {
  content: "\f678";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mountain:before {
  content: "\f6fc";
}

.fa-mountains:before {
  content: "\f6fd";
}

.fa-mouse:before {
  content: "\f8cc";
}

.fa-mouse-alt:before {
  content: "\f8cd";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-mp3-player:before {
  content: "\f8ce";
}

.fa-mug:before {
  content: "\f874";
}

.fa-mug-hot:before {
  content: "\f7b6";
}

.fa-mug-marshmallows:before {
  content: "\f7b7";
}

.fa-mug-tea:before {
  content: "\f875";
}

.fa-music:before {
  content: "\f001";
}

.fa-music-alt:before {
  content: "\f8cf";
}

.fa-music-alt-slash:before {
  content: "\f8d0";
}

.fa-music-slash:before {
  content: "\f8d1";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-narwhal:before {
  content: "\f6fe";
}

.fa-neos:before {
  content: "\f612";
}

.fa-network-wired:before {
  content: "\f6ff";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-not-equal:before {
  content: "\f53e";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-octagon:before {
  content: "\f306";
}

.fa-octopus-deploy:before {
  content: "\e082";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-oil-can:before {
  content: "\f613";
}

.fa-oil-temp:before {
  content: "\f614";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-om:before {
  content: "\f679";
}

.fa-omega:before {
  content: "\f67a";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-orcid:before {
  content: "\f8d2";
}

.fa-ornament:before {
  content: "\f7b8";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-otter:before {
  content: "\f700";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-outlet:before {
  content: "\e01c";
}

.fa-oven:before {
  content: "\e01d";
}

.fa-overline:before {
  content: "\f876";
}

.fa-page-break:before {
  content: "\f877";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-pager:before {
  content: "\f815";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-paint-brush-alt:before {
  content: "\f5a9";
}

.fa-paint-roller:before {
  content: "\f5aa";
}

.fa-palette:before {
  content: "\f53f";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-pallet-alt:before {
  content: "\f483";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-parachute-box:before {
  content: "\f4cd";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-paragraph-rtl:before {
  content: "\f878";
}

.fa-parking:before {
  content: "\f540";
}

.fa-parking-circle:before {
  content: "\f615";
}

.fa-parking-circle-slash:before {
  content: "\f616";
}

.fa-parking-slash:before {
  content: "\f617";
}

.fa-passport:before {
  content: "\f5ab";
}

.fa-pastafarianism:before {
  content: "\f67b";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paw-alt:before {
  content: "\f701";
}

.fa-paw-claws:before {
  content: "\f702";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-peace:before {
  content: "\f67c";
}

.fa-pegasus:before {
  content: "\f703";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-fancy:before {
  content: "\f5ac";
}

.fa-pen-nib:before {
  content: "\f5ad";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pencil-paintbrush:before {
  content: "\f618";
}

.fa-pencil-ruler:before {
  content: "\f5ae";
}

.fa-pennant:before {
  content: "\f456";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-people-arrows:before {
  content: "\e068";
}

.fa-people-carry:before {
  content: "\f4ce";
}

.fa-pepper-hot:before {
  content: "\f816";
}

.fa-perbyte:before {
  content: "\e083";
}

.fa-percent:before {
  content: "\f295";
}

.fa-percentage:before {
  content: "\f541";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-person-booth:before {
  content: "\f756";
}

.fa-person-carry:before {
  content: "\f4cf";
}

.fa-person-dolly:before {
  content: "\f4d0";
}

.fa-person-dolly-empty:before {
  content: "\f4d1";
}

.fa-person-sign:before {
  content: "\f757";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-alt:before {
  content: "\f879";
}

.fa-phone-laptop:before {
  content: "\f87a";
}

.fa-phone-office:before {
  content: "\f67d";
}

.fa-phone-plus:before {
  content: "\f4d2";
}

.fa-phone-rotary:before {
  content: "\f8d3";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-square-alt:before {
  content: "\f87b";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-photo-video:before {
  content: "\f87c";
}

.fa-php:before {
  content: "\f457";
}

.fa-pi:before {
  content: "\f67e";
}

.fa-piano:before {
  content: "\f8d4";
}

.fa-piano-keyboard:before {
  content: "\f8d5";
}

.fa-pie:before {
  content: "\f705";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pied-piper-square:before {
  content: "\e01e";
}

.fa-pig:before {
  content: "\f706";
}

.fa-piggy-bank:before {
  content: "\f4d3";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-pizza:before {
  content: "\f817";
}

.fa-pizza-slice:before {
  content: "\f818";
}

.fa-place-of-worship:before {
  content: "\f67f";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-alt:before {
  content: "\f3de";
}

.fa-plane-arrival:before {
  content: "\f5af";
}

.fa-plane-departure:before {
  content: "\f5b0";
}

.fa-plane-slash:before {
  content: "\e069";
}

.fa-planet-moon:before {
  content: "\e01f";
}

.fa-planet-ringed:before {
  content: "\e020";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-hexagon:before {
  content: "\f300";
}

.fa-plus-octagon:before {
  content: "\f301";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-podium:before {
  content: "\f680";
}

.fa-podium-star:before {
  content: "\f758";
}

.fa-police-box:before {
  content: "\e021";
}

.fa-poll:before {
  content: "\f681";
}

.fa-poll-h:before {
  content: "\f682";
}

.fa-poll-people:before {
  content: "\f759";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-poo-storm:before {
  content: "\f75a";
}

.fa-poop:before {
  content: "\f619";
}

.fa-popcorn:before {
  content: "\f819";
}

.fa-portal-enter:before {
  content: "\e022";
}

.fa-portal-exit:before {
  content: "\e023";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-pray:before {
  content: "\f683";
}

.fa-praying-hands:before {
  content: "\f684";
}

.fa-prescription:before {
  content: "\f5b1";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-presentation:before {
  content: "\f685";
}

.fa-print:before {
  content: "\f02f";
}

.fa-print-search:before {
  content: "\f81a";
}

.fa-print-slash:before {
  content: "\f686";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-project-diagram:before {
  content: "\f542";
}

.fa-projector:before {
  content: "\f8d6";
}

.fa-pump-medical:before {
  content: "\e06a";
}

.fa-pump-soap:before {
  content: "\e06b";
}

.fa-pumpkin:before {
  content: "\f707";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-question-square:before {
  content: "\f2fd";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-quran:before {
  content: "\f687";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-rabbit:before {
  content: "\f708";
}

.fa-rabbit-fast:before {
  content: "\f709";
}

.fa-racquet:before {
  content: "\f45a";
}

.fa-radar:before {
  content: "\e024";
}

.fa-radiation:before {
  content: "\f7b9";
}

.fa-radiation-alt:before {
  content: "\f7ba";
}

.fa-radio:before {
  content: "\f8d7";
}

.fa-radio-alt:before {
  content: "\f8d8";
}

.fa-rainbow:before {
  content: "\f75b";
}

.fa-raindrops:before {
  content: "\f75c";
}

.fa-ram:before {
  content: "\f70a";
}

.fa-ramp-loading:before {
  content: "\f4d4";
}

.fa-random:before {
  content: "\f074";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-raygun:before {
  content: "\e025";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-receipt:before {
  content: "\f543";
}

.fa-record-vinyl:before {
  content: "\f8d9";
}

.fa-rectangle-landscape:before {
  content: "\f2fa";
}

.fa-rectangle-portrait:before {
  content: "\f2fb";
}

.fa-rectangle-wide:before {
  content: "\f2fc";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-refrigerator:before {
  content: "\e026";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-remove-format:before {
  content: "\f87d";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-repeat:before {
  content: "\f363";
}

.fa-repeat-1:before {
  content: "\f365";
}

.fa-repeat-1-alt:before {
  content: "\f366";
}

.fa-repeat-alt:before {
  content: "\f364";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-republican:before {
  content: "\f75e";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-restroom:before {
  content: "\f7bd";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-retweet-alt:before {
  content: "\f361";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-ribbon:before {
  content: "\f4d6";
}

.fa-ring:before {
  content: "\f70b";
}

.fa-rings-wedding:before {
  content: "\f81b";
}

.fa-road:before {
  content: "\f018";
}

.fa-robot:before {
  content: "\f544";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocket-launch:before {
  content: "\e027";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-route:before {
  content: "\f4d7";
}

.fa-route-highway:before {
  content: "\f61a";
}

.fa-route-interstate:before {
  content: "\f61b";
}

.fa-router:before {
  content: "\f8da";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-ruler:before {
  content: "\f545";
}

.fa-ruler-combined:before {
  content: "\f546";
}

.fa-ruler-horizontal:before {
  content: "\f547";
}

.fa-ruler-triangle:before {
  content: "\f61c";
}

.fa-ruler-vertical:before {
  content: "\f548";
}

.fa-running:before {
  content: "\f70c";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-rust:before {
  content: "\e07a";
}

.fa-rv:before {
  content: "\f7be";
}

.fa-sack:before {
  content: "\f81c";
}

.fa-sack-dollar:before {
  content: "\f81d";
}

.fa-sad-cry:before {
  content: "\f5b3";
}

.fa-sad-tear:before {
  content: "\f5b4";
}

.fa-safari:before {
  content: "\f267";
}

.fa-salad:before {
  content: "\f81e";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-sandwich:before {
  content: "\f81f";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-satellite:before {
  content: "\f7bf";
}

.fa-satellite-dish:before {
  content: "\f7c0";
}

.fa-sausage:before {
  content: "\f820";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-sax-hot:before {
  content: "\f8db";
}

.fa-saxophone:before {
  content: "\f8dc";
}

.fa-scalpel:before {
  content: "\f61d";
}

.fa-scalpel-path:before {
  content: "\f61e";
}

.fa-scanner:before {
  content: "\f488";
}

.fa-scanner-image:before {
  content: "\f8f3";
}

.fa-scanner-keyboard:before {
  content: "\f489";
}

.fa-scanner-touchscreen:before {
  content: "\f48a";
}

.fa-scarecrow:before {
  content: "\f70d";
}

.fa-scarf:before {
  content: "\f7c1";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-school:before {
  content: "\f549";
}

.fa-screwdriver:before {
  content: "\f54a";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scroll:before {
  content: "\f70e";
}

.fa-scroll-old:before {
  content: "\f70f";
}

.fa-scrubber:before {
  content: "\f2f8";
}

.fa-scythe:before {
  content: "\f710";
}

.fa-sd-card:before {
  content: "\f7c2";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-dollar:before {
  content: "\f688";
}

.fa-search-location:before {
  content: "\f689";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-seedling:before {
  content: "\f4d8";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-send-back:before {
  content: "\f87e";
}

.fa-send-backward:before {
  content: "\f87f";
}

.fa-sensor:before {
  content: "\e028";
}

.fa-sensor-alert:before {
  content: "\e029";
}

.fa-sensor-fire:before {
  content: "\e02a";
}

.fa-sensor-on:before {
  content: "\e02b";
}

.fa-sensor-smoke:before {
  content: "\e02c";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shapes:before {
  content: "\f61f";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-all:before {
  content: "\f367";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-sheep:before {
  content: "\f711";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield:before {
  content: "\f132";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-shield-check:before {
  content: "\f2f7";
}

.fa-shield-cross:before {
  content: "\f712";
}

.fa-shield-virus:before {
  content: "\e06c";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shipping-timed:before {
  content: "\f48c";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shish-kebab:before {
  content: "\f821";
}

.fa-shoe-prints:before {
  content: "\f54b";
}

.fa-shopify:before {
  content: "\e057";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-shovel:before {
  content: "\f713";
}

.fa-shovel-snow:before {
  content: "\f7c3";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shredder:before {
  content: "\f68a";
}

.fa-shuttle-van:before {
  content: "\f5b6";
}

.fa-shuttlecock:before {
  content: "\f45b";
}

.fa-sickle:before {
  content: "\f822";
}

.fa-sigma:before {
  content: "\f68b";
}

.fa-sign:before {
  content: "\f4d9";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-signal-1:before {
  content: "\f68c";
}

.fa-signal-2:before {
  content: "\f68d";
}

.fa-signal-3:before {
  content: "\f68e";
}

.fa-signal-4:before {
  content: "\f68f";
}

.fa-signal-alt:before {
  content: "\f690";
}

.fa-signal-alt-1:before {
  content: "\f691";
}

.fa-signal-alt-2:before {
  content: "\f692";
}

.fa-signal-alt-3:before {
  content: "\f693";
}

.fa-signal-alt-slash:before {
  content: "\f694";
}

.fa-signal-slash:before {
  content: "\f695";
}

.fa-signal-stream:before {
  content: "\f8dd";
}

.fa-signature:before {
  content: "\f5b7";
}

.fa-sim-card:before {
  content: "\f7c4";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sink:before {
  content: "\e06d";
}

.fa-siren:before {
  content: "\e02d";
}

.fa-siren-on:before {
  content: "\e02e";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-sith:before {
  content: "\f512";
}

.fa-skating:before {
  content: "\f7c5";
}

.fa-skeleton:before {
  content: "\f620";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-ski-jump:before {
  content: "\f7c7";
}

.fa-ski-lift:before {
  content: "\f7c8";
}

.fa-skiing:before {
  content: "\f7c9";
}

.fa-skiing-nordic:before {
  content: "\f7ca";
}

.fa-skull:before {
  content: "\f54c";
}

.fa-skull-cow:before {
  content: "\f8de";
}

.fa-skull-crossbones:before {
  content: "\f714";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-slash:before {
  content: "\f715";
}

.fa-sledding:before {
  content: "\f7cb";
}

.fa-sleigh:before {
  content: "\f7cc";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-sliders-h-square:before {
  content: "\f3f0";
}

.fa-sliders-v:before {
  content: "\f3f1";
}

.fa-sliders-v-square:before {
  content: "\f3f2";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-smile-plus:before {
  content: "\f5b9";
}

.fa-smile-wink:before {
  content: "\f4da";
}

.fa-smog:before {
  content: "\f75f";
}

.fa-smoke:before {
  content: "\f760";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-smoking-ban:before {
  content: "\f54d";
}

.fa-sms:before {
  content: "\f7cd";
}

.fa-snake:before {
  content: "\f716";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snooze:before {
  content: "\f880";
}

.fa-snow-blowing:before {
  content: "\f761";
}

.fa-snowboarding:before {
  content: "\f7ce";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-snowflakes:before {
  content: "\f7cf";
}

.fa-snowman:before {
  content: "\f7d0";
}

.fa-snowmobile:before {
  content: "\f7d1";
}

.fa-snowplow:before {
  content: "\f7d2";
}

.fa-soap:before {
  content: "\e06e";
}

.fa-socks:before {
  content: "\f696";
}

.fa-solar-panel:before {
  content: "\f5ba";
}

.fa-solar-system:before {
  content: "\e02f";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-down-alt:before {
  content: "\f881";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-alpha-up-alt:before {
  content: "\f882";
}

.fa-sort-alt:before {
  content: "\f883";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-down-alt:before {
  content: "\f884";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-amount-up-alt:before {
  content: "\f885";
}

.fa-sort-circle:before {
  content: "\e030";
}

.fa-sort-circle-down:before {
  content: "\e031";
}

.fa-sort-circle-up:before {
  content: "\e032";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-down-alt:before {
  content: "\f886";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-numeric-up-alt:before {
  content: "\f887";
}

.fa-sort-shapes-down:before {
  content: "\f888";
}

.fa-sort-shapes-down-alt:before {
  content: "\f889";
}

.fa-sort-shapes-up:before {
  content: "\f88a";
}

.fa-sort-shapes-up-alt:before {
  content: "\f88b";
}

.fa-sort-size-down:before {
  content: "\f88c";
}

.fa-sort-size-down-alt:before {
  content: "\f88d";
}

.fa-sort-size-up:before {
  content: "\f88e";
}

.fa-sort-size-up-alt:before {
  content: "\f88f";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-soup:before {
  content: "\f823";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-spa:before {
  content: "\f5bb";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-space-station-moon:before {
  content: "\e033";
}

.fa-space-station-moon-alt:before {
  content: "\e034";
}

.fa-spade:before {
  content: "\f2f4";
}

.fa-sparkles:before {
  content: "\f890";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-speaker:before {
  content: "\f8df";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-speakers:before {
  content: "\f8e0";
}

.fa-spell-check:before {
  content: "\f891";
}

.fa-spider:before {
  content: "\f717";
}

.fa-spider-black-widow:before {
  content: "\f718";
}

.fa-spider-web:before {
  content: "\f719";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-spinner-third:before {
  content: "\f3f4";
}

.fa-splotch:before {
  content: "\f5bc";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-spray-can:before {
  content: "\f5bd";
}

.fa-sprinkler:before {
  content: "\e035";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-square-root:before {
  content: "\f697";
}

.fa-square-root-alt:before {
  content: "\f698";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-squirrel:before {
  content: "\f71a";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stackpath:before {
  content: "\f842";
}

.fa-staff:before {
  content: "\f71b";
}

.fa-stamp:before {
  content: "\f5bf";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-christmas:before {
  content: "\f7d4";
}

.fa-star-exclamation:before {
  content: "\f2f3";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}

.fa-star-shooting:before {
  content: "\e036";
}

.fa-starfighter:before {
  content: "\e037";
}

.fa-starfighter-alt:before {
  content: "\e038";
}

.fa-stars:before {
  content: "\f762";
}

.fa-starship:before {
  content: "\e039";
}

.fa-starship-freighter:before {
  content: "\e03a";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steak:before {
  content: "\f824";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-steering-wheel:before {
  content: "\f622";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stocking:before {
  content: "\f7d5";
}

.fa-stomach:before {
  content: "\f623";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-stopwatch-20:before {
  content: "\e06f";
}

.fa-store:before {
  content: "\f54e";
}

.fa-store-alt:before {
  content: "\f54f";
}

.fa-store-alt-slash:before {
  content: "\e070";
}

.fa-store-slash:before {
  content: "\e071";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stream:before {
  content: "\f550";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-stretcher:before {
  content: "\f825";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-stroopwafel:before {
  content: "\f551";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-suitcase-rolling:before {
  content: "\f5c1";
}

.fa-sun:before {
  content: "\f185";
}

.fa-sun-cloud:before {
  content: "\f763";
}

.fa-sun-dust:before {
  content: "\f764";
}

.fa-sun-haze:before {
  content: "\f765";
}

.fa-sunglasses:before {
  content: "\f892";
}

.fa-sunrise:before {
  content: "\f766";
}

.fa-sunset:before {
  content: "\f767";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-surprise:before {
  content: "\f5c2";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swatchbook:before {
  content: "\f5c3";
}

.fa-swift:before {
  content: "\f8e1";
}

.fa-swimmer:before {
  content: "\f5c4";
}

.fa-swimming-pool:before {
  content: "\f5c5";
}

.fa-sword:before {
  content: "\f71c";
}

.fa-sword-laser:before {
  content: "\e03b";
}

.fa-sword-laser-alt:before {
  content: "\e03c";
}

.fa-swords:before {
  content: "\f71d";
}

.fa-swords-laser:before {
  content: "\e03d";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-synagogue:before {
  content: "\f69b";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablet-android:before {
  content: "\f3fb";
}

.fa-tablet-android-alt:before {
  content: "\f3fc";
}

.fa-tablet-rugged:before {
  content: "\f48f";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer:before {
  content: "\f0e4";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tachometer-alt-average:before {
  content: "\f624";
}

.fa-tachometer-alt-fast:before {
  content: "\f625";
}

.fa-tachometer-alt-fastest:before {
  content: "\f626";
}

.fa-tachometer-alt-slow:before {
  content: "\f627";
}

.fa-tachometer-alt-slowest:before {
  content: "\f628";
}

.fa-tachometer-average:before {
  content: "\f629";
}

.fa-tachometer-fast:before {
  content: "\f62a";
}

.fa-tachometer-fastest:before {
  content: "\f62b";
}

.fa-tachometer-slow:before {
  content: "\f62c";
}

.fa-tachometer-slowest:before {
  content: "\f62d";
}

.fa-taco:before {
  content: "\f826";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tally:before {
  content: "\f69c";
}

.fa-tanakh:before {
  content: "\f827";
}

.fa-tape:before {
  content: "\f4db";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-tasks-alt:before {
  content: "\f828";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-teeth:before {
  content: "\f62e";
}

.fa-teeth-open:before {
  content: "\f62f";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-telescope:before {
  content: "\e03e";
}

.fa-temperature-down:before {
  content: "\e03f";
}

.fa-temperature-frigid:before {
  content: "\f768";
}

.fa-temperature-high:before {
  content: "\f769";
}

.fa-temperature-hot:before {
  content: "\f76a";
}

.fa-temperature-low:before {
  content: "\f76b";
}

.fa-temperature-up:before {
  content: "\e040";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tenge:before {
  content: "\f7d7";
}

.fa-tennis-ball:before {
  content: "\f45e";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text:before {
  content: "\f893";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-size:before {
  content: "\f894";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-theater-masks:before {
  content: "\f630";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-theta:before {
  content: "\f69e";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-thunderstorm:before {
  content: "\f76c";
}

.fa-thunderstorm-moon:before {
  content: "\f76d";
}

.fa-thunderstorm-sun:before {
  content: "\f76e";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-tiktok:before {
  content: "\e07b";
}

.fa-tilde:before {
  content: "\f69f";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-times-hexagon:before {
  content: "\f2ee";
}

.fa-times-octagon:before {
  content: "\f2f0";
}

.fa-times-square:before {
  content: "\f2d3";
}

.fa-tint:before {
  content: "\f043";
}

.fa-tint-slash:before {
  content: "\f5c7";
}

.fa-tire:before {
  content: "\f631";
}

.fa-tire-flat:before {
  content: "\f632";
}

.fa-tire-pressure-warning:before {
  content: "\f633";
}

.fa-tire-rugged:before {
  content: "\f634";
}

.fa-tired:before {
  content: "\f5c8";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-toilet:before {
  content: "\f7d8";
}

.fa-toilet-paper:before {
  content: "\f71e";
}

.fa-toilet-paper-alt:before {
  content: "\f71f";
}

.fa-toilet-paper-slash:before {
  content: "\e072";
}

.fa-tombstone:before {
  content: "\f720";
}

.fa-tombstone-alt:before {
  content: "\f721";
}

.fa-toolbox:before {
  content: "\f552";
}

.fa-tools:before {
  content: "\f7d9";
}

.fa-tooth:before {
  content: "\f5c9";
}

.fa-toothbrush:before {
  content: "\f635";
}

.fa-torah:before {
  content: "\f6a0";
}

.fa-torii-gate:before {
  content: "\f6a1";
}

.fa-tornado:before {
  content: "\f76f";
}

.fa-tractor:before {
  content: "\f722";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-traffic-cone:before {
  content: "\f636";
}

.fa-traffic-light:before {
  content: "\f637";
}

.fa-traffic-light-go:before {
  content: "\f638";
}

.fa-traffic-light-slow:before {
  content: "\f639";
}

.fa-traffic-light-stop:before {
  content: "\f63a";
}

.fa-trailer:before {
  content: "\e041";
}

.fa-train:before {
  content: "\f238";
}

.fa-tram:before {
  content: "\f7da";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-transporter:before {
  content: "\e042";
}

.fa-transporter-1:before {
  content: "\e043";
}

.fa-transporter-2:before {
  content: "\e044";
}

.fa-transporter-3:before {
  content: "\e045";
}

.fa-transporter-empty:before {
  content: "\e046";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-trash-restore:before {
  content: "\f829";
}

.fa-trash-restore-alt:before {
  content: "\f82a";
}

.fa-trash-undo:before {
  content: "\f895";
}

.fa-trash-undo-alt:before {
  content: "\f896";
}

.fa-treasure-chest:before {
  content: "\f723";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-tree-alt:before {
  content: "\f400";
}

.fa-tree-christmas:before {
  content: "\f7db";
}

.fa-tree-decorated:before {
  content: "\f7dc";
}

.fa-tree-large:before {
  content: "\f7dd";
}

.fa-tree-palm:before {
  content: "\f82b";
}

.fa-trees:before {
  content: "\f724";
}

.fa-trello:before {
  content: "\f181";
}

.fa-triangle:before {
  content: "\f2ec";
}

.fa-triangle-music:before {
  content: "\f8e2";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-trophy-alt:before {
  content: "\f2eb";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-truck-container:before {
  content: "\f4dc";
}

.fa-truck-couch:before {
  content: "\f4dd";
}

.fa-truck-loading:before {
  content: "\f4de";
}

.fa-truck-monster:before {
  content: "\f63b";
}

.fa-truck-moving:before {
  content: "\f4df";
}

.fa-truck-pickup:before {
  content: "\f63c";
}

.fa-truck-plow:before {
  content: "\f7de";
}

.fa-truck-ramp:before {
  content: "\f4e0";
}

.fa-trumpet:before {
  content: "\f8e3";
}

.fa-tshirt:before {
  content: "\f553";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-turkey:before {
  content: "\f725";
}

.fa-turntable:before {
  content: "\f8e4";
}

.fa-turtle:before {
  content: "\f726";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-tv-alt:before {
  content: "\f8e5";
}

.fa-tv-music:before {
  content: "\f8e6";
}

.fa-tv-retro:before {
  content: "\f401";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typewriter:before {
  content: "\f8e7";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-ufo:before {
  content: "\e047";
}

.fa-ufo-beam:before {
  content: "\e048";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbraco:before {
  content: "\f8e8";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-umbrella-beach:before {
  content: "\f5ca";
}

.fa-uncharted:before {
  content: "\e084";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-unicorn:before {
  content: "\f727";
}

.fa-union:before {
  content: "\f6a2";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-unity:before {
  content: "\e049";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-unsplash:before {
  content: "\e07c";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-usb-drive:before {
  content: "\f8e9";
}

.fa-usd-circle:before {
  content: "\f2e8";
}

.fa-usd-square:before {
  content: "\f2e9";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alien:before {
  content: "\e04a";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-alt-slash:before {
  content: "\f4fa";
}

.fa-user-astronaut:before {
  content: "\f4fb";
}

.fa-user-chart:before {
  content: "\f6a3";
}

.fa-user-check:before {
  content: "\f4fc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-clock:before {
  content: "\f4fd";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-cowboy:before {
  content: "\f8ea";
}

.fa-user-crown:before {
  content: "\f6a4";
}

.fa-user-edit:before {
  content: "\f4ff";
}

.fa-user-friends:before {
  content: "\f500";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-user-hard-hat:before {
  content: "\f82c";
}

.fa-user-headset:before {
  content: "\f82d";
}

.fa-user-injured:before {
  content: "\f728";
}

.fa-user-lock:before {
  content: "\f502";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-md-chat:before {
  content: "\f82e";
}

.fa-user-minus:before {
  content: "\f503";
}

.fa-user-music:before {
  content: "\f8eb";
}

.fa-user-ninja:before {
  content: "\f504";
}

.fa-user-nurse:before {
  content: "\f82f";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-robot:before {
  content: "\e04b";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-shield:before {
  content: "\f505";
}

.fa-user-slash:before {
  content: "\f506";
}

.fa-user-tag:before {
  content: "\f507";
}

.fa-user-tie:before {
  content: "\f508";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-user-unlock:before {
  content: "\e058";
}

.fa-user-visor:before {
  content: "\e04c";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-users-class:before {
  content: "\f63d";
}

.fa-users-cog:before {
  content: "\f509";
}

.fa-users-crown:before {
  content: "\f6a5";
}

.fa-users-medical:before {
  content: "\f830";
}

.fa-users-slash:before {
  content: "\e073";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-fork:before {
  content: "\f2e3";
}

.fa-utensil-knife:before {
  content: "\f2e4";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-utensils-alt:before {
  content: "\f2e6";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-vacuum:before {
  content: "\e04d";
}

.fa-vacuum-robot:before {
  content: "\e04e";
}

.fa-value-absolute:before {
  content: "\f6a6";
}

.fa-vector-square:before {
  content: "\f5cb";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-vest:before {
  content: "\e085";
}

.fa-vest-patches:before {
  content: "\e086";
}

.fa-vhs:before {
  content: "\f8ec";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-video-plus:before {
  content: "\f4e1";
}

.fa-video-slash:before {
  content: "\f4e2";
}

.fa-vihara:before {
  content: "\f6a7";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-violin:before {
  content: "\f8ed";
}

.fa-virus:before {
  content: "\e074";
}

.fa-virus-slash:before {
  content: "\e075";
}

.fa-viruses:before {
  content: "\e076";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-voicemail:before {
  content: "\f897";
}

.fa-volcano:before {
  content: "\f770";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume:before {
  content: "\f6a8";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f6a9";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-slash:before {
  content: "\f2e2";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vote-nay:before {
  content: "\f771";
}

.fa-vote-yea:before {
  content: "\f772";
}

.fa-vr-cardboard:before {
  content: "\f729";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-wagon-covered:before {
  content: "\f8ee";
}

.fa-walker:before {
  content: "\f831";
}

.fa-walkie-talkie:before {
  content: "\f8ef";
}

.fa-walking:before {
  content: "\f554";
}

.fa-wallet:before {
  content: "\f555";
}

.fa-wand:before {
  content: "\f72a";
}

.fa-wand-magic:before {
  content: "\f72b";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-warehouse-alt:before {
  content: "\f495";
}

.fa-washer:before {
  content: "\f898";
}

.fa-watch:before {
  content: "\f2e1";
}

.fa-watch-calculator:before {
  content: "\f8f0";
}

.fa-watch-fitness:before {
  content: "\f63e";
}

.fa-watchman-monitoring:before {
  content: "\e087";
}

.fa-water:before {
  content: "\f773";
}

.fa-water-lower:before {
  content: "\f774";
}

.fa-water-rise:before {
  content: "\f775";
}

.fa-wave-sine:before {
  content: "\f899";
}

.fa-wave-square:before {
  content: "\f83e";
}

.fa-wave-triangle:before {
  content: "\f89a";
}

.fa-waveform:before {
  content: "\f8f1";
}

.fa-waveform-path:before {
  content: "\f8f2";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-webcam:before {
  content: "\f832";
}

.fa-webcam-slash:before {
  content: "\f833";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weight-hanging:before {
  content: "\f5cd";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whale:before {
  content: "\f72c";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheat:before {
  content: "\f72d";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whistle:before {
  content: "\f460";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wifi-1:before {
  content: "\f6aa";
}

.fa-wifi-2:before {
  content: "\f6ab";
}

.fa-wifi-slash:before {
  content: "\f6ac";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wind:before {
  content: "\f72e";
}

.fa-wind-turbine:before {
  content: "\f89b";
}

.fa-wind-warning:before {
  content: "\f776";
}

.fa-window:before {
  content: "\f40e";
}

.fa-window-alt:before {
  content: "\f40f";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-frame:before {
  content: "\e04f";
}

.fa-window-frame-open:before {
  content: "\e050";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-windsock:before {
  content: "\f777";
}

.fa-wine-bottle:before {
  content: "\f72f";
}

.fa-wine-glass:before {
  content: "\f4e3";
}

.fa-wine-glass-alt:before {
  content: "\f5ce";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wodu:before {
  content: "\e088";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-wreath:before {
  content: "\f7e2";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yin-yang:before {
  content: "\f6ad";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fontawesome/fa-brands-400.eot");
  src: url("../fonts/fontawesome/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fontawesome/fa-brands-400.woff2") format("woff2"), url("../fonts/fontawesome/fa-brands-400.woff") format("woff"), url("../fonts/fontawesome/fa-brands-400.ttf") format("truetype"), url("../fonts/fontawesome/fa-brands-400.svg#fontawesome") format("svg");
}
.fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fontawesome/fa-regular-400.eot");
  src: url("../fonts/fontawesome/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fontawesome/fa-regular-400.woff2") format("woff2"), url("../fonts/fontawesome/fa-regular-400.woff") format("woff"), url("../fonts/fontawesome/fa-regular-400.ttf") format("truetype"), url("../fonts/fontawesome/fa-regular-400.svg#fontawesome") format("svg");
}
.far {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("/fonts/fontawesome/fa-solid-900.eot");
  src: url("/fonts/fontawesome/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/fa-solid-900.woff2") format("woff2"), url("/fonts/fontawesome/fa-solid-900.woff") format("woff"), url("/fonts/fontawesome/fa-solid-900.ttf") format("truetype"), url("/fonts/fontawesome/fa-solid-900.svg#fontawesome") format("svg");
}
.fa,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fontawesome/fa-light-300.eot");
  src: url("../fonts/fontawesome/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../fonts/fontawesome/fa-light-300.woff2") format("woff2"), url("../fonts/fontawesome/fa-light-300.woff") format("woff"), url("../fonts/fontawesome/fa-light-300.ttf") format("truetype"), url("../fonts/fontawesome/fa-light-300.svg#fontawesome") format("svg");
}
.fal {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}

/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("/fonts/fontawesome/fa-duotone-900.eot");
  src: url("/fonts/fontawesome/fa-duotone-900.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/fa-duotone-900.woff2") format("woff2"), url("/fonts/fontawesome/fa-duotone-900.woff") format("woff"), url("/fonts/fontawesome/fa-duotone-900.ttf") format("truetype"), url("/fonts/fontawesome/fa-duotone-900.svg#fontawesome") format("svg");
}
.fad {
  position: relative;
  font-family: "Font Awesome 5 Duotone";
  font-weight: 900;
}

.fad:before {
  position: absolute;
  color: var(--fa-primary-color, inherit);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}

.fad:after {
  color: var(--fa-secondary-color, inherit);
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad:before,
.fad.fa-swap-opacity:before {
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad:after,
.fad.fa-swap-opacity:after {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}

.fad.fa-inverse {
  color: #fff;
}

.fad.fa-stack-1x, .fad.fa-stack-2x {
  position: absolute;
}

.fad.fa-stack-1x:before,
.fad.fa-stack-2x:before,
.fad.fa-fw:before {
  left: 50%;
  transform: translateX(-50%);
}

.fad.fa-abacus:after {
  content: "\10f640";
}

.fad.fa-acorn:after {
  content: "\10f6ae";
}

.fad.fa-ad:after {
  content: "\10f641";
}

.fad.fa-address-book:after {
  content: "\10f2b9";
}

.fad.fa-address-card:after {
  content: "\10f2bb";
}

.fad.fa-adjust:after {
  content: "\10f042";
}

.fad.fa-air-conditioner:after {
  content: "\10f8f4";
}

.fad.fa-air-freshener:after {
  content: "\10f5d0";
}

.fad.fa-alarm-clock:after {
  content: "\10f34e";
}

.fad.fa-alarm-exclamation:after {
  content: "\10f843";
}

.fad.fa-alarm-plus:after {
  content: "\10f844";
}

.fad.fa-alarm-snooze:after {
  content: "\10f845";
}

.fad.fa-album:after {
  content: "\10f89f";
}

.fad.fa-album-collection:after {
  content: "\10f8a0";
}

.fad.fa-alicorn:after {
  content: "\10f6b0";
}

.fad.fa-alien:after {
  content: "\10f8f5";
}

.fad.fa-alien-monster:after {
  content: "\10f8f6";
}

.fad.fa-align-center:after {
  content: "\10f037";
}

.fad.fa-align-justify:after {
  content: "\10f039";
}

.fad.fa-align-left:after {
  content: "\10f036";
}

.fad.fa-align-right:after {
  content: "\10f038";
}

.fad.fa-align-slash:after {
  content: "\10f846";
}

.fad.fa-allergies:after {
  content: "\10f461";
}

.fad.fa-ambulance:after {
  content: "\10f0f9";
}

.fad.fa-american-sign-language-interpreting:after {
  content: "\10f2a3";
}

.fad.fa-amp-guitar:after {
  content: "\10f8a1";
}

.fad.fa-analytics:after {
  content: "\10f643";
}

.fad.fa-anchor:after {
  content: "\10f13d";
}

.fad.fa-angel:after {
  content: "\10f779";
}

.fad.fa-angle-double-down:after {
  content: "\10f103";
}

.fad.fa-angle-double-left:after {
  content: "\10f100";
}

.fad.fa-angle-double-right:after {
  content: "\10f101";
}

.fad.fa-angle-double-up:after {
  content: "\10f102";
}

.fad.fa-angle-down:after {
  content: "\10f107";
}

.fad.fa-angle-left:after {
  content: "\10f104";
}

.fad.fa-angle-right:after {
  content: "\10f105";
}

.fad.fa-angle-up:after {
  content: "\10f106";
}

.fad.fa-angry:after {
  content: "\10f556";
}

.fad.fa-ankh:after {
  content: "\10f644";
}

.fad.fa-apple-alt:after {
  content: "\10f5d1";
}

.fad.fa-apple-crate:after {
  content: "\10f6b1";
}

.fad.fa-archive:after {
  content: "\10f187";
}

.fad.fa-archway:after {
  content: "\10f557";
}

.fad.fa-arrow-alt-circle-down:after {
  content: "\10f358";
}

.fad.fa-arrow-alt-circle-left:after {
  content: "\10f359";
}

.fad.fa-arrow-alt-circle-right:after {
  content: "\10f35a";
}

.fad.fa-arrow-alt-circle-up:after {
  content: "\10f35b";
}

.fad.fa-arrow-alt-down:after {
  content: "\10f354";
}

.fad.fa-arrow-alt-from-bottom:after {
  content: "\10f346";
}

.fad.fa-arrow-alt-from-left:after {
  content: "\10f347";
}

.fad.fa-arrow-alt-from-right:after {
  content: "\10f348";
}

.fad.fa-arrow-alt-from-top:after {
  content: "\10f349";
}

.fad.fa-arrow-alt-left:after {
  content: "\10f355";
}

.fad.fa-arrow-alt-right:after {
  content: "\10f356";
}

.fad.fa-arrow-alt-square-down:after {
  content: "\10f350";
}

.fad.fa-arrow-alt-square-left:after {
  content: "\10f351";
}

.fad.fa-arrow-alt-square-right:after {
  content: "\10f352";
}

.fad.fa-arrow-alt-square-up:after {
  content: "\10f353";
}

.fad.fa-arrow-alt-to-bottom:after {
  content: "\10f34a";
}

.fad.fa-arrow-alt-to-left:after {
  content: "\10f34b";
}

.fad.fa-arrow-alt-to-right:after {
  content: "\10f34c";
}

.fad.fa-arrow-alt-to-top:after {
  content: "\10f34d";
}

.fad.fa-arrow-alt-up:after {
  content: "\10f357";
}

.fad.fa-arrow-circle-down:after {
  content: "\10f0ab";
}

.fad.fa-arrow-circle-left:after {
  content: "\10f0a8";
}

.fad.fa-arrow-circle-right:after {
  content: "\10f0a9";
}

.fad.fa-arrow-circle-up:after {
  content: "\10f0aa";
}

.fad.fa-arrow-down:after {
  content: "\10f063";
}

.fad.fa-arrow-from-bottom:after {
  content: "\10f342";
}

.fad.fa-arrow-from-left:after {
  content: "\10f343";
}

.fad.fa-arrow-from-right:after {
  content: "\10f344";
}

.fad.fa-arrow-from-top:after {
  content: "\10f345";
}

.fad.fa-arrow-left:after {
  content: "\10f060";
}

.fad.fa-arrow-right:after {
  content: "\10f061";
}

.fad.fa-arrow-square-down:after {
  content: "\10f339";
}

.fad.fa-arrow-square-left:after {
  content: "\10f33a";
}

.fad.fa-arrow-square-right:after {
  content: "\10f33b";
}

.fad.fa-arrow-square-up:after {
  content: "\10f33c";
}

.fad.fa-arrow-to-bottom:after {
  content: "\10f33d";
}

.fad.fa-arrow-to-left:after {
  content: "\10f33e";
}

.fad.fa-arrow-to-right:after {
  content: "\10f340";
}

.fad.fa-arrow-to-top:after {
  content: "\10f341";
}

.fad.fa-arrow-up:after {
  content: "\10f062";
}

.fad.fa-arrows:after {
  content: "\10f047";
}

.fad.fa-arrows-alt:after {
  content: "\10f0b2";
}

.fad.fa-arrows-alt-h:after {
  content: "\10f337";
}

.fad.fa-arrows-alt-v:after {
  content: "\10f338";
}

.fad.fa-arrows-h:after {
  content: "\10f07e";
}

.fad.fa-arrows-v:after {
  content: "\10f07d";
}

.fad.fa-assistive-listening-systems:after {
  content: "\10f2a2";
}

.fad.fa-asterisk:after {
  content: "\10f069";
}

.fad.fa-at:after {
  content: "\10f1fa";
}

.fad.fa-atlas:after {
  content: "\10f558";
}

.fad.fa-atom:after {
  content: "\10f5d2";
}

.fad.fa-atom-alt:after {
  content: "\10f5d3";
}

.fad.fa-audio-description:after {
  content: "\10f29e";
}

.fad.fa-award:after {
  content: "\10f559";
}

.fad.fa-axe:after {
  content: "\10f6b2";
}

.fad.fa-axe-battle:after {
  content: "\10f6b3";
}

.fad.fa-baby:after {
  content: "\10f77c";
}

.fad.fa-baby-carriage:after {
  content: "\10f77d";
}

.fad.fa-backpack:after {
  content: "\10f5d4";
}

.fad.fa-backspace:after {
  content: "\10f55a";
}

.fad.fa-backward:after {
  content: "\10f04a";
}

.fad.fa-bacon:after {
  content: "\10f7e5";
}

.fad.fa-bacteria:after {
  content: "\10e059";
}

.fad.fa-bacterium:after {
  content: "\10e05a";
}

.fad.fa-badge:after {
  content: "\10f335";
}

.fad.fa-badge-check:after {
  content: "\10f336";
}

.fad.fa-badge-dollar:after {
  content: "\10f645";
}

.fad.fa-badge-percent:after {
  content: "\10f646";
}

.fad.fa-badge-sheriff:after {
  content: "\10f8a2";
}

.fad.fa-badger-honey:after {
  content: "\10f6b4";
}

.fad.fa-bags-shopping:after {
  content: "\10f847";
}

.fad.fa-bahai:after {
  content: "\10f666";
}

.fad.fa-balance-scale:after {
  content: "\10f24e";
}

.fad.fa-balance-scale-left:after {
  content: "\10f515";
}

.fad.fa-balance-scale-right:after {
  content: "\10f516";
}

.fad.fa-ball-pile:after {
  content: "\10f77e";
}

.fad.fa-ballot:after {
  content: "\10f732";
}

.fad.fa-ballot-check:after {
  content: "\10f733";
}

.fad.fa-ban:after {
  content: "\10f05e";
}

.fad.fa-band-aid:after {
  content: "\10f462";
}

.fad.fa-banjo:after {
  content: "\10f8a3";
}

.fad.fa-barcode:after {
  content: "\10f02a";
}

.fad.fa-barcode-alt:after {
  content: "\10f463";
}

.fad.fa-barcode-read:after {
  content: "\10f464";
}

.fad.fa-barcode-scan:after {
  content: "\10f465";
}

.fad.fa-bars:after {
  content: "\10f0c9";
}

.fad.fa-baseball:after {
  content: "\10f432";
}

.fad.fa-baseball-ball:after {
  content: "\10f433";
}

.fad.fa-basketball-ball:after {
  content: "\10f434";
}

.fad.fa-basketball-hoop:after {
  content: "\10f435";
}

.fad.fa-bat:after {
  content: "\10f6b5";
}

.fad.fa-bath:after {
  content: "\10f2cd";
}

.fad.fa-battery-bolt:after {
  content: "\10f376";
}

.fad.fa-battery-empty:after {
  content: "\10f244";
}

.fad.fa-battery-full:after {
  content: "\10f240";
}

.fad.fa-battery-half:after {
  content: "\10f242";
}

.fad.fa-battery-quarter:after {
  content: "\10f243";
}

.fad.fa-battery-slash:after {
  content: "\10f377";
}

.fad.fa-battery-three-quarters:after {
  content: "\10f241";
}

.fad.fa-bed:after {
  content: "\10f236";
}

.fad.fa-bed-alt:after {
  content: "\10f8f7";
}

.fad.fa-bed-bunk:after {
  content: "\10f8f8";
}

.fad.fa-bed-empty:after {
  content: "\10f8f9";
}

.fad.fa-beer:after {
  content: "\10f0fc";
}

.fad.fa-bell:after {
  content: "\10f0f3";
}

.fad.fa-bell-exclamation:after {
  content: "\10f848";
}

.fad.fa-bell-on:after {
  content: "\10f8fa";
}

.fad.fa-bell-plus:after {
  content: "\10f849";
}

.fad.fa-bell-school:after {
  content: "\10f5d5";
}

.fad.fa-bell-school-slash:after {
  content: "\10f5d6";
}

.fad.fa-bell-slash:after {
  content: "\10f1f6";
}

.fad.fa-bells:after {
  content: "\10f77f";
}

.fad.fa-betamax:after {
  content: "\10f8a4";
}

.fad.fa-bezier-curve:after {
  content: "\10f55b";
}

.fad.fa-bible:after {
  content: "\10f647";
}

.fad.fa-bicycle:after {
  content: "\10f206";
}

.fad.fa-biking:after {
  content: "\10f84a";
}

.fad.fa-biking-mountain:after {
  content: "\10f84b";
}

.fad.fa-binoculars:after {
  content: "\10f1e5";
}

.fad.fa-biohazard:after {
  content: "\10f780";
}

.fad.fa-birthday-cake:after {
  content: "\10f1fd";
}

.fad.fa-blanket:after {
  content: "\10f498";
}

.fad.fa-blender:after {
  content: "\10f517";
}

.fad.fa-blender-phone:after {
  content: "\10f6b6";
}

.fad.fa-blind:after {
  content: "\10f29d";
}

.fad.fa-blinds:after {
  content: "\10f8fb";
}

.fad.fa-blinds-open:after {
  content: "\10f8fc";
}

.fad.fa-blinds-raised:after {
  content: "\10f8fd";
}

.fad.fa-blog:after {
  content: "\10f781";
}

.fad.fa-bold:after {
  content: "\10f032";
}

.fad.fa-bolt:after {
  content: "\10f0e7";
}

.fad.fa-bomb:after {
  content: "\10f1e2";
}

.fad.fa-bone:after {
  content: "\10f5d7";
}

.fad.fa-bone-break:after {
  content: "\10f5d8";
}

.fad.fa-bong:after {
  content: "\10f55c";
}

.fad.fa-book:after {
  content: "\10f02d";
}

.fad.fa-book-alt:after {
  content: "\10f5d9";
}

.fad.fa-book-dead:after {
  content: "\10f6b7";
}

.fad.fa-book-heart:after {
  content: "\10f499";
}

.fad.fa-book-medical:after {
  content: "\10f7e6";
}

.fad.fa-book-open:after {
  content: "\10f518";
}

.fad.fa-book-reader:after {
  content: "\10f5da";
}

.fad.fa-book-spells:after {
  content: "\10f6b8";
}

.fad.fa-book-user:after {
  content: "\10f7e7";
}

.fad.fa-bookmark:after {
  content: "\10f02e";
}

.fad.fa-books:after {
  content: "\10f5db";
}

.fad.fa-books-medical:after {
  content: "\10f7e8";
}

.fad.fa-boombox:after {
  content: "\10f8a5";
}

.fad.fa-boot:after {
  content: "\10f782";
}

.fad.fa-booth-curtain:after {
  content: "\10f734";
}

.fad.fa-border-all:after {
  content: "\10f84c";
}

.fad.fa-border-bottom:after {
  content: "\10f84d";
}

.fad.fa-border-center-h:after {
  content: "\10f89c";
}

.fad.fa-border-center-v:after {
  content: "\10f89d";
}

.fad.fa-border-inner:after {
  content: "\10f84e";
}

.fad.fa-border-left:after {
  content: "\10f84f";
}

.fad.fa-border-none:after {
  content: "\10f850";
}

.fad.fa-border-outer:after {
  content: "\10f851";
}

.fad.fa-border-right:after {
  content: "\10f852";
}

.fad.fa-border-style:after {
  content: "\10f853";
}

.fad.fa-border-style-alt:after {
  content: "\10f854";
}

.fad.fa-border-top:after {
  content: "\10f855";
}

.fad.fa-bow-arrow:after {
  content: "\10f6b9";
}

.fad.fa-bowling-ball:after {
  content: "\10f436";
}

.fad.fa-bowling-pins:after {
  content: "\10f437";
}

.fad.fa-box:after {
  content: "\10f466";
}

.fad.fa-box-alt:after {
  content: "\10f49a";
}

.fad.fa-box-ballot:after {
  content: "\10f735";
}

.fad.fa-box-check:after {
  content: "\10f467";
}

.fad.fa-box-fragile:after {
  content: "\10f49b";
}

.fad.fa-box-full:after {
  content: "\10f49c";
}

.fad.fa-box-heart:after {
  content: "\10f49d";
}

.fad.fa-box-open:after {
  content: "\10f49e";
}

.fad.fa-box-tissue:after {
  content: "\10e05b";
}

.fad.fa-box-up:after {
  content: "\10f49f";
}

.fad.fa-box-usd:after {
  content: "\10f4a0";
}

.fad.fa-boxes:after {
  content: "\10f468";
}

.fad.fa-boxes-alt:after {
  content: "\10f4a1";
}

.fad.fa-boxing-glove:after {
  content: "\10f438";
}

.fad.fa-brackets:after {
  content: "\10f7e9";
}

.fad.fa-brackets-curly:after {
  content: "\10f7ea";
}

.fad.fa-braille:after {
  content: "\10f2a1";
}

.fad.fa-brain:after {
  content: "\10f5dc";
}

.fad.fa-bread-loaf:after {
  content: "\10f7eb";
}

.fad.fa-bread-slice:after {
  content: "\10f7ec";
}

.fad.fa-briefcase:after {
  content: "\10f0b1";
}

.fad.fa-briefcase-medical:after {
  content: "\10f469";
}

.fad.fa-bring-forward:after {
  content: "\10f856";
}

.fad.fa-bring-front:after {
  content: "\10f857";
}

.fad.fa-broadcast-tower:after {
  content: "\10f519";
}

.fad.fa-broom:after {
  content: "\10f51a";
}

.fad.fa-browser:after {
  content: "\10f37e";
}

.fad.fa-brush:after {
  content: "\10f55d";
}

.fad.fa-bug:after {
  content: "\10f188";
}

.fad.fa-building:after {
  content: "\10f1ad";
}

.fad.fa-bullhorn:after {
  content: "\10f0a1";
}

.fad.fa-bullseye:after {
  content: "\10f140";
}

.fad.fa-bullseye-arrow:after {
  content: "\10f648";
}

.fad.fa-bullseye-pointer:after {
  content: "\10f649";
}

.fad.fa-burger-soda:after {
  content: "\10f858";
}

.fad.fa-burn:after {
  content: "\10f46a";
}

.fad.fa-burrito:after {
  content: "\10f7ed";
}

.fad.fa-bus:after {
  content: "\10f207";
}

.fad.fa-bus-alt:after {
  content: "\10f55e";
}

.fad.fa-bus-school:after {
  content: "\10f5dd";
}

.fad.fa-business-time:after {
  content: "\10f64a";
}

.fad.fa-cabinet-filing:after {
  content: "\10f64b";
}

.fad.fa-cactus:after {
  content: "\10f8a7";
}

.fad.fa-calculator:after {
  content: "\10f1ec";
}

.fad.fa-calculator-alt:after {
  content: "\10f64c";
}

.fad.fa-calendar:after {
  content: "\10f133";
}

.fad.fa-calendar-alt:after {
  content: "\10f073";
}

.fad.fa-calendar-check:after {
  content: "\10f274";
}

.fad.fa-calendar-day:after {
  content: "\10f783";
}

.fad.fa-calendar-edit:after {
  content: "\10f333";
}

.fad.fa-calendar-exclamation:after {
  content: "\10f334";
}

.fad.fa-calendar-minus:after {
  content: "\10f272";
}

.fad.fa-calendar-plus:after {
  content: "\10f271";
}

.fad.fa-calendar-star:after {
  content: "\10f736";
}

.fad.fa-calendar-times:after {
  content: "\10f273";
}

.fad.fa-calendar-week:after {
  content: "\10f784";
}

.fad.fa-camcorder:after {
  content: "\10f8a8";
}

.fad.fa-camera:after {
  content: "\10f030";
}

.fad.fa-camera-alt:after {
  content: "\10f332";
}

.fad.fa-camera-home:after {
  content: "\10f8fe";
}

.fad.fa-camera-movie:after {
  content: "\10f8a9";
}

.fad.fa-camera-polaroid:after {
  content: "\10f8aa";
}

.fad.fa-camera-retro:after {
  content: "\10f083";
}

.fad.fa-campfire:after {
  content: "\10f6ba";
}

.fad.fa-campground:after {
  content: "\10f6bb";
}

.fad.fa-candle-holder:after {
  content: "\10f6bc";
}

.fad.fa-candy-cane:after {
  content: "\10f786";
}

.fad.fa-candy-corn:after {
  content: "\10f6bd";
}

.fad.fa-cannabis:after {
  content: "\10f55f";
}

.fad.fa-capsules:after {
  content: "\10f46b";
}

.fad.fa-car:after {
  content: "\10f1b9";
}

.fad.fa-car-alt:after {
  content: "\10f5de";
}

.fad.fa-car-battery:after {
  content: "\10f5df";
}

.fad.fa-car-building:after {
  content: "\10f859";
}

.fad.fa-car-bump:after {
  content: "\10f5e0";
}

.fad.fa-car-bus:after {
  content: "\10f85a";
}

.fad.fa-car-crash:after {
  content: "\10f5e1";
}

.fad.fa-car-garage:after {
  content: "\10f5e2";
}

.fad.fa-car-mechanic:after {
  content: "\10f5e3";
}

.fad.fa-car-side:after {
  content: "\10f5e4";
}

.fad.fa-car-tilt:after {
  content: "\10f5e5";
}

.fad.fa-car-wash:after {
  content: "\10f5e6";
}

.fad.fa-caravan:after {
  content: "\10f8ff";
}

.fad.fa-caravan-alt:after {
  content: "\10e000";
}

.fad.fa-caret-circle-down:after {
  content: "\10f32d";
}

.fad.fa-caret-circle-left:after {
  content: "\10f32e";
}

.fad.fa-caret-circle-right:after {
  content: "\10f330";
}

.fad.fa-caret-circle-up:after {
  content: "\10f331";
}

.fad.fa-caret-down:after {
  content: "\10f0d7";
}

.fad.fa-caret-left:after {
  content: "\10f0d9";
}

.fad.fa-caret-right:after {
  content: "\10f0da";
}

.fad.fa-caret-square-down:after {
  content: "\10f150";
}

.fad.fa-caret-square-left:after {
  content: "\10f191";
}

.fad.fa-caret-square-right:after {
  content: "\10f152";
}

.fad.fa-caret-square-up:after {
  content: "\10f151";
}

.fad.fa-caret-up:after {
  content: "\10f0d8";
}

.fad.fa-carrot:after {
  content: "\10f787";
}

.fad.fa-cars:after {
  content: "\10f85b";
}

.fad.fa-cart-arrow-down:after {
  content: "\10f218";
}

.fad.fa-cart-plus:after {
  content: "\10f217";
}

.fad.fa-cash-register:after {
  content: "\10f788";
}

.fad.fa-cassette-tape:after {
  content: "\10f8ab";
}

.fad.fa-cat:after {
  content: "\10f6be";
}

.fad.fa-cat-space:after {
  content: "\10e001";
}

.fad.fa-cauldron:after {
  content: "\10f6bf";
}

.fad.fa-cctv:after {
  content: "\10f8ac";
}

.fad.fa-certificate:after {
  content: "\10f0a3";
}

.fad.fa-chair:after {
  content: "\10f6c0";
}

.fad.fa-chair-office:after {
  content: "\10f6c1";
}

.fad.fa-chalkboard:after {
  content: "\10f51b";
}

.fad.fa-chalkboard-teacher:after {
  content: "\10f51c";
}

.fad.fa-charging-station:after {
  content: "\10f5e7";
}

.fad.fa-chart-area:after {
  content: "\10f1fe";
}

.fad.fa-chart-bar:after {
  content: "\10f080";
}

.fad.fa-chart-line:after {
  content: "\10f201";
}

.fad.fa-chart-line-down:after {
  content: "\10f64d";
}

.fad.fa-chart-network:after {
  content: "\10f78a";
}

.fad.fa-chart-pie:after {
  content: "\10f200";
}

.fad.fa-chart-pie-alt:after {
  content: "\10f64e";
}

.fad.fa-chart-scatter:after {
  content: "\10f7ee";
}

.fad.fa-check:after {
  content: "\10f00c";
}

.fad.fa-check-circle:after {
  content: "\10f058";
}

.fad.fa-check-double:after {
  content: "\10f560";
}

.fad.fa-check-square:after {
  content: "\10f14a";
}

.fad.fa-cheese:after {
  content: "\10f7ef";
}

.fad.fa-cheese-swiss:after {
  content: "\10f7f0";
}

.fad.fa-cheeseburger:after {
  content: "\10f7f1";
}

.fad.fa-chess:after {
  content: "\10f439";
}

.fad.fa-chess-bishop:after {
  content: "\10f43a";
}

.fad.fa-chess-bishop-alt:after {
  content: "\10f43b";
}

.fad.fa-chess-board:after {
  content: "\10f43c";
}

.fad.fa-chess-clock:after {
  content: "\10f43d";
}

.fad.fa-chess-clock-alt:after {
  content: "\10f43e";
}

.fad.fa-chess-king:after {
  content: "\10f43f";
}

.fad.fa-chess-king-alt:after {
  content: "\10f440";
}

.fad.fa-chess-knight:after {
  content: "\10f441";
}

.fad.fa-chess-knight-alt:after {
  content: "\10f442";
}

.fad.fa-chess-pawn:after {
  content: "\10f443";
}

.fad.fa-chess-pawn-alt:after {
  content: "\10f444";
}

.fad.fa-chess-queen:after {
  content: "\10f445";
}

.fad.fa-chess-queen-alt:after {
  content: "\10f446";
}

.fad.fa-chess-rook:after {
  content: "\10f447";
}

.fad.fa-chess-rook-alt:after {
  content: "\10f448";
}

.fad.fa-chevron-circle-down:after {
  content: "\10f13a";
}

.fad.fa-chevron-circle-left:after {
  content: "\10f137";
}

.fad.fa-chevron-circle-right:after {
  content: "\10f138";
}

.fad.fa-chevron-circle-up:after {
  content: "\10f139";
}

.fad.fa-chevron-double-down:after {
  content: "\10f322";
}

.fad.fa-chevron-double-left:after {
  content: "\10f323";
}

.fad.fa-chevron-double-right:after {
  content: "\10f324";
}

.fad.fa-chevron-double-up:after {
  content: "\10f325";
}

.fad.fa-chevron-down:after {
  content: "\10f078";
}

.fad.fa-chevron-left:after {
  content: "\10f053";
}

.fad.fa-chevron-right:after {
  content: "\10f054";
}

.fad.fa-chevron-square-down:after {
  content: "\10f329";
}

.fad.fa-chevron-square-left:after {
  content: "\10f32a";
}

.fad.fa-chevron-square-right:after {
  content: "\10f32b";
}

.fad.fa-chevron-square-up:after {
  content: "\10f32c";
}

.fad.fa-chevron-up:after {
  content: "\10f077";
}

.fad.fa-child:after {
  content: "\10f1ae";
}

.fad.fa-chimney:after {
  content: "\10f78b";
}

.fad.fa-church:after {
  content: "\10f51d";
}

.fad.fa-circle:after {
  content: "\10f111";
}

.fad.fa-circle-notch:after {
  content: "\10f1ce";
}

.fad.fa-city:after {
  content: "\10f64f";
}

.fad.fa-clarinet:after {
  content: "\10f8ad";
}

.fad.fa-claw-marks:after {
  content: "\10f6c2";
}

.fad.fa-clinic-medical:after {
  content: "\10f7f2";
}

.fad.fa-clipboard:after {
  content: "\10f328";
}

.fad.fa-clipboard-check:after {
  content: "\10f46c";
}

.fad.fa-clipboard-list:after {
  content: "\10f46d";
}

.fad.fa-clipboard-list-check:after {
  content: "\10f737";
}

.fad.fa-clipboard-prescription:after {
  content: "\10f5e8";
}

.fad.fa-clipboard-user:after {
  content: "\10f7f3";
}

.fad.fa-clock:after {
  content: "\10f017";
}

.fad.fa-clone:after {
  content: "\10f24d";
}

.fad.fa-closed-captioning:after {
  content: "\10f20a";
}

.fad.fa-cloud:after {
  content: "\10f0c2";
}

.fad.fa-cloud-download:after {
  content: "\10f0ed";
}

.fad.fa-cloud-download-alt:after {
  content: "\10f381";
}

.fad.fa-cloud-drizzle:after {
  content: "\10f738";
}

.fad.fa-cloud-hail:after {
  content: "\10f739";
}

.fad.fa-cloud-hail-mixed:after {
  content: "\10f73a";
}

.fad.fa-cloud-meatball:after {
  content: "\10f73b";
}

.fad.fa-cloud-moon:after {
  content: "\10f6c3";
}

.fad.fa-cloud-moon-rain:after {
  content: "\10f73c";
}

.fad.fa-cloud-music:after {
  content: "\10f8ae";
}

.fad.fa-cloud-rain:after {
  content: "\10f73d";
}

.fad.fa-cloud-rainbow:after {
  content: "\10f73e";
}

.fad.fa-cloud-showers:after {
  content: "\10f73f";
}

.fad.fa-cloud-showers-heavy:after {
  content: "\10f740";
}

.fad.fa-cloud-sleet:after {
  content: "\10f741";
}

.fad.fa-cloud-snow:after {
  content: "\10f742";
}

.fad.fa-cloud-sun:after {
  content: "\10f6c4";
}

.fad.fa-cloud-sun-rain:after {
  content: "\10f743";
}

.fad.fa-cloud-upload:after {
  content: "\10f0ee";
}

.fad.fa-cloud-upload-alt:after {
  content: "\10f382";
}

.fad.fa-clouds:after {
  content: "\10f744";
}

.fad.fa-clouds-moon:after {
  content: "\10f745";
}

.fad.fa-clouds-sun:after {
  content: "\10f746";
}

.fad.fa-club:after {
  content: "\10f327";
}

.fad.fa-cocktail:after {
  content: "\10f561";
}

.fad.fa-code:after {
  content: "\10f121";
}

.fad.fa-code-branch:after {
  content: "\10f126";
}

.fad.fa-code-commit:after {
  content: "\10f386";
}

.fad.fa-code-merge:after {
  content: "\10f387";
}

.fad.fa-coffee:after {
  content: "\10f0f4";
}

.fad.fa-coffee-pot:after {
  content: "\10e002";
}

.fad.fa-coffee-togo:after {
  content: "\10f6c5";
}

.fad.fa-coffin:after {
  content: "\10f6c6";
}

.fad.fa-coffin-cross:after {
  content: "\10e051";
}

.fad.fa-cog:after {
  content: "\10f013";
}

.fad.fa-cogs:after {
  content: "\10f085";
}

.fad.fa-coin:after {
  content: "\10f85c";
}

.fad.fa-coins:after {
  content: "\10f51e";
}

.fad.fa-columns:after {
  content: "\10f0db";
}

.fad.fa-comet:after {
  content: "\10e003";
}

.fad.fa-comment:after {
  content: "\10f075";
}

.fad.fa-comment-alt:after {
  content: "\10f27a";
}

.fad.fa-comment-alt-check:after {
  content: "\10f4a2";
}

.fad.fa-comment-alt-dollar:after {
  content: "\10f650";
}

.fad.fa-comment-alt-dots:after {
  content: "\10f4a3";
}

.fad.fa-comment-alt-edit:after {
  content: "\10f4a4";
}

.fad.fa-comment-alt-exclamation:after {
  content: "\10f4a5";
}

.fad.fa-comment-alt-lines:after {
  content: "\10f4a6";
}

.fad.fa-comment-alt-medical:after {
  content: "\10f7f4";
}

.fad.fa-comment-alt-minus:after {
  content: "\10f4a7";
}

.fad.fa-comment-alt-music:after {
  content: "\10f8af";
}

.fad.fa-comment-alt-plus:after {
  content: "\10f4a8";
}

.fad.fa-comment-alt-slash:after {
  content: "\10f4a9";
}

.fad.fa-comment-alt-smile:after {
  content: "\10f4aa";
}

.fad.fa-comment-alt-times:after {
  content: "\10f4ab";
}

.fad.fa-comment-check:after {
  content: "\10f4ac";
}

.fad.fa-comment-dollar:after {
  content: "\10f651";
}

.fad.fa-comment-dots:after {
  content: "\10f4ad";
}

.fad.fa-comment-edit:after {
  content: "\10f4ae";
}

.fad.fa-comment-exclamation:after {
  content: "\10f4af";
}

.fad.fa-comment-lines:after {
  content: "\10f4b0";
}

.fad.fa-comment-medical:after {
  content: "\10f7f5";
}

.fad.fa-comment-minus:after {
  content: "\10f4b1";
}

.fad.fa-comment-music:after {
  content: "\10f8b0";
}

.fad.fa-comment-plus:after {
  content: "\10f4b2";
}

.fad.fa-comment-slash:after {
  content: "\10f4b3";
}

.fad.fa-comment-smile:after {
  content: "\10f4b4";
}

.fad.fa-comment-times:after {
  content: "\10f4b5";
}

.fad.fa-comments:after {
  content: "\10f086";
}

.fad.fa-comments-alt:after {
  content: "\10f4b6";
}

.fad.fa-comments-alt-dollar:after {
  content: "\10f652";
}

.fad.fa-comments-dollar:after {
  content: "\10f653";
}

.fad.fa-compact-disc:after {
  content: "\10f51f";
}

.fad.fa-compass:after {
  content: "\10f14e";
}

.fad.fa-compass-slash:after {
  content: "\10f5e9";
}

.fad.fa-compress:after {
  content: "\10f066";
}

.fad.fa-compress-alt:after {
  content: "\10f422";
}

.fad.fa-compress-arrows-alt:after {
  content: "\10f78c";
}

.fad.fa-compress-wide:after {
  content: "\10f326";
}

.fad.fa-computer-classic:after {
  content: "\10f8b1";
}

.fad.fa-computer-speaker:after {
  content: "\10f8b2";
}

.fad.fa-concierge-bell:after {
  content: "\10f562";
}

.fad.fa-construction:after {
  content: "\10f85d";
}

.fad.fa-container-storage:after {
  content: "\10f4b7";
}

.fad.fa-conveyor-belt:after {
  content: "\10f46e";
}

.fad.fa-conveyor-belt-alt:after {
  content: "\10f46f";
}

.fad.fa-cookie:after {
  content: "\10f563";
}

.fad.fa-cookie-bite:after {
  content: "\10f564";
}

.fad.fa-copy:after {
  content: "\10f0c5";
}

.fad.fa-copyright:after {
  content: "\10f1f9";
}

.fad.fa-corn:after {
  content: "\10f6c7";
}

.fad.fa-couch:after {
  content: "\10f4b8";
}

.fad.fa-cow:after {
  content: "\10f6c8";
}

.fad.fa-cowbell:after {
  content: "\10f8b3";
}

.fad.fa-cowbell-more:after {
  content: "\10f8b4";
}

.fad.fa-credit-card:after {
  content: "\10f09d";
}

.fad.fa-credit-card-blank:after {
  content: "\10f389";
}

.fad.fa-credit-card-front:after {
  content: "\10f38a";
}

.fad.fa-cricket:after {
  content: "\10f449";
}

.fad.fa-croissant:after {
  content: "\10f7f6";
}

.fad.fa-crop:after {
  content: "\10f125";
}

.fad.fa-crop-alt:after {
  content: "\10f565";
}

.fad.fa-cross:after {
  content: "\10f654";
}

.fad.fa-crosshairs:after {
  content: "\10f05b";
}

.fad.fa-crow:after {
  content: "\10f520";
}

.fad.fa-crown:after {
  content: "\10f521";
}

.fad.fa-crutch:after {
  content: "\10f7f7";
}

.fad.fa-crutches:after {
  content: "\10f7f8";
}

.fad.fa-cube:after {
  content: "\10f1b2";
}

.fad.fa-cubes:after {
  content: "\10f1b3";
}

.fad.fa-curling:after {
  content: "\10f44a";
}

.fad.fa-cut:after {
  content: "\10f0c4";
}

.fad.fa-dagger:after {
  content: "\10f6cb";
}

.fad.fa-database:after {
  content: "\10f1c0";
}

.fad.fa-deaf:after {
  content: "\10f2a4";
}

.fad.fa-debug:after {
  content: "\10f7f9";
}

.fad.fa-deer:after {
  content: "\10f78e";
}

.fad.fa-deer-rudolph:after {
  content: "\10f78f";
}

.fad.fa-democrat:after {
  content: "\10f747";
}

.fad.fa-desktop:after {
  content: "\10f108";
}

.fad.fa-desktop-alt:after {
  content: "\10f390";
}

.fad.fa-dewpoint:after {
  content: "\10f748";
}

.fad.fa-dharmachakra:after {
  content: "\10f655";
}

.fad.fa-diagnoses:after {
  content: "\10f470";
}

.fad.fa-diamond:after {
  content: "\10f219";
}

.fad.fa-dice:after {
  content: "\10f522";
}

.fad.fa-dice-d10:after {
  content: "\10f6cd";
}

.fad.fa-dice-d12:after {
  content: "\10f6ce";
}

.fad.fa-dice-d20:after {
  content: "\10f6cf";
}

.fad.fa-dice-d4:after {
  content: "\10f6d0";
}

.fad.fa-dice-d6:after {
  content: "\10f6d1";
}

.fad.fa-dice-d8:after {
  content: "\10f6d2";
}

.fad.fa-dice-five:after {
  content: "\10f523";
}

.fad.fa-dice-four:after {
  content: "\10f524";
}

.fad.fa-dice-one:after {
  content: "\10f525";
}

.fad.fa-dice-six:after {
  content: "\10f526";
}

.fad.fa-dice-three:after {
  content: "\10f527";
}

.fad.fa-dice-two:after {
  content: "\10f528";
}

.fad.fa-digging:after {
  content: "\10f85e";
}

.fad.fa-digital-tachograph:after {
  content: "\10f566";
}

.fad.fa-diploma:after {
  content: "\10f5ea";
}

.fad.fa-directions:after {
  content: "\10f5eb";
}

.fad.fa-disc-drive:after {
  content: "\10f8b5";
}

.fad.fa-disease:after {
  content: "\10f7fa";
}

.fad.fa-divide:after {
  content: "\10f529";
}

.fad.fa-dizzy:after {
  content: "\10f567";
}

.fad.fa-dna:after {
  content: "\10f471";
}

.fad.fa-do-not-enter:after {
  content: "\10f5ec";
}

.fad.fa-dog:after {
  content: "\10f6d3";
}

.fad.fa-dog-leashed:after {
  content: "\10f6d4";
}

.fad.fa-dollar-sign:after {
  content: "\10f155";
}

.fad.fa-dolly:after {
  content: "\10f472";
}

.fad.fa-dolly-empty:after {
  content: "\10f473";
}

.fad.fa-dolly-flatbed:after {
  content: "\10f474";
}

.fad.fa-dolly-flatbed-alt:after {
  content: "\10f475";
}

.fad.fa-dolly-flatbed-empty:after {
  content: "\10f476";
}

.fad.fa-donate:after {
  content: "\10f4b9";
}

.fad.fa-door-closed:after {
  content: "\10f52a";
}

.fad.fa-door-open:after {
  content: "\10f52b";
}

.fad.fa-dot-circle:after {
  content: "\10f192";
}

.fad.fa-dove:after {
  content: "\10f4ba";
}

.fad.fa-download:after {
  content: "\10f019";
}

.fad.fa-drafting-compass:after {
  content: "\10f568";
}

.fad.fa-dragon:after {
  content: "\10f6d5";
}

.fad.fa-draw-circle:after {
  content: "\10f5ed";
}

.fad.fa-draw-polygon:after {
  content: "\10f5ee";
}

.fad.fa-draw-square:after {
  content: "\10f5ef";
}

.fad.fa-dreidel:after {
  content: "\10f792";
}

.fad.fa-drone:after {
  content: "\10f85f";
}

.fad.fa-drone-alt:after {
  content: "\10f860";
}

.fad.fa-drum:after {
  content: "\10f569";
}

.fad.fa-drum-steelpan:after {
  content: "\10f56a";
}

.fad.fa-drumstick:after {
  content: "\10f6d6";
}

.fad.fa-drumstick-bite:after {
  content: "\10f6d7";
}

.fad.fa-dryer:after {
  content: "\10f861";
}

.fad.fa-dryer-alt:after {
  content: "\10f862";
}

.fad.fa-duck:after {
  content: "\10f6d8";
}

.fad.fa-dumbbell:after {
  content: "\10f44b";
}

.fad.fa-dumpster:after {
  content: "\10f793";
}

.fad.fa-dumpster-fire:after {
  content: "\10f794";
}

.fad.fa-dungeon:after {
  content: "\10f6d9";
}

.fad.fa-ear:after {
  content: "\10f5f0";
}

.fad.fa-ear-muffs:after {
  content: "\10f795";
}

.fad.fa-eclipse:after {
  content: "\10f749";
}

.fad.fa-eclipse-alt:after {
  content: "\10f74a";
}

.fad.fa-edit:after {
  content: "\10f044";
}

.fad.fa-egg:after {
  content: "\10f7fb";
}

.fad.fa-egg-fried:after {
  content: "\10f7fc";
}

.fad.fa-eject:after {
  content: "\10f052";
}

.fad.fa-elephant:after {
  content: "\10f6da";
}

.fad.fa-ellipsis-h:after {
  content: "\10f141";
}

.fad.fa-ellipsis-h-alt:after {
  content: "\10f39b";
}

.fad.fa-ellipsis-v:after {
  content: "\10f142";
}

.fad.fa-ellipsis-v-alt:after {
  content: "\10f39c";
}

.fad.fa-empty-set:after {
  content: "\10f656";
}

.fad.fa-engine-warning:after {
  content: "\10f5f2";
}

.fad.fa-envelope:after {
  content: "\10f0e0";
}

.fad.fa-envelope-open:after {
  content: "\10f2b6";
}

.fad.fa-envelope-open-dollar:after {
  content: "\10f657";
}

.fad.fa-envelope-open-text:after {
  content: "\10f658";
}

.fad.fa-envelope-square:after {
  content: "\10f199";
}

.fad.fa-equals:after {
  content: "\10f52c";
}

.fad.fa-eraser:after {
  content: "\10f12d";
}

.fad.fa-ethernet:after {
  content: "\10f796";
}

.fad.fa-euro-sign:after {
  content: "\10f153";
}

.fad.fa-exchange:after {
  content: "\10f0ec";
}

.fad.fa-exchange-alt:after {
  content: "\10f362";
}

.fad.fa-exclamation:after {
  content: "\10f12a";
}

.fad.fa-exclamation-circle:after {
  content: "\10f06a";
}

.fad.fa-exclamation-square:after {
  content: "\10f321";
}

.fad.fa-exclamation-triangle:after {
  content: "\10f071";
}

.fad.fa-expand:after {
  content: "\10f065";
}

.fad.fa-expand-alt:after {
  content: "\10f424";
}

.fad.fa-expand-arrows:after {
  content: "\10f31d";
}

.fad.fa-expand-arrows-alt:after {
  content: "\10f31e";
}

.fad.fa-expand-wide:after {
  content: "\10f320";
}

.fad.fa-external-link:after {
  content: "\10f08e";
}

.fad.fa-external-link-alt:after {
  content: "\10f35d";
}

.fad.fa-external-link-square:after {
  content: "\10f14c";
}

.fad.fa-external-link-square-alt:after {
  content: "\10f360";
}

.fad.fa-eye:after {
  content: "\10f06e";
}

.fad.fa-eye-dropper:after {
  content: "\10f1fb";
}

.fad.fa-eye-evil:after {
  content: "\10f6db";
}

.fad.fa-eye-slash:after {
  content: "\10f070";
}

.fad.fa-fan:after {
  content: "\10f863";
}

.fad.fa-fan-table:after {
  content: "\10e004";
}

.fad.fa-farm:after {
  content: "\10f864";
}

.fad.fa-fast-backward:after {
  content: "\10f049";
}

.fad.fa-fast-forward:after {
  content: "\10f050";
}

.fad.fa-faucet:after {
  content: "\10e005";
}

.fad.fa-faucet-drip:after {
  content: "\10e006";
}

.fad.fa-fax:after {
  content: "\10f1ac";
}

.fad.fa-feather:after {
  content: "\10f52d";
}

.fad.fa-feather-alt:after {
  content: "\10f56b";
}

.fad.fa-female:after {
  content: "\10f182";
}

.fad.fa-field-hockey:after {
  content: "\10f44c";
}

.fad.fa-fighter-jet:after {
  content: "\10f0fb";
}

.fad.fa-file:after {
  content: "\10f15b";
}

.fad.fa-file-alt:after {
  content: "\10f15c";
}

.fad.fa-file-archive:after {
  content: "\10f1c6";
}

.fad.fa-file-audio:after {
  content: "\10f1c7";
}

.fad.fa-file-certificate:after {
  content: "\10f5f3";
}

.fad.fa-file-chart-line:after {
  content: "\10f659";
}

.fad.fa-file-chart-pie:after {
  content: "\10f65a";
}

.fad.fa-file-check:after {
  content: "\10f316";
}

.fad.fa-file-code:after {
  content: "\10f1c9";
}

.fad.fa-file-contract:after {
  content: "\10f56c";
}

.fad.fa-file-csv:after {
  content: "\10f6dd";
}

.fad.fa-file-download:after {
  content: "\10f56d";
}

.fad.fa-file-edit:after {
  content: "\10f31c";
}

.fad.fa-file-excel:after {
  content: "\10f1c3";
}

.fad.fa-file-exclamation:after {
  content: "\10f31a";
}

.fad.fa-file-export:after {
  content: "\10f56e";
}

.fad.fa-file-image:after {
  content: "\10f1c5";
}

.fad.fa-file-import:after {
  content: "\10f56f";
}

.fad.fa-file-invoice:after {
  content: "\10f570";
}

.fad.fa-file-invoice-dollar:after {
  content: "\10f571";
}

.fad.fa-file-medical:after {
  content: "\10f477";
}

.fad.fa-file-medical-alt:after {
  content: "\10f478";
}

.fad.fa-file-minus:after {
  content: "\10f318";
}

.fad.fa-file-music:after {
  content: "\10f8b6";
}

.fad.fa-file-pdf:after {
  content: "\10f1c1";
}

.fad.fa-file-plus:after {
  content: "\10f319";
}

.fad.fa-file-powerpoint:after {
  content: "\10f1c4";
}

.fad.fa-file-prescription:after {
  content: "\10f572";
}

.fad.fa-file-search:after {
  content: "\10f865";
}

.fad.fa-file-signature:after {
  content: "\10f573";
}

.fad.fa-file-spreadsheet:after {
  content: "\10f65b";
}

.fad.fa-file-times:after {
  content: "\10f317";
}

.fad.fa-file-upload:after {
  content: "\10f574";
}

.fad.fa-file-user:after {
  content: "\10f65c";
}

.fad.fa-file-video:after {
  content: "\10f1c8";
}

.fad.fa-file-word:after {
  content: "\10f1c2";
}

.fad.fa-files-medical:after {
  content: "\10f7fd";
}

.fad.fa-fill:after {
  content: "\10f575";
}

.fad.fa-fill-drip:after {
  content: "\10f576";
}

.fad.fa-film:after {
  content: "\10f008";
}

.fad.fa-film-alt:after {
  content: "\10f3a0";
}

.fad.fa-film-canister:after {
  content: "\10f8b7";
}

.fad.fa-filter:after {
  content: "\10f0b0";
}

.fad.fa-fingerprint:after {
  content: "\10f577";
}

.fad.fa-fire:after {
  content: "\10f06d";
}

.fad.fa-fire-alt:after {
  content: "\10f7e4";
}

.fad.fa-fire-extinguisher:after {
  content: "\10f134";
}

.fad.fa-fire-smoke:after {
  content: "\10f74b";
}

.fad.fa-fireplace:after {
  content: "\10f79a";
}

.fad.fa-first-aid:after {
  content: "\10f479";
}

.fad.fa-fish:after {
  content: "\10f578";
}

.fad.fa-fish-cooked:after {
  content: "\10f7fe";
}

.fad.fa-fist-raised:after {
  content: "\10f6de";
}

.fad.fa-flag:after {
  content: "\10f024";
}

.fad.fa-flag-alt:after {
  content: "\10f74c";
}

.fad.fa-flag-checkered:after {
  content: "\10f11e";
}

.fad.fa-flag-usa:after {
  content: "\10f74d";
}

.fad.fa-flame:after {
  content: "\10f6df";
}

.fad.fa-flashlight:after {
  content: "\10f8b8";
}

.fad.fa-flask:after {
  content: "\10f0c3";
}

.fad.fa-flask-poison:after {
  content: "\10f6e0";
}

.fad.fa-flask-potion:after {
  content: "\10f6e1";
}

.fad.fa-flower:after {
  content: "\10f7ff";
}

.fad.fa-flower-daffodil:after {
  content: "\10f800";
}

.fad.fa-flower-tulip:after {
  content: "\10f801";
}

.fad.fa-flushed:after {
  content: "\10f579";
}

.fad.fa-flute:after {
  content: "\10f8b9";
}

.fad.fa-flux-capacitor:after {
  content: "\10f8ba";
}

.fad.fa-fog:after {
  content: "\10f74e";
}

.fad.fa-folder:after {
  content: "\10f07b";
}

.fad.fa-folder-download:after {
  content: "\10e053";
}

.fad.fa-folder-minus:after {
  content: "\10f65d";
}

.fad.fa-folder-open:after {
  content: "\10f07c";
}

.fad.fa-folder-plus:after {
  content: "\10f65e";
}

.fad.fa-folder-times:after {
  content: "\10f65f";
}

.fad.fa-folder-tree:after {
  content: "\10f802";
}

.fad.fa-folder-upload:after {
  content: "\10e054";
}

.fad.fa-folders:after {
  content: "\10f660";
}

.fad.fa-font:after {
  content: "\10f031";
}

.fad.fa-font-awesome-logo-full:after {
  content: "\10f4e6";
}

.fad.fa-font-case:after {
  content: "\10f866";
}

.fad.fa-football-ball:after {
  content: "\10f44e";
}

.fad.fa-football-helmet:after {
  content: "\10f44f";
}

.fad.fa-forklift:after {
  content: "\10f47a";
}

.fad.fa-forward:after {
  content: "\10f04e";
}

.fad.fa-fragile:after {
  content: "\10f4bb";
}

.fad.fa-french-fries:after {
  content: "\10f803";
}

.fad.fa-frog:after {
  content: "\10f52e";
}

.fad.fa-frosty-head:after {
  content: "\10f79b";
}

.fad.fa-frown:after {
  content: "\10f119";
}

.fad.fa-frown-open:after {
  content: "\10f57a";
}

.fad.fa-function:after {
  content: "\10f661";
}

.fad.fa-funnel-dollar:after {
  content: "\10f662";
}

.fad.fa-futbol:after {
  content: "\10f1e3";
}

.fad.fa-galaxy:after {
  content: "\10e008";
}

.fad.fa-game-board:after {
  content: "\10f867";
}

.fad.fa-game-board-alt:after {
  content: "\10f868";
}

.fad.fa-game-console-handheld:after {
  content: "\10f8bb";
}

.fad.fa-gamepad:after {
  content: "\10f11b";
}

.fad.fa-gamepad-alt:after {
  content: "\10f8bc";
}

.fad.fa-garage:after {
  content: "\10e009";
}

.fad.fa-garage-car:after {
  content: "\10e00a";
}

.fad.fa-garage-open:after {
  content: "\10e00b";
}

.fad.fa-gas-pump:after {
  content: "\10f52f";
}

.fad.fa-gas-pump-slash:after {
  content: "\10f5f4";
}

.fad.fa-gavel:after {
  content: "\10f0e3";
}

.fad.fa-gem:after {
  content: "\10f3a5";
}

.fad.fa-genderless:after {
  content: "\10f22d";
}

.fad.fa-ghost:after {
  content: "\10f6e2";
}

.fad.fa-gift:after {
  content: "\10f06b";
}

.fad.fa-gift-card:after {
  content: "\10f663";
}

.fad.fa-gifts:after {
  content: "\10f79c";
}

.fad.fa-gingerbread-man:after {
  content: "\10f79d";
}

.fad.fa-glass:after {
  content: "\10f804";
}

.fad.fa-glass-champagne:after {
  content: "\10f79e";
}

.fad.fa-glass-cheers:after {
  content: "\10f79f";
}

.fad.fa-glass-citrus:after {
  content: "\10f869";
}

.fad.fa-glass-martini:after {
  content: "\10f000";
}

.fad.fa-glass-martini-alt:after {
  content: "\10f57b";
}

.fad.fa-glass-whiskey:after {
  content: "\10f7a0";
}

.fad.fa-glass-whiskey-rocks:after {
  content: "\10f7a1";
}

.fad.fa-glasses:after {
  content: "\10f530";
}

.fad.fa-glasses-alt:after {
  content: "\10f5f5";
}

.fad.fa-globe:after {
  content: "\10f0ac";
}

.fad.fa-globe-africa:after {
  content: "\10f57c";
}

.fad.fa-globe-americas:after {
  content: "\10f57d";
}

.fad.fa-globe-asia:after {
  content: "\10f57e";
}

.fad.fa-globe-europe:after {
  content: "\10f7a2";
}

.fad.fa-globe-snow:after {
  content: "\10f7a3";
}

.fad.fa-globe-stand:after {
  content: "\10f5f6";
}

.fad.fa-golf-ball:after {
  content: "\10f450";
}

.fad.fa-golf-club:after {
  content: "\10f451";
}

.fad.fa-gopuram:after {
  content: "\10f664";
}

.fad.fa-graduation-cap:after {
  content: "\10f19d";
}

.fad.fa-gramophone:after {
  content: "\10f8bd";
}

.fad.fa-greater-than:after {
  content: "\10f531";
}

.fad.fa-greater-than-equal:after {
  content: "\10f532";
}

.fad.fa-grimace:after {
  content: "\10f57f";
}

.fad.fa-grin:after {
  content: "\10f580";
}

.fad.fa-grin-alt:after {
  content: "\10f581";
}

.fad.fa-grin-beam:after {
  content: "\10f582";
}

.fad.fa-grin-beam-sweat:after {
  content: "\10f583";
}

.fad.fa-grin-hearts:after {
  content: "\10f584";
}

.fad.fa-grin-squint:after {
  content: "\10f585";
}

.fad.fa-grin-squint-tears:after {
  content: "\10f586";
}

.fad.fa-grin-stars:after {
  content: "\10f587";
}

.fad.fa-grin-tears:after {
  content: "\10f588";
}

.fad.fa-grin-tongue:after {
  content: "\10f589";
}

.fad.fa-grin-tongue-squint:after {
  content: "\10f58a";
}

.fad.fa-grin-tongue-wink:after {
  content: "\10f58b";
}

.fad.fa-grin-wink:after {
  content: "\10f58c";
}

.fad.fa-grip-horizontal:after {
  content: "\10f58d";
}

.fad.fa-grip-lines:after {
  content: "\10f7a4";
}

.fad.fa-grip-lines-vertical:after {
  content: "\10f7a5";
}

.fad.fa-grip-vertical:after {
  content: "\10f58e";
}

.fad.fa-guitar:after {
  content: "\10f7a6";
}

.fad.fa-guitar-electric:after {
  content: "\10f8be";
}

.fad.fa-guitars:after {
  content: "\10f8bf";
}

.fad.fa-h-square:after {
  content: "\10f0fd";
}

.fad.fa-h1:after {
  content: "\10f313";
}

.fad.fa-h2:after {
  content: "\10f314";
}

.fad.fa-h3:after {
  content: "\10f315";
}

.fad.fa-h4:after {
  content: "\10f86a";
}

.fad.fa-hamburger:after {
  content: "\10f805";
}

.fad.fa-hammer:after {
  content: "\10f6e3";
}

.fad.fa-hammer-war:after {
  content: "\10f6e4";
}

.fad.fa-hamsa:after {
  content: "\10f665";
}

.fad.fa-hand-heart:after {
  content: "\10f4bc";
}

.fad.fa-hand-holding:after {
  content: "\10f4bd";
}

.fad.fa-hand-holding-box:after {
  content: "\10f47b";
}

.fad.fa-hand-holding-heart:after {
  content: "\10f4be";
}

.fad.fa-hand-holding-magic:after {
  content: "\10f6e5";
}

.fad.fa-hand-holding-medical:after {
  content: "\10e05c";
}

.fad.fa-hand-holding-seedling:after {
  content: "\10f4bf";
}

.fad.fa-hand-holding-usd:after {
  content: "\10f4c0";
}

.fad.fa-hand-holding-water:after {
  content: "\10f4c1";
}

.fad.fa-hand-lizard:after {
  content: "\10f258";
}

.fad.fa-hand-middle-finger:after {
  content: "\10f806";
}

.fad.fa-hand-paper:after {
  content: "\10f256";
}

.fad.fa-hand-peace:after {
  content: "\10f25b";
}

.fad.fa-hand-point-down:after {
  content: "\10f0a7";
}

.fad.fa-hand-point-left:after {
  content: "\10f0a5";
}

.fad.fa-hand-point-right:after {
  content: "\10f0a4";
}

.fad.fa-hand-point-up:after {
  content: "\10f0a6";
}

.fad.fa-hand-pointer:after {
  content: "\10f25a";
}

.fad.fa-hand-receiving:after {
  content: "\10f47c";
}

.fad.fa-hand-rock:after {
  content: "\10f255";
}

.fad.fa-hand-scissors:after {
  content: "\10f257";
}

.fad.fa-hand-sparkles:after {
  content: "\10e05d";
}

.fad.fa-hand-spock:after {
  content: "\10f259";
}

.fad.fa-hands:after {
  content: "\10f4c2";
}

.fad.fa-hands-heart:after {
  content: "\10f4c3";
}

.fad.fa-hands-helping:after {
  content: "\10f4c4";
}

.fad.fa-hands-usd:after {
  content: "\10f4c5";
}

.fad.fa-hands-wash:after {
  content: "\10e05e";
}

.fad.fa-handshake:after {
  content: "\10f2b5";
}

.fad.fa-handshake-alt:after {
  content: "\10f4c6";
}

.fad.fa-handshake-alt-slash:after {
  content: "\10e05f";
}

.fad.fa-handshake-slash:after {
  content: "\10e060";
}

.fad.fa-hanukiah:after {
  content: "\10f6e6";
}

.fad.fa-hard-hat:after {
  content: "\10f807";
}

.fad.fa-hashtag:after {
  content: "\10f292";
}

.fad.fa-hat-chef:after {
  content: "\10f86b";
}

.fad.fa-hat-cowboy:after {
  content: "\10f8c0";
}

.fad.fa-hat-cowboy-side:after {
  content: "\10f8c1";
}

.fad.fa-hat-santa:after {
  content: "\10f7a7";
}

.fad.fa-hat-winter:after {
  content: "\10f7a8";
}

.fad.fa-hat-witch:after {
  content: "\10f6e7";
}

.fad.fa-hat-wizard:after {
  content: "\10f6e8";
}

.fad.fa-hdd:after {
  content: "\10f0a0";
}

.fad.fa-head-side:after {
  content: "\10f6e9";
}

.fad.fa-head-side-brain:after {
  content: "\10f808";
}

.fad.fa-head-side-cough:after {
  content: "\10e061";
}

.fad.fa-head-side-cough-slash:after {
  content: "\10e062";
}

.fad.fa-head-side-headphones:after {
  content: "\10f8c2";
}

.fad.fa-head-side-mask:after {
  content: "\10e063";
}

.fad.fa-head-side-medical:after {
  content: "\10f809";
}

.fad.fa-head-side-virus:after {
  content: "\10e064";
}

.fad.fa-head-vr:after {
  content: "\10f6ea";
}

.fad.fa-heading:after {
  content: "\10f1dc";
}

.fad.fa-headphones:after {
  content: "\10f025";
}

.fad.fa-headphones-alt:after {
  content: "\10f58f";
}

.fad.fa-headset:after {
  content: "\10f590";
}

.fad.fa-heart:after {
  content: "\10f004";
}

.fad.fa-heart-broken:after {
  content: "\10f7a9";
}

.fad.fa-heart-circle:after {
  content: "\10f4c7";
}

.fad.fa-heart-rate:after {
  content: "\10f5f8";
}

.fad.fa-heart-square:after {
  content: "\10f4c8";
}

.fad.fa-heartbeat:after {
  content: "\10f21e";
}

.fad.fa-heat:after {
  content: "\10e00c";
}

.fad.fa-helicopter:after {
  content: "\10f533";
}

.fad.fa-helmet-battle:after {
  content: "\10f6eb";
}

.fad.fa-hexagon:after {
  content: "\10f312";
}

.fad.fa-highlighter:after {
  content: "\10f591";
}

.fad.fa-hiking:after {
  content: "\10f6ec";
}

.fad.fa-hippo:after {
  content: "\10f6ed";
}

.fad.fa-history:after {
  content: "\10f1da";
}

.fad.fa-hockey-mask:after {
  content: "\10f6ee";
}

.fad.fa-hockey-puck:after {
  content: "\10f453";
}

.fad.fa-hockey-sticks:after {
  content: "\10f454";
}

.fad.fa-holly-berry:after {
  content: "\10f7aa";
}

.fad.fa-home:after {
  content: "\10f015";
}

.fad.fa-home-alt:after {
  content: "\10f80a";
}

.fad.fa-home-heart:after {
  content: "\10f4c9";
}

.fad.fa-home-lg:after {
  content: "\10f80b";
}

.fad.fa-home-lg-alt:after {
  content: "\10f80c";
}

.fad.fa-hood-cloak:after {
  content: "\10f6ef";
}

.fad.fa-horizontal-rule:after {
  content: "\10f86c";
}

.fad.fa-horse:after {
  content: "\10f6f0";
}

.fad.fa-horse-head:after {
  content: "\10f7ab";
}

.fad.fa-horse-saddle:after {
  content: "\10f8c3";
}

.fad.fa-hospital:after {
  content: "\10f0f8";
}

.fad.fa-hospital-alt:after {
  content: "\10f47d";
}

.fad.fa-hospital-symbol:after {
  content: "\10f47e";
}

.fad.fa-hospital-user:after {
  content: "\10f80d";
}

.fad.fa-hospitals:after {
  content: "\10f80e";
}

.fad.fa-hot-tub:after {
  content: "\10f593";
}

.fad.fa-hotdog:after {
  content: "\10f80f";
}

.fad.fa-hotel:after {
  content: "\10f594";
}

.fad.fa-hourglass:after {
  content: "\10f254";
}

.fad.fa-hourglass-end:after {
  content: "\10f253";
}

.fad.fa-hourglass-half:after {
  content: "\10f252";
}

.fad.fa-hourglass-start:after {
  content: "\10f251";
}

.fad.fa-house:after {
  content: "\10e00d";
}

.fad.fa-house-damage:after {
  content: "\10f6f1";
}

.fad.fa-house-day:after {
  content: "\10e00e";
}

.fad.fa-house-flood:after {
  content: "\10f74f";
}

.fad.fa-house-leave:after {
  content: "\10e00f";
}

.fad.fa-house-night:after {
  content: "\10e010";
}

.fad.fa-house-return:after {
  content: "\10e011";
}

.fad.fa-house-signal:after {
  content: "\10e012";
}

.fad.fa-house-user:after {
  content: "\10e065";
}

.fad.fa-hryvnia:after {
  content: "\10f6f2";
}

.fad.fa-humidity:after {
  content: "\10f750";
}

.fad.fa-hurricane:after {
  content: "\10f751";
}

.fad.fa-i-cursor:after {
  content: "\10f246";
}

.fad.fa-ice-cream:after {
  content: "\10f810";
}

.fad.fa-ice-skate:after {
  content: "\10f7ac";
}

.fad.fa-icicles:after {
  content: "\10f7ad";
}

.fad.fa-icons:after {
  content: "\10f86d";
}

.fad.fa-icons-alt:after {
  content: "\10f86e";
}

.fad.fa-id-badge:after {
  content: "\10f2c1";
}

.fad.fa-id-card:after {
  content: "\10f2c2";
}

.fad.fa-id-card-alt:after {
  content: "\10f47f";
}

.fad.fa-igloo:after {
  content: "\10f7ae";
}

.fad.fa-image:after {
  content: "\10f03e";
}

.fad.fa-image-polaroid:after {
  content: "\10f8c4";
}

.fad.fa-images:after {
  content: "\10f302";
}

.fad.fa-inbox:after {
  content: "\10f01c";
}

.fad.fa-inbox-in:after {
  content: "\10f310";
}

.fad.fa-inbox-out:after {
  content: "\10f311";
}

.fad.fa-indent:after {
  content: "\10f03c";
}

.fad.fa-industry:after {
  content: "\10f275";
}

.fad.fa-industry-alt:after {
  content: "\10f3b3";
}

.fad.fa-infinity:after {
  content: "\10f534";
}

.fad.fa-info:after {
  content: "\10f129";
}

.fad.fa-info-circle:after {
  content: "\10f05a";
}

.fad.fa-info-square:after {
  content: "\10f30f";
}

.fad.fa-inhaler:after {
  content: "\10f5f9";
}

.fad.fa-integral:after {
  content: "\10f667";
}

.fad.fa-intersection:after {
  content: "\10f668";
}

.fad.fa-inventory:after {
  content: "\10f480";
}

.fad.fa-island-tropical:after {
  content: "\10f811";
}

.fad.fa-italic:after {
  content: "\10f033";
}

.fad.fa-jack-o-lantern:after {
  content: "\10f30e";
}

.fad.fa-jedi:after {
  content: "\10f669";
}

.fad.fa-joint:after {
  content: "\10f595";
}

.fad.fa-journal-whills:after {
  content: "\10f66a";
}

.fad.fa-joystick:after {
  content: "\10f8c5";
}

.fad.fa-jug:after {
  content: "\10f8c6";
}

.fad.fa-kaaba:after {
  content: "\10f66b";
}

.fad.fa-kazoo:after {
  content: "\10f8c7";
}

.fad.fa-kerning:after {
  content: "\10f86f";
}

.fad.fa-key:after {
  content: "\10f084";
}

.fad.fa-key-skeleton:after {
  content: "\10f6f3";
}

.fad.fa-keyboard:after {
  content: "\10f11c";
}

.fad.fa-keynote:after {
  content: "\10f66c";
}

.fad.fa-khanda:after {
  content: "\10f66d";
}

.fad.fa-kidneys:after {
  content: "\10f5fb";
}

.fad.fa-kiss:after {
  content: "\10f596";
}

.fad.fa-kiss-beam:after {
  content: "\10f597";
}

.fad.fa-kiss-wink-heart:after {
  content: "\10f598";
}

.fad.fa-kite:after {
  content: "\10f6f4";
}

.fad.fa-kiwi-bird:after {
  content: "\10f535";
}

.fad.fa-knife-kitchen:after {
  content: "\10f6f5";
}

.fad.fa-lambda:after {
  content: "\10f66e";
}

.fad.fa-lamp:after {
  content: "\10f4ca";
}

.fad.fa-lamp-desk:after {
  content: "\10e014";
}

.fad.fa-lamp-floor:after {
  content: "\10e015";
}

.fad.fa-landmark:after {
  content: "\10f66f";
}

.fad.fa-landmark-alt:after {
  content: "\10f752";
}

.fad.fa-language:after {
  content: "\10f1ab";
}

.fad.fa-laptop:after {
  content: "\10f109";
}

.fad.fa-laptop-code:after {
  content: "\10f5fc";
}

.fad.fa-laptop-house:after {
  content: "\10e066";
}

.fad.fa-laptop-medical:after {
  content: "\10f812";
}

.fad.fa-lasso:after {
  content: "\10f8c8";
}

.fad.fa-laugh:after {
  content: "\10f599";
}

.fad.fa-laugh-beam:after {
  content: "\10f59a";
}

.fad.fa-laugh-squint:after {
  content: "\10f59b";
}

.fad.fa-laugh-wink:after {
  content: "\10f59c";
}

.fad.fa-layer-group:after {
  content: "\10f5fd";
}

.fad.fa-layer-minus:after {
  content: "\10f5fe";
}

.fad.fa-layer-plus:after {
  content: "\10f5ff";
}

.fad.fa-leaf:after {
  content: "\10f06c";
}

.fad.fa-leaf-heart:after {
  content: "\10f4cb";
}

.fad.fa-leaf-maple:after {
  content: "\10f6f6";
}

.fad.fa-leaf-oak:after {
  content: "\10f6f7";
}

.fad.fa-lemon:after {
  content: "\10f094";
}

.fad.fa-less-than:after {
  content: "\10f536";
}

.fad.fa-less-than-equal:after {
  content: "\10f537";
}

.fad.fa-level-down:after {
  content: "\10f149";
}

.fad.fa-level-down-alt:after {
  content: "\10f3be";
}

.fad.fa-level-up:after {
  content: "\10f148";
}

.fad.fa-level-up-alt:after {
  content: "\10f3bf";
}

.fad.fa-life-ring:after {
  content: "\10f1cd";
}

.fad.fa-light-ceiling:after {
  content: "\10e016";
}

.fad.fa-light-switch:after {
  content: "\10e017";
}

.fad.fa-light-switch-off:after {
  content: "\10e018";
}

.fad.fa-light-switch-on:after {
  content: "\10e019";
}

.fad.fa-lightbulb:after {
  content: "\10f0eb";
}

.fad.fa-lightbulb-dollar:after {
  content: "\10f670";
}

.fad.fa-lightbulb-exclamation:after {
  content: "\10f671";
}

.fad.fa-lightbulb-on:after {
  content: "\10f672";
}

.fad.fa-lightbulb-slash:after {
  content: "\10f673";
}

.fad.fa-lights-holiday:after {
  content: "\10f7b2";
}

.fad.fa-line-columns:after {
  content: "\10f870";
}

.fad.fa-line-height:after {
  content: "\10f871";
}

.fad.fa-link:after {
  content: "\10f0c1";
}

.fad.fa-lips:after {
  content: "\10f600";
}

.fad.fa-lira-sign:after {
  content: "\10f195";
}

.fad.fa-list:after {
  content: "\10f03a";
}

.fad.fa-list-alt:after {
  content: "\10f022";
}

.fad.fa-list-music:after {
  content: "\10f8c9";
}

.fad.fa-list-ol:after {
  content: "\10f0cb";
}

.fad.fa-list-ul:after {
  content: "\10f0ca";
}

.fad.fa-location:after {
  content: "\10f601";
}

.fad.fa-location-arrow:after {
  content: "\10f124";
}

.fad.fa-location-circle:after {
  content: "\10f602";
}

.fad.fa-location-slash:after {
  content: "\10f603";
}

.fad.fa-lock:after {
  content: "\10f023";
}

.fad.fa-lock-alt:after {
  content: "\10f30d";
}

.fad.fa-lock-open:after {
  content: "\10f3c1";
}

.fad.fa-lock-open-alt:after {
  content: "\10f3c2";
}

.fad.fa-long-arrow-alt-down:after {
  content: "\10f309";
}

.fad.fa-long-arrow-alt-left:after {
  content: "\10f30a";
}

.fad.fa-long-arrow-alt-right:after {
  content: "\10f30b";
}

.fad.fa-long-arrow-alt-up:after {
  content: "\10f30c";
}

.fad.fa-long-arrow-down:after {
  content: "\10f175";
}

.fad.fa-long-arrow-left:after {
  content: "\10f177";
}

.fad.fa-long-arrow-right:after {
  content: "\10f178";
}

.fad.fa-long-arrow-up:after {
  content: "\10f176";
}

.fad.fa-loveseat:after {
  content: "\10f4cc";
}

.fad.fa-low-vision:after {
  content: "\10f2a8";
}

.fad.fa-luchador:after {
  content: "\10f455";
}

.fad.fa-luggage-cart:after {
  content: "\10f59d";
}

.fad.fa-lungs:after {
  content: "\10f604";
}

.fad.fa-lungs-virus:after {
  content: "\10e067";
}

.fad.fa-mace:after {
  content: "\10f6f8";
}

.fad.fa-magic:after {
  content: "\10f0d0";
}

.fad.fa-magnet:after {
  content: "\10f076";
}

.fad.fa-mail-bulk:after {
  content: "\10f674";
}

.fad.fa-mailbox:after {
  content: "\10f813";
}

.fad.fa-male:after {
  content: "\10f183";
}

.fad.fa-mandolin:after {
  content: "\10f6f9";
}

.fad.fa-map:after {
  content: "\10f279";
}

.fad.fa-map-marked:after {
  content: "\10f59f";
}

.fad.fa-map-marked-alt:after {
  content: "\10f5a0";
}

.fad.fa-map-marker:after {
  content: "\10f041";
}

.fad.fa-map-marker-alt:after {
  content: "\10f3c5";
}

.fad.fa-map-marker-alt-slash:after {
  content: "\10f605";
}

.fad.fa-map-marker-check:after {
  content: "\10f606";
}

.fad.fa-map-marker-edit:after {
  content: "\10f607";
}

.fad.fa-map-marker-exclamation:after {
  content: "\10f608";
}

.fad.fa-map-marker-minus:after {
  content: "\10f609";
}

.fad.fa-map-marker-plus:after {
  content: "\10f60a";
}

.fad.fa-map-marker-question:after {
  content: "\10f60b";
}

.fad.fa-map-marker-slash:after {
  content: "\10f60c";
}

.fad.fa-map-marker-smile:after {
  content: "\10f60d";
}

.fad.fa-map-marker-times:after {
  content: "\10f60e";
}

.fad.fa-map-pin:after {
  content: "\10f276";
}

.fad.fa-map-signs:after {
  content: "\10f277";
}

.fad.fa-marker:after {
  content: "\10f5a1";
}

.fad.fa-mars:after {
  content: "\10f222";
}

.fad.fa-mars-double:after {
  content: "\10f227";
}

.fad.fa-mars-stroke:after {
  content: "\10f229";
}

.fad.fa-mars-stroke-h:after {
  content: "\10f22b";
}

.fad.fa-mars-stroke-v:after {
  content: "\10f22a";
}

.fad.fa-mask:after {
  content: "\10f6fa";
}

.fad.fa-meat:after {
  content: "\10f814";
}

.fad.fa-medal:after {
  content: "\10f5a2";
}

.fad.fa-medkit:after {
  content: "\10f0fa";
}

.fad.fa-megaphone:after {
  content: "\10f675";
}

.fad.fa-meh:after {
  content: "\10f11a";
}

.fad.fa-meh-blank:after {
  content: "\10f5a4";
}

.fad.fa-meh-rolling-eyes:after {
  content: "\10f5a5";
}

.fad.fa-memory:after {
  content: "\10f538";
}

.fad.fa-menorah:after {
  content: "\10f676";
}

.fad.fa-mercury:after {
  content: "\10f223";
}

.fad.fa-meteor:after {
  content: "\10f753";
}

.fad.fa-microchip:after {
  content: "\10f2db";
}

.fad.fa-microphone:after {
  content: "\10f130";
}

.fad.fa-microphone-alt:after {
  content: "\10f3c9";
}

.fad.fa-microphone-alt-slash:after {
  content: "\10f539";
}

.fad.fa-microphone-slash:after {
  content: "\10f131";
}

.fad.fa-microphone-stand:after {
  content: "\10f8cb";
}

.fad.fa-microscope:after {
  content: "\10f610";
}

.fad.fa-microwave:after {
  content: "\10e01b";
}

.fad.fa-mind-share:after {
  content: "\10f677";
}

.fad.fa-minus:after {
  content: "\10f068";
}

.fad.fa-minus-circle:after {
  content: "\10f056";
}

.fad.fa-minus-hexagon:after {
  content: "\10f307";
}

.fad.fa-minus-octagon:after {
  content: "\10f308";
}

.fad.fa-minus-square:after {
  content: "\10f146";
}

.fad.fa-mistletoe:after {
  content: "\10f7b4";
}

.fad.fa-mitten:after {
  content: "\10f7b5";
}

.fad.fa-mobile:after {
  content: "\10f10b";
}

.fad.fa-mobile-alt:after {
  content: "\10f3cd";
}

.fad.fa-mobile-android:after {
  content: "\10f3ce";
}

.fad.fa-mobile-android-alt:after {
  content: "\10f3cf";
}

.fad.fa-money-bill:after {
  content: "\10f0d6";
}

.fad.fa-money-bill-alt:after {
  content: "\10f3d1";
}

.fad.fa-money-bill-wave:after {
  content: "\10f53a";
}

.fad.fa-money-bill-wave-alt:after {
  content: "\10f53b";
}

.fad.fa-money-check:after {
  content: "\10f53c";
}

.fad.fa-money-check-alt:after {
  content: "\10f53d";
}

.fad.fa-money-check-edit:after {
  content: "\10f872";
}

.fad.fa-money-check-edit-alt:after {
  content: "\10f873";
}

.fad.fa-monitor-heart-rate:after {
  content: "\10f611";
}

.fad.fa-monkey:after {
  content: "\10f6fb";
}

.fad.fa-monument:after {
  content: "\10f5a6";
}

.fad.fa-moon:after {
  content: "\10f186";
}

.fad.fa-moon-cloud:after {
  content: "\10f754";
}

.fad.fa-moon-stars:after {
  content: "\10f755";
}

.fad.fa-mortar-pestle:after {
  content: "\10f5a7";
}

.fad.fa-mosque:after {
  content: "\10f678";
}

.fad.fa-motorcycle:after {
  content: "\10f21c";
}

.fad.fa-mountain:after {
  content: "\10f6fc";
}

.fad.fa-mountains:after {
  content: "\10f6fd";
}

.fad.fa-mouse:after {
  content: "\10f8cc";
}

.fad.fa-mouse-alt:after {
  content: "\10f8cd";
}

.fad.fa-mouse-pointer:after {
  content: "\10f245";
}

.fad.fa-mp3-player:after {
  content: "\10f8ce";
}

.fad.fa-mug:after {
  content: "\10f874";
}

.fad.fa-mug-hot:after {
  content: "\10f7b6";
}

.fad.fa-mug-marshmallows:after {
  content: "\10f7b7";
}

.fad.fa-mug-tea:after {
  content: "\10f875";
}

.fad.fa-music:after {
  content: "\10f001";
}

.fad.fa-music-alt:after {
  content: "\10f8cf";
}

.fad.fa-music-alt-slash:after {
  content: "\10f8d0";
}

.fad.fa-music-slash:after {
  content: "\10f8d1";
}

.fad.fa-narwhal:after {
  content: "\10f6fe";
}

.fad.fa-network-wired:after {
  content: "\10f6ff";
}

.fad.fa-neuter:after {
  content: "\10f22c";
}

.fad.fa-newspaper:after {
  content: "\10f1ea";
}

.fad.fa-not-equal:after {
  content: "\10f53e";
}

.fad.fa-notes-medical:after {
  content: "\10f481";
}

.fad.fa-object-group:after {
  content: "\10f247";
}

.fad.fa-object-ungroup:after {
  content: "\10f248";
}

.fad.fa-octagon:after {
  content: "\10f306";
}

.fad.fa-oil-can:after {
  content: "\10f613";
}

.fad.fa-oil-temp:after {
  content: "\10f614";
}

.fad.fa-om:after {
  content: "\10f679";
}

.fad.fa-omega:after {
  content: "\10f67a";
}

.fad.fa-ornament:after {
  content: "\10f7b8";
}

.fad.fa-otter:after {
  content: "\10f700";
}

.fad.fa-outdent:after {
  content: "\10f03b";
}

.fad.fa-outlet:after {
  content: "\10e01c";
}

.fad.fa-oven:after {
  content: "\10e01d";
}

.fad.fa-overline:after {
  content: "\10f876";
}

.fad.fa-page-break:after {
  content: "\10f877";
}

.fad.fa-pager:after {
  content: "\10f815";
}

.fad.fa-paint-brush:after {
  content: "\10f1fc";
}

.fad.fa-paint-brush-alt:after {
  content: "\10f5a9";
}

.fad.fa-paint-roller:after {
  content: "\10f5aa";
}

.fad.fa-palette:after {
  content: "\10f53f";
}

.fad.fa-pallet:after {
  content: "\10f482";
}

.fad.fa-pallet-alt:after {
  content: "\10f483";
}

.fad.fa-paper-plane:after {
  content: "\10f1d8";
}

.fad.fa-paperclip:after {
  content: "\10f0c6";
}

.fad.fa-parachute-box:after {
  content: "\10f4cd";
}

.fad.fa-paragraph:after {
  content: "\10f1dd";
}

.fad.fa-paragraph-rtl:after {
  content: "\10f878";
}

.fad.fa-parking:after {
  content: "\10f540";
}

.fad.fa-parking-circle:after {
  content: "\10f615";
}

.fad.fa-parking-circle-slash:after {
  content: "\10f616";
}

.fad.fa-parking-slash:after {
  content: "\10f617";
}

.fad.fa-passport:after {
  content: "\10f5ab";
}

.fad.fa-pastafarianism:after {
  content: "\10f67b";
}

.fad.fa-paste:after {
  content: "\10f0ea";
}

.fad.fa-pause:after {
  content: "\10f04c";
}

.fad.fa-pause-circle:after {
  content: "\10f28b";
}

.fad.fa-paw:after {
  content: "\10f1b0";
}

.fad.fa-paw-alt:after {
  content: "\10f701";
}

.fad.fa-paw-claws:after {
  content: "\10f702";
}

.fad.fa-peace:after {
  content: "\10f67c";
}

.fad.fa-pegasus:after {
  content: "\10f703";
}

.fad.fa-pen:after {
  content: "\10f304";
}

.fad.fa-pen-alt:after {
  content: "\10f305";
}

.fad.fa-pen-fancy:after {
  content: "\10f5ac";
}

.fad.fa-pen-nib:after {
  content: "\10f5ad";
}

.fad.fa-pen-square:after {
  content: "\10f14b";
}

.fad.fa-pencil:after {
  content: "\10f040";
}

.fad.fa-pencil-alt:after {
  content: "\10f303";
}

.fad.fa-pencil-paintbrush:after {
  content: "\10f618";
}

.fad.fa-pencil-ruler:after {
  content: "\10f5ae";
}

.fad.fa-pennant:after {
  content: "\10f456";
}

.fad.fa-people-arrows:after {
  content: "\10e068";
}

.fad.fa-people-carry:after {
  content: "\10f4ce";
}

.fad.fa-pepper-hot:after {
  content: "\10f816";
}

.fad.fa-percent:after {
  content: "\10f295";
}

.fad.fa-percentage:after {
  content: "\10f541";
}

.fad.fa-person-booth:after {
  content: "\10f756";
}

.fad.fa-person-carry:after {
  content: "\10f4cf";
}

.fad.fa-person-dolly:after {
  content: "\10f4d0";
}

.fad.fa-person-dolly-empty:after {
  content: "\10f4d1";
}

.fad.fa-person-sign:after {
  content: "\10f757";
}

.fad.fa-phone:after {
  content: "\10f095";
}

.fad.fa-phone-alt:after {
  content: "\10f879";
}

.fad.fa-phone-laptop:after {
  content: "\10f87a";
}

.fad.fa-phone-office:after {
  content: "\10f67d";
}

.fad.fa-phone-plus:after {
  content: "\10f4d2";
}

.fad.fa-phone-rotary:after {
  content: "\10f8d3";
}

.fad.fa-phone-slash:after {
  content: "\10f3dd";
}

.fad.fa-phone-square:after {
  content: "\10f098";
}

.fad.fa-phone-square-alt:after {
  content: "\10f87b";
}

.fad.fa-phone-volume:after {
  content: "\10f2a0";
}

.fad.fa-photo-video:after {
  content: "\10f87c";
}

.fad.fa-pi:after {
  content: "\10f67e";
}

.fad.fa-piano:after {
  content: "\10f8d4";
}

.fad.fa-piano-keyboard:after {
  content: "\10f8d5";
}

.fad.fa-pie:after {
  content: "\10f705";
}

.fad.fa-pig:after {
  content: "\10f706";
}

.fad.fa-piggy-bank:after {
  content: "\10f4d3";
}

.fad.fa-pills:after {
  content: "\10f484";
}

.fad.fa-pizza:after {
  content: "\10f817";
}

.fad.fa-pizza-slice:after {
  content: "\10f818";
}

.fad.fa-place-of-worship:after {
  content: "\10f67f";
}

.fad.fa-plane:after {
  content: "\10f072";
}

.fad.fa-plane-alt:after {
  content: "\10f3de";
}

.fad.fa-plane-arrival:after {
  content: "\10f5af";
}

.fad.fa-plane-departure:after {
  content: "\10f5b0";
}

.fad.fa-plane-slash:after {
  content: "\10e069";
}

.fad.fa-planet-moon:after {
  content: "\10e01f";
}

.fad.fa-planet-ringed:after {
  content: "\10e020";
}

.fad.fa-play:after {
  content: "\10f04b";
}

.fad.fa-play-circle:after {
  content: "\10f144";
}

.fad.fa-plug:after {
  content: "\10f1e6";
}

.fad.fa-plus:after {
  content: "\10f067";
}

.fad.fa-plus-circle:after {
  content: "\10f055";
}

.fad.fa-plus-hexagon:after {
  content: "\10f300";
}

.fad.fa-plus-octagon:after {
  content: "\10f301";
}

.fad.fa-plus-square:after {
  content: "\10f0fe";
}

.fad.fa-podcast:after {
  content: "\10f2ce";
}

.fad.fa-podium:after {
  content: "\10f680";
}

.fad.fa-podium-star:after {
  content: "\10f758";
}

.fad.fa-police-box:after {
  content: "\10e021";
}

.fad.fa-poll:after {
  content: "\10f681";
}

.fad.fa-poll-h:after {
  content: "\10f682";
}

.fad.fa-poll-people:after {
  content: "\10f759";
}

.fad.fa-poo:after {
  content: "\10f2fe";
}

.fad.fa-poo-storm:after {
  content: "\10f75a";
}

.fad.fa-poop:after {
  content: "\10f619";
}

.fad.fa-popcorn:after {
  content: "\10f819";
}

.fad.fa-portal-enter:after {
  content: "\10e022";
}

.fad.fa-portal-exit:after {
  content: "\10e023";
}

.fad.fa-portrait:after {
  content: "\10f3e0";
}

.fad.fa-pound-sign:after {
  content: "\10f154";
}

.fad.fa-power-off:after {
  content: "\10f011";
}

.fad.fa-pray:after {
  content: "\10f683";
}

.fad.fa-praying-hands:after {
  content: "\10f684";
}

.fad.fa-prescription:after {
  content: "\10f5b1";
}

.fad.fa-prescription-bottle:after {
  content: "\10f485";
}

.fad.fa-prescription-bottle-alt:after {
  content: "\10f486";
}

.fad.fa-presentation:after {
  content: "\10f685";
}

.fad.fa-print:after {
  content: "\10f02f";
}

.fad.fa-print-search:after {
  content: "\10f81a";
}

.fad.fa-print-slash:after {
  content: "\10f686";
}

.fad.fa-procedures:after {
  content: "\10f487";
}

.fad.fa-project-diagram:after {
  content: "\10f542";
}

.fad.fa-projector:after {
  content: "\10f8d6";
}

.fad.fa-pump-medical:after {
  content: "\10e06a";
}

.fad.fa-pump-soap:after {
  content: "\10e06b";
}

.fad.fa-pumpkin:after {
  content: "\10f707";
}

.fad.fa-puzzle-piece:after {
  content: "\10f12e";
}

.fad.fa-qrcode:after {
  content: "\10f029";
}

.fad.fa-question:after {
  content: "\10f128";
}

.fad.fa-question-circle:after {
  content: "\10f059";
}

.fad.fa-question-square:after {
  content: "\10f2fd";
}

.fad.fa-quidditch:after {
  content: "\10f458";
}

.fad.fa-quote-left:after {
  content: "\10f10d";
}

.fad.fa-quote-right:after {
  content: "\10f10e";
}

.fad.fa-quran:after {
  content: "\10f687";
}

.fad.fa-rabbit:after {
  content: "\10f708";
}

.fad.fa-rabbit-fast:after {
  content: "\10f709";
}

.fad.fa-racquet:after {
  content: "\10f45a";
}

.fad.fa-radar:after {
  content: "\10e024";
}

.fad.fa-radiation:after {
  content: "\10f7b9";
}

.fad.fa-radiation-alt:after {
  content: "\10f7ba";
}

.fad.fa-radio:after {
  content: "\10f8d7";
}

.fad.fa-radio-alt:after {
  content: "\10f8d8";
}

.fad.fa-rainbow:after {
  content: "\10f75b";
}

.fad.fa-raindrops:after {
  content: "\10f75c";
}

.fad.fa-ram:after {
  content: "\10f70a";
}

.fad.fa-ramp-loading:after {
  content: "\10f4d4";
}

.fad.fa-random:after {
  content: "\10f074";
}

.fad.fa-raygun:after {
  content: "\10e025";
}

.fad.fa-receipt:after {
  content: "\10f543";
}

.fad.fa-record-vinyl:after {
  content: "\10f8d9";
}

.fad.fa-rectangle-landscape:after {
  content: "\10f2fa";
}

.fad.fa-rectangle-portrait:after {
  content: "\10f2fb";
}

.fad.fa-rectangle-wide:after {
  content: "\10f2fc";
}

.fad.fa-recycle:after {
  content: "\10f1b8";
}

.fad.fa-redo:after {
  content: "\10f01e";
}

.fad.fa-redo-alt:after {
  content: "\10f2f9";
}

.fad.fa-refrigerator:after {
  content: "\10e026";
}

.fad.fa-registered:after {
  content: "\10f25d";
}

.fad.fa-remove-format:after {
  content: "\10f87d";
}

.fad.fa-repeat:after {
  content: "\10f363";
}

.fad.fa-repeat-1:after {
  content: "\10f365";
}

.fad.fa-repeat-1-alt:after {
  content: "\10f366";
}

.fad.fa-repeat-alt:after {
  content: "\10f364";
}

.fad.fa-reply:after {
  content: "\10f3e5";
}

.fad.fa-reply-all:after {
  content: "\10f122";
}

.fad.fa-republican:after {
  content: "\10f75e";
}

.fad.fa-restroom:after {
  content: "\10f7bd";
}

.fad.fa-retweet:after {
  content: "\10f079";
}

.fad.fa-retweet-alt:after {
  content: "\10f361";
}

.fad.fa-ribbon:after {
  content: "\10f4d6";
}

.fad.fa-ring:after {
  content: "\10f70b";
}

.fad.fa-rings-wedding:after {
  content: "\10f81b";
}

.fad.fa-road:after {
  content: "\10f018";
}

.fad.fa-robot:after {
  content: "\10f544";
}

.fad.fa-rocket:after {
  content: "\10f135";
}

.fad.fa-rocket-launch:after {
  content: "\10e027";
}

.fad.fa-route:after {
  content: "\10f4d7";
}

.fad.fa-route-highway:after {
  content: "\10f61a";
}

.fad.fa-route-interstate:after {
  content: "\10f61b";
}

.fad.fa-router:after {
  content: "\10f8da";
}

.fad.fa-rss:after {
  content: "\10f09e";
}

.fad.fa-rss-square:after {
  content: "\10f143";
}

.fad.fa-ruble-sign:after {
  content: "\10f158";
}

.fad.fa-ruler:after {
  content: "\10f545";
}

.fad.fa-ruler-combined:after {
  content: "\10f546";
}

.fad.fa-ruler-horizontal:after {
  content: "\10f547";
}

.fad.fa-ruler-triangle:after {
  content: "\10f61c";
}

.fad.fa-ruler-vertical:after {
  content: "\10f548";
}

.fad.fa-running:after {
  content: "\10f70c";
}

.fad.fa-rupee-sign:after {
  content: "\10f156";
}

.fad.fa-rv:after {
  content: "\10f7be";
}

.fad.fa-sack:after {
  content: "\10f81c";
}

.fad.fa-sack-dollar:after {
  content: "\10f81d";
}

.fad.fa-sad-cry:after {
  content: "\10f5b3";
}

.fad.fa-sad-tear:after {
  content: "\10f5b4";
}

.fad.fa-salad:after {
  content: "\10f81e";
}

.fad.fa-sandwich:after {
  content: "\10f81f";
}

.fad.fa-satellite:after {
  content: "\10f7bf";
}

.fad.fa-satellite-dish:after {
  content: "\10f7c0";
}

.fad.fa-sausage:after {
  content: "\10f820";
}

.fad.fa-save:after {
  content: "\10f0c7";
}

.fad.fa-sax-hot:after {
  content: "\10f8db";
}

.fad.fa-saxophone:after {
  content: "\10f8dc";
}

.fad.fa-scalpel:after {
  content: "\10f61d";
}

.fad.fa-scalpel-path:after {
  content: "\10f61e";
}

.fad.fa-scanner:after {
  content: "\10f488";
}

.fad.fa-scanner-image:after {
  content: "\10f8f3";
}

.fad.fa-scanner-keyboard:after {
  content: "\10f489";
}

.fad.fa-scanner-touchscreen:after {
  content: "\10f48a";
}

.fad.fa-scarecrow:after {
  content: "\10f70d";
}

.fad.fa-scarf:after {
  content: "\10f7c1";
}

.fad.fa-school:after {
  content: "\10f549";
}

.fad.fa-screwdriver:after {
  content: "\10f54a";
}

.fad.fa-scroll:after {
  content: "\10f70e";
}

.fad.fa-scroll-old:after {
  content: "\10f70f";
}

.fad.fa-scrubber:after {
  content: "\10f2f8";
}

.fad.fa-scythe:after {
  content: "\10f710";
}

.fad.fa-sd-card:after {
  content: "\10f7c2";
}

.fad.fa-search:after {
  content: "\10f002";
}

.fad.fa-search-dollar:after {
  content: "\10f688";
}

.fad.fa-search-location:after {
  content: "\10f689";
}

.fad.fa-search-minus:after {
  content: "\10f010";
}

.fad.fa-search-plus:after {
  content: "\10f00e";
}

.fad.fa-seedling:after {
  content: "\10f4d8";
}

.fad.fa-send-back:after {
  content: "\10f87e";
}

.fad.fa-send-backward:after {
  content: "\10f87f";
}

.fad.fa-sensor:after {
  content: "\10e028";
}

.fad.fa-sensor-alert:after {
  content: "\10e029";
}

.fad.fa-sensor-fire:after {
  content: "\10e02a";
}

.fad.fa-sensor-on:after {
  content: "\10e02b";
}

.fad.fa-sensor-smoke:after {
  content: "\10e02c";
}

.fad.fa-server:after {
  content: "\10f233";
}

.fad.fa-shapes:after {
  content: "\10f61f";
}

.fad.fa-share:after {
  content: "\10f064";
}

.fad.fa-share-all:after {
  content: "\10f367";
}

.fad.fa-share-alt:after {
  content: "\10f1e0";
}

.fad.fa-share-alt-square:after {
  content: "\10f1e1";
}

.fad.fa-share-square:after {
  content: "\10f14d";
}

.fad.fa-sheep:after {
  content: "\10f711";
}

.fad.fa-shekel-sign:after {
  content: "\10f20b";
}

.fad.fa-shield:after {
  content: "\10f132";
}

.fad.fa-shield-alt:after {
  content: "\10f3ed";
}

.fad.fa-shield-check:after {
  content: "\10f2f7";
}

.fad.fa-shield-cross:after {
  content: "\10f712";
}

.fad.fa-shield-virus:after {
  content: "\10e06c";
}

.fad.fa-ship:after {
  content: "\10f21a";
}

.fad.fa-shipping-fast:after {
  content: "\10f48b";
}

.fad.fa-shipping-timed:after {
  content: "\10f48c";
}

.fad.fa-shish-kebab:after {
  content: "\10f821";
}

.fad.fa-shoe-prints:after {
  content: "\10f54b";
}

.fad.fa-shopping-bag:after {
  content: "\10f290";
}

.fad.fa-shopping-basket:after {
  content: "\10f291";
}

.fad.fa-shopping-cart:after {
  content: "\10f07a";
}

.fad.fa-shovel:after {
  content: "\10f713";
}

.fad.fa-shovel-snow:after {
  content: "\10f7c3";
}

.fad.fa-shower:after {
  content: "\10f2cc";
}

.fad.fa-shredder:after {
  content: "\10f68a";
}

.fad.fa-shuttle-van:after {
  content: "\10f5b6";
}

.fad.fa-shuttlecock:after {
  content: "\10f45b";
}

.fad.fa-sickle:after {
  content: "\10f822";
}

.fad.fa-sigma:after {
  content: "\10f68b";
}

.fad.fa-sign:after {
  content: "\10f4d9";
}

.fad.fa-sign-in:after {
  content: "\10f090";
}

.fad.fa-sign-in-alt:after {
  content: "\10f2f6";
}

.fad.fa-sign-language:after {
  content: "\10f2a7";
}

.fad.fa-sign-out:after {
  content: "\10f08b";
}

.fad.fa-sign-out-alt:after {
  content: "\10f2f5";
}

.fad.fa-signal:after {
  content: "\10f012";
}

.fad.fa-signal-1:after {
  content: "\10f68c";
}

.fad.fa-signal-2:after {
  content: "\10f68d";
}

.fad.fa-signal-3:after {
  content: "\10f68e";
}

.fad.fa-signal-4:after {
  content: "\10f68f";
}

.fad.fa-signal-alt:after {
  content: "\10f690";
}

.fad.fa-signal-alt-1:after {
  content: "\10f691";
}

.fad.fa-signal-alt-2:after {
  content: "\10f692";
}

.fad.fa-signal-alt-3:after {
  content: "\10f693";
}

.fad.fa-signal-alt-slash:after {
  content: "\10f694";
}

.fad.fa-signal-slash:after {
  content: "\10f695";
}

.fad.fa-signal-stream:after {
  content: "\10f8dd";
}

.fad.fa-signature:after {
  content: "\10f5b7";
}

.fad.fa-sim-card:after {
  content: "\10f7c4";
}

.fad.fa-sink:after {
  content: "\10e06d";
}

.fad.fa-siren:after {
  content: "\10e02d";
}

.fad.fa-siren-on:after {
  content: "\10e02e";
}

.fad.fa-sitemap:after {
  content: "\10f0e8";
}

.fad.fa-skating:after {
  content: "\10f7c5";
}

.fad.fa-skeleton:after {
  content: "\10f620";
}

.fad.fa-ski-jump:after {
  content: "\10f7c7";
}

.fad.fa-ski-lift:after {
  content: "\10f7c8";
}

.fad.fa-skiing:after {
  content: "\10f7c9";
}

.fad.fa-skiing-nordic:after {
  content: "\10f7ca";
}

.fad.fa-skull:after {
  content: "\10f54c";
}

.fad.fa-skull-cow:after {
  content: "\10f8de";
}

.fad.fa-skull-crossbones:after {
  content: "\10f714";
}

.fad.fa-slash:after {
  content: "\10f715";
}

.fad.fa-sledding:after {
  content: "\10f7cb";
}

.fad.fa-sleigh:after {
  content: "\10f7cc";
}

.fad.fa-sliders-h:after {
  content: "\10f1de";
}

.fad.fa-sliders-h-square:after {
  content: "\10f3f0";
}

.fad.fa-sliders-v:after {
  content: "\10f3f1";
}

.fad.fa-sliders-v-square:after {
  content: "\10f3f2";
}

.fad.fa-smile:after {
  content: "\10f118";
}

.fad.fa-smile-beam:after {
  content: "\10f5b8";
}

.fad.fa-smile-plus:after {
  content: "\10f5b9";
}

.fad.fa-smile-wink:after {
  content: "\10f4da";
}

.fad.fa-smog:after {
  content: "\10f75f";
}

.fad.fa-smoke:after {
  content: "\10f760";
}

.fad.fa-smoking:after {
  content: "\10f48d";
}

.fad.fa-smoking-ban:after {
  content: "\10f54d";
}

.fad.fa-sms:after {
  content: "\10f7cd";
}

.fad.fa-snake:after {
  content: "\10f716";
}

.fad.fa-snooze:after {
  content: "\10f880";
}

.fad.fa-snow-blowing:after {
  content: "\10f761";
}

.fad.fa-snowboarding:after {
  content: "\10f7ce";
}

.fad.fa-snowflake:after {
  content: "\10f2dc";
}

.fad.fa-snowflakes:after {
  content: "\10f7cf";
}

.fad.fa-snowman:after {
  content: "\10f7d0";
}

.fad.fa-snowmobile:after {
  content: "\10f7d1";
}

.fad.fa-snowplow:after {
  content: "\10f7d2";
}

.fad.fa-soap:after {
  content: "\10e06e";
}

.fad.fa-socks:after {
  content: "\10f696";
}

.fad.fa-solar-panel:after {
  content: "\10f5ba";
}

.fad.fa-solar-system:after {
  content: "\10e02f";
}

.fad.fa-sort:after {
  content: "\10f0dc";
}

.fad.fa-sort-alpha-down:after {
  content: "\10f15d";
}

.fad.fa-sort-alpha-down-alt:after {
  content: "\10f881";
}

.fad.fa-sort-alpha-up:after {
  content: "\10f15e";
}

.fad.fa-sort-alpha-up-alt:after {
  content: "\10f882";
}

.fad.fa-sort-alt:after {
  content: "\10f883";
}

.fad.fa-sort-amount-down:after {
  content: "\10f160";
}

.fad.fa-sort-amount-down-alt:after {
  content: "\10f884";
}

.fad.fa-sort-amount-up:after {
  content: "\10f161";
}

.fad.fa-sort-amount-up-alt:after {
  content: "\10f885";
}

.fad.fa-sort-circle:after {
  content: "\10e030";
}

.fad.fa-sort-circle-down:after {
  content: "\10e031";
}

.fad.fa-sort-circle-up:after {
  content: "\10e032";
}

.fad.fa-sort-down:after {
  content: "\10f0dd";
}

.fad.fa-sort-numeric-down:after {
  content: "\10f162";
}

.fad.fa-sort-numeric-down-alt:after {
  content: "\10f886";
}

.fad.fa-sort-numeric-up:after {
  content: "\10f163";
}

.fad.fa-sort-numeric-up-alt:after {
  content: "\10f887";
}

.fad.fa-sort-shapes-down:after {
  content: "\10f888";
}

.fad.fa-sort-shapes-down-alt:after {
  content: "\10f889";
}

.fad.fa-sort-shapes-up:after {
  content: "\10f88a";
}

.fad.fa-sort-shapes-up-alt:after {
  content: "\10f88b";
}

.fad.fa-sort-size-down:after {
  content: "\10f88c";
}

.fad.fa-sort-size-down-alt:after {
  content: "\10f88d";
}

.fad.fa-sort-size-up:after {
  content: "\10f88e";
}

.fad.fa-sort-size-up-alt:after {
  content: "\10f88f";
}

.fad.fa-sort-up:after {
  content: "\10f0de";
}

.fad.fa-soup:after {
  content: "\10f823";
}

.fad.fa-spa:after {
  content: "\10f5bb";
}

.fad.fa-space-shuttle:after {
  content: "\10f197";
}

.fad.fa-space-station-moon:after {
  content: "\10e033";
}

.fad.fa-space-station-moon-alt:after {
  content: "\10e034";
}

.fad.fa-spade:after {
  content: "\10f2f4";
}

.fad.fa-sparkles:after {
  content: "\10f890";
}

.fad.fa-speaker:after {
  content: "\10f8df";
}

.fad.fa-speakers:after {
  content: "\10f8e0";
}

.fad.fa-spell-check:after {
  content: "\10f891";
}

.fad.fa-spider:after {
  content: "\10f717";
}

.fad.fa-spider-black-widow:after {
  content: "\10f718";
}

.fad.fa-spider-web:after {
  content: "\10f719";
}

.fad.fa-spinner:after {
  content: "\10f110";
}

.fad.fa-spinner-third:after {
  content: "\10f3f4";
}

.fad.fa-splotch:after {
  content: "\10f5bc";
}

.fad.fa-spray-can:after {
  content: "\10f5bd";
}

.fad.fa-sprinkler:after {
  content: "\10e035";
}

.fad.fa-square:after {
  content: "\10f0c8";
}

.fad.fa-square-full:after {
  content: "\10f45c";
}

.fad.fa-square-root:after {
  content: "\10f697";
}

.fad.fa-square-root-alt:after {
  content: "\10f698";
}

.fad.fa-squirrel:after {
  content: "\10f71a";
}

.fad.fa-staff:after {
  content: "\10f71b";
}

.fad.fa-stamp:after {
  content: "\10f5bf";
}

.fad.fa-star:after {
  content: "\10f005";
}

.fad.fa-star-and-crescent:after {
  content: "\10f699";
}

.fad.fa-star-christmas:after {
  content: "\10f7d4";
}

.fad.fa-star-exclamation:after {
  content: "\10f2f3";
}

.fad.fa-star-half:after {
  content: "\10f089";
}

.fad.fa-star-half-alt:after {
  content: "\10f5c0";
}

.fad.fa-star-of-david:after {
  content: "\10f69a";
}

.fad.fa-star-of-life:after {
  content: "\10f621";
}

.fad.fa-star-shooting:after {
  content: "\10e036";
}

.fad.fa-starfighter:after {
  content: "\10e037";
}

.fad.fa-starfighter-alt:after {
  content: "\10e038";
}

.fad.fa-stars:after {
  content: "\10f762";
}

.fad.fa-starship:after {
  content: "\10e039";
}

.fad.fa-starship-freighter:after {
  content: "\10e03a";
}

.fad.fa-steak:after {
  content: "\10f824";
}

.fad.fa-steering-wheel:after {
  content: "\10f622";
}

.fad.fa-step-backward:after {
  content: "\10f048";
}

.fad.fa-step-forward:after {
  content: "\10f051";
}

.fad.fa-stethoscope:after {
  content: "\10f0f1";
}

.fad.fa-sticky-note:after {
  content: "\10f249";
}

.fad.fa-stocking:after {
  content: "\10f7d5";
}

.fad.fa-stomach:after {
  content: "\10f623";
}

.fad.fa-stop:after {
  content: "\10f04d";
}

.fad.fa-stop-circle:after {
  content: "\10f28d";
}

.fad.fa-stopwatch:after {
  content: "\10f2f2";
}

.fad.fa-stopwatch-20:after {
  content: "\10e06f";
}

.fad.fa-store:after {
  content: "\10f54e";
}

.fad.fa-store-alt:after {
  content: "\10f54f";
}

.fad.fa-store-alt-slash:after {
  content: "\10e070";
}

.fad.fa-store-slash:after {
  content: "\10e071";
}

.fad.fa-stream:after {
  content: "\10f550";
}

.fad.fa-street-view:after {
  content: "\10f21d";
}

.fad.fa-stretcher:after {
  content: "\10f825";
}

.fad.fa-strikethrough:after {
  content: "\10f0cc";
}

.fad.fa-stroopwafel:after {
  content: "\10f551";
}

.fad.fa-subscript:after {
  content: "\10f12c";
}

.fad.fa-subway:after {
  content: "\10f239";
}

.fad.fa-suitcase:after {
  content: "\10f0f2";
}

.fad.fa-suitcase-rolling:after {
  content: "\10f5c1";
}

.fad.fa-sun:after {
  content: "\10f185";
}

.fad.fa-sun-cloud:after {
  content: "\10f763";
}

.fad.fa-sun-dust:after {
  content: "\10f764";
}

.fad.fa-sun-haze:after {
  content: "\10f765";
}

.fad.fa-sunglasses:after {
  content: "\10f892";
}

.fad.fa-sunrise:after {
  content: "\10f766";
}

.fad.fa-sunset:after {
  content: "\10f767";
}

.fad.fa-superscript:after {
  content: "\10f12b";
}

.fad.fa-surprise:after {
  content: "\10f5c2";
}

.fad.fa-swatchbook:after {
  content: "\10f5c3";
}

.fad.fa-swimmer:after {
  content: "\10f5c4";
}

.fad.fa-swimming-pool:after {
  content: "\10f5c5";
}

.fad.fa-sword:after {
  content: "\10f71c";
}

.fad.fa-sword-laser:after {
  content: "\10e03b";
}

.fad.fa-sword-laser-alt:after {
  content: "\10e03c";
}

.fad.fa-swords:after {
  content: "\10f71d";
}

.fad.fa-swords-laser:after {
  content: "\10e03d";
}

.fad.fa-synagogue:after {
  content: "\10f69b";
}

.fad.fa-sync:after {
  content: "\10f021";
}

.fad.fa-sync-alt:after {
  content: "\10f2f1";
}

.fad.fa-syringe:after {
  content: "\10f48e";
}

.fad.fa-table:after {
  content: "\10f0ce";
}

.fad.fa-table-tennis:after {
  content: "\10f45d";
}

.fad.fa-tablet:after {
  content: "\10f10a";
}

.fad.fa-tablet-alt:after {
  content: "\10f3fa";
}

.fad.fa-tablet-android:after {
  content: "\10f3fb";
}

.fad.fa-tablet-android-alt:after {
  content: "\10f3fc";
}

.fad.fa-tablet-rugged:after {
  content: "\10f48f";
}

.fad.fa-tablets:after {
  content: "\10f490";
}

.fad.fa-tachometer:after {
  content: "\10f0e4";
}

.fad.fa-tachometer-alt:after {
  content: "\10f3fd";
}

.fad.fa-tachometer-alt-average:after {
  content: "\10f624";
}

.fad.fa-tachometer-alt-fast:after {
  content: "\10f625";
}

.fad.fa-tachometer-alt-fastest:after {
  content: "\10f626";
}

.fad.fa-tachometer-alt-slow:after {
  content: "\10f627";
}

.fad.fa-tachometer-alt-slowest:after {
  content: "\10f628";
}

.fad.fa-tachometer-average:after {
  content: "\10f629";
}

.fad.fa-tachometer-fast:after {
  content: "\10f62a";
}

.fad.fa-tachometer-fastest:after {
  content: "\10f62b";
}

.fad.fa-tachometer-slow:after {
  content: "\10f62c";
}

.fad.fa-tachometer-slowest:after {
  content: "\10f62d";
}

.fad.fa-taco:after {
  content: "\10f826";
}

.fad.fa-tag:after {
  content: "\10f02b";
}

.fad.fa-tags:after {
  content: "\10f02c";
}

.fad.fa-tally:after {
  content: "\10f69c";
}

.fad.fa-tanakh:after {
  content: "\10f827";
}

.fad.fa-tape:after {
  content: "\10f4db";
}

.fad.fa-tasks:after {
  content: "\10f0ae";
}

.fad.fa-tasks-alt:after {
  content: "\10f828";
}

.fad.fa-taxi:after {
  content: "\10f1ba";
}

.fad.fa-teeth:after {
  content: "\10f62e";
}

.fad.fa-teeth-open:after {
  content: "\10f62f";
}

.fad.fa-telescope:after {
  content: "\10e03e";
}

.fad.fa-temperature-down:after {
  content: "\10e03f";
}

.fad.fa-temperature-frigid:after {
  content: "\10f768";
}

.fad.fa-temperature-high:after {
  content: "\10f769";
}

.fad.fa-temperature-hot:after {
  content: "\10f76a";
}

.fad.fa-temperature-low:after {
  content: "\10f76b";
}

.fad.fa-temperature-up:after {
  content: "\10e040";
}

.fad.fa-tenge:after {
  content: "\10f7d7";
}

.fad.fa-tennis-ball:after {
  content: "\10f45e";
}

.fad.fa-terminal:after {
  content: "\10f120";
}

.fad.fa-text:after {
  content: "\10f893";
}

.fad.fa-text-height:after {
  content: "\10f034";
}

.fad.fa-text-size:after {
  content: "\10f894";
}

.fad.fa-text-width:after {
  content: "\10f035";
}

.fad.fa-th:after {
  content: "\10f00a";
}

.fad.fa-th-large:after {
  content: "\10f009";
}

.fad.fa-th-list:after {
  content: "\10f00b";
}

.fad.fa-theater-masks:after {
  content: "\10f630";
}

.fad.fa-thermometer:after {
  content: "\10f491";
}

.fad.fa-thermometer-empty:after {
  content: "\10f2cb";
}

.fad.fa-thermometer-full:after {
  content: "\10f2c7";
}

.fad.fa-thermometer-half:after {
  content: "\10f2c9";
}

.fad.fa-thermometer-quarter:after {
  content: "\10f2ca";
}

.fad.fa-thermometer-three-quarters:after {
  content: "\10f2c8";
}

.fad.fa-theta:after {
  content: "\10f69e";
}

.fad.fa-thumbs-down:after {
  content: "\10f165";
}

.fad.fa-thumbs-up:after {
  content: "\10f164";
}

.fad.fa-thumbtack:after {
  content: "\10f08d";
}

.fad.fa-thunderstorm:after {
  content: "\10f76c";
}

.fad.fa-thunderstorm-moon:after {
  content: "\10f76d";
}

.fad.fa-thunderstorm-sun:after {
  content: "\10f76e";
}

.fad.fa-ticket:after {
  content: "\10f145";
}

.fad.fa-ticket-alt:after {
  content: "\10f3ff";
}

.fad.fa-tilde:after {
  content: "\10f69f";
}

.fad.fa-times:after {
  content: "\10f00d";
}

.fad.fa-times-circle:after {
  content: "\10f057";
}

.fad.fa-times-hexagon:after {
  content: "\10f2ee";
}

.fad.fa-times-octagon:after {
  content: "\10f2f0";
}

.fad.fa-times-square:after {
  content: "\10f2d3";
}

.fad.fa-tint:after {
  content: "\10f043";
}

.fad.fa-tint-slash:after {
  content: "\10f5c7";
}

.fad.fa-tire:after {
  content: "\10f631";
}

.fad.fa-tire-flat:after {
  content: "\10f632";
}

.fad.fa-tire-pressure-warning:after {
  content: "\10f633";
}

.fad.fa-tire-rugged:after {
  content: "\10f634";
}

.fad.fa-tired:after {
  content: "\10f5c8";
}

.fad.fa-toggle-off:after {
  content: "\10f204";
}

.fad.fa-toggle-on:after {
  content: "\10f205";
}

.fad.fa-toilet:after {
  content: "\10f7d8";
}

.fad.fa-toilet-paper:after {
  content: "\10f71e";
}

.fad.fa-toilet-paper-alt:after {
  content: "\10f71f";
}

.fad.fa-toilet-paper-slash:after {
  content: "\10e072";
}

.fad.fa-tombstone:after {
  content: "\10f720";
}

.fad.fa-tombstone-alt:after {
  content: "\10f721";
}

.fad.fa-toolbox:after {
  content: "\10f552";
}

.fad.fa-tools:after {
  content: "\10f7d9";
}

.fad.fa-tooth:after {
  content: "\10f5c9";
}

.fad.fa-toothbrush:after {
  content: "\10f635";
}

.fad.fa-torah:after {
  content: "\10f6a0";
}

.fad.fa-torii-gate:after {
  content: "\10f6a1";
}

.fad.fa-tornado:after {
  content: "\10f76f";
}

.fad.fa-tractor:after {
  content: "\10f722";
}

.fad.fa-trademark:after {
  content: "\10f25c";
}

.fad.fa-traffic-cone:after {
  content: "\10f636";
}

.fad.fa-traffic-light:after {
  content: "\10f637";
}

.fad.fa-traffic-light-go:after {
  content: "\10f638";
}

.fad.fa-traffic-light-slow:after {
  content: "\10f639";
}

.fad.fa-traffic-light-stop:after {
  content: "\10f63a";
}

.fad.fa-trailer:after {
  content: "\10e041";
}

.fad.fa-train:after {
  content: "\10f238";
}

.fad.fa-tram:after {
  content: "\10f7da";
}

.fad.fa-transgender:after {
  content: "\10f224";
}

.fad.fa-transgender-alt:after {
  content: "\10f225";
}

.fad.fa-transporter:after {
  content: "\10e042";
}

.fad.fa-transporter-1:after {
  content: "\10e043";
}

.fad.fa-transporter-2:after {
  content: "\10e044";
}

.fad.fa-transporter-3:after {
  content: "\10e045";
}

.fad.fa-transporter-empty:after {
  content: "\10e046";
}

.fad.fa-trash:after {
  content: "\10f1f8";
}

.fad.fa-trash-alt:after {
  content: "\10f2ed";
}

.fad.fa-trash-restore:after {
  content: "\10f829";
}

.fad.fa-trash-restore-alt:after {
  content: "\10f82a";
}

.fad.fa-trash-undo:after {
  content: "\10f895";
}

.fad.fa-trash-undo-alt:after {
  content: "\10f896";
}

.fad.fa-treasure-chest:after {
  content: "\10f723";
}

.fad.fa-tree:after {
  content: "\10f1bb";
}

.fad.fa-tree-alt:after {
  content: "\10f400";
}

.fad.fa-tree-christmas:after {
  content: "\10f7db";
}

.fad.fa-tree-decorated:after {
  content: "\10f7dc";
}

.fad.fa-tree-large:after {
  content: "\10f7dd";
}

.fad.fa-tree-palm:after {
  content: "\10f82b";
}

.fad.fa-trees:after {
  content: "\10f724";
}

.fad.fa-triangle:after {
  content: "\10f2ec";
}

.fad.fa-triangle-music:after {
  content: "\10f8e2";
}

.fad.fa-trophy:after {
  content: "\10f091";
}

.fad.fa-trophy-alt:after {
  content: "\10f2eb";
}

.fad.fa-truck:after {
  content: "\10f0d1";
}

.fad.fa-truck-container:after {
  content: "\10f4dc";
}

.fad.fa-truck-couch:after {
  content: "\10f4dd";
}

.fad.fa-truck-loading:after {
  content: "\10f4de";
}

.fad.fa-truck-monster:after {
  content: "\10f63b";
}

.fad.fa-truck-moving:after {
  content: "\10f4df";
}

.fad.fa-truck-pickup:after {
  content: "\10f63c";
}

.fad.fa-truck-plow:after {
  content: "\10f7de";
}

.fad.fa-truck-ramp:after {
  content: "\10f4e0";
}

.fad.fa-trumpet:after {
  content: "\10f8e3";
}

.fad.fa-tshirt:after {
  content: "\10f553";
}

.fad.fa-tty:after {
  content: "\10f1e4";
}

.fad.fa-turkey:after {
  content: "\10f725";
}

.fad.fa-turntable:after {
  content: "\10f8e4";
}

.fad.fa-turtle:after {
  content: "\10f726";
}

.fad.fa-tv:after {
  content: "\10f26c";
}

.fad.fa-tv-alt:after {
  content: "\10f8e5";
}

.fad.fa-tv-music:after {
  content: "\10f8e6";
}

.fad.fa-tv-retro:after {
  content: "\10f401";
}

.fad.fa-typewriter:after {
  content: "\10f8e7";
}

.fad.fa-ufo:after {
  content: "\10e047";
}

.fad.fa-ufo-beam:after {
  content: "\10e048";
}

.fad.fa-umbrella:after {
  content: "\10f0e9";
}

.fad.fa-umbrella-beach:after {
  content: "\10f5ca";
}

.fad.fa-underline:after {
  content: "\10f0cd";
}

.fad.fa-undo:after {
  content: "\10f0e2";
}

.fad.fa-undo-alt:after {
  content: "\10f2ea";
}

.fad.fa-unicorn:after {
  content: "\10f727";
}

.fad.fa-union:after {
  content: "\10f6a2";
}

.fad.fa-universal-access:after {
  content: "\10f29a";
}

.fad.fa-university:after {
  content: "\10f19c";
}

.fad.fa-unlink:after {
  content: "\10f127";
}

.fad.fa-unlock:after {
  content: "\10f09c";
}

.fad.fa-unlock-alt:after {
  content: "\10f13e";
}

.fad.fa-upload:after {
  content: "\10f093";
}

.fad.fa-usb-drive:after {
  content: "\10f8e9";
}

.fad.fa-usd-circle:after {
  content: "\10f2e8";
}

.fad.fa-usd-square:after {
  content: "\10f2e9";
}

.fad.fa-user:after {
  content: "\10f007";
}

.fad.fa-user-alien:after {
  content: "\10e04a";
}

.fad.fa-user-alt:after {
  content: "\10f406";
}

.fad.fa-user-alt-slash:after {
  content: "\10f4fa";
}

.fad.fa-user-astronaut:after {
  content: "\10f4fb";
}

.fad.fa-user-chart:after {
  content: "\10f6a3";
}

.fad.fa-user-check:after {
  content: "\10f4fc";
}

.fad.fa-user-circle:after {
  content: "\10f2bd";
}

.fad.fa-user-clock:after {
  content: "\10f4fd";
}

.fad.fa-user-cog:after {
  content: "\10f4fe";
}

.fad.fa-user-cowboy:after {
  content: "\10f8ea";
}

.fad.fa-user-crown:after {
  content: "\10f6a4";
}

.fad.fa-user-edit:after {
  content: "\10f4ff";
}

.fad.fa-user-friends:after {
  content: "\10f500";
}

.fad.fa-user-graduate:after {
  content: "\10f501";
}

.fad.fa-user-hard-hat:after {
  content: "\10f82c";
}

.fad.fa-user-headset:after {
  content: "\10f82d";
}

.fad.fa-user-injured:after {
  content: "\10f728";
}

.fad.fa-user-lock:after {
  content: "\10f502";
}

.fad.fa-user-md:after {
  content: "\10f0f0";
}

.fad.fa-user-md-chat:after {
  content: "\10f82e";
}

.fad.fa-user-minus:after {
  content: "\10f503";
}

.fad.fa-user-music:after {
  content: "\10f8eb";
}

.fad.fa-user-ninja:after {
  content: "\10f504";
}

.fad.fa-user-nurse:after {
  content: "\10f82f";
}

.fad.fa-user-plus:after {
  content: "\10f234";
}

.fad.fa-user-robot:after {
  content: "\10e04b";
}

.fad.fa-user-secret:after {
  content: "\10f21b";
}

.fad.fa-user-shield:after {
  content: "\10f505";
}

.fad.fa-user-slash:after {
  content: "\10f506";
}

.fad.fa-user-tag:after {
  content: "\10f507";
}

.fad.fa-user-tie:after {
  content: "\10f508";
}

.fad.fa-user-times:after {
  content: "\10f235";
}

.fad.fa-user-unlock:after {
  content: "\10e058";
}

.fad.fa-user-visor:after {
  content: "\10e04c";
}

.fad.fa-users:after {
  content: "\10f0c0";
}

.fad.fa-users-class:after {
  content: "\10f63d";
}

.fad.fa-users-cog:after {
  content: "\10f509";
}

.fad.fa-users-crown:after {
  content: "\10f6a5";
}

.fad.fa-users-medical:after {
  content: "\10f830";
}

.fad.fa-users-slash:after {
  content: "\10e073";
}

.fad.fa-utensil-fork:after {
  content: "\10f2e3";
}

.fad.fa-utensil-knife:after {
  content: "\10f2e4";
}

.fad.fa-utensil-spoon:after {
  content: "\10f2e5";
}

.fad.fa-utensils:after {
  content: "\10f2e7";
}

.fad.fa-utensils-alt:after {
  content: "\10f2e6";
}

.fad.fa-vacuum:after {
  content: "\10e04d";
}

.fad.fa-vacuum-robot:after {
  content: "\10e04e";
}

.fad.fa-value-absolute:after {
  content: "\10f6a6";
}

.fad.fa-vector-square:after {
  content: "\10f5cb";
}

.fad.fa-venus:after {
  content: "\10f221";
}

.fad.fa-venus-double:after {
  content: "\10f226";
}

.fad.fa-venus-mars:after {
  content: "\10f228";
}

.fad.fa-vest:after {
  content: "\10e085";
}

.fad.fa-vest-patches:after {
  content: "\10e086";
}

.fad.fa-vhs:after {
  content: "\10f8ec";
}

.fad.fa-vial:after {
  content: "\10f492";
}

.fad.fa-vials:after {
  content: "\10f493";
}

.fad.fa-video:after {
  content: "\10f03d";
}

.fad.fa-video-plus:after {
  content: "\10f4e1";
}

.fad.fa-video-slash:after {
  content: "\10f4e2";
}

.fad.fa-vihara:after {
  content: "\10f6a7";
}

.fad.fa-violin:after {
  content: "\10f8ed";
}

.fad.fa-virus:after {
  content: "\10e074";
}

.fad.fa-virus-slash:after {
  content: "\10e075";
}

.fad.fa-viruses:after {
  content: "\10e076";
}

.fad.fa-voicemail:after {
  content: "\10f897";
}

.fad.fa-volcano:after {
  content: "\10f770";
}

.fad.fa-volleyball-ball:after {
  content: "\10f45f";
}

.fad.fa-volume:after {
  content: "\10f6a8";
}

.fad.fa-volume-down:after {
  content: "\10f027";
}

.fad.fa-volume-mute:after {
  content: "\10f6a9";
}

.fad.fa-volume-off:after {
  content: "\10f026";
}

.fad.fa-volume-slash:after {
  content: "\10f2e2";
}

.fad.fa-volume-up:after {
  content: "\10f028";
}

.fad.fa-vote-nay:after {
  content: "\10f771";
}

.fad.fa-vote-yea:after {
  content: "\10f772";
}

.fad.fa-vr-cardboard:after {
  content: "\10f729";
}

.fad.fa-wagon-covered:after {
  content: "\10f8ee";
}

.fad.fa-walker:after {
  content: "\10f831";
}

.fad.fa-walkie-talkie:after {
  content: "\10f8ef";
}

.fad.fa-walking:after {
  content: "\10f554";
}

.fad.fa-wallet:after {
  content: "\10f555";
}

.fad.fa-wand:after {
  content: "\10f72a";
}

.fad.fa-wand-magic:after {
  content: "\10f72b";
}

.fad.fa-warehouse:after {
  content: "\10f494";
}

.fad.fa-warehouse-alt:after {
  content: "\10f495";
}

.fad.fa-washer:after {
  content: "\10f898";
}

.fad.fa-watch:after {
  content: "\10f2e1";
}

.fad.fa-watch-calculator:after {
  content: "\10f8f0";
}

.fad.fa-watch-fitness:after {
  content: "\10f63e";
}

.fad.fa-water:after {
  content: "\10f773";
}

.fad.fa-water-lower:after {
  content: "\10f774";
}

.fad.fa-water-rise:after {
  content: "\10f775";
}

.fad.fa-wave-sine:after {
  content: "\10f899";
}

.fad.fa-wave-square:after {
  content: "\10f83e";
}

.fad.fa-wave-triangle:after {
  content: "\10f89a";
}

.fad.fa-waveform:after {
  content: "\10f8f1";
}

.fad.fa-waveform-path:after {
  content: "\10f8f2";
}

.fad.fa-webcam:after {
  content: "\10f832";
}

.fad.fa-webcam-slash:after {
  content: "\10f833";
}

.fad.fa-weight:after {
  content: "\10f496";
}

.fad.fa-weight-hanging:after {
  content: "\10f5cd";
}

.fad.fa-whale:after {
  content: "\10f72c";
}

.fad.fa-wheat:after {
  content: "\10f72d";
}

.fad.fa-wheelchair:after {
  content: "\10f193";
}

.fad.fa-whistle:after {
  content: "\10f460";
}

.fad.fa-wifi:after {
  content: "\10f1eb";
}

.fad.fa-wifi-1:after {
  content: "\10f6aa";
}

.fad.fa-wifi-2:after {
  content: "\10f6ab";
}

.fad.fa-wifi-slash:after {
  content: "\10f6ac";
}

.fad.fa-wind:after {
  content: "\10f72e";
}

.fad.fa-wind-turbine:after {
  content: "\10f89b";
}

.fad.fa-wind-warning:after {
  content: "\10f776";
}

.fad.fa-window:after {
  content: "\10f40e";
}

.fad.fa-window-alt:after {
  content: "\10f40f";
}

.fad.fa-window-close:after {
  content: "\10f410";
}

.fad.fa-window-frame:after {
  content: "\10e04f";
}

.fad.fa-window-frame-open:after {
  content: "\10e050";
}

.fad.fa-window-maximize:after {
  content: "\10f2d0";
}

.fad.fa-window-minimize:after {
  content: "\10f2d1";
}

.fad.fa-window-restore:after {
  content: "\10f2d2";
}

.fad.fa-windsock:after {
  content: "\10f777";
}

.fad.fa-wine-bottle:after {
  content: "\10f72f";
}

.fad.fa-wine-glass:after {
  content: "\10f4e3";
}

.fad.fa-wine-glass-alt:after {
  content: "\10f5ce";
}

.fad.fa-won-sign:after {
  content: "\10f159";
}

.fad.fa-wreath:after {
  content: "\10f7e2";
}

.fad.fa-wrench:after {
  content: "\10f0ad";
}

.fad.fa-x-ray:after {
  content: "\10f497";
}

.fad.fa-yen-sign:after {
  content: "\10f157";
}

.fad.fa-yin-yang:after {
  content: "\10f6ad";
}

@font-face {
  font-family: "simple-line-icons";
  src: url("../fonts/simple-line-icons/Simple-Line-Icons.eot?v=2.4.0");
  src: url("../fonts/simple-line-icons/Simple-Line-Icons.eot?v=2.4.0#iefix") format("embedded-opentype"), url("../fonts/simple-line-icons/Simple-Line-Icons.woff2?v=2.4.0") format("woff2"), url("../fonts/simple-line-icons/Simple-Line-Icons.ttf?v=2.4.0") format("truetype"), url("../fonts/simple-line-icons/Simple-Line-Icons.woff?v=2.4.0") format("woff"), url("../fonts/simple-line-icons/Simple-Line-Icons.svg?v=2.4.0#simple-line-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
.si {
  font-family: "simple-line-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.si-user:before {
  content: "\e005";
}

.si-people:before {
  content: "\e001";
}

.si-user-female:before {
  content: "\e000";
}

.si-user-follow:before {
  content: "\e002";
}

.si-user-following:before {
  content: "\e003";
}

.si-user-unfollow:before {
  content: "\e004";
}

.si-login:before {
  content: "\e066";
}

.si-logout:before {
  content: "\e065";
}

.si-emotsmile:before {
  content: "\e021";
}

.si-phone:before {
  content: "\e600";
}

.si-call-end:before {
  content: "\e048";
}

.si-call-in:before {
  content: "\e047";
}

.si-call-out:before {
  content: "\e046";
}

.si-map:before {
  content: "\e033";
}

.si-location-pin:before {
  content: "\e096";
}

.si-direction:before {
  content: "\e042";
}

.si-directions:before {
  content: "\e041";
}

.si-compass:before {
  content: "\e045";
}

.si-layers:before {
  content: "\e034";
}

.si-menu:before {
  content: "\e601";
}

.si-list:before {
  content: "\e067";
}

.si-options-vertical:before {
  content: "\e602";
}

.si-options:before {
  content: "\e603";
}

.si-arrow-down:before {
  content: "\e604";
}

.si-arrow-left:before {
  content: "\e605";
}

.si-arrow-right:before {
  content: "\e606";
}

.si-arrow-up:before {
  content: "\e607";
}

.si-arrow-up-circle:before {
  content: "\e078";
}

.si-arrow-left-circle:before {
  content: "\e07a";
}

.si-arrow-right-circle:before {
  content: "\e079";
}

.si-arrow-down-circle:before {
  content: "\e07b";
}

.si-check:before {
  content: "\e080";
}

.si-clock:before {
  content: "\e081";
}

.si-plus:before {
  content: "\e095";
}

.si-minus:before {
  content: "\e615";
}

.si-close:before {
  content: "\e082";
}

.si-event:before {
  content: "\e619";
}

.si-exclamation:before {
  content: "\e617";
}

.si-organization:before {
  content: "\e616";
}

.si-trophy:before {
  content: "\e006";
}

.si-screen-smartphone:before {
  content: "\e010";
}

.si-screen-desktop:before {
  content: "\e011";
}

.si-plane:before {
  content: "\e012";
}

.si-notebook:before {
  content: "\e013";
}

.si-mustache:before {
  content: "\e014";
}

.si-mouse:before {
  content: "\e015";
}

.si-magnet:before {
  content: "\e016";
}

.si-energy:before {
  content: "\e020";
}

.si-disc:before {
  content: "\e022";
}

.si-cursor:before {
  content: "\e06e";
}

.si-cursor-move:before {
  content: "\e023";
}

.si-crop:before {
  content: "\e024";
}

.si-chemistry:before {
  content: "\e026";
}

.si-speedometer:before {
  content: "\e007";
}

.si-shield:before {
  content: "\e00e";
}

.si-screen-tablet:before {
  content: "\e00f";
}

.si-magic-wand:before {
  content: "\e017";
}

.si-hourglass:before {
  content: "\e018";
}

.si-graduation:before {
  content: "\e019";
}

.si-ghost:before {
  content: "\e01a";
}

.si-game-controller:before {
  content: "\e01b";
}

.si-fire:before {
  content: "\e01c";
}

.si-eyeglass:before {
  content: "\e01d";
}

.si-envelope-open:before {
  content: "\e01e";
}

.si-envelope-letter:before {
  content: "\e01f";
}

.si-bell:before {
  content: "\e027";
}

.si-badge:before {
  content: "\e028";
}

.si-anchor:before {
  content: "\e029";
}

.si-wallet:before {
  content: "\e02a";
}

.si-vector:before {
  content: "\e02b";
}

.si-speech:before {
  content: "\e02c";
}

.si-puzzle:before {
  content: "\e02d";
}

.si-printer:before {
  content: "\e02e";
}

.si-present:before {
  content: "\e02f";
}

.si-playlist:before {
  content: "\e030";
}

.si-pin:before {
  content: "\e031";
}

.si-picture:before {
  content: "\e032";
}

.si-handbag:before {
  content: "\e035";
}

.si-globe-alt:before {
  content: "\e036";
}

.si-globe:before {
  content: "\e037";
}

.si-folder-alt:before {
  content: "\e039";
}

.si-folder:before {
  content: "\e089";
}

.si-film:before {
  content: "\e03a";
}

.si-feed:before {
  content: "\e03b";
}

.si-drop:before {
  content: "\e03e";
}

.si-drawer:before {
  content: "\e03f";
}

.si-docs:before {
  content: "\e040";
}

.si-doc:before {
  content: "\e085";
}

.si-diamond:before {
  content: "\e043";
}

.si-cup:before {
  content: "\e044";
}

.si-calculator:before {
  content: "\e049";
}

.si-bubbles:before {
  content: "\e04a";
}

.si-briefcase:before {
  content: "\e04b";
}

.si-book-open:before {
  content: "\e04c";
}

.si-basket-loaded:before {
  content: "\e04d";
}

.si-basket:before {
  content: "\e04e";
}

.si-bag:before {
  content: "\e04f";
}

.si-action-undo:before {
  content: "\e050";
}

.si-action-redo:before {
  content: "\e051";
}

.si-wrench:before {
  content: "\e052";
}

.si-umbrella:before {
  content: "\e053";
}

.si-trash:before {
  content: "\e054";
}

.si-tag:before {
  content: "\e055";
}

.si-support:before {
  content: "\e056";
}

.si-frame:before {
  content: "\e038";
}

.si-size-fullscreen:before {
  content: "\e057";
}

.si-size-actual:before {
  content: "\e058";
}

.si-shuffle:before {
  content: "\e059";
}

.si-share-alt:before {
  content: "\e05a";
}

.si-share:before {
  content: "\e05b";
}

.si-rocket:before {
  content: "\e05c";
}

.si-question:before {
  content: "\e05d";
}

.si-pie-chart:before {
  content: "\e05e";
}

.si-pencil:before {
  content: "\e05f";
}

.si-note:before {
  content: "\e060";
}

.si-loop:before {
  content: "\e064";
}

.si-home:before {
  content: "\e069";
}

.si-grid:before {
  content: "\e06a";
}

.si-graph:before {
  content: "\e06b";
}

.si-microphone:before {
  content: "\e063";
}

.si-music-tone-alt:before {
  content: "\e061";
}

.si-music-tone:before {
  content: "\e062";
}

.si-earphones-alt:before {
  content: "\e03c";
}

.si-earphones:before {
  content: "\e03d";
}

.si-equalizer:before {
  content: "\e06c";
}

.si-like:before {
  content: "\e068";
}

.si-dislike:before {
  content: "\e06d";
}

.si-control-start:before {
  content: "\e06f";
}

.si-control-rewind:before {
  content: "\e070";
}

.si-control-play:before {
  content: "\e071";
}

.si-control-pause:before {
  content: "\e072";
}

.si-control-forward:before {
  content: "\e073";
}

.si-control-end:before {
  content: "\e074";
}

.si-volume-1:before {
  content: "\e09f";
}

.si-volume-2:before {
  content: "\e0a0";
}

.si-volume-off:before {
  content: "\e0a1";
}

.si-calendar:before {
  content: "\e075";
}

.si-bulb:before {
  content: "\e076";
}

.si-chart:before {
  content: "\e077";
}

.si-ban:before {
  content: "\e07c";
}

.si-bubble:before {
  content: "\e07d";
}

.si-camrecorder:before {
  content: "\e07e";
}

.si-camera:before {
  content: "\e07f";
}

.si-cloud-download:before {
  content: "\e083";
}

.si-cloud-upload:before {
  content: "\e084";
}

.si-envelope:before {
  content: "\e086";
}

.si-eye:before {
  content: "\e087";
}

.si-flag:before {
  content: "\e088";
}

.si-heart:before {
  content: "\e08a";
}

.si-info:before {
  content: "\e08b";
}

.si-key:before {
  content: "\e08c";
}

.si-link:before {
  content: "\e08d";
}

.si-lock:before {
  content: "\e08e";
}

.si-lock-open:before {
  content: "\e08f";
}

.si-magnifier:before {
  content: "\e090";
}

.si-magnifier-add:before {
  content: "\e091";
}

.si-magnifier-remove:before {
  content: "\e092";
}

.si-paper-clip:before {
  content: "\e093";
}

.si-paper-plane:before {
  content: "\e094";
}

.si-power:before {
  content: "\e097";
}

.si-refresh:before {
  content: "\e098";
}

.si-reload:before {
  content: "\e099";
}

.si-settings:before {
  content: "\e09a";
}

.si-star:before {
  content: "\e09b";
}

.si-symbol-female:before {
  content: "\e09c";
}

.si-symbol-male:before {
  content: "\e09d";
}

.si-target:before {
  content: "\e09e";
}

.si-credit-card:before {
  content: "\e025";
}

.si-paypal:before {
  content: "\e608";
}

.si-social-tumblr:before {
  content: "\e00a";
}

.si-social-twitter:before {
  content: "\e009";
}

.si-social-facebook:before {
  content: "\e00b";
}

.si-social-instagram:before {
  content: "\e609";
}

.si-social-linkedin:before {
  content: "\e60a";
}

.si-social-pinterest:before {
  content: "\e60b";
}

.si-social-github:before {
  content: "\e60c";
}

.si-social-google:before {
  content: "\e60d";
}

.si-social-reddit:before {
  content: "\e60e";
}

.si-social-skype:before {
  content: "\e60f";
}

.si-social-dribbble:before {
  content: "\e00d";
}

.si-social-behance:before {
  content: "\e610";
}

.si-social-foursqare:before {
  content: "\e611";
}

.si-social-soundcloud:before {
  content: "\e612";
}

.si-social-spotify:before {
  content: "\e613";
}

.si-social-stumbleupon:before {
  content: "\e614";
}

.si-social-youtube:before {
  content: "\e008";
}

.si-social-dropbox:before {
  content: "\e00c";
}

.si-social-vkontakte:before {
  content: "\e618";
}

.si-social-steam:before {
  content: "\e620";
}

.si-users:before {
  content: "\e001";
}

.si-bar-chart:before {
  content: "\e077";
}

.si-camcorder:before {
  content: "\e07e";
}

.si-emoticon-smile:before {
  content: "\e021";
}

.si-eyeglasses:before {
  content: "\e01d";
}

.si-moustache:before {
  content: "\e014";
}

.si-pointer:before {
  content: "\e096";
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s 0.5s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  transition-delay: 0s;
  transition-duration: 0s;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.simplebar-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: scrollbar !important;
}

.simplebar-dummy-scrollbar-size > div {
  width: 200%;
  height: 200%;
  margin: 10px 0;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-scrollbar {
  left: auto;
  right: 2px;
  width: 4px;
}

.simplebar-scrollbar::before {
  left: 0;
  right: 0;
  background: #1F2937;
}
.sidebar-dark #sidebar .simplebar-scrollbar::before {
  background: #fff;
}

.datepicker {
  z-index: 1056 !important;
  padding: 1rem;
}

.datepicker table thead th,
.datepicker table tbody td {
  padding: 0.375rem;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #faeab9;
  border-color: #faeab9;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #2356d7;
  border-color: #2356d7;
}

.fc.fc-theme-standard a {
  color: #212529;
}
.fc.fc-theme-standard .fc-button-primary {
  color: #212529;
  background-color: #dde2e9;
  border-color: #dde2e9;
}
.fc.fc-theme-standard .fc-button-primary:not(:disabled):hover {
  color: #212529;
  background-color: #ebeef2;
  border-color: #ebeef2;
}
.fc.fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active, .fc.fc-theme-standard .fc-button-primary:not(:disabled):active {
  color: #212529;
  background-color: #f6f7f9;
  border-color: #f6f7f9;
}
.fc.fc-theme-standard .fc-button-primary:focus, .fc.fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active:focus, .fc.fc-theme-standard .fc-button-primary:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 86, 215, 0.4);
}
.fc.fc-theme-standard th,
.fc.fc-theme-standard td,
.fc.fc-theme-standard .fc-scrollgrid,
.fc.fc-theme-standard .fc-list {
  border-color: #dde2e9;
}
.fc.fc-theme-standard .fc-h-event {
  background-color: #2356d7;
  border: #2356d7;
}
.fc.fc-theme-standard .fc-col-header-cell {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 0.676375rem;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #f6f7f9;
}
.fc.fc-theme-standard .fc-list-day-cushion {
  background-color: #f6f7f9;
}
@media (max-width: 1199.98px) {
  .fc.fc-theme-standard .fc-toolbar.fc-header-toolbar {
    display: block;
  }
  .fc.fc-theme-standard .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk > div,
.fc.fc-theme-standard .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk > .btn {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .fc.fc-theme-standard .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:not(:first-child) {
    margin-top: 1rem;
  }
}

.flatpickr-input.form-control:disabled,
.flatpickr-input.form-control[readonly],
.input.form-control:disabled,
.input.form-control[readonly] {
  color: #212529;
  background-color: #fff;
  border-color: #dfe3ea;
}

.flatpickr-weekdays {
  height: 2rem;
  align-items: flex-end;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  border-color: #2356d7;
  background: #2356d7;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #2356d7;
}

#page-container.rtl-support {
  direction: rtl;
  text-align: right;
}
#page-container.rtl-support .breadcrumb.breadcrumb-alt .breadcrumb-item + .breadcrumb-item::before {
  content: "\e605";
}
#page-container.rtl-support .breadcrumb-item + .breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: 0;
}
#page-container.rtl-support .breadcrumb-item + .breadcrumb-item::before {
  float: right;
  padding-right: 0;
  padding-left: 0.5rem;
}
@media (min-width: 992px) {
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar .content-side,
#page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar .content-header {
    transform: translateX(-190px) translateY(0) translateZ(0);
  }
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar:hover .content-side,
#page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar:hover .content-header {
    transform: translateX(0);
  }
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar:not(:hover) .nav-main {
    transform: translateX(1.25rem);
  }
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar:not(:hover) .nav-main .nav-main-link-icon {
    transform: translateX(-1rem);
  }
}
#page-container.rtl-support .nav-main {
  padding-left: auto;
  padding-right: 0;
}
#page-container.rtl-support .nav-main-link .nav-main-link-icon {
  margin-right: 0;
  margin-left: 0.625rem;
}
#page-container.rtl-support .nav-main-link .nav-main-link-badge {
  margin-right: 0.625rem;
  margin-left: auto;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  font-size: 0.75rem;
}
#page-container.rtl-support .nav-main-link.nav-main-link-submenu {
  padding-right: 1.25rem;
  padding-left: 2rem;
}
#page-container.rtl-support .nav-main-link.nav-main-link-submenu::before, #page-container.rtl-support .nav-main-link.nav-main-link-submenu::after {
  right: auto;
  left: 0.625rem;
}
#page-container.rtl-support .nav-main-link.nav-main-link-submenu::before {
  content: "\f105";
}
#page-container.rtl-support .nav-main-submenu {
  padding-right: 2.25rem;
  padding-left: 0;
}
#page-container.rtl-support .nav-main-submenu .nav-main-link {
  padding-right: 0;
  padding-left: 0.625rem;
}
#page-container.rtl-support .nav-main-submenu .nav-main-link.nav-main-link-submenu {
  padding-left: 2rem;
}
#page-container.rtl-support .nav-main-submenu .nav-main-submenu {
  padding-right: 0.75rem;
  padding-left: 0;
}
#page-container.rtl-support .nav-main-item.open > .nav-main-link-submenu::before {
  transform: rotate(90deg);
}
@media (min-width: 992px) {
  #page-container.rtl-support .nav-main-horizontal .nav-main-link-submenu::before {
    content: "\f107";
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu {
    left: auto;
    right: 0;
    padding-left: auto;
    padding-right: 0;
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu.nav-main-submenu-left {
    right: auto;
    left: 0;
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu .nav-main-link {
    padding-left: 0.625rem;
    padding-right: 1rem;
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu .nav-main-link.nav-main-link-submenu::before {
    content: "\f104";
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu .nav-main-item.open > .nav-main-link-submenu::before {
    transform: scaleX(-1);
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-item.open > .nav-main-link-submenu::before {
    transform: scaleY(-1);
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu .nav-main-submenu {
    left: -100%;
    right: auto;
  }
  #page-container.rtl-support .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu::before {
    transform: scaleY(-1);
  }
  #page-container.rtl-support .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover > .nav-main-link-submenu::before {
    transform: scaleX(-1);
  }
}

#page-container.dark-mode {
  background-color: #19222d;
}

.dark-mode,
.dark-mode #side-overlay,
.dark-mode #page-loader {
  background-color: #19222d;
  color: #bec8d5;
}
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6,
.dark-mode .h1,
.dark-mode .h2,
.dark-mode .h3,
.dark-mode .h4,
.dark-mode .h5,
.dark-mode .h6 {
  color: #dde2e9;
}
.dark-mode .content-heading,
.dark-mode .content-heading small,
.dark-mode .content-heading .small {
  color: #99a8bd;
}
.dark-mode hr {
  border-top-color: #2a384b;
}
.dark-mode code {
  color: #e685b5;
}
.dark-mode .story p,
.dark-mode p.story {
  color: #9faec1;
}
.dark-mode a:not(.mini-nav-item):not(.badge):not(.btn):not(.btn-block-option):not(.block):not(.dropdown-item):not(.nav-link):not(.page-link):not(.alert-link):not(.nav-main-link):not(.list-group-item-action):not(.close):not(.fc-event):not(.text-success-light):not(.text-danger-light):not(.text-warning-light):not(.text-info-light) {
  color: #3565de;
}
.dark-mode a:not(.mini-nav-item):not(.badge):not(.btn):not(.btn-block-option):not(.block):not(.dropdown-item):not(.nav-link):not(.page-link):not(.alert-link):not(.nav-main-link):not(.list-group-item-action):not(.close):not(.fc-event):not(.text-success-light):not(.text-danger-light):not(.text-warning-light):not(.text-info-light).link-fx::before {
  background-color: #3565de;
}
.dark-mode a:not(.mini-nav-item):not(.badge):not(.btn):not(.btn-block-option):not(.block):not(.dropdown-item):not(.nav-link):not(.page-link):not(.alert-link):not(.nav-main-link):not(.list-group-item-action):not(.close):not(.fc-event):not(.text-success-light):not(.text-danger-light):not(.text-warning-light):not(.text-info-light):hover {
  color: #2356d7;
}
.dark-mode .bg-body {
  background-color: #19222d !important;
}
.dark-mode a.bg-body:hover, .dark-mode a.bg-body:focus,
.dark-mode button.bg-body:hover,
.dark-mode button.bg-body:focus {
  background-color: #07090d !important;
}
.dark-mode .bg-body-light {
  background-color: #253041 !important;
}
.dark-mode a.bg-body-light:hover, .dark-mode a.bg-body-light:focus,
.dark-mode button.bg-body-light:hover,
.dark-mode button.bg-body-light:focus {
  background-color: #121820 !important;
}
.dark-mode .bg-body-dark {
  background-color: #171e28 !important;
}
.dark-mode a.bg-body-dark:hover, .dark-mode a.bg-body-dark:focus,
.dark-mode button.bg-body-dark:hover,
.dark-mode button.bg-body-dark:focus {
  background-color: #040608 !important;
}
.dark-mode .bg-body-extra-light {
  background-color: #1F2937 !important;
}
.dark-mode a.bg-body-extra-light:hover, .dark-mode a.bg-body-extra-light:focus,
.dark-mode button.bg-body-extra-light:hover,
.dark-mode button.bg-body-extra-light:focus {
  background-color: #0d1116 !important;
}
.dark-mode .bg-muted {
  background-color: #99a8bd !important;
}
.dark-mode a.bg-muted:hover, .dark-mode a.bg-muted:focus,
.dark-mode button.bg-muted:hover,
.dark-mode button.bg-muted:focus {
  background-color: #7a8ea9 !important;
}
.dark-mode .text-primary {
  color: #3565de !important;
}
.dark-mode a.text-primary.link-fx::before {
  background-color: #3565de !important;
}
.dark-mode a.text-primary:hover, .dark-mode a.text-primary:focus {
  color: #1f4dc1 !important;
}
.dark-mode .text-success {
  color: #16b15e !important;
}
.dark-mode a.text-success.link-fx::before {
  background-color: #16b15e !important;
}
.dark-mode a.text-success:hover, .dark-mode a.text-success:focus {
  color: #108346 !important;
}
.dark-mode .text-warning {
  color: #f3661c !important;
}
.dark-mode a.text-warning.link-fx::before {
  background-color: #f3661c !important;
}
.dark-mode a.text-warning:hover, .dark-mode a.text-warning:focus {
  color: #d24f0b !important;
}
.dark-mode .text-info {
  color: #16a5c1 !important;
}
.dark-mode a.text-info.link-fx::before {
  background-color: #16a5c1 !important;
}
.dark-mode a.text-info:hover, .dark-mode a.text-info:focus {
  color: #107d93 !important;
}
.dark-mode .text-danger {
  color: #e12e55 !important;
}
.dark-mode a.text-danger.link-fx::before {
  background-color: #e12e55 !important;
}
.dark-mode a.text-danger:hover, .dark-mode a.text-danger:focus {
  color: #c01c40 !important;
}
.dark-mode .text-body-bg {
  color: #19222d !important;
}
.dark-mode a.text-body-bg.link-fx::before {
  background-color: #19222d !important;
}
.dark-mode a.text-body-bg:hover, .dark-mode a.text-body-bg:focus {
  color: #07090d !important;
}
.dark-mode .text-body-bg-dark {
  color: #141a23 !important;
}
.dark-mode a.text-body-bg-dark.link-fx::before {
  background-color: #141a23 !important;
}
.dark-mode a.text-body-bg-dark:hover, .dark-mode a.text-body-bg-dark:focus {
  color: #020203 !important;
}
.dark-mode .text-body-bg-light {
  color: #253041 !important;
}
.dark-mode a.text-body-bg-light.link-fx::before {
  background-color: #253041 !important;
}
.dark-mode a.text-body-bg-light:hover, .dark-mode a.text-body-bg-light:focus {
  color: #121820 !important;
}
.dark-mode .text-body-color {
  color: #bec8d5 !important;
}
.dark-mode a.text-body-color.link-fx::before {
  background-color: #bec8d5 !important;
}
.dark-mode a.text-body-color:hover, .dark-mode a.text-body-color:focus {
  color: #9faec1 !important;
}
.dark-mode .text-body-color-dark {
  color: #9faec1 !important;
}
.dark-mode a.text-body-color-dark.link-fx::before {
  background-color: #9faec1 !important;
}
.dark-mode a.text-body-color-dark:hover, .dark-mode a.text-body-color-dark:focus {
  color: #8093ad !important;
}
.dark-mode .text-body-color-light {
  color: #dde2e9 !important;
}
.dark-mode a.text-body-color-light.link-fx::before {
  background-color: #dde2e9 !important;
}
.dark-mode a.text-body-color-light:hover, .dark-mode a.text-body-color-light:focus {
  color: #bec8d5 !important;
}
.dark-mode .text-dark {
  color: #9faec1 !important;
}
.dark-mode a.text-dark.link-fx::before {
  background-color: #9faec1 !important;
}
.dark-mode a.text-dark:hover, .dark-mode a.text-dark:focus {
  color: #8093ad !important;
}
.dark-mode .text-muted {
  color: #99a8bd !important;
}
.dark-mode a.text-muted.link-fx::before {
  background-color: #99a8bd !important;
}
.dark-mode a.text-muted:hover, .dark-mode a.text-muted:focus {
  color: #7a8ea9 !important;
}
.dark-mode .btn-secondary,
.dark-mode .btn-alt-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #283547;
  --bs-btn-border-color: #283547;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #161d27;
  --bs-btn-hover-border-color: #161d27;
  --bs-btn-focus-shadow-rgb: 72, 83, 99;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #202a39;
  --bs-btn-active-border-color: #1e2835;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #283547;
  --bs-btn-disabled-border-color: #283547;
}
.dark-mode .nav-link {
  color: #bec8d5;
}
.dark-mode .nav-link:hover, .dark-mode .nav-link:focus {
  color: #2356d7;
}
.dark-mode .nav-pills .nav-link {
  color: #8093ad;
}
.dark-mode .nav-pills .nav-link:hover, .dark-mode .nav-pills .nav-link:focus {
  background-color: #2e3c51;
}
.dark-mode .nav-pills .nav-link.active,
.dark-mode .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2356d7;
}
.dark-mode .nav-tabs {
  border-bottom-color: #2a384b;
}
.dark-mode .nav-tabs .nav-link:hover, .dark-mode .nav-tabs .nav-link:focus {
  border-color: #2a384b #2a384b #2a384b;
}
.dark-mode .nav-tabs .nav-link.active,
.dark-mode .nav-tabs .nav-item.show .nav-link {
  color: #bec8d5;
  background-color: transparent;
  border-color: #2a384b #2a384b #1F2937;
}
.dark-mode .nav-tabs-block {
  background-color: #2a384b;
}
.dark-mode .nav-tabs-block .nav-link {
  border-color: transparent;
  color: #bec8d5;
}
.dark-mode .nav-tabs-block .nav-link:hover, .dark-mode .nav-tabs-block .nav-link:focus {
  color: #2356d7;
  background-color: #253041;
  border-color: transparent;
}
.dark-mode .nav-tabs-block .nav-link.active,
.dark-mode .nav-tabs-block .nav-item.show .nav-link {
  color: #bec8d5;
  background-color: #1F2937;
  border-color: transparent;
}
.dark-mode .nav-tabs-alt {
  border-bottom-color: #253041;
}
.dark-mode .nav-tabs-alt .nav-link {
  color: #bec8d5;
  background-color: transparent;
  border-color: transparent;
}
.dark-mode .nav-tabs-alt .nav-link:hover, .dark-mode .nav-tabs-alt .nav-link:focus {
  color: #2356d7;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #2356d7;
}
.dark-mode .nav-tabs-alt .nav-link.active,
.dark-mode .nav-tabs-alt .nav-item.show .nav-link {
  color: #bec8d5;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #2356d7;
}
.dark-mode .nav-items a:hover {
  background-color: #2e3c51;
}
.dark-mode .nav-items a:active {
  background-color: transparent;
}
.dark-mode .nav-items > li:not(:last-child) > a {
  border-bottom-color: #2a384b;
}
.dark-mode .card {
  background-color: #1F2937;
}
.dark-mode .card.card-borderless {
  box-shadow: 0 1px 2px rgba(24, 31, 42, 0.5), 0 1px 2px rgba(24, 31, 42, 0.5);
}
.dark-mode .card > .card-header:not(.bg-transparent),
.dark-mode .card > .card-footer:not(.bg-transparent) {
  background-color: #2a384b;
}
.dark-mode .card > .card-header:not(.border-bottom-0),
.dark-mode .card > .card-footer:not(.border-top-0) {
  border-color: #2a384b;
}
.dark-mode .card:not(.card-borderless),
.dark-mode .card:not(.card-borderless) > .card-header {
  border-color: #2a384b;
}
.dark-mode .card > .card-header .block-title small, .dark-mode .card > .card-header .block-title .small {
  color: #99a8bd;
}
.dark-mode .page-link {
  color: #8093ad;
}
.dark-mode .page-link:hover {
  color: #9faec1;
}
.dark-mode .page-link:focus {
  color: #bec8d5;
  background-color: transparent;
}
.dark-mode .page-item.active .page-link {
  background-color: transparent;
}
.dark-mode .page-item.disabled .page-link {
  color: #4e6179;
  background-color: transparent;
  border-bottom: transparent;
}
.dark-mode .list-group-item-action {
  color: #bec8d5;
}
.dark-mode .list-group-item-action:hover, .dark-mode .list-group-item-action:focus {
  color: #bec8d5;
  background-color: #273446;
}
.dark-mode .list-group-item-action:active {
  color: #bec8d5;
  background-color: #19222d;
}
.dark-mode .list-group-item-action.disabled {
  color: #637998;
}
.dark-mode .list-group-item {
  color: #bec8d5;
  background-color: #253041;
  border-color: #171e28;
}
.dark-mode .list-group-item.active {
  color: #fff;
  background-color: #2356d7;
  border-color: #2356d7;
}
.dark-mode .popover {
  border-color: #171e28;
  background-color: #253041;
}
.dark-mode .bs-popover-top .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=top] .popover-arrow::before, .dark-mode .bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: #171e28;
}
.dark-mode .bs-popover-top .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=top] .popover-arrow::after, .dark-mode .bs-popover-auto[data-popper-placement^=top] .popover-arrow::after {
  border-top-color: #253041;
}
.dark-mode .bs-popover-end .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=right] .popover-arrow::before, .dark-mode .bs-popover-auto[data-popper-placement^=right] .popover-arrow::before {
  border-right-color: #171e28;
}
.dark-mode .bs-popover-end .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=right] .popover-arrow::after, .dark-mode .bs-popover-auto[data-popper-placement^=right] .popover-arrow::after {
  border-right-color: #253041;
}
.dark-mode .bs-popover-bottom .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=bottom] .popover-arrow::before, .dark-mode .bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: #171e28;
}
.dark-mode .bs-popover-bottom .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=bottom] .popover-arrow::after, .dark-mode .bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: #253041;
}
.dark-mode .bs-popover-start .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=left] .popover-arrow::before, .dark-mode .bs-popover-auto[data-popper-placement^=left] .popover-arrow::before {
  border-left-color: #171e28;
}
.dark-mode .bs-popover-start .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=left] .popover-arrow::after, .dark-mode .bs-popover-auto[data-popper-placement^=left] .popover-arrow::after {
  border-left-color: #253041;
}
.dark-mode .popover-header {
  color: #dde2e9;
  background-color: #253041;
  border-bottom-color: #171e28;
}
.dark-mode .popover-body {
  color: #bec8d5;
  background-color: #253041;
}
.dark-mode .dropdown-menu {
  color: #bec8d5;
  background-color: #253041;
  border-color: #253041;
  box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.25);
}
.dark-mode .dropdown-menu .dropdown-item {
  color: #afbbcb;
}
.dark-mode .dropdown-menu .dropdown-item:hover, .dark-mode .dropdown-menu .dropdown-item:focus {
  color: #bec8d5;
  background-color: #2e3c51;
}
.dark-mode .dropdown-menu .dropdown-item.active, .dark-mode .dropdown-menu .dropdown-item:active {
  color: #ced5df;
  background-color: #35465e;
}
.dark-mode .dropdown-menu .dropdown-item.disabled, .dark-mode .dropdown-menu .dropdown-item:disabled {
  color: #4e6179;
}
.dark-mode .dropdown-menu .dropdown-divider {
  border-color: #314158;
}
.dark-mode .dropdown-menu .dropdown-item-text {
  color: #bec8d5;
}
.dark-mode .dropdown-menu .dropdown-header {
  color: #90a0b7 !important;
}
.dark-mode .table {
  --bs-table-color: darken($body-bg-dark, 10%);
  --bs-table-bg: $primary-darker;
  --bs-table-striped-color: #f6f7f9;
  --bs-table-striped-bg: #1c2532;
  --bs-table-active-color: #f6f7f9;
  --bs-table-active-bg: #2a384b;
  --bs-table-hover-color: #f6f7f9;
  --bs-table-hover-bg: #1a232f;
  color: #f6f7f9;
  border-color: #141a23;
}
.dark-mode .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #141a23;
}
.dark-mode .table-primary {
  --bs-table-color: #fff;
  --bs-table-bg: rgba(28, 69, 172, 0.75);
  --bs-table-border-color: rgba(63, 98, 185, 0.775);
  --bs-table-striped-bg: rgba(46, 84, 179, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(63, 98, 185, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(55, 91, 182, 0.76875);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-mode .table-info {
  --bs-table-color: #fff;
  --bs-table-bg: rgba(30, 58, 138, 0.75);
  --bs-table-border-color: rgba(65, 89, 156, 0.775);
  --bs-table-striped-bg: rgba(48, 74, 147, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(65, 89, 156, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(57, 81, 152, 0.76875);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-mode .table-success {
  --bs-table-color: #fff;
  --bs-table-bg: rgba(20, 83, 45, 0.75);
  --bs-table-border-color: rgba(57, 110, 78, 0.775);
  --bs-table-striped-bg: rgba(39, 97, 62, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(57, 110, 78, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(48, 103, 70, 0.76875);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-mode .table-danger {
  --bs-table-color: #fff;
  --bs-table-bg: rgba(127, 29, 29, 0.75);
  --bs-table-border-color: rgba(147, 64, 64, 0.775);
  --bs-table-striped-bg: rgba(137, 47, 47, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(147, 64, 64, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(142, 56, 56, 0.76875);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-mode .table-warning {
  --bs-table-color: #fff;
  --bs-table-bg: rgba(113, 63, 18, 0.75);
  --bs-table-border-color: rgba(135, 93, 55, 0.775);
  --bs-table-striped-bg: rgba(124, 78, 37, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(135, 93, 55, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(130, 86, 46, 0.76875);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-mode .form-text {
  color: #8093ad;
}
.dark-mode .form-control::placeholder {
  color: #637998;
}
.dark-mode .form-floating > .form-control::placeholder {
  color: transparent;
}
.dark-mode .form-control,
.dark-mode .form-select {
  color: #bec8d5;
  background-color: #171e28;
  border-color: #2e3c51;
}
.dark-mode .form-control:focus,
.dark-mode .form-select:focus {
  color: #fff;
  background-color: #171e28;
  border-color: #2356d7;
}
.dark-mode .form-control:disabled,
.dark-mode .form-select:disabled {
  background-color: #232e3e;
  border-color: #232e3e;
}
.dark-mode .form-select:focus::-ms-value {
  color: #bec8d5;
  background-color: #171e28;
}
.dark-mode .form-control-plaintext {
  color: #bec8d5;
}
.dark-mode .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.676375rem;
  color: #19c76a;
}
.dark-mode .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.676375rem;
  color: #000;
  background-color: rgba(25, 199, 106, 0.9);
  border-radius: var(--bs-border-radius);
}
.was-validated .dark-mode:valid ~ .valid-feedback,
.was-validated .dark-mode:valid ~ .valid-tooltip, .dark-mode.is-valid ~ .valid-feedback,
.dark-mode.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .dark-mode .form-control:valid, .dark-mode .form-control.is-valid {
  border-color: #19c76a;
}
.was-validated .dark-mode .form-control:valid:focus, .dark-mode .form-control.is-valid:focus {
  border-color: #19c76a;
  box-shadow: 0 0 0 0.25rem rgba(25, 199, 106, 0.25);
}
.was-validated .dark-mode .form-select:valid, .dark-mode .form-select.is-valid {
  border-color: #19c76a;
}
.was-validated .dark-mode .form-select:valid:focus, .dark-mode .form-select.is-valid:focus {
  border-color: #19c76a;
  box-shadow: 0 0 0 0.25rem rgba(25, 199, 106, 0.25);
}
.was-validated .dark-mode .form-check-input:valid, .dark-mode .form-check-input.is-valid {
  border-color: #19c76a;
}
.was-validated .dark-mode .form-check-input:valid:checked, .dark-mode .form-check-input.is-valid:checked {
  background-color: #19c76a;
}
.was-validated .dark-mode .form-check-input:valid:focus, .dark-mode .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 199, 106, 0.25);
}
.was-validated .dark-mode .form-check-input:valid ~ .form-check-label, .dark-mode .form-check-input.is-valid ~ .form-check-label {
  color: #19c76a;
}
.dark-mode .form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.was-validated .dark-mode .input-group > .form-control:not(:focus):valid, .dark-mode .input-group > .form-control:not(:focus).is-valid,
.was-validated .dark-mode .input-group > .form-select:not(:focus):valid,
.dark-mode .input-group > .form-select:not(:focus).is-valid,
.was-validated .dark-mode .input-group > .form-floating:not(:focus-within):valid,
.dark-mode .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}
.dark-mode .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.676375rem;
  color: #e75a79;
}
.dark-mode .invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.676375rem;
  color: #fff;
  background-color: rgba(231, 90, 121, 0.9);
  border-radius: var(--bs-border-radius);
}
.was-validated .dark-mode:invalid ~ .invalid-feedback,
.was-validated .dark-mode:invalid ~ .invalid-tooltip, .dark-mode.is-invalid ~ .invalid-feedback,
.dark-mode.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .dark-mode .form-control:invalid, .dark-mode .form-control.is-invalid {
  border-color: #e75a79;
}
.was-validated .dark-mode .form-control:invalid:focus, .dark-mode .form-control.is-invalid:focus {
  border-color: #e75a79;
  box-shadow: 0 0 0 0.25rem rgba(231, 90, 121, 0.25);
}
.was-validated .dark-mode .form-select:invalid, .dark-mode .form-select.is-invalid {
  border-color: #e75a79;
}
.was-validated .dark-mode .form-select:invalid:focus, .dark-mode .form-select.is-invalid:focus {
  border-color: #e75a79;
  box-shadow: 0 0 0 0.25rem rgba(231, 90, 121, 0.25);
}
.was-validated .dark-mode .form-check-input:invalid, .dark-mode .form-check-input.is-invalid {
  border-color: #e75a79;
}
.was-validated .dark-mode .form-check-input:invalid:checked, .dark-mode .form-check-input.is-invalid:checked {
  background-color: #e75a79;
}
.was-validated .dark-mode .form-check-input:invalid:focus, .dark-mode .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(231, 90, 121, 0.25);
}
.was-validated .dark-mode .form-check-input:invalid ~ .form-check-label, .dark-mode .form-check-input.is-invalid ~ .form-check-label {
  color: #e75a79;
}
.dark-mode .form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.was-validated .dark-mode .input-group > .form-control:not(:focus):invalid, .dark-mode .input-group > .form-control:not(:focus).is-invalid,
.was-validated .dark-mode .input-group > .form-select:not(:focus):invalid,
.dark-mode .input-group > .form-select:not(:focus).is-invalid,
.was-validated .dark-mode .input-group > .form-floating:not(:focus-within):invalid,
.dark-mode .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}
.dark-mode .form-control.form-control-alt {
  color: #bec8d5;
  border-color: #19222d;
  background-color: #19222d;
}
.dark-mode .form-control.form-control-alt:focus {
  color: #fff;
  border-color: #171e28;
  background-color: #171e28;
  box-shadow: none;
}
.dark-mode .form-control.form-control-alt.is-valid {
  border-color: rgba(20, 83, 45, 0.85);
  background-color: rgba(20, 83, 45, 0.85);
}
.dark-mode .form-control.form-control-alt.is-valid::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-mode .form-control.form-control-alt.is-valid:focus {
  border-color: #14532d;
  background-color: #14532d;
}
.dark-mode .form-control.form-control-alt.is-invalid {
  border-color: rgba(127, 29, 29, 0.85);
  background-color: rgba(127, 29, 29, 0.85);
}
.dark-mode .form-control.form-control-alt.is-invalid::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-mode .form-control.form-control-alt.is-invalid:focus {
  border-color: #7f1d1d;
  background-color: #7f1d1d;
}
.dark-mode .input-group-text {
  color: #bec8d5;
  background-color: #19222d;
  border-color: #2e3c51;
}
.dark-mode .input-group-text.input-group-text-alt {
  background-color: #171e28;
  border-color: #171e28;
}
.dark-mode .is-valid ~ .valid-feedback,
.dark-mode .is-valid ~ .valid-tooltip,
.dark-mode .was-validated :valid ~ .valid-feedback,
.dark-mode .was-validated :valid ~ .valid-tooltip,
.dark-mode .is-invalid ~ .invalid-feedback,
.dark-mode .is-invalid ~ .invalid-tooltip,
.dark-mode .was-validated :invalid ~ .invalid-feedback,
.dark-mode .was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.dark-mode .form-check-input {
  background-color: #19222d;
  border-color: #2e3c51;
}
.dark-mode .form-check-input:focus {
  border-color: #2356d7;
}
.dark-mode .form-check-input:checked {
  background-color: #2356d7;
  border-color: #2356d7;
}
.dark-mode .form-block .form-check-label {
  border-color: #2e3c51;
}
.dark-mode .form-block .form-check-label:hover {
  border-color: #304056;
}
.dark-mode .form-block .form-check-label::before {
  background-color: #2356d7;
}
.dark-mode .form-block .form-check-input:checked ~ .form-check-label {
  border-color: #2356d7;
}
.dark-mode .form-block .form-check-input:focus ~ .form-check-label {
  border-color: #2356d7;
  box-shadow: 0 0 0 0.25rem rgba(35, 86, 215, 0.25);
}
.dark-mode .form-block .form-check-input:disabled:not([checked]) + .form-check-label:hover,
.dark-mode .form-block .form-check-input[readonly]:not([checked]) + .form-check-label:hover {
  border-color: #171e28;
}
.dark-mode .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.15);
}
.dark-mode .breadcrumb.breadcrumb-alt .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.15);
}
.dark-mode .breadcrumb-item.active {
  color: #fff;
}
.dark-mode .alert-primary {
  color: #fff;
  background-color: #2356d7;
  border-color: #2356d7;
  box-shadow: none;
}
.dark-mode .alert-primary hr {
  border-top-color: #1f4dc1;
}
.dark-mode .alert-primary .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-secondary {
  color: #fff;
  background-color: #2a384b;
  border-color: #2a384b;
  box-shadow: none;
}
.dark-mode .alert-secondary hr {
  border-top-color: #212b3a;
}
.dark-mode .alert-secondary .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-success {
  color: #fff;
  background-color: #0e743e;
  border-color: #0e743e;
  box-shadow: none;
}
.dark-mode .alert-success hr {
  border-top-color: #0b5d32;
}
.dark-mode .alert-success .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-info {
  color: #fff;
  background-color: #0e6d80;
  border-color: #0e6d80;
  box-shadow: none;
}
.dark-mode .alert-info hr {
  border-top-color: #0b5969;
}
.dark-mode .alert-info .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-warning {
  color: #fff;
  background-color: #b04209;
  border-color: #b04209;
  box-shadow: none;
}
.dark-mode .alert-warning hr {
  border-top-color: #983908;
}
.dark-mode .alert-warning .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-danger {
  color: #fff;
  background-color: #a11735;
  border-color: #a11735;
  box-shadow: none;
}
.dark-mode .alert-danger hr {
  border-top-color: #8b142e;
}
.dark-mode .alert-danger .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-dark {
  color: #fff;
  background-color: #141a23;
  border-color: #141a23;
  box-shadow: none;
}
.dark-mode .alert-dark hr {
  border-top-color: #0b0e13;
}
.dark-mode .alert-dark .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-light {
  color: #fff;
  background-color: #35465e;
  border-color: #35465e;
  box-shadow: none;
}
.dark-mode .alert-light hr {
  border-top-color: #2c3a4e;
}
.dark-mode .alert-light .alert-link {
  color: #e6e6e6;
}
.dark-mode .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.dark-mode .progress {
  background-color: #171e28;
}
.dark-mode .list-activity > li:not(:last-child) {
  border-bottom-color: #171e28;
}
.dark-mode .modal-content {
  background: #1F2937;
}
.dark-mode .toast {
  background-color: #1F2937;
}
.dark-mode .toast-header {
  color: #bec8d5;
  background-color: #2a384b;
}
.dark-mode .border {
  border-color: #2a384b !important;
}
.dark-mode .border-top {
  border-top-color: #2a384b !important;
}
.dark-mode .border-end {
  border-right-color: #2a384b !important;
}
.dark-mode .border-bottom {
  border-bottom-color: #2a384b !important;
}
.dark-mode .border-start {
  border-left-color: #2a384b !important;
}
.dark-mode .border-primary {
  border-color: #2356d7 !important;
}
.dark-mode .border-white {
  border-color: #fff !important;
}
.dark-mode .border-white-op {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.dark-mode .border-black-op {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.dark-mode .block {
  background-color: #1F2937;
  box-shadow: 0 1px 2px rgba(24, 31, 42, 0.5), 0 1px 2px rgba(24, 31, 42, 0.5);
}
.dark-mode .block.block-bordered {
  border: 1px solid #2a384b;
  box-shadow: none;
}
.dark-mode .block .block-header-default {
  background-color: #2a384b !important;
}
.dark-mode .block .block-title small, .dark-mode .block .block-title .small {
  color: #99a8bd;
}
.dark-mode .block.block-mode-loading::after {
  color: #fff;
}
.dark-mode .block.block-transparent {
  background-color: transparent;
  box-shadow: none;
}
.dark-mode .block.block-mode-fullscreen.block-transparent {
  background-color: #1F2937;
}
.dark-mode .block .block,
.dark-mode .content-side .block {
  box-shadow: none;
}
.dark-mode a.block {
  color: #bec8d5;
}
.dark-mode a.block.block-link-pop:hover {
  box-shadow: 0 0.5rem 2.5rem #10161d;
}
.dark-mode a.block.block-link-pop:active {
  box-shadow: 0 0.375rem 0.55rem #1d2734;
}
.dark-mode a.block.block-link-shadow:hover {
  box-shadow: 0 0 1.5rem #10161d;
}
.dark-mode a.block.block-link-shadow:active {
  box-shadow: 0 0 0.75rem #161d27;
}
.dark-mode .btn-block-option {
  color: #99a8bd;
}
.block-header-default .dark-mode .btn-block-option {
  color: #8a9bb3;
}
.dark-mode .btn-block-option:hover, .dark-mode .btn-block-option:focus {
  color: #7a8ea9;
}
.dark-mode .btn-block-option:active {
  color: #99a8bd;
}
.dark-mode a.btn-block-option:focus,
.dark-mode .active > a.btn-block-option,
.dark-mode .show > button.btn-block-option {
  color: #7a8ea9;
}
.dark-mode .block.block-themed .btn-block-option,
.dark-mode .block.block-themed .btn-block-option:hover,
.dark-mode .block.block-themed .btn-block-option:focus,
.dark-mode .block.block-themed .btn-block-option:active,
.dark-mode .block.block-themed a.btn-block-option:focus,
.dark-mode .block.block-themed .active > a.btn-block-option,
.dark-mode .block.block-themed .show > button.btn-block-option {
  color: #fff;
}
.dark-mode .timeline::before {
  background-color: #2a384b;
}
.dark-mode .timeline-event-icon {
  box-shadow: 0 0.375rem 1.5rem #1F2937;
}
@media (min-width: 1200px) {
  .dark-mode .timeline-centered .timeline-event-time {
    background-color: #2a384b;
  }
}
.dark-mode .img-thumb {
  background-color: #141a23;
}
.dark-mode .swal2-popup {
  background-color: #1F2937;
}
.dark-mode .swal2-html-container {
  color: #bec8d5;
}
.dark-mode .jvectormap-container {
  background-color: #1F2937 !important;
}
.dark-mode .ck.ck-editor {
  color: #212529;
}
.dark-mode .dropzone {
  background-color: #253041;
  border-color: #2a384b;
}
.dark-mode .dropzone .dz-message {
  color: #bec8d5;
}
.dark-mode .dropzone:hover {
  background-color: #2a384b;
  border-color: #2356d7;
}
.dark-mode .dropzone:hover .dz-message {
  color: #2356d7;
}
.dark-mode .dropzone .dz-preview.dz-image-preview {
  background-color: transparent;
}
.dark-mode .fc.fc-theme-standard a {
  color: #afbbcb !important;
}
.dark-mode .fc.fc-theme-standard .fc-button-primary {
  color: #afbbcb;
  background-color: #171e28;
  border-color: #171e28;
}
.dark-mode .fc.fc-theme-standard .fc-button-primary:not(:disabled):hover {
  color: #bec8d5;
  background-color: #253041;
  border-color: #253041;
}
.dark-mode .fc.fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active, .dark-mode .fc.fc-theme-standard .fc-button-primary:not(:disabled):active {
  color: #bec8d5;
  background-color: #253041;
  border-color: #253041;
}
.dark-mode .fc.fc-theme-standard .fc-button-primary:focus, .dark-mode .fc.fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active:focus, .dark-mode .fc.fc-theme-standard .fc-button-primary:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 86, 215, 0.4);
}
.dark-mode .fc.fc-theme-standard .fc-list, .dark-mode .fc.fc-theme-standard .fc-scrollgrid,
.dark-mode .fc.fc-theme-standard th,
.dark-mode .fc.fc-theme-standard td {
  border-color: #141a23;
}
.dark-mode .fc.fc-theme-standard .fc-list-day-cushion,
.dark-mode .fc.fc-theme-standard .fc-col-header-cell {
  background-color: #253041;
}
.dark-mode .fc.fc-theme-standard .fc-list-event:hover td {
  background-color: #1a232f;
}
.dark-mode .irs,
.dark-mode .irs--round .irs-grid-text {
  color: #bec8d5;
}
.dark-mode .irs.irs--round .irs-min,
.dark-mode .irs.irs--round .irs-max,
.dark-mode .irs.irs--round .irs-line,
.dark-mode .irs.irs--round .irs-grid-pol,
.dark-mode .irs.irs--round .irs-handle {
  color: #bec8d5;
  background: #19222d;
}
.dark-mode .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #637998;
}
.dark-mode .select2-container--default .select2-selection--single,
.dark-mode .select2-container--default .select2-selection--multiple {
  background-color: #171e28;
  border-color: #2e3c51;
}
.dark-mode .select2-container--default.select2-container--focus .select2-selection--multiple, .dark-mode .select2-container--default.select2-container--focus .select2-selection--single, .dark-mode .select2-container--default.select2-container--open .select2-selection--multiple, .dark-mode .select2-container--default.select2-container--open .select2-selection--single {
  border-color: #2356d7;
}
.dark-mode .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #bec8d5;
}
.dark-mode .select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #2e3c51;
}
.dark-mode .select2-container--default .select2-dropdown .select2-search__field:focus {
  border-color: #2356d7;
}
.dark-mode .select2-container--default .select2-dropdown {
  background-color: #171e28;
  border-color: #2e3c51;
}
.dark-mode .select2-container--default .select2-search--dropdown .select2-search__field {
  color: #bec8d5;
  background-color: #171e28;
  border-color: #2e3c51;
}
.dark-mode .select2-container--default .select2-results__option[aria-selected=true] {
  color: #fff;
  background-color: #2356d7;
}
.dark-mode .select2-container--default .select2-search__field::placeholder {
  color: #637998;
}
.dark-mode .is-valid + .select2-container--default .select2-selection--single,
.dark-mode .is-valid + .select2-container--default .select2-selection--multiple,
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #19c76a;
}
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--multiple,
.dark-mode .is-valid + .select2-container--default.select2-container--open .select2-selection--single,
.dark-mode .is-valid + .select2-container--default.select2-container--open .select2-selection--multiple {
  box-shadow: 0 0 0 0.25rem rgba(25, 199, 106, 0.25);
}
.dark-mode .is-invalid + .select2-container--default .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default .select2-selection--multiple,
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #e44467;
}
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--multiple,
.dark-mode .is-invalid + .select2-container--default.select2-container--open .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default.select2-container--open .select2-selection--multiple {
  box-shadow: 0 0 0 0.25rem rgba(228, 68, 103, 0.25);
}
.dark-mode .datepicker .datepicker-switch:hover,
.dark-mode .datepicker .next:hover,
.dark-mode .datepicker .prev:hover,
.dark-mode .datepicker tfoot tr th:hover,
.dark-mode .datepicker table tr td.day:hover,
.dark-mode .datepicker table tr td.focused {
  background-color: #171e28;
}
.dark-mode .datepicker table tr td.selected,
.dark-mode .datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #171e28;
  border-color: #171e28;
}
.dark-mode .datepicker table tr td.range {
  color: #bec8d5;
  background-color: #171e28;
  border-color: #171e28;
}
.dark-mode .datepicker table tr td.active:hover,
.dark-mode .datepicker table tr td.active:hover:hover,
.dark-mode .datepicker table tr td.active.disabled:hover,
.dark-mode .datepicker table tr td.active.disabled:hover:hover,
.dark-mode .datepicker table tr td.active:focus,
.dark-mode .datepicker table tr td.active:hover:focus,
.dark-mode .datepicker table tr td.active.disabled:focus,
.dark-mode .datepicker table tr td.active.disabled:hover:focus,
.dark-mode .datepicker table tr td.active:active,
.dark-mode .datepicker table tr td.active:hover:active,
.dark-mode .datepicker table tr td.active.disabled:active,
.dark-mode .datepicker table tr td.active.disabled:hover:active,
.dark-mode .datepicker table tr td.active.active,
.dark-mode .datepicker table tr td.active:hover.active,
.dark-mode .datepicker table tr td.active.disabled.active,
.dark-mode .datepicker table tr td.active.disabled:hover.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active:hover,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active.disabled,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dark-mode .datepicker table tr td span.active:hover,
.dark-mode .datepicker table tr td span.active:hover:hover,
.dark-mode .datepicker table tr td span.active.disabled:hover,
.dark-mode .datepicker table tr td span.active.disabled:hover:hover,
.dark-mode .datepicker table tr td span.active:focus,
.dark-mode .datepicker table tr td span.active:hover:focus,
.dark-mode .datepicker table tr td span.active.disabled:focus,
.dark-mode .datepicker table tr td span.active.disabled:hover:focus,
.dark-mode .datepicker table tr td span.active:active,
.dark-mode .datepicker table tr td span.active:hover:active,
.dark-mode .datepicker table tr td span.active.disabled:active,
.dark-mode .datepicker table tr td span.active.disabled:hover:active,
.dark-mode .datepicker table tr td span.active.active,
.dark-mode .datepicker table tr td span.active:hover.active,
.dark-mode .datepicker table tr td span.active.disabled.active,
.dark-mode .datepicker table tr td span.active.disabled:hover.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active:hover,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #2356d7;
  border-color: #2356d7;
}
.dark-mode .flatpickr-input.form-control:disabled,
.dark-mode .flatpickr-input.form-control[readonly],
.dark-mode .input.form-control:disabled,
.dark-mode .input.form-control[readonly] {
  color: #bec8d5;
  background-color: #171e28;
  border-color: #2e3c51;
}
.dark-mode .flatpickr-day.selected,
.dark-mode .flatpickr-day.startRange,
.dark-mode .flatpickr-day.endRange,
.dark-mode .flatpickr-day.selected.inRange,
.dark-mode .flatpickr-day.startRange.inRange,
.dark-mode .flatpickr-day.endRange.inRange,
.dark-mode .flatpickr-day.selected:focus,
.dark-mode .flatpickr-day.startRange:focus,
.dark-mode .flatpickr-day.endRange:focus,
.dark-mode .flatpickr-day.selected:hover,
.dark-mode .flatpickr-day.startRange:hover,
.dark-mode .flatpickr-day.endRange:hover,
.dark-mode .flatpickr-day.selected.prevMonthDay,
.dark-mode .flatpickr-day.startRange.prevMonthDay,
.dark-mode .flatpickr-day.endRange.prevMonthDay,
.dark-mode .flatpickr-day.selected.nextMonthDay,
.dark-mode .flatpickr-day.startRange.nextMonthDay,
.dark-mode .flatpickr-day.endRange.nextMonthDay {
  border-color: #2356d7;
  background: #2356d7;
}
.dark-mode .flatpickr-months .flatpickr-prev-month:hover svg,
.dark-mode .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #2356d7;
}
.dark-mode table.dataTable.table-striped > tbody > tr.odd > * {
  box-shadow: inset 0 0 0 9999px #1c2532;
}

.iziToast-capsule {
  font-size: 0;
  height: 0;
  width: 100%;
  transform: translateZ(0);
  backface-visibility: hidden;
  transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.iziToast-capsule,
.iziToast-capsule * {
  box-sizing: border-box;
}

.iziToast-overlay {
  display: block;
  position: fixed;
  top: -100px;
  left: 0;
  right: 0;
  bottom: -100px;
  z-index: 997;
}

.iziToast {
  display: inline-block;
  clear: both;
  position: relative;
  font-family: Lato, Tahoma, Arial;
  font-size: 14px;
  padding: 8px 45px 9px 0;
  background: rgba(238, 238, 238, 0.9);
  border-color: rgba(238, 238, 238, 0.9);
  width: 100%;
  pointer-events: all;
  cursor: default;
  transform: translateX(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 54px;
}

.iziToast > .iziToast-progressbar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, 0.2);
}

.iziToast > .iziToast-progressbar > div {
  height: 2px;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0 0 3px 3px;
}

.iziToast.iziToast-balloon:before {
  content: "";
  position: absolute;
  right: 8px;
  left: auto;
  width: 0;
  height: 0;
  top: 100%;
  border-right: 0px solid transparent;
  border-left: 15px solid transparent;
  border-top: 10px solid #000;
  border-top-color: inherit;
  border-radius: 0;
}

.iziToast.iziToast-balloon .iziToast-progressbar {
  top: 0;
  bottom: auto;
}

.iziToast.iziToast-balloon > div {
  border-radius: 0 0 0 3px;
}

.iziToast > .iziToast-cover {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.1);
}

.iziToast > .iziToast-close {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  padding: 0;
  opacity: 0.6;
  width: 42px;
  height: 100%;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAJPAAACTwBcGfW0QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAD3SURBVFiF1ZdtDoMgDEBfdi4PwAX8vLFn0qT7wxantojKupmQmCi8R4tSACpgjC2ICCUbEBa8ingjsU1AXRBeR8aLN64FiknswN8CYefBBDQ3whuFESy7WyQMeC0ipEI0A+0FeBvHUFN8xPaUhAH/iKoWsnXHGegy4J0yxialOfaHJAz4bhRzQzgDvdGnz4GbAonZbCQMuBm1K/kcFu8Mp1N2cFFpsxsMuJqqbIGExGl4loARajU1twskJLLhIsID7+tvUoDnIjTg5T9DPH9EBrz8rxjPzciAl9+O8SxI8CzJ8CxKFfh3ynK8Dyb8wNHM/XDqejx/AtNyPO87tNybAAAAAElFTkSuQmCC") 50% 50%/8px no-repeat;
  cursor: pointer;
  outline: 0;
}

.iziToast > .iziToast-close:hover {
  opacity: 1;
}

.iziToast > .iziToast-body {
  position: relative;
  padding: 0 0 0 10px;
  height: auto;
  min-height: 36px;
  margin: 0 0 0 15px;
  text-align: left;
}

.iziToast > .iziToast-body:after {
  content: "";
  display: table;
  clear: both;
}

.iziToast > .iziToast-body .iziToast-texts {
  margin: 10px 0 0;
  padding-right: 2px;
  display: inline-block;
  float: left;
}

.iziToast > .iziToast-body .iziToast-inputs {
  min-height: 19px;
  float: left;
  margin: 3px -2px;
}

.iziToast > .iziToast-body .iziToast-inputs > input:not([type=checkbox]):not([type=radio]),
.iziToast > .iziToast-body .iziToast-inputs > select {
  position: relative;
  display: inline-block;
  margin: 2px;
  border-radius: 2px;
  border: 0;
  padding: 4px 7px;
  font-size: 13px;
  letter-spacing: 0.02em;
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  min-height: 26px;
}

.iziToast > .iziToast-body .iziToast-buttons > a:focus,
.iziToast > .iziToast-body .iziToast-buttons > button:focus,
.iziToast > .iziToast-body .iziToast-buttons > input:not([type=checkbox]):not([type=radio]):focus,
.iziToast > .iziToast-body .iziToast-inputs > input:not([type=checkbox]):not([type=radio]):focus,
.iziToast > .iziToast-body .iziToast-inputs > select:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6);
}

.iziToast > .iziToast-body .iziToast-buttons {
  min-height: 17px;
  float: left;
  margin: 4px -2px;
}

.iziToast > .iziToast-body .iziToast-buttons > a,
.iziToast > .iziToast-body .iziToast-buttons > button,
.iziToast > .iziToast-body .iziToast-buttons > input:not([type=checkbox]):not([type=radio]) {
  position: relative;
  display: inline-block;
  margin: 2px;
  border-radius: 2px;
  border: 0;
  padding: 5px 10px;
  font-size: 12px;
  letter-spacing: 0.02em;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  color: #000;
}

.iziToast > .iziToast-body .iziToast-buttons > a:hover,
.iziToast > .iziToast-body .iziToast-buttons > button:hover,
.iziToast > .iziToast-body .iziToast-buttons > input:not([type=checkbox]):not([type=radio]):hover {
  background: rgba(0, 0, 0, 0.2);
}

.iziToast > .iziToast-body .iziToast-buttons > a:active,
.iziToast > .iziToast-body .iziToast-buttons > button:active,
.iziToast > .iziToast-body .iziToast-buttons > input:not([type=checkbox]):not([type=radio]):active {
  top: 1px;
}

.iziToast > .iziToast-body .iziToast-icon {
  position: absolute;
  left: 0;
  top: 50%;
  display: table;
  font-size: 23px;
  line-height: 24px;
  margin-top: -12px;
  color: #000;
  width: 24px;
  height: 24px;
}

.iziToast > .iziToast-body .iziToast-message,
.iziToast > .iziToast-body .iziToast-title {
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  white-space: normal;
  float: left;
  text-align: left;
}

.iziToast > .iziToast-body .iziToast-icon.ico-info {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAflBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACCtoPsAAAAKXRSTlMA6PsIvDob+OapavVhWRYPrIry2MxGQ97czsOzpJaMcE0qJQOwVtKjfxCVFeIAAAI3SURBVFjDlJPZsoIwEETnCiGyb8q+qmjl/3/wFmGKwjBROS9QWbtnOqDDGPq4MdMkSc0m7gcDDhF4NRdv8NoL4EcMpzoJglPl/KTDz4WW3IdvXEvxkfIKn7BMZb1bFK4yZFqghZ03jk0nG8N5NBwzx9xU5cxAg8fXi20/hDdC316lcA8o7t16eRuQvW1XGd2d2P8QSHQDDbdIII/9CR3lUF+lbucfJy4WfMS64EJPORnrZxtfc2pjJdnbuags3l04TTtJMXrdTph4Pyg4XAjugAJqMDf5Rf+oXx2/qi4u6nipakIi7CsgiuMSEF9IGKg8heQJKkxIfFSUU/egWSwNrS1fPDtLfon8sZOcYUQml1Qv9a3kfwsEUyJEMgFBKzdV8o3Iw9yAjg1jdLQCV4qbd3no8yD2GugaC3oMbF0NYHCpJYSDhNI5N2DAWB4F4z9Aj/04Cna/x7eVAQ17vRjQZPh+G/kddYv0h49yY4NWNDWMMOMUIRYvlTECmrN8pUAjo5RCMn8KoPmbJ/+Appgnk//Sy90GYBCGgm7IAskQ7D9hFKW4ApB1ei3FSYD9PjGAKygAV+ARFYBH5BsVgG9kkBSAQWKUFYBRZpkUgGVinRWAdUZQDABBQdIcAElDVBUAUUXWHQBZx1gMAGMprM0AsLbVXHsA5trZe93/wp3svQ0YNb/jWV3AIOLsMtlznSNOH7JqjOpDVh7z8qCZR10ftvO4nxeOvPLkpSuvfXnxzKtvXr7j+v8C5ii0e71At7cAAAAASUVORK5CYII=") 50% 50%/85% no-repeat;
}

.iziToast > .iziToast-body .iziToast-icon.ico-warning {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAkFBMVEUAAAAAAAABAAIAAAABAAIAAAMAAAABAAIBAAIBAAIAAAIAAAABAAIAAAABAAICAAICAAIAAAIAAAAAAAAAAAABAAIBAAIAAAMAAAABAAIBAAMBAAECAAIAAAIAAAIAAAABAAIBAAIBAAMBAAIBAAEAAAIAAAMAAAAAAAABAAECAAICAAIAAAIAAAMAAAQAAAE05yNAAAAAL3RSTlMAB+kD7V8Q+PXicwv7I9iYhkAzJxnx01IV5cmnk2xmHfzexsK4eEw5L7Gei39aRw640awAAAHQSURBVFjD7ZfJdoJAEEWJgCiI4oDiPM8m7///LidErRO7sHrY5u7YXLr7vKqu9kTC0HPmo9n8cJbEQOzqqAdAUHeUZACQuTkGDQBoDJwkHZR0XBz9FkpafXuHP0SJ09mGeJLZ5wwlTmcbA0THPmdEK7XPGTG1zxmInn3OiJ19zkB0jSVTKExMHT0wjAwlWzC0fSPHF1gWRpIhWMYm7fYTFcQGlbemf4dFfdTGg0B/KXM8qBU/3wntbq7rSGqvJ9kla6IpueFJet8fxfem5yhykjyOgNaWF1qSGd5JMNNxpNF7SZQaVh5JzLrTCZIEJ1GyEyVyd+pClMjdaSJK5O40giSRu5PfFiVyd1pAksjdKRnrSsbVdbiHrgT7yss315fkVQPLFQrL+4FHeOXKO5YRFEKv5AiFaMlKLlBpJuVCJlC5sJfvCgztru/3NmBYccPgGTxRAzxn1XGEMUf58pXZvjoOsOCgjL08+b53mtfAM/SVsZcjKLtysQZPqIy9HPP3m/3zKItRwT0LyQo8sTr26tcO83DIUMWIJjierHLsJda/tbNBFY0BP/bKtcM8HNIWCK3aYR4OMzgxo5w5EFLOLKDExXAm9gI4E3iAO94/Ct/lKWuM2LMGbgAAAABJRU5ErkJggg==") 50% 50%/85% no-repeat;
}

.iziToast > .iziToast-body .iziToast-icon.ico-error {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAeFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAVyEiIAAAAJ3RSTlMA3BsB98QV8uSyWVUFz7+kcWMM2LuZioBpTUVBNcq2qaibj4d1azLZZYABAAACZElEQVRYw7WX25KCMAyGAxUoFDkpiohnV97/DXeGBtoOUprZ2dyo1K82fxKbwJJVp+KQZ7so2mX5oThVQLKwjDe9YZu4DF3ptAn6rxY0qQPOEq9fNC9ha3y77a22ba24v+9Xbe8v8x03dPOC2/NdvB6xeSreLfGJpnx0TyotKqLm2s7Jd/WO6ivXNp0tCy02R/aFz5VQ5wUPlUL5fIfj5KIlVGU0nWHm/5QtoTVMWY8mzIVu1K9O7XH2JiU/xnOOT39gnUfj+lFHddx4tFjL3/H8jjzaFCy2Rf0c/fdQyQszI8BDR973IyMSKa4krjxAiW/lkRvMP+bKK9WbYS1ASQg8dKjaUGlYPwRe/WoIkz8tiQchH5QAEMv6T0k8MD4mUyWr4E7jAWqZ+xWcMIYkXvlwggJ3IvFK+wIOcpXAo8n8P0COAaXyKH4OsjBuZB4ew0IGu+H1SebhNazsQBbWm8yj+hFuUJB5eMsN0IUXmYendAFFfJB5uEkRMYwxmcd6zDGRtmQePEykAgubymMRFmMxCSIPCRbTuFNN5OGORTjmNGc0Po0m8Uv0gcCry6xUhR2QeLii9tofbEfhz/qvNti+OfPqNm2Mq6105FUMvdT4GPmufMiV8PqBMkc+DdT1bjYYbjzU/ew23VP4n3mLAz4n8Jtv/Ui3ceTT2mzz5o1mZt0gnBpmsdjqRqVlmplcPdqa7X23kL9brdm2t/uBYDPn2+tyu48mtIGD10JTuUrukVrbCFiwDzcHrPjxKt7PW+AZQyT/WESO+1WL7f3o+WLHL2dYMSZsg6dg/z360ofvP4//v1NPzgs28WlWAAAAAElFTkSuQmCC") 50% 50%/80% no-repeat;
}

.iziToast > .iziToast-body .iziToast-icon.ico-success {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAIVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABt0UjBAAAACnRSTlMApAPhIFn82wgGv8mVtwAAAKVJREFUSMft0LEJAkEARNFFFEw1NFJb8CKjAy1AEOzAxNw+bEEEg6nyFjbY4LOzcBwX7S/gwUxoTdIn+Jbv4Lv8bx446+kB6VsBtK0B+wbMCKxrwL33wOrVeeChX28n7KTOTjgoEu6DRSYAgAAAAkAmAIAAAAIACQIkMkACAAgAIACAyECBKAOJuCagTJwSUCaUAEMAABEBRwAAEQFLbCJgO4bW+AZKGnktR+jAFAAAAABJRU5ErkJggg==") 50% 50%/85% no-repeat;
}

.iziToast > .iziToast-body .iziToast-icon.ico-question {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhCQkUEhFovxTxAAAEDklEQVRo3s2ZTWgTQRTHf03ipTRUqghNSgsRjHgQrFUQC6JgD1Kak3gQUUoPqRdBglf1oBehBws9Cn4cGk+1SOmh2upBxAYVoeJHrR9tgq0i1Cq0lqYeks7MbpPdmU00/c8hm9n33v/t7Nt5M2+qMEWQI0QIibZKRrQpHvLL2KI2wnQzzBKrDm2RIeKEy01dTYKUI7G1ZRknQXV5yP10kTYgly1NF/5S6duZ8ES+1iZodyaocrjXxE0OFeifYYgp0mRIkwFChAkRJsIxGgrIP+I0n82fvZW5dc/zkss0O2o1c5mX6/TmaDWl77RFe5YkUW3tKEmyFv0lOvXJ/fTYnmCEFuMRbGHEZqVHLyT9DFjUJmkzJl9DG5MWWwM6Llif/gF1nukB6nhgGwUXdFrE+wiURA8QoM9i0zEWWpXQW+ZsyeRrOMuyEo5Fv4gmy4dXPvqcC+pH2VRYaMwy+OWG+iLGCgm0W0Kv9HdvR8ASjmKCXpuK/bxiV/76A/v5UdDIZuKcJGjrnec5KZ7wwsWFOp6xPX/9mt2sqDe7FO+Kf/fXHBPPDWpdXGhTpLvUG9VKwh1xMDDjkvu+cNDFBTk7ptX1QkKZ850m3duu6fcrWxwdaFFyREJ2j4vOpKP6Du6z4uJCv8sYJIVkCnJBGGZaBONO3roY2EqNrSfIPi7SKP4fdXyNUd6I6wbSAHEl33tFLe+FlSsusnK90A0+oEPcuufZgXnOi+u9LrKSJQZQw6LwqBnv2CKsfHORbFbyQhA6xN/pEuihSdj56Co7LWRjPiKie6gkB2LiKuUqK5kiPkLiz1QJ9K1cNXBAMoUCigNpQ9IqDtMI1HKA4/jyvUsaoSyZLA5kjOjDPFZen8Ql5TsvBskUgjciIPSX3QAXC86DT7VWvlEh/xZ+ij9BDVWJ0QL0SbZq6QaFxoLPcXPmBLveLCc4wXdDK6s+6/vwhCSniFLPXW0NJe5UB8zKCsviqpc7vGPVQFcyZbyPwGD+d5ZnxmNWlhG4xSBZZjivjIWHEQgoDkSMjMwTo54569JSE5IpA7EyJSMTyGTUAUFlO1ZKOtaHTMeL1PhYYFTcihmY2cQ5+ullj7EDkiVfVez2sCTz8yiv84djhg7IJVk81xFWJlPdfHBG0flkRC/zQFZ+DSllNtfDdUsOMCliyGX5uOzU3ZhIXFDof4m1gDuKbEx0t2YS25gVGpcMnr/I1kx3c6piB8P8ZoqEwfMX3ZyCXynJTmq/U7NUXqfUzCbWL1wqVKBQUeESzQYoUlW8TAcVL1RCxUu1G6BYXfFyfQ4VPbDI4T8d2WzgQ6sc/vmxnTsqfHCZQzUJxm1h5dxS5Tu6lQgTZ0ipqRVqSwzTbbLHMt+c19iO76tsx/cLZub+Ali+tYC93olEAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA5LTA5VDIwOjE4OjE3KzAyOjAwjKtfjgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wOS0wOVQyMDoxODoxNyswMjowMP325zIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") 50% 50%/85% no-repeat;
}

.iziToast > .iziToast-body .iziToast-title {
  margin: 0;
  color: #000;
}

.iziToast > .iziToast-body .iziToast-message {
  margin: 0 0 10px;
  color: rgba(0, 0, 0, 0.6);
}

.iziToast.iziToast-animateInside .iziToast-buttons-child,
.iziToast.iziToast-animateInside .iziToast-icon,
.iziToast.iziToast-animateInside .iziToast-inputs-child,
.iziToast.iziToast-animateInside .iziToast-message,
.iziToast.iziToast-animateInside .iziToast-title {
  opacity: 0;
}

.iziToast-target {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.iziToast-target .iziToast-capsule {
  overflow: hidden;
}

.iziToast-target .iziToast-capsule:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.iziToast-target .iziToast-capsule .iziToast {
  width: 100%;
  float: left;
}

.iziToast-wrapper {
  z-index: 99999;
  position: fixed;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}

.iziToast-wrapper .iziToast.iziToast-balloon:before {
  border-right: 0 solid transparent;
  border-left: 15px solid transparent;
  border-top: 10px solid #000;
  border-top-color: inherit;
  right: 8px;
  left: auto;
}

.iziToast-wrapper-bottomLeft {
  left: 0;
  bottom: 0;
  text-align: left;
}

.iziToast-wrapper-bottomLeft .iziToast.iziToast-balloon:before,
.iziToast-wrapper-topLeft .iziToast.iziToast-balloon:before {
  border-right: 15px solid transparent;
  border-left: 0 solid transparent;
  right: auto;
  left: 8px;
}

.iziToast-wrapper-bottomRight {
  right: 0;
  bottom: 0;
  text-align: right;
}

.iziToast-wrapper-topLeft {
  left: 0;
  top: 0;
  text-align: left;
}

.iziToast-wrapper-topRight {
  top: 0;
  right: 0;
  text-align: right;
}

.iziToast-wrapper-topCenter {
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.iziToast-wrapper-bottomCenter {
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.iziToast-wrapper-center {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

.iziToast-rtl {
  direction: rtl;
  padding: 8px 0 9px 45px;
  font-family: Tahoma, Lato, Arial;
}

.iziToast-rtl .iziToast-body .iziToast-icon,
.iziToast-rtl .iziToast-cover {
  left: auto;
  right: 0;
}

.iziToast-rtl .iziToast-close {
  right: auto;
  left: 0;
}

.iziToast-rtl .iziToast-body {
  padding: 0 10px 0 0;
  margin: 0 16px 0 0;
  text-align: right;
}

.iziToast-rtl .iziToast-body .iziToast-buttons,
.iziToast-rtl .iziToast-body .iziToast-inputs,
.iziToast-rtl .iziToast-body .iziToast-message,
.iziToast-rtl .iziToast-body .iziToast-texts,
.iziToast-rtl .iziToast-body .iziToast-title {
  float: right;
  text-align: right;
}

@media only screen and (min-width: 568px) {
  .iziToast-wrapper {
    padding: 10px 15px;
  }
  .iziToast {
    margin: 5px 0;
    border-radius: 3px;
    width: auto;
  }
  .iziToast:after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    box-shadow: inset 0 -10px 20px -10px rgba(0, 0, 0, 0.2), inset 0 0 5px rgba(0, 0, 0, 0.1), 0 8px 8px -5px rgba(0, 0, 0, 0.25);
  }
  .iziToast:not(.iziToast-rtl) .iziToast-cover {
    border-radius: 3px 0 0 3px;
  }
  .iziToast.iziToast-rtl .iziToast-cover {
    border-radius: 0 3px 3px 0;
  }
  .iziToast.iziToast-color-dark:after {
    box-shadow: inset 0 -10px 20px -10px rgba(255, 255, 255, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.25);
  }
  .iziToast.iziToast-balloon .iziToast-progressbar {
    background: 0 0;
  }
  .iziToast.iziToast-balloon:after {
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.25), inset 0 10px 20px -5px rgba(0, 0, 0, 0.25);
  }
  .iziToast-target .iziToast:after {
    box-shadow: inset 0 -10px 20px -10px rgba(0, 0, 0, 0.2), inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
}
.iziToast.iziToast-theme-dark {
  background: #565c70;
  border-color: #565c70;
}

.iziToast.iziToast-theme-dark .iziToast-icon,
.iziToast.iziToast-theme-dark .iziToast-title {
  color: #fff;
}

.iziToast.iziToast-theme-dark .iziToast-message {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
}

.iziToast.iziToast-theme-dark .iziToast-close {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfgCR4OIQIPSao6AAAAwElEQVRIx72VUQ6EIAwFmz2XB+AConhjzqTJ7JeGKhLYlyx/BGdoBVpjIpMJNjgIZDKTkQHYmYfwmR2AfAqGFBcO2QjXZCd24bEggvd1KBx+xlwoDpYmvnBUUy68DYXD77ESr8WDtYqvxRex7a8oHP4Wo1Mkt5I68Mc+qYqv1h5OsZmZsQ3gj/02h6cO/KEYx29hu3R+VTTwz6D3TymIP1E8RvEiiVdZfEzicxYLiljSxKIqlnW5seitTW6uYnv/Aqh4whX3mEUrAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE2LTA5LTMwVDE0OjMzOjAyKzAyOjAwl6RMVgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNi0wOS0zMFQxNDozMzowMiswMjowMOb59OoAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") 50% 50%/8px no-repeat;
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-info {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAflBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////vroaSAAAAKXRSTlMA6PsIvDob+OapavVhWRYPrIry2MxGQ97czsOzpJaMcE0qJQOwVtKjfxCVFeIAAAI3SURBVFjDlJPZsoIwEETnCiGyb8q+qmjl/3/wFmGKwjBROS9QWbtnOqDDGPq4MdMkSc0m7gcDDhF4NRdv8NoL4EcMpzoJglPl/KTDz4WW3IdvXEvxkfIKn7BMZb1bFK4yZFqghZ03jk0nG8N5NBwzx9xU5cxAg8fXi20/hDdC316lcA8o7t16eRuQvW1XGd2d2P8QSHQDDbdIII/9CR3lUF+lbucfJy4WfMS64EJPORnrZxtfc2pjJdnbuags3l04TTtJMXrdTph4Pyg4XAjugAJqMDf5Rf+oXx2/qi4u6nipakIi7CsgiuMSEF9IGKg8heQJKkxIfFSUU/egWSwNrS1fPDtLfon8sZOcYUQml1Qv9a3kfwsEUyJEMgFBKzdV8o3Iw9yAjg1jdLQCV4qbd3no8yD2GugaC3oMbF0NYHCpJYSDhNI5N2DAWB4F4z9Aj/04Cna/x7eVAQ17vRjQZPh+G/kddYv0h49yY4NWNDWMMOMUIRYvlTECmrN8pUAjo5RCMn8KoPmbJ/+Appgnk//Sy90GYBCGgm7IAskQ7D9hFKW4ApB1ei3FSYD9PjGAKygAV+ARFYBH5BsVgG9kkBSAQWKUFYBRZpkUgGVinRWAdUZQDABBQdIcAElDVBUAUUXWHQBZx1gMAGMprM0AsLbVXHsA5trZe93/wp3svQ0YNb/jWV3AIOLsMtlznSNOH7JqjOpDVh7z8qCZR10ftvO4nxeOvPLkpSuvfXnxzKtvXr7j+v8C5ii0e71At7cAAAAASUVORK5CYII=") 50% 50%/85% no-repeat;
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-warning {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAllBMVEUAAAD////+//3+//3+//3///////z+//3+//3+//3////////////9//3////+//39//3///3////////////+//3+//39//3///z+//z+//7///3///3///3///3////////+//3+//3+//3+//z+//3+//7///3///z////////+//79//3///3///z///v+//3///+trXouAAAAMHRSTlMAB+j87RBf+PXiCwQClSPYhkAzJxnx05tSyadzcmxmHRbp5d7Gwrh4TDkvsYt/WkdQzCITAAAB1UlEQVRYw+3XaXKCQBCGYSIIighoxCVqNJrEPfly/8vFImKXduNsf/Mc4K1y7FnwlMLQc/bUbj85R6bA1LXRDICg6RjJcZa7NQYtnLUGTpERSiOXxrOPkv9s30iGKDmtbYir3H7OUHJa2ylAuvZzRvzUfs7Ii/2cgfTt54x82s8ZSM848gJmYtroQzA2jHwA+LkBIEuMGt+QIng1igzlyMrkuP2CyOi47axRaYTL5jhDJehoR+aovC29s3iIyly3Eb+hRCvZo2qsGTnhKr2cLDS+J73GsqBI9W80UCmWWpEuhIjh6ZRGjyNRarjzKGJ2Ou2himCvjHwqI+rTqQdlRH06TZQR9ek0hiqiPp06mV4ke7QPX6ERUZxO8Uo3sqrfhxvoRrCpvXwL/UjR9GRHMIvLgke4d5QbiwhM6JV2YKKF4vIl7XIBkwm4keryJVmvk/TfwcmPwQNkUQuyA2/sYGwnXL7GPu4bW1jYsmevrNj09/MGZMOEPXslQVqO8hqykD17JfPHP/bmo2yGGpdZiH3IZvzZa7B3+IdDjjpjesHJcvbs5dZ/e+cddVoDdvlq7x12Nac+iN7e4R8OXTjp0pw5CGnOLNDEzeBs5gVwFniAO+8f8wvfeXP2hyqnmwAAAABJRU5ErkJggg==") 50% 50%/85% no-repeat;
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-error {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAeFBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////GqOSsAAAAJ3RSTlMA3BsB98QV8uSyWVUFz6RxYwzYvbupmYqAaU1FQTXKv7abj4d1azKNUit3AAACZElEQVRYw7WXaZOCMAyGw30UORRQBLxX/v8/3BkaWjrY2szO5otKfGrzJrEp6Kw6F8f8sI+i/SE/FucKSBaWiT8p5idlaEtnXTB9tKDLLHAvdSatOan3je93k9F2vRF36+mr1a6eH2NFNydoHq/ieU/UXcWjjk9XykdNWq2ywtp4tXL6Wb2T/MqtzzZutsrNyfvA51KoQROhVCjfrnASIRpSVUZiD5v4RbWExjRdJzSmOsZFvzYz59kRSr6V5zE+/QELHkNdb3VRx45HS1b1u+zfkkcbRAZ3qJ9l/A4qefHUDMShJe+6kZKJDD2pLQ9Q4lu+5Q7rz7Plperd7AtQEgIPI6o2dxr2D4GXvxqCiKcn8cD4gxIAEt7/GYkHL16KqeJd0NB4gJbXfgVnzCGJlzGcocCVSLzUvoAj9xJ4NF7/R8gxoVQexc/hgBpSebjPjgPs59cHmYfn7NkDb6wXmUf1I1ygIPPw4gtgCE8yDw8eAop4J/PQcBExjQmZx37MsZB2ZB4cLKQCG5vKYxMWSzMxIg8pNtOyUkvkocEmXGo69mh8FgnxS4yBwMvDrJSNHZB4uC3ayz/YkcIP4lflwVIT+OU07ZSjrbTkZQ6dTPkYubZ8GC/Cqxu6WvJZII93dcCw46GdNqdpTeF/tiMOuDGB9z/NI6NvyWetGPM0g+bVNeovBmamHXWj0nCbEaGeTMN2PWrqd6cM26ZxP2DeJvj+ph/30Zi/GmRbtlK5SptI+nwGGnvH6gUruT+L16MJHF+58rwNIifTV0vM8+hwMeOXAb6Yx0wXT+b999WXfvn+8/X/F7fWzjdTord5AAAAAElFTkSuQmCC") 50% 50%/80% no-repeat;
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-success {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAIVBMVEUAAAD////////////////////////////////////////PIev5AAAACnRSTlMApAPhIFn82wgGv8mVtwAAAKVJREFUSMft0LEJAkEARNFFFEw1NFJb8CKjAy1AEOzAxNw+bEEEg6nyFjbY4LOzcBwX7S/gwUxoTdIn+Jbv4Lv8bx446+kB6VsBtK0B+wbMCKxrwL33wOrVeeChX28n7KTOTjgoEu6DRSYAgAAAAkAmAIAAAAIACQIkMkACAAgAIACAyECBKAOJuCagTJwSUCaUAEMAABEBRwAAEQFLbCJgO4bW+AZKGnktR+jAFAAAAABJRU5ErkJggg==") 50% 50%/85% no-repeat;
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-question {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhCQkUEg18vki+AAAETUlEQVRo3s1ZTWhbRxD+VlIuxsLFCYVIIQYVopBDoK5bKDWUBupDMNbJ5FBKg/FBziUQdE9yaC+FHBrwsdCfQ9RTGoLxwWl+DqHEojUFFydxnB9bInZDqOsErBrr6yGvs/ueX97bldTKo4Pe7puZb3Z33s7srIIjMY1jyCEjP6ImvyX8pF64arSHznKC06wzijY5xSKz7YbuYokV2lODsyyxqz3gSY6z6gCuqcpxJluFH+Z8U+D/0jyHoxFUBHgfvsGHIS9WMIUlVFFDFTUAGWSRQRY5HMeBEP6b+Ew9dh/7INd2jGeO59kfKdXP85zbIbfGQVf4sYC3N1hm3lo6zzIbPvk6x+zBk7wQGMEMB5xncIAzAS0XrFySSV72iS1yyBVcdA1x0afrsoUJgdFfY2+z8ADAXl7zz0KcwJiPfZKpVuABgClO+nRG+QIHDdfb4qlWwUXvKW4Z7vi6L4J9vg+vbfCeCeZH2RfOdMOc/HbCA4BvIW6EMQz7XK/ltd+hP+VzR9mgva2YSfyGI17fA7ynnocqeQNFfIJ0oHsdv6CC2+rXGBN6cQdveY3fcVRtmy/HDete+93zy8jA8zV7YkwYMrjHzRddRsCdiVCwwmh6wg9iTNC7Y9XIF1iS7kbUpsvvGEdPuTfSgAEjRpR096x0liPFD/Eqt2NMuBQzB2XhrACAApjFsuQFh9XdGAX70B3oSuNdnMVBaX+sopYxjwVpHFBVACyKTXNoktjD+6Ll8xhenS9MAAkAI/Lux2YNUOs4I413Ypg1SgEAu7kpFvWjaeJe0fJHDGe/cNaZBkekudw8PMA+0fMwlndZeAsJ5KR/qhUDUJCnSiyvRsolkJHGUgvjH8QXDgZopEzKMKDqCKrwEQ4C6MH7GEXC665buLJG8hlQc4LP4paxfJrOqYVYYY2UARfEIazTbgDg2dB98GebzJd54b8L/iWNdLyooeR6CHyZ+6xk0yKxkYg6nEVSUG4VJ9QJ9cxRCxO+9WiOyvgUeexXP1hLGH5nGuBWVtiSp4vqe3VP0UFWI9Wan4Er3v8q7jjPWVtm4FtcQQMrOKO2nOQCM5AyDMi56FDrKHA/1nyppS1ppBpYaE8wciEjGI2AaeM41kI4doDX4XiT3Qm1gevyruCgZg9P8xIv8m1nCzTKq6oiJ9xTMiZ505P5m8cdZ0CnZMVXHVljM7WMBzxpyDxygtdxoCEFTaMIWbZU85UvBjgUMYy0fBaAF8V1Lj9qWQ1aMZ5f4k9r+AGMSkMP1vZoZih6k6sicc5h/OFHM9vDqU/VIU7zJZdYYsKGH4g4nAJMGiXZRds1pVMoZ69RM5vfkbh0qkBhsnS2RLMLilQdL9MBHS9UAh0v1e6CYnXHy/WeeCcvLDwl/9OVze69tPKM+M+v7eJN6OzFpWdEF0ucDbhVNFXadnVrmJFlkVNGTS2M6pzmhMvltfPhnN2B63sVuL7fcNP3D1TSk2ihosPrAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA5LTA5VDIwOjE4OjEzKzAyOjAweOR7nQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wOS0wOVQyMDoxODoxMyswMjowMAm5wyEAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") 50% 50%/85% no-repeat;
}

.iziToast.iziToast-theme-dark .iziToast-buttons > a,
.iziToast.iziToast-theme-dark .iziToast-buttons > button,
.iziToast.iziToast-theme-dark .iziToast-buttons > input {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

.iziToast.iziToast-theme-dark .iziToast-buttons > a:hover,
.iziToast.iziToast-theme-dark .iziToast-buttons > button:hover,
.iziToast.iziToast-theme-dark .iziToast-buttons > input:hover {
  background: rgba(255, 255, 255, 0.2);
}

.iziToast.iziToast-theme-dark .iziToast-buttons > a:focus,
.iziToast.iziToast-theme-dark .iziToast-buttons > button:focus,
.iziToast.iziToast-theme-dark .iziToast-buttons > input:focus {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6);
}

.iziToast.iziToast-color-red {
  background: rgba(255, 175, 180, 0.9);
  border-color: rgba(255, 175, 180, 0.9);
}

.iziToast.iziToast-color-orange {
  background: rgba(255, 207, 165, 0.9);
  border-color: rgba(255, 207, 165, 0.9);
}

.iziToast.iziToast-color-yellow {
  background: rgba(255, 249, 178, 0.9);
  border-color: rgba(255, 249, 178, 0.9);
}

.iziToast.iziToast-color-blue {
  background: rgba(157, 222, 255, 0.9);
  border-color: rgba(157, 222, 255, 0.9);
}

.iziToast.iziToast-color-green {
  background: rgba(166, 239, 184, 0.9);
  border-color: rgba(166, 239, 184, 0.9);
}

.iziToast.iziToast-layout2 .iziToast-body .iziToast-message,
.iziToast.iziToast-layout2 .iziToast-body .iziToast-texts {
  width: 100%;
}

.iziToast.iziToast-layout3 {
  border-radius: 2px;
}

.iziToast.iziToast-layout3::after {
  display: none;
}

.iziToast .revealIn,
.iziToast.revealIn {
  -webkit-animation: 1s cubic-bezier(0.25, 1.6, 0.25, 1) both iziT-revealIn;
  -moz-animation: 1s cubic-bezier(0.25, 1.6, 0.25, 1) both iziT-revealIn;
  animation: 1s cubic-bezier(0.25, 1.6, 0.25, 1) both iziT-revealIn;
}

.iziToast .slideIn,
.iziToast.slideIn {
  -webkit-animation: 1s cubic-bezier(0.16, 0.81, 0.32, 1) both iziT-slideIn;
  -moz-animation: 1s cubic-bezier(0.16, 0.81, 0.32, 1) both iziT-slideIn;
  animation: 1s cubic-bezier(0.16, 0.81, 0.32, 1) both iziT-slideIn;
}

.iziToast.bounceInLeft {
  -webkit-animation: 0.7s ease-in-out both iziT-bounceInLeft;
  animation: 0.7s ease-in-out both iziT-bounceInLeft;
}

.iziToast.bounceInRight {
  -webkit-animation: 0.85s ease-in-out both iziT-bounceInRight;
  animation: 0.85s ease-in-out both iziT-bounceInRight;
}

.iziToast.bounceInDown {
  -webkit-animation: 0.7s ease-in-out both iziT-bounceInDown;
  animation: 0.7s ease-in-out both iziT-bounceInDown;
}

.iziToast.bounceInUp {
  -webkit-animation: 0.7s ease-in-out both iziT-bounceInUp;
  animation: 0.7s ease-in-out both iziT-bounceInUp;
}

.iziToast .fadeIn,
.iziToast-overlay.fadeIn,
.iziToast.fadeIn {
  -webkit-animation: 0.5s both iziT-fadeIn;
  animation: 0.5s both iziT-fadeIn;
}

.iziToast.fadeInUp {
  -webkit-animation: 0.7s both iziT-fadeInUp;
  animation: 0.7s both iziT-fadeInUp;
}

.iziToast.fadeInDown {
  -webkit-animation: 0.7s both iziT-fadeInDown;
  animation: 0.7s both iziT-fadeInDown;
}

.iziToast.fadeInLeft {
  -webkit-animation: 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both iziT-fadeInLeft;
  animation: 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both iziT-fadeInLeft;
}

.iziToast.fadeInRight {
  -webkit-animation: 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both iziT-fadeInRight;
  animation: 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both iziT-fadeInRight;
}

.iziToast.flipInX {
  -webkit-animation: 0.85s cubic-bezier(0.35, 0, 0.25, 1) both iziT-flipInX;
  animation: 0.85s cubic-bezier(0.35, 0, 0.25, 1) both iziT-flipInX;
}

.iziToast-overlay.fadeOut,
.iziToast.fadeOut {
  -webkit-animation: 0.7s both iziT-fadeOut;
  animation: 0.7s both iziT-fadeOut;
}

.iziToast.fadeOutDown {
  -webkit-animation: 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both iziT-fadeOutDown;
  animation: 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both iziT-fadeOutDown;
}

.iziToast.fadeOutUp {
  -webkit-animation: 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both iziT-fadeOutUp;
  animation: 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both iziT-fadeOutUp;
}

.iziToast.fadeOutLeft {
  -webkit-animation: 0.5s both iziT-fadeOutLeft;
  animation: 0.5s both iziT-fadeOutLeft;
}

.iziToast.fadeOutRight {
  -webkit-animation: 0.5s both iziT-fadeOutRight;
  animation: 0.5s both iziT-fadeOutRight;
}

.iziToast.flipOutX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation: 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both iziT-flipOutX;
  animation: 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both iziT-flipOutX;
}

@-webkit-keyframes iziT-revealIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 1);
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes iziT-revealIn {
  0% {
    opacity: 0;
    -moz-transform: scale3d(0.3, 0.3, 1);
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes iziT-slideIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes iziT-slideIn {
  0% {
    opacity: 0;
    -moz-transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-webkit-keyframes iziT-bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(280px);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  70% {
    -webkit-transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-webkit-keyframes iziT-bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-280px);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  70% {
    -webkit-transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-webkit-keyframes iziT-bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateY(10px);
  }
  70% {
    -webkit-transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-webkit-keyframes iziT-bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateY(-10px);
  }
  70% {
    -webkit-transform: translateY(5px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes iziT-revealIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 1);
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes iziT-revealIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 1);
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes iziT-revealIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 1);
  }
  100% {
    opacity: 1;
  }
}
@keyframes iziT-revealIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 1);
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes iziT-slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes iziT-slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-o-keyframes iziT-slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes iziT-slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-moz-keyframes iziT-bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(280px);
  }
  50% {
    opacity: 1;
    transform: translateX(-20px);
  }
  70% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes iziT-bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(280px);
  }
  50% {
    opacity: 1;
    transform: translateX(-20px);
  }
  70% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes iziT-bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(280px);
  }
  50% {
    opacity: 1;
    transform: translateX(-20px);
  }
  70% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes iziT-bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(280px);
  }
  50% {
    opacity: 1;
    transform: translateX(-20px);
  }
  70% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes iziT-bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-280px);
  }
  50% {
    opacity: 1;
    transform: translateX(20px);
  }
  70% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes iziT-bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-280px);
  }
  50% {
    opacity: 1;
    transform: translateX(20px);
  }
  70% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes iziT-bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-280px);
  }
  50% {
    opacity: 1;
    transform: translateX(20px);
  }
  70% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes iziT-bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-280px);
  }
  50% {
    opacity: 1;
    transform: translateX(20px);
  }
  70% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes iziT-bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  50% {
    opacity: 1;
    transform: translateY(10px);
  }
  70% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes iziT-bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  50% {
    opacity: 1;
    transform: translateY(10px);
  }
  70% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@-o-keyframes iziT-bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  50% {
    opacity: 1;
    transform: translateY(10px);
  }
  70% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes iziT-bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  50% {
    opacity: 1;
    transform: translateY(10px);
  }
  70% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@-moz-keyframes iziT-bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  50% {
    opacity: 1;
    transform: translateY(-10px);
  }
  70% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes iziT-bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  50% {
    opacity: 1;
    transform: translateY(-10px);
  }
  70% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
@-o-keyframes iziT-bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  50% {
    opacity: 1;
    transform: translateY(-10px);
  }
  70% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes iziT-bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  50% {
    opacity: 1;
    transform: translateY(-10px);
  }
  70% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
@-moz-keyframes iziT-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes iziT-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes iziT-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes iziT-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes iziT-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes iziT-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-o-keyframes iziT-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes iziT-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes iziT-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes iziT-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-o-keyframes iziT-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes iziT-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes iziT-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes iziT-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-o-keyframes iziT-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes iziT-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes iziT-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes iziT-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-o-keyframes iziT-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes iziT-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes iziT-flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-webkit-keyframes iziT-flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-o-keyframes iziT-flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes iziT-flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-moz-keyframes iziT-fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes iziT-fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes iziT-fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes iziT-fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes iziT-fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-webkit-keyframes iziT-fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-o-keyframes iziT-fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes iziT-fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-moz-keyframes iziT-fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-webkit-keyframes iziT-fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-o-keyframes iziT-fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes iziT-fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-moz-keyframes iziT-fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0);
  }
}
@-webkit-keyframes iziT-fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0);
  }
}
@-o-keyframes iziT-fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0);
  }
}
@keyframes iziT-fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0);
  }
}
@-moz-keyframes iziT-fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
  }
}
@-webkit-keyframes iziT-fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
  }
}
@-o-keyframes iziT-fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
  }
}
@keyframes iziT-fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
  }
}
@-moz-keyframes iziT-flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@-webkit-keyframes iziT-flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@-o-keyframes iziT-flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes iziT-flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
/**
 *  Datepicker
 *
 */
.beta:after {
  content: "Ambiente de Teste";
  position: fixed;
  width: 100%;
  height: 25px;
  background: #EE8E4A;
  top: 0px;
  left: 0px;
  text-align: center;
  font-size: 13px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 27px;
  display: block;
  z-index: 99999;
}

[v-cloak] > * {
  display: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
[v-cloak]::before {
  /*content: "loading...";*/
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: inline-flex;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -15px;
  margin-left: -15px;
  margin-bottom: 50px;
  border-radius: 50%;
  /*border: 1px solid #ccc;
      border-top-color: #07d;*/
  border-top: 2px solid #07d;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
}

.iziToast.iziToast-color-green {
  background: rgba(103, 143, 54, 0.9) !important;
  border-color: rgba(166, 239, 184, 0.9) !important;
}

.iziToast.iziToast-color-orange {
  background: rgba(255, 140, 66, 0.9) !important;
  border-color: rgba(255, 110, 46, 0.9) !important;
}

.iziToast.iziToast-color-red {
  background: rgba(247, 23, 53, 0.9) !important;
  border-color: rgba(255, 175, 180, 0.9) !important;
}

.iziToast.iziToast-color-blue {
  background: rgba(2, 128, 144, 0.9) !important;
  border-color: rgba(157, 222, 255, 0.9) !important;
}

body.swal2-shown > [aria-hidden=true] {
  transition: 0.1s filter;
  filter: blur(10px);
}

/*
 * autocomplete
 */
.aa-input-container {
  position: relative;
}

.algolia-autocomplete {
  width: 100%;
}

.algolia-autocomplete .aa-input,
.algolia-autocomplete .aa-hint {
  width: 100%;
}

.aa-input-search {
  width: 100%;
  padding: 6px 28px 12px 12px;
  border: 2px solid #e4e4e4;
  border-radius: 4px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  box-shadow: 4px 4px 0 rgba(241, 241, 241, 0.35);
  font-size: 11px;
  box-sizing: border-box;
  color: #333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.aa-input-search::-webkit-search-decoration,
.aa-input-search::-webkit-search-cancel-button,
.aa-input-search::-webkit-search-results-button,
.aa-input-search::-webkit-search-results-decoration {
  display: none;
}

.aa-input-search:focus {
  outline: 0;
  border-color: #3a96cf;
  box-shadow: 4px 4px 0 rgba(58, 150, 207, 0.1);
}

.aa-input-icon {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  fill: #e4e4e4;
  pointer-events: none;
}

.aa-hint {
  color: #65728f;
}

.aa-dropdown-menu {
  background-color: #fff;
  border: 2px solid rgba(228, 228, 228, 0.6);
  border-top-width: 0;
  width: 100%;
  top: 1px;
  box-shadow: 4px 4px 0 rgba(241, 241, 241, 0.35);
  font-size: 10px;
  border-radius: 4px;
  box-sizing: border-box;
}

.aa-suggestion {
  text-transform: uppercase;
  padding: 6px 12px;
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.375rem;
}

.aa-suggestion > .title {
  margin: 0;
  font-size: 0.675rem;
  font-weight: 600;
  flex-basis: 100%;
  text-transform: uppercase;
}

.aa-suggestion > .break {
  flex-basis: 100%;
  height: 0;
  width: 100%;
}

.aa-suggestion:hover,
.aa-suggestion.aa-cursor {
  background-color: #B2D7FF;
}

.aa-suggestion > span:first-child {
  color: #333;
}

.aa-suggestion > span:last-child {
  text-transform: uppercase;
  color: #a9a9a9;
}

.aa-suggestions-category {
  text-transform: uppercase;
  border-bottom: 2px solid rgba(228, 228, 228, 0.6);
  border-top: 2px solid rgba(228, 228, 228, 0.6);
  padding: 6px 12px;
  color: #a9a9a9;
}

.aa-suggestion > span:first-child em,
.aa-suggestion > span:last-child em {
  font-weight: 700;
  font-style: normal;
  background-color: rgba(58, 150, 207, 0.1);
  padding: 2px 0 2px 2px;
}

.nav-main-horizontal-workflow {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  overflow: auto;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  position: absolute;
  top: 100%;
  z-index: 9999;
  left: 0px;
  right: auto;
  display: block;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  cursor: pointer;
  padding: 3px 2px;
}

.autocomplete-text {
  flex: 1 1 auto;
  min-height: 1.25rem;
  margin: 0;
  font-size: 0.6rem;
  text-transform: uppercase;
  /* letter-spacing: .0312rem; */
}

.autocomplete-result.is-active {
  background-color: #8bafd6;
  color: #000;
}

.autocomplete-result:hover {
  background-color: #B2D7FF;
  border-radius: 0.3rem;
  padding: 0.25rem !important;
  color: #000;
}

:root {
  --aa-search-input-height: 44px;
  --aa-input-icon-size: 20px;
  --aa-base-unit: 16;
  --aa-spacing-factor: 1;
  --aa-spacing: calc(var(--aa-base-unit) * var(--aa-spacing-factor) * 1px);
  --aa-spacing-half: calc(var(--aa-spacing) / 2);
  --aa-panel-max-height: 650px;
  --aa-base-z-index: 9999;
  --aa-font-size: calc(var(--aa-base-unit) * 1px);
  --aa-font-family: inherit;
  --aa-font-weight-medium: 500;
  --aa-font-weight-semibold: 600;
  --aa-font-weight-bold: 700;
  --aa-icon-size: 20px;
  --aa-icon-stroke-width: 1.6;
  --aa-icon-color-rgb: 119, 119, 163;
  --aa-icon-color-alpha: 1;
  --aa-action-icon-size: 20px;
  --aa-text-color-rgb: 38, 38, 39;
  --aa-text-color-alpha: 1;
  --aa-primary-color-rgb: 62, 52, 211;
  --aa-primary-color-alpha: 0.2;
  --aa-muted-color-rgb: 128, 126, 163;
  --aa-muted-color-alpha: 0.6;
  --aa-panel-border-color-rgb: 128, 126, 163;
  --aa-panel-border-color-alpha: 0.3;
  --aa-input-border-color-rgb: 128, 126, 163;
  --aa-input-border-color-alpha: 0.8;
  --aa-background-color-rgb: 255, 255, 255;
  --aa-background-color-alpha: 1;
  --aa-input-background-color-rgb: 255, 255, 255;
  --aa-input-background-color-alpha: 1;
  --aa-selected-color-rgb: 179, 173, 214;
  --aa-selected-color-alpha: 0.205;
  --aa-description-highlight-background-color-rgb: 245, 223, 77;
  --aa-description-highlight-background-color-alpha: 0.5;
  --aa-detached-media-query: (max-width: 680px);
  --aa-detached-modal-media-query: (min-width: 680px);
  --aa-detached-modal-max-width: 680px;
  --aa-detached-modal-max-height: 500px;
  --aa-overlay-color-rgb: 115, 114, 129;
  --aa-overlay-color-alpha: 0.4;
  --aa-panel-shadow: 0 0 0 1px rgba(35, 38, 59, 0.1),
      0 6px 16px -4px rgba(35, 38, 59, 0.15);
  --aa-scrollbar-width: 13px;
  --aa-scrollbar-track-background-color-rgb: 234, 234, 234;
  --aa-scrollbar-track-background-color-alpha: 1;
  --aa-scrollbar-thumb-background-color-rgb: var(--aa-background-color-rgb);
  --aa-scrollbar-thumb-background-color-alpha: 1;
}
@media (hover: none) and (pointer: coarse) {
  :root {
    --aa-spacing-factor: 1.2;
    --aa-action-icon-size: 22px;
  }
}

body {
  /* stylelint-disable selector-no-qualifying-type, selector-class-pattern */
  /* stylelint-enable selector-no-qualifying-type, selector-class-pattern */
}
body[data-theme=dark], body.dark {
  --aa-text-color-rgb: 183, 192, 199;
  --aa-primary-color-rgb: 146, 138, 255;
  --aa-muted-color-rgb: 146, 138, 255;
  --aa-input-background-color-rgb: 0, 3, 9;
  --aa-background-color-rgb: 21, 24, 42;
  --aa-selected-color-rgb: 146, 138, 255;
  --aa-selected-color-alpha: 0.25;
  --aa-description-highlight-background-color-rgb: 0 255 255;
  --aa-description-highlight-background-color-alpha: 0.25;
  --aa-icon-color-rgb: 119, 119, 163;
  --aa-panel-shadow: inset 1px 1px 0 0 rgb(44, 46, 64),
      0 3px 8px 0 rgb(0, 3, 9);
  --aa-scrollbar-track-background-color-rgb: 44, 46, 64;
  --aa-scrollbar-thumb-background-color-rgb: var(--aa-background-color-rgb);
}

.aa-Panel *, .aa-Autocomplete *,
.aa-DetachedFormContainer * {
  box-sizing: border-box;
}

.aa-Panel, .aa-Autocomplete,
.aa-DetachedFormContainer {
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  font-family: var(--aa-font-family);
  font-size: var(--aa-font-size);
  font-weight: normal;
  line-height: 1em;
  margin: 0;
  padding: 0;
  text-align: left;
}

.aa-Form {
  align-items: center;
  background-color: rgba(var(--aa-input-background-color-rgb), var(--aa-input-background-color-alpha));
  border: 1px solid rgba(var(--aa-input-border-color-rgb), var(--aa-input-border-color-alpha));
  border-radius: 3px;
  display: flex;
  line-height: 1em;
  margin: 0;
  position: relative;
  width: 100%;
}
.aa-Form:focus-within {
  border-color: rgba(var(--aa-primary-color-rgb), 1);
  box-shadow: rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha)) 0 0 0 2px, inset rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha)) 0 0 0 2px;
  outline: currentColor none medium;
}
.aa-InputWrapperPrefix {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: var(--aa-search-input-height);
  order: 1;
}
.aa-Label,
.aa-LoadingIndicator {
  cursor: initial;
  flex-shrink: 0;
  height: 100%;
  padding: 0;
  text-align: left;
}
.aa-Label svg,
.aa-LoadingIndicator svg {
  color: rgba(var(--aa-primary-color-rgb), 1);
  height: auto;
  max-height: var(--aa-input-icon-size);
  stroke-width: var(--aa-icon-stroke-width);
  width: var(--aa-input-icon-size);
}

.aa-SubmitButton,
.aa-LoadingIndicator {
  height: 100%;
  padding-left: calc(var(--aa-spacing) * 0.75 - 1px);
  padding-right: var(--aa-spacing-half);
  width: calc(var(--aa-spacing) * 1.75 + var(--aa-icon-size) - 1px);
}
@media (hover: none) and (pointer: coarse) {
  .aa-SubmitButton,
.aa-LoadingIndicator {
    padding-left: calc(var(--aa-spacing-half) / 2 - 1px);
    width: calc(var(--aa-icon-size) + var(--aa-spacing) * 1.25 - 1px);
  }
}

.aa-SubmitButton {
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
}

.aa-LoadingIndicator {
  align-items: center;
  display: flex;
  justify-content: center;
}
.aa-LoadingIndicator[hidden] {
  display: none;
}

.aa-InputWrapper {
  order: 3;
  position: relative;
  width: 100%;
}
.aa-Input {
  appearance: none;
  background: none;
  border: 0;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  font: inherit;
  height: var(--aa-search-input-height);
  padding: 0;
  width: 100%;
}
.aa-Input::placeholder {
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  opacity: 1;
}
.aa-Input:focus {
  border-color: none;
  box-shadow: none;
  outline: none;
}
.aa-Input::-webkit-search-decoration, .aa-Input::-webkit-search-cancel-button, .aa-Input::-webkit-search-results-button, .aa-Input::-webkit-search-results-decoration {
  appearance: none;
}

.aa-InputWrapperSuffix {
  align-items: center;
  display: flex;
  height: var(--aa-search-input-height);
  order: 4;
}
.aa-ClearButton {
  align-items: center;
  background: none;
  border: 0;
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  cursor: pointer;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0 calc(var(--aa-spacing) * 0.8333333333 - 0.5px);
}
@media (hover: none) and (pointer: coarse) {
  .aa-ClearButton {
    padding: 0 calc(var(--aa-spacing) * 0.6666666667 - 0.5px);
  }
}
.aa-ClearButton:hover, .aa-ClearButton:focus {
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
}
.aa-ClearButton[hidden] {
  display: none;
}
.aa-ClearButton svg {
  stroke-width: var(--aa-icon-stroke-width);
  width: var(--aa-icon-size);
}

.aa-Panel {
  background-color: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  border-radius: calc(var(--aa-spacing) / 4);
  box-shadow: var(--aa-panel-shadow);
  margin: 8px 0 0;
  overflow: hidden;
  position: absolute;
  transition: opacity 200ms ease-in, filter 200ms ease-in;
}
@media screen and (prefers-reduced-motion) {
  .aa-Panel {
    transition: none;
  }
}
.aa-Panel button {
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}
.aa-PanelLayout {
  height: 100%;
  margin: 0;
  max-height: var(--aa-panel-max-height);
  overflow-y: auto;
  padding: 0;
  position: relative;
  text-align: left;
}
.aa-PanelLayoutColumns--twoGolden {
  display: grid;
  grid-template-columns: 39.2% auto;
  overflow: hidden;
  padding: 0;
}

.aa-PanelLayoutColumns--two {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  overflow: hidden;
  padding: 0;
}

.aa-PanelLayoutColumns--three {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  overflow: hidden;
  padding: 0;
}

.aa-Panel--stalled .aa-Source {
  filter: grayscale(1);
  opacity: 0.8;
}

.aa-Panel--scrollable {
  margin: 0;
  max-height: var(--aa-panel-max-height);
  overflow-x: hidden;
  overflow-y: auto;
  padding: var(--aa-spacing-half);
  scrollbar-color: rgba(var(--aa-scrollbar-thumb-background-color-rgb), var(--aa-scrollbar-thumb-background-color-alpha)) rgba(var(--aa-scrollbar-track-background-color-rgb), var(--aa-scrollbar-track-background-color-alpha));
  scrollbar-width: thin;
}
.aa-Panel--scrollable::-webkit-scrollbar {
  width: var(--aa-scrollbar-width);
}
.aa-Panel--scrollable::-webkit-scrollbar-track {
  background-color: rgba(var(--aa-scrollbar-track-background-color-rgb), var(--aa-scrollbar-track-background-color-alpha));
}
.aa-Panel--scrollable::-webkit-scrollbar-thumb {
  background-color: rgba(var(--aa-scrollbar-thumb-background-color-rgb), var(--aa-scrollbar-thumb-background-color-alpha));
  border-color: rgba(var(--aa-scrollbar-track-background-color-rgb), var(--aa-scrollbar-track-background-color-alpha));
  border-radius: 9999px;
  border-style: solid;
  border-width: 3px 2px 3px 3px;
}

.aa-Source {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
.aa-Source:empty {
  display: none;
}
.aa-SourceNoResults {
  font-size: 1em;
  margin: 0;
  padding: var(--aa-spacing);
}

.aa-List {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.aa-SourceHeader {
  margin: var(--aa-spacing-half) 0.5em var(--aa-spacing-half) 0;
  padding: 0;
  position: relative;
}
.aa-SourceHeader:empty {
  display: none;
}
.aa-SourceHeaderTitle {
  background: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  color: rgba(var(--aa-primary-color-rgb), 1);
  display: inline-block;
  font-size: 0.8em;
  font-weight: var(--aa-font-weight-semibold);
  margin: 0;
  padding: 0 var(--aa-spacing-half) 0 0;
  position: relative;
  z-index: var(--aa-base-z-index);
}

.aa-SourceHeaderLine {
  border-bottom: solid 1px rgba(var(--aa-primary-color-rgb), 1);
  display: block;
  height: 2px;
  left: 0;
  margin: 0;
  opacity: 0.3;
  padding: 0;
  position: absolute;
  right: 0;
  top: var(--aa-spacing-half);
  z-index: calc(var(--aa-base-z-index) - 1);
}

.aa-SourceFooterSeeAll {
  background: linear-gradient(180deg, rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha)), rgba(128, 126, 163, 0.14));
  border: 1px solid rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  border-radius: 5px;
  box-shadow: inset 0 0 2px #fff, 0 2px 2px -1px rgba(76, 69, 88, 0.15);
  color: inherit;
  font-size: 0.95em;
  font-weight: var(--aa-font-weight-medium);
  padding: 0.475em 1em 0.6em;
  text-decoration: none;
}
.aa-SourceFooterSeeAll:focus, .aa-SourceFooterSeeAll:hover {
  border: 1px solid rgba(var(--aa-primary-color-rgb), 1);
  color: rgba(var(--aa-primary-color-rgb), 1);
}

.aa-Item {
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: grid;
  min-height: calc(var(--aa-spacing) * 2.5);
  padding: calc(var(--aa-spacing-half) / 2);
}
.aa-Item[aria-selected=true] {
  background-color: rgba(var(--aa-selected-color-rgb), var(--aa-selected-color-alpha));
}
.aa-Item[aria-selected=true] .aa-ItemActionButton,
.aa-Item[aria-selected=true] .aa-ActiveOnly {
  visibility: visible;
}
.aa-ItemIcon {
  align-items: center;
  background: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(var(--aa-panel-border-color-rgb), var(--aa-panel-border-color-alpha));
  color: rgba(var(--aa-icon-color-rgb), var(--aa-icon-color-alpha));
  display: flex;
  flex-shrink: 0;
  font-size: 0.7em;
  height: calc(var(--aa-icon-size) + var(--aa-spacing-half));
  justify-content: center;
  overflow: hidden;
  stroke-width: var(--aa-icon-stroke-width);
  text-align: center;
  width: calc(var(--aa-icon-size) + var(--aa-spacing-half));
}
.aa-ItemIcon img {
  height: auto;
  max-height: calc(var(--aa-icon-size) + var(--aa-spacing-half) - 8px);
  max-width: calc(var(--aa-icon-size) + var(--aa-spacing-half) - 8px);
  width: auto;
}
.aa-ItemIcon svg {
  height: var(--aa-icon-size);
  width: var(--aa-icon-size);
}
.aa-ItemIcon--alignTop {
  align-self: flex-start;
}

.aa-ItemIcon--noBorder {
  background: none;
  box-shadow: none;
}

.aa-ItemIcon--picture {
  height: 96px;
  width: 96px;
}
.aa-ItemIcon--picture img {
  max-height: 100%;
  max-width: 100%;
  padding: var(--aa-spacing-half);
}

.aa-ItemContent {
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: var(--aa-spacing-half);
  grid-auto-flow: column;
  line-height: 1.25em;
  overflow: hidden;
}
.aa-ItemContent:empty {
  display: none;
}
.aa-ItemContent mark, .aa-ItemContent .mark {
  background: none;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  font-style: normal;
  font-weight: var(--aa-font-weight-bold);
}
.aa-ItemContent--dual {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
.aa-ItemContent--dual .aa-ItemContentTitle,
.aa-ItemContent--dual .aa-ItemContentSubtitle {
  display: block;
}

.aa-ItemContent--indented {
  padding-left: calc(var(--aa-icon-size) + var(--aa-spacing));
}

.aa-ItemContentBody {
  display: grid;
  gap: calc(var(--aa-spacing-half) / 2);
}

.aa-ItemContentTitle {
  display: inline-block;
  margin: 0 0.5em 0 0;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.aa-ItemContentSubtitle {
  font-size: 0.92em;
}
.aa-ItemContentSubtitleIcon::before {
  border-color: rgba(var(--aa-muted-color-rgb), 0.64);
  border-style: solid;
  content: "";
  display: inline-block;
  left: 1px;
  position: relative;
  top: -3px;
}

.aa-ItemContentSubtitle--inline .aa-ItemContentSubtitleIcon::before {
  border-width: 0 0 1.5px;
  margin-left: var(--aa-spacing-half);
  margin-right: calc(var(--aa-spacing-half) / 2);
  width: calc(var(--aa-spacing-half) + 2px);
}

.aa-ItemContentSubtitle--standalone {
  align-items: center;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  display: grid;
  gap: var(--aa-spacing-half);
  grid-auto-flow: column;
  justify-content: start;
}
.aa-ItemContentSubtitle--standalone .aa-ItemContentSubtitleIcon::before {
  border-radius: 0 0 0 3px;
  border-width: 0 0 1.5px 1.5px;
  height: var(--aa-spacing-half);
  width: var(--aa-spacing-half);
}

.aa-ItemContentSubtitleCategory {
  color: rgba(var(--aa-muted-color-rgb), 1);
  font-weight: 500;
}

.aa-ItemContentDescription {
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  font-size: 0.85em;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.aa-ItemContentDescription:empty {
  display: none;
}
.aa-ItemContentDescription mark, .aa-ItemContentDescription .mark {
  background: rgba(var(--aa-description-highlight-background-color-rgb), var(--aa-description-highlight-background-color-alpha));
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  font-style: normal;
  font-weight: var(--aa-font-weight-medium);
}

.aa-ItemContentDash {
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  display: none;
  opacity: 0.4;
}

.aa-ItemContentTag {
  background-color: rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha));
  border-radius: 3px;
  margin: 0 0.4em 0 0;
  padding: 0.08em 0.3em;
}

.aa-ItemWrapper,
.aa-ItemLink {
  align-items: center;
  color: inherit;
  display: grid;
  gap: calc(var(--aa-spacing-half) / 2);
  grid-auto-flow: column;
  justify-content: space-between;
  width: 100%;
}

.aa-ItemLink {
  color: inherit;
  text-decoration: none;
}

.aa-ItemActions {
  display: grid;
  grid-auto-flow: column;
  height: 100%;
  justify-self: end;
  margin: 0 calc(var(--aa-spacing) / -3);
  padding: 0 2px 0 0;
}

.aa-ItemActionButton {
  align-items: center;
  background: none;
  border: 0;
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  padding: 0;
}
.aa-ItemActionButton:hover svg, .aa-ItemActionButton:focus svg {
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
}
@media (hover: none) and (pointer: coarse) {
  .aa-ItemActionButton:hover svg, .aa-ItemActionButton:focus svg {
    color: inherit;
  }
}
.aa-ItemActionButton svg {
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  margin: 0;
  margin: calc(var(--aa-spacing) / 3);
  stroke-width: var(--aa-icon-stroke-width);
  width: var(--aa-action-icon-size);
}

.aa-ActiveOnly {
  visibility: hidden;
}

.aa-PanelHeader {
  align-items: center;
  background: rgba(var(--aa-primary-color-rgb), 1);
  color: #fff;
  display: grid;
  height: var(--aa-modal-header-height);
  margin: 0;
  padding: var(--aa-spacing-half) var(--aa-spacing);
  position: relative;
}
.aa-PanelHeader::after {
  background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 1), rgba(var(--aa-background-color-rgb), 0));
  bottom: calc(var(--aa-spacing-half) * -1);
  content: "";
  height: var(--aa-spacing-half);
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  z-index: var(--aa-base-z-index);
}

.aa-PanelFooter {
  background-color: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  box-shadow: inset 0 1px 0 rgba(var(--aa-panel-border-color-rgb), var(--aa-panel-border-color-alpha));
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: var(--aa-spacing);
  position: relative;
  z-index: var(--aa-base-z-index);
}
.aa-PanelFooter::after {
  background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 0), rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha)));
  content: "";
  height: var(--aa-spacing);
  left: 0;
  opacity: 0.12;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: calc(var(--aa-spacing) * -1);
  z-index: calc(var(--aa-base-z-index) - 1);
}

.aa-DetachedContainer {
  background: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  bottom: 0;
  box-shadow: var(--aa-panel-shadow);
  display: flex;
  flex-direction: column;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--aa-base-z-index);
}
.aa-DetachedContainer::after {
  height: 32px;
}
.aa-DetachedContainer .aa-SourceHeader {
  margin: var(--aa-spacing-half) 0 var(--aa-spacing-half) 2px;
}
.aa-DetachedContainer .aa-Panel {
  background-color: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  border-radius: 0;
  box-shadow: none;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  position: relative;
}
.aa-DetachedContainer .aa-PanelLayout {
  bottom: 0;
  box-shadow: none;
  left: 0;
  margin: 0;
  max-height: none;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.aa-DetachedFormContainer {
  border-bottom: solid 1px rgba(var(--aa-panel-border-color-rgb), var(--aa-panel-border-color-alpha));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: var(--aa-spacing-half);
}
.aa-DetachedCancelButton {
  background: none;
  border: 0;
  border-radius: 3px;
  color: inherit;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  cursor: pointer;
  font: inherit;
  margin: 0 0 0 var(--aa-spacing-half);
  padding: 0 var(--aa-spacing-half);
}
.aa-DetachedCancelButton:hover, .aa-DetachedCancelButton:focus {
  box-shadow: inset 0 0 0 1px rgba(var(--aa-panel-border-color-rgb), var(--aa-panel-border-color-alpha));
}

.aa-DetachedContainer--modal {
  border-radius: 6px;
  bottom: inherit;
  height: auto;
  margin: 0 auto;
  max-width: var(--aa-detached-modal-max-width);
  position: absolute;
  top: 3%;
}
.aa-DetachedContainer--modal .aa-PanelLayout {
  max-height: var(--aa-detached-modal-max-height);
  padding-bottom: var(--aa-spacing-half);
  position: static;
}

.aa-DetachedSearchButton {
  align-items: center;
  background-color: rgba(var(--aa-input-background-color-rgb), var(--aa-input-background-color-alpha));
  border: 1px solid rgba(var(--aa-input-border-color-rgb), var(--aa-input-border-color-alpha));
  border-radius: 3px;
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  cursor: pointer;
  display: flex;
  font: inherit;
  font-family: var(--aa-font-family);
  font-size: var(--aa-font-size);
  height: var(--aa-search-input-height);
  margin: 0;
  padding: 0 calc(var(--aa-search-input-height) / 8);
  position: relative;
  text-align: left;
  width: 100%;
}
.aa-DetachedSearchButton:focus {
  border-color: rgba(var(--aa-primary-color-rgb), 1);
  box-shadow: rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha)) 0 0 0 3px, inset rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha)) 0 0 0 2px;
  outline: currentColor none medium;
}
.aa-DetachedSearchButtonIcon {
  align-items: center;
  color: rgba(var(--aa-primary-color-rgb), 1);
  cursor: initial;
  display: flex;
  height: 100%;
  justify-content: center;
  width: calc(var(--aa-icon-size) + var(--aa-spacing));
}

.aa-Detached {
  height: 100vh;
  overflow: hidden;
}

.aa-DetachedOverlay {
  background-color: rgba(var(--aa-overlay-color-rgb), var(--aa-overlay-color-alpha));
  height: 100vh;
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: calc(var(--aa-base-z-index) - 1);
}

.aa-GradientTop,
.aa-GradientBottom {
  height: var(--aa-spacing-half);
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  z-index: var(--aa-base-z-index);
}

.aa-GradientTop {
  background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 1), rgba(var(--aa-background-color-rgb), 0));
  top: 0;
}

.aa-GradientBottom {
  background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 0), rgba(var(--aa-background-color-rgb), 1));
  border-bottom-left-radius: calc(var(--aa-spacing) / 4);
  border-bottom-right-radius: calc(var(--aa-spacing) / 4);
  bottom: 0;
}

@media (hover: none) and (pointer: coarse) {
  .aa-DesktopOnly {
    display: none;
  }
}

@media (hover: hover) {
  .aa-TouchOnly {
    display: none;
  }
}

/**
 *
 * tailwind ripoff
 *
 */
.bg-transparent {
  background-color: transparent;
}

.bg-black {
  background-color: rgb(0, 0, 0);
}

.bg-white {
  background-color: rgb(255, 255, 255);
}

.bg-slate-50 {
  background-color: rgb(248, 250, 252);
}

.bg-slate-100 {
  background-color: rgb(241, 245, 249);
}

.bg-slate-200 {
  background-color: rgb(226, 232, 240);
}

.bg-slate-300 {
  background-color: rgb(203, 213, 225);
}

.bg-slate-400 {
  background-color: rgb(148, 163, 184);
}

.bg-slate-500 {
  background-color: rgb(100, 116, 139);
}

.bg-slate-600 {
  background-color: rgb(71, 85, 105);
}

.bg-slate-700 {
  background-color: rgb(51, 65, 85);
}

.bg-slate-800 {
  background-color: rgb(30, 41, 59);
}

.bg-slate-900 {
  background-color: rgb(15, 23, 42);
}

.bg-gray-50 {
  background-color: rgb(249, 250, 251);
}

.bg-gray-100 {
  background-color: rgb(243, 244, 246);
}

.bg-gray-200 {
  background-color: rgb(229, 231, 235);
}

.bg-gray-300 {
  background-color: rgb(209, 213, 219);
}

.bg-gray-400 {
  background-color: rgb(156, 163, 175);
}

.bg-gray-500 {
  background-color: rgb(107, 114, 128);
}

.bg-gray-600 {
  background-color: rgb(75, 85, 99);
}

.bg-gray-700 {
  background-color: rgb(55, 65, 81);
}

.bg-gray-800 {
  background-color: rgb(31, 41, 55);
}

.bg-gray-900 {
  background-color: rgb(17, 24, 39);
}

.bg-zinc-50 {
  background-color: rgb(250, 250, 250);
}

.bg-zinc-100 {
  background-color: rgb(244, 244, 245);
}

.bg-zinc-200 {
  background-color: rgb(228, 228, 231);
}

.bg-zinc-300 {
  background-color: rgb(212, 212, 216);
}

.bg-zinc-400 {
  background-color: rgb(161, 161, 170);
}

.bg-zinc-500 {
  background-color: rgb(113, 113, 122);
}

.bg-zinc-600 {
  background-color: rgb(82, 82, 91);
}

.bg-zinc-700 {
  background-color: rgb(63, 63, 70);
}

.bg-zinc-800 {
  background-color: rgb(39, 39, 42);
}

.bg-zinc-900 {
  background-color: rgb(24, 24, 27);
}

.bg-neutral-50 {
  background-color: rgb(250, 250, 250);
}

.bg-neutral-100 {
  background-color: rgb(245, 245, 245);
}

.bg-neutral-200 {
  background-color: rgb(229, 229, 229);
}

.bg-neutral-300 {
  background-color: rgb(212, 212, 212);
}

.bg-neutral-400 {
  background-color: rgb(163, 163, 163);
}

.bg-neutral-500 {
  background-color: rgb(115, 115, 115);
}

.bg-neutral-600 {
  background-color: rgb(82, 82, 82);
}

.bg-neutral-700 {
  background-color: rgb(64, 64, 64);
}

.bg-neutral-800 {
  background-color: rgb(38, 38, 38);
}

.bg-neutral-900 {
  background-color: rgb(23, 23, 23);
}

.bg-stone-50 {
  background-color: rgb(250, 250, 249);
}

.bg-stone-100 {
  background-color: rgb(245, 245, 244);
}

.bg-stone-200 {
  background-color: rgb(231, 229, 228);
}

.bg-stone-300 {
  background-color: rgb(214, 211, 209);
}

.bg-stone-400 {
  background-color: rgb(168, 162, 158);
}

.bg-stone-500 {
  background-color: rgb(120, 113, 108);
}

.bg-stone-600 {
  background-color: rgb(87, 83, 78);
}

.bg-stone-700 {
  background-color: rgb(68, 64, 60);
}

.bg-stone-800 {
  background-color: rgb(41, 37, 36);
}

.bg-stone-900 {
  background-color: rgb(28, 25, 23);
}

.bg-red-50 {
  background-color: rgb(254, 242, 242);
}

.bg-red-100 {
  background-color: rgb(254, 226, 226);
}

.bg-red-200 {
  background-color: rgb(254, 202, 202);
}

.bg-red-300 {
  background-color: rgb(252, 165, 165);
}

.bg-red-400 {
  background-color: rgb(248, 113, 113);
}

.bg-red-500 {
  background-color: rgb(239, 68, 68);
}

.bg-red-600 {
  background-color: rgb(220, 38, 38);
}

.bg-red-700 {
  background-color: rgb(185, 28, 28);
}

.bg-red-800 {
  background-color: rgb(153, 27, 27);
}

.bg-red-900 {
  background-color: rgb(127, 29, 29);
}

.bg-orange-50 {
  background-color: rgb(255, 247, 237);
}

.bg-orange-100 {
  background-color: rgb(255, 237, 213);
}

.bg-orange-200 {
  background-color: rgb(254, 215, 170);
}

.bg-orange-300 {
  background-color: rgb(253, 186, 116);
}

.bg-orange-400 {
  background-color: rgb(251, 146, 60);
}

.bg-orange-500 {
  background-color: rgb(249, 115, 22);
}

.bg-orange-600 {
  background-color: rgb(234, 88, 12);
}

.bg-orange-700 {
  background-color: rgb(194, 65, 12);
}

.bg-orange-800 {
  background-color: rgb(154, 52, 18);
}

.bg-orange-900 {
  background-color: rgb(124, 45, 18);
}

.bg-amber-50 {
  background-color: rgb(255, 251, 235);
}

.bg-amber-100 {
  background-color: rgb(254, 243, 199);
}

.bg-amber-200 {
  background-color: rgb(253, 230, 138);
}

.bg-amber-300 {
  background-color: rgb(252, 211, 77);
}

.bg-amber-400 {
  background-color: rgb(251, 191, 36);
}

.bg-amber-500 {
  background-color: rgb(245, 158, 11);
}

.bg-amber-600 {
  background-color: rgb(217, 119, 6);
}

.bg-amber-700 {
  background-color: rgb(180, 83, 9);
}

.bg-amber-800 {
  background-color: rgb(146, 64, 14);
}

.bg-amber-900 {
  background-color: rgb(120, 53, 15);
}

.bg-yellow-50 {
  background-color: rgb(254, 252, 232);
}

.bg-yellow-100 {
  background-color: rgb(254, 249, 195);
}

.bg-yellow-200 {
  background-color: rgb(254, 240, 138);
}

.bg-yellow-300 {
  background-color: rgb(253, 224, 71);
}

.bg-yellow-400 {
  background-color: rgb(250, 204, 21);
}

.bg-yellow-500 {
  background-color: rgb(234, 179, 8);
}

.bg-yellow-600 {
  background-color: rgb(202, 138, 4);
}

.bg-yellow-700 {
  background-color: rgb(161, 98, 7);
}

.bg-yellow-800 {
  background-color: rgb(133, 77, 14);
}

.bg-yellow-900 {
  background-color: rgb(113, 63, 18);
}

.bg-lime-50 {
  background-color: rgb(247, 254, 231);
}

.bg-lime-100 {
  background-color: rgb(236, 252, 203);
}

.bg-lime-200 {
  background-color: rgb(217, 249, 157);
}

.bg-lime-300 {
  background-color: rgb(190, 242, 100);
}

.bg-lime-400 {
  background-color: rgb(163, 230, 53);
}

.bg-lime-500 {
  background-color: rgb(132, 204, 22);
}

.bg-lime-600 {
  background-color: rgb(101, 163, 13);
}

.bg-lime-700 {
  background-color: rgb(77, 124, 15);
}

.bg-lime-800 {
  background-color: rgb(63, 98, 18);
}

.bg-lime-900 {
  background-color: rgb(54, 83, 20);
}

.bg-green-50 {
  background-color: rgb(240, 253, 244);
}

.bg-green-100 {
  background-color: rgb(220, 252, 231);
}

.bg-green-200 {
  background-color: rgb(187, 247, 208);
}

.bg-green-300 {
  background-color: rgb(134, 239, 172);
}

.bg-green-400 {
  background-color: rgb(74, 222, 128);
}

.bg-green-500 {
  background-color: rgb(34, 197, 94);
}

.bg-green-600 {
  background-color: rgb(22, 163, 74);
}

.bg-green-700 {
  background-color: rgb(21, 128, 61);
}

.bg-green-800 {
  background-color: rgb(22, 101, 52);
}

.bg-green-900 {
  background-color: rgb(20, 83, 45);
}

.bg-emerald-50 {
  background-color: rgb(236, 253, 245);
}

.bg-emerald-100 {
  background-color: rgb(209, 250, 229);
}

.bg-emerald-200 {
  background-color: rgb(167, 243, 208);
}

.bg-emerald-300 {
  background-color: rgb(110, 231, 183);
}

.bg-emerald-400 {
  background-color: rgb(52, 211, 153);
}

.bg-emerald-500 {
  background-color: rgb(16, 185, 129);
}

.bg-emerald-600 {
  background-color: rgb(5, 150, 105);
}

.bg-emerald-700 {
  background-color: rgb(4, 120, 87);
}

.bg-emerald-800 {
  background-color: rgb(6, 95, 70);
}

.bg-emerald-900 {
  background-color: rgb(6, 78, 59);
}

.bg-teal-50 {
  background-color: rgb(240, 253, 250);
}

.bg-teal-100 {
  background-color: rgb(204, 251, 241);
}

.bg-teal-200 {
  background-color: rgb(153, 246, 228);
}

.bg-teal-300 {
  background-color: rgb(94, 234, 212);
}

.bg-teal-400 {
  background-color: rgb(45, 212, 191);
}

.bg-teal-500 {
  background-color: rgb(20, 184, 166);
}

.bg-teal-600 {
  background-color: rgb(13, 148, 136);
}

.bg-teal-700 {
  background-color: rgb(15, 118, 110);
}

.bg-teal-800 {
  background-color: rgb(17, 94, 89);
}

.bg-teal-900 {
  background-color: rgb(19, 78, 74);
}

.bg-cyan-50 {
  background-color: rgb(236, 254, 255);
}

.bg-cyan-100 {
  background-color: rgb(207, 250, 254);
}

.bg-cyan-200 {
  background-color: rgb(165, 243, 252);
}

.bg-cyan-300 {
  background-color: rgb(103, 232, 249);
}

.bg-cyan-400 {
  background-color: rgb(34, 211, 238);
}

.bg-cyan-500 {
  background-color: rgb(6, 182, 212);
}

.bg-cyan-600 {
  background-color: rgb(8, 145, 178);
}

.bg-cyan-700 {
  background-color: rgb(14, 116, 144);
}

.bg-cyan-800 {
  background-color: rgb(21, 94, 117);
}

.bg-cyan-900 {
  background-color: rgb(22, 78, 99);
}

.bg-sky-50 {
  background-color: rgb(240, 249, 255);
}

.bg-sky-100 {
  background-color: rgb(224, 242, 254);
}

.bg-sky-200 {
  background-color: rgb(186, 230, 253);
}

.bg-sky-300 {
  background-color: rgb(125, 211, 252);
}

.bg-sky-400 {
  background-color: rgb(56, 189, 248);
}

.bg-sky-500 {
  background-color: rgb(14, 165, 233);
}

.bg-sky-600 {
  background-color: rgb(2, 132, 199);
}

.bg-sky-700 {
  background-color: rgb(3, 105, 161);
}

.bg-sky-800 {
  background-color: rgb(7, 89, 133);
}

.bg-sky-900 {
  background-color: rgb(12, 74, 110);
}

.bg-blue-50 {
  background-color: rgb(239, 246, 255);
}

.bg-blue-100 {
  background-color: rgb(219, 234, 254);
}

.bg-blue-200 {
  background-color: rgb(191, 219, 254);
}

.bg-blue-300 {
  background-color: rgb(147, 197, 253);
}

.bg-blue-400 {
  background-color: rgb(96, 165, 250);
}

.bg-blue-500 {
  background-color: rgb(59, 130, 246);
}

.bg-blue-600 {
  background-color: rgb(37, 99, 235);
}

.bg-blue-700 {
  background-color: rgb(29, 78, 216);
}

.bg-blue-800 {
  background-color: rgb(30, 64, 175);
}

.bg-blue-900 {
  background-color: rgb(30, 58, 138);
}

.bg-indigo-50 {
  background-color: rgb(238, 242, 255);
}

.bg-indigo-100 {
  background-color: rgb(224, 231, 255);
}

.bg-indigo-200 {
  background-color: rgb(199, 210, 254);
}

.bg-indigo-300 {
  background-color: rgb(165, 180, 252);
}

.bg-indigo-400 {
  background-color: rgb(129, 140, 248);
}

.bg-indigo-500 {
  background-color: rgb(99, 102, 241);
}

.bg-indigo-600 {
  background-color: rgb(79, 70, 229);
}

.bg-indigo-700 {
  background-color: rgb(67, 56, 202);
}

.bg-indigo-800 {
  background-color: rgb(55, 48, 163);
}

.bg-indigo-900 {
  background-color: rgb(49, 46, 129);
}

.bg-violet-50 {
  background-color: rgb(245, 243, 255);
}

.bg-violet-100 {
  background-color: rgb(237, 233, 254);
}

.bg-violet-200 {
  background-color: rgb(221, 214, 254);
}

.bg-violet-300 {
  background-color: rgb(196, 181, 253);
}

.bg-violet-400 {
  background-color: rgb(167, 139, 250);
}

.bg-violet-500 {
  background-color: rgb(139, 92, 246);
}

.bg-violet-600 {
  background-color: rgb(124, 58, 237);
}

.bg-violet-700 {
  background-color: rgb(109, 40, 217);
}

.bg-violet-800 {
  background-color: rgb(91, 33, 182);
}

.bg-violet-900 {
  background-color: rgb(76, 29, 149);
}

.bg-purple-50 {
  background-color: rgb(250, 245, 255);
}

.bg-purple-100 {
  background-color: rgb(243, 232, 255);
}

.bg-purple-200 {
  background-color: rgb(233, 213, 255);
}

.bg-purple-300 {
  background-color: rgb(216, 180, 254);
}

.bg-purple-400 {
  background-color: rgb(192, 132, 252);
}

.bg-purple-500 {
  background-color: rgb(168, 85, 247);
}

.bg-purple-600 {
  background-color: rgb(147, 51, 234);
}

.bg-purple-700 {
  background-color: rgb(126, 34, 206);
}

.bg-purple-800 {
  background-color: rgb(107, 33, 168);
}

.bg-purple-900 {
  background-color: rgb(88, 28, 135);
}

.bg-fuchsia-50 {
  background-color: rgb(253, 244, 255);
}

.bg-fuchsia-100 {
  background-color: rgb(250, 232, 255);
}

.bg-fuchsia-200 {
  background-color: rgb(245, 208, 254);
}

.bg-fuchsia-300 {
  background-color: rgb(240, 171, 252);
}

.bg-fuchsia-400 {
  background-color: rgb(232, 121, 249);
}

.bg-fuchsia-500 {
  background-color: rgb(217, 70, 239);
}

.bg-fuchsia-600 {
  background-color: rgb(192, 38, 211);
}

.bg-fuchsia-700 {
  background-color: rgb(162, 28, 175);
}

.bg-fuchsia-800 {
  background-color: rgb(134, 25, 143);
}

.bg-fuchsia-900 {
  background-color: rgb(112, 26, 117);
}

.bg-pink-50 {
  background-color: rgb(253, 242, 248);
}

.bg-pink-100 {
  background-color: rgb(252, 231, 243);
}

.bg-pink-200 {
  background-color: rgb(251, 207, 232);
}

.bg-pink-300 {
  background-color: rgb(249, 168, 212);
}

.bg-pink-400 {
  background-color: rgb(244, 114, 182);
}

.bg-pink-500 {
  background-color: rgb(236, 72, 153);
}

.bg-pink-600 {
  background-color: rgb(219, 39, 119);
}

.bg-pink-700 {
  background-color: rgb(190, 24, 93);
}

.bg-pink-800 {
  background-color: rgb(157, 23, 77);
}

.bg-pink-900 {
  background-color: rgb(131, 24, 67);
}

.bg-rose-50 {
  background-color: rgb(255, 241, 242);
}

.bg-rose-100 {
  background-color: rgb(255, 228, 230);
}

.bg-rose-200 {
  background-color: rgb(254, 205, 211);
}

.bg-rose-300 {
  background-color: rgb(253, 164, 175);
}

.bg-rose-400 {
  background-color: rgb(251, 113, 133);
}

.bg-rose-500 {
  background-color: rgb(244, 63, 94);
}

.bg-rose-600 {
  background-color: rgb(225, 29, 72);
}

.bg-rose-700 {
  background-color: rgb(190, 18, 60);
}

.bg-rose-800 {
  background-color: rgb(159, 18, 57);
}

.bg-rose-900 {
  background-color: rgb(136, 19, 55);
}

.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.text-slate-50 {
  color: rgb(248, 250, 252);
}

.text-slate-100 {
  color: rgb(241, 245, 249);
}

.text-slate-200 {
  color: rgb(226, 232, 240);
}

.text-slate-300 {
  color: rgb(203, 213, 225);
}

.text-slate-400 {
  color: rgb(148, 163, 184);
}

.text-slate-500 {
  color: rgb(100, 116, 139);
}

.text-slate-600 {
  color: rgb(71, 85, 105);
}

.text-slate-700 {
  color: rgb(51, 65, 85);
}

.text-slate-800 {
  color: rgb(30, 41, 59);
}

.text-slate-900 {
  color: rgb(15, 23, 42);
}

.text-gray-50 {
  color: rgb(249, 250, 251);
}

.text-gray-100 {
  color: rgb(243, 244, 246);
}

.text-gray-200 {
  color: rgb(229, 231, 235);
}

.text-gray-300 {
  color: rgb(209, 213, 219);
}

.text-gray-400 {
  color: rgb(156, 163, 175);
}

.text-gray-500 {
  color: rgb(107, 114, 128);
}

.text-gray-600 {
  color: rgb(75, 85, 99);
}

.text-gray-700 {
  color: rgb(55, 65, 81);
}

.text-gray-800 {
  color: rgb(31, 41, 55);
}

.text-gray-900 {
  color: rgb(17, 24, 39);
}

.text-zinc-50 {
  color: rgb(250, 250, 250);
}

.text-zinc-100 {
  color: rgb(244, 244, 245);
}

.text-zinc-200 {
  color: rgb(228, 228, 231);
}

.text-zinc-300 {
  color: rgb(212, 212, 216);
}

.text-zinc-400 {
  color: rgb(161, 161, 170);
}

.text-zinc-500 {
  color: rgb(113, 113, 122);
}

.text-zinc-600 {
  color: rgb(82, 82, 91);
}

.text-zinc-700 {
  color: rgb(63, 63, 70);
}

.text-zinc-800 {
  color: rgb(39, 39, 42);
}

.text-zinc-900 {
  color: rgb(24, 24, 27);
}

.text-neutral-50 {
  color: rgb(250, 250, 250);
}

.text-neutral-100 {
  color: rgb(245, 245, 245);
}

.text-neutral-200 {
  color: rgb(229, 229, 229);
}

.text-neutral-300 {
  color: rgb(212, 212, 212);
}

.text-neutral-400 {
  color: rgb(163, 163, 163);
}

.text-neutral-500 {
  color: rgb(115, 115, 115);
}

.text-neutral-600 {
  color: rgb(82, 82, 82);
}

.text-neutral-700 {
  color: rgb(64, 64, 64);
}

.text-neutral-800 {
  color: rgb(38, 38, 38);
}

.text-neutral-900 {
  color: rgb(23, 23, 23);
}

.text-stone-50 {
  color: rgb(250, 250, 249);
}

.text-stone-100 {
  color: rgb(245, 245, 244);
}

.text-stone-200 {
  color: rgb(231, 229, 228);
}

.text-stone-300 {
  color: rgb(214, 211, 209);
}

.text-stone-400 {
  color: rgb(168, 162, 158);
}

.text-stone-500 {
  color: rgb(120, 113, 108);
}

.text-stone-600 {
  color: rgb(87, 83, 78);
}

.text-stone-700 {
  color: rgb(68, 64, 60);
}

.text-stone-800 {
  color: rgb(41, 37, 36);
}

.text-stone-900 {
  color: rgb(28, 25, 23);
}

.text-red-50 {
  color: rgb(254, 242, 242);
}

.text-red-100 {
  color: rgb(254, 226, 226);
}

.text-red-200 {
  color: rgb(254, 202, 202);
}

.text-red-300 {
  color: rgb(252, 165, 165);
}

.text-red-400 {
  color: rgb(248, 113, 113);
}

.text-red-500 {
  color: rgb(239, 68, 68);
}

.text-red-600 {
  color: rgb(220, 38, 38);
}

.text-red-700 {
  color: rgb(185, 28, 28);
}

.text-red-800 {
  color: rgb(153, 27, 27);
}

.text-red-900 {
  color: rgb(127, 29, 29);
}

.text-orange-50 {
  color: rgb(255, 247, 237);
}

.text-orange-100 {
  color: rgb(255, 237, 213);
}

.text-orange-200 {
  color: rgb(254, 215, 170);
}

.text-orange-300 {
  color: rgb(253, 186, 116);
}

.text-orange-400 {
  color: rgb(251, 146, 60);
}

.text-orange-500 {
  color: rgb(249, 115, 22);
}

.text-orange-600 {
  color: rgb(234, 88, 12);
}

.text-orange-700 {
  color: rgb(194, 65, 12);
}

.text-orange-800 {
  color: rgb(154, 52, 18);
}

.text-orange-900 {
  color: rgb(124, 45, 18);
}

.text-amber-50 {
  color: rgb(255, 251, 235);
}

.text-amber-100 {
  color: rgb(254, 243, 199);
}

.text-amber-200 {
  color: rgb(253, 230, 138);
}

.text-amber-300 {
  color: rgb(252, 211, 77);
}

.text-amber-400 {
  color: rgb(251, 191, 36);
}

.text-amber-500 {
  color: rgb(245, 158, 11);
}

.text-amber-600 {
  color: rgb(217, 119, 6);
}

.text-amber-700 {
  color: rgb(180, 83, 9);
}

.text-amber-800 {
  color: rgb(146, 64, 14);
}

.text-amber-900 {
  color: rgb(120, 53, 15);
}

.text-yellow-50 {
  color: rgb(254, 252, 232);
}

.text-yellow-100 {
  color: rgb(254, 249, 195);
}

.text-yellow-200 {
  color: rgb(254, 240, 138);
}

.text-yellow-300 {
  color: rgb(253, 224, 71);
}

.text-yellow-400 {
  color: rgb(250, 204, 21);
}

.text-yellow-500 {
  color: rgb(234, 179, 8);
}

.text-yellow-600 {
  color: rgb(202, 138, 4);
}

.text-yellow-700 {
  color: rgb(161, 98, 7);
}

.text-yellow-800 {
  color: rgb(133, 77, 14);
}

.text-yellow-900 {
  color: rgb(113, 63, 18);
}

.text-lime-50 {
  color: rgb(247, 254, 231);
}

.text-lime-100 {
  color: rgb(236, 252, 203);
}

.text-lime-200 {
  color: rgb(217, 249, 157);
}

.text-lime-300 {
  color: rgb(190, 242, 100);
}

.text-lime-400 {
  color: rgb(163, 230, 53);
}

.text-lime-500 {
  color: rgb(132, 204, 22);
}

.text-lime-600 {
  color: rgb(101, 163, 13);
}

.text-lime-700 {
  color: rgb(77, 124, 15);
}

.text-lime-800 {
  color: rgb(63, 98, 18);
}

.text-lime-900 {
  color: rgb(54, 83, 20);
}

.text-green-50 {
  color: rgb(240, 253, 244);
}

.text-green-100 {
  color: rgb(220, 252, 231);
}

.text-green-200 {
  color: rgb(187, 247, 208);
}

.text-green-300 {
  color: rgb(134, 239, 172);
}

.text-green-400 {
  color: rgb(74, 222, 128);
}

.text-green-500 {
  color: rgb(34, 197, 94);
}

.text-green-600 {
  color: rgb(22, 163, 74);
}

.text-green-700 {
  color: rgb(21, 128, 61);
}

.text-green-800 {
  color: rgb(22, 101, 52);
}

.text-green-900 {
  color: rgb(20, 83, 45);
}

.text-emerald-50 {
  color: rgb(236, 253, 245);
}

.text-emerald-100 {
  color: rgb(209, 250, 229);
}

.text-emerald-200 {
  color: rgb(167, 243, 208);
}

.text-emerald-300 {
  color: rgb(110, 231, 183);
}

.text-emerald-400 {
  color: rgb(52, 211, 153);
}

.text-emerald-500 {
  color: rgb(16, 185, 129);
}

.text-emerald-600 {
  color: rgb(5, 150, 105);
}

.text-emerald-700 {
  color: rgb(4, 120, 87);
}

.text-emerald-800 {
  color: rgb(6, 95, 70);
}

.text-emerald-900 {
  color: rgb(6, 78, 59);
}

.text-teal-50 {
  color: rgb(240, 253, 250);
}

.text-teal-100 {
  color: rgb(204, 251, 241);
}

.text-teal-200 {
  color: rgb(153, 246, 228);
}

.text-teal-300 {
  color: rgb(94, 234, 212);
}

.text-teal-400 {
  color: rgb(45, 212, 191);
}

.text-teal-500 {
  color: rgb(20, 184, 166);
}

.text-teal-600 {
  color: rgb(13, 148, 136);
}

.text-teal-700 {
  color: rgb(15, 118, 110);
}

.text-teal-800 {
  color: rgb(17, 94, 89);
}

.text-teal-900 {
  color: rgb(19, 78, 74);
}

.text-cyan-50 {
  color: rgb(236, 254, 255);
}

.text-cyan-100 {
  color: rgb(207, 250, 254);
}

.text-cyan-200 {
  color: rgb(165, 243, 252);
}

.text-cyan-300 {
  color: rgb(103, 232, 249);
}

.text-cyan-400 {
  color: rgb(34, 211, 238);
}

.text-cyan-500 {
  color: rgb(6, 182, 212);
}

.text-cyan-600 {
  color: rgb(8, 145, 178);
}

.text-cyan-700 {
  color: rgb(14, 116, 144);
}

.text-cyan-800 {
  color: rgb(21, 94, 117);
}

.text-cyan-900 {
  color: rgb(22, 78, 99);
}

.text-sky-50 {
  color: rgb(240, 249, 255);
}

.text-sky-100 {
  color: rgb(224, 242, 254);
}

.text-sky-200 {
  color: rgb(186, 230, 253);
}

.text-sky-300 {
  color: rgb(125, 211, 252);
}

.text-sky-400 {
  color: rgb(56, 189, 248);
}

.text-sky-500 {
  color: rgb(14, 165, 233);
}

.text-sky-600 {
  color: rgb(2, 132, 199);
}

.text-sky-700 {
  color: rgb(3, 105, 161);
}

.text-sky-800 {
  color: rgb(7, 89, 133);
}

.text-sky-900 {
  color: rgb(12, 74, 110);
}

.text-blue-50 {
  color: rgb(239, 246, 255);
}

.text-blue-100 {
  color: rgb(219, 234, 254);
}

.text-blue-200 {
  color: rgb(191, 219, 254);
}

.text-blue-300 {
  color: rgb(147, 197, 253);
}

.text-blue-400 {
  color: rgb(96, 165, 250);
}

.text-blue-500 {
  color: rgb(59, 130, 246);
}

.text-blue-600 {
  color: rgb(37, 99, 235);
}

.text-blue-700 {
  color: rgb(29, 78, 216);
}

.text-blue-800 {
  color: rgb(30, 64, 175);
}

.text-blue-900 {
  color: rgb(30, 58, 138);
}

.text-indigo-50 {
  color: rgb(238, 242, 255);
}

.text-indigo-100 {
  color: rgb(224, 231, 255);
}

.text-indigo-200 {
  color: rgb(199, 210, 254);
}

.text-indigo-300 {
  color: rgb(165, 180, 252);
}

.text-indigo-400 {
  color: rgb(129, 140, 248);
}

.text-indigo-500 {
  color: rgb(99, 102, 241);
}

.text-indigo-600 {
  color: rgb(79, 70, 229);
}

.text-indigo-700 {
  color: rgb(67, 56, 202);
}

.text-indigo-800 {
  color: rgb(55, 48, 163);
}

.text-indigo-900 {
  color: rgb(49, 46, 129);
}

.text-violet-50 {
  color: rgb(245, 243, 255);
}

.text-violet-100 {
  color: rgb(237, 233, 254);
}

.text-violet-200 {
  color: rgb(221, 214, 254);
}

.text-violet-300 {
  color: rgb(196, 181, 253);
}

.text-violet-400 {
  color: rgb(167, 139, 250);
}

.text-violet-500 {
  color: rgb(139, 92, 246);
}

.text-violet-600 {
  color: rgb(124, 58, 237);
}

.text-violet-700 {
  color: rgb(109, 40, 217);
}

.text-violet-800 {
  color: rgb(91, 33, 182);
}

.text-violet-900 {
  color: rgb(76, 29, 149);
}

.text-purple-50 {
  color: rgb(250, 245, 255);
}

.text-purple-100 {
  color: rgb(243, 232, 255);
}

.text-purple-200 {
  color: rgb(233, 213, 255);
}

.text-purple-300 {
  color: rgb(216, 180, 254);
}

.text-purple-400 {
  color: rgb(192, 132, 252);
}

.text-purple-500 {
  color: rgb(168, 85, 247);
}

.text-purple-600 {
  color: rgb(147, 51, 234);
}

.text-purple-700 {
  color: rgb(126, 34, 206);
}

.text-purple-800 {
  color: rgb(107, 33, 168);
}

.text-purple-900 {
  color: rgb(88, 28, 135);
}

.text-fuchsia-50 {
  color: rgb(253, 244, 255);
}

.text-fuchsia-100 {
  color: rgb(250, 232, 255);
}

.text-fuchsia-200 {
  color: rgb(245, 208, 254);
}

.text-fuchsia-300 {
  color: rgb(240, 171, 252);
}

.text-fuchsia-400 {
  color: rgb(232, 121, 249);
}

.text-fuchsia-500 {
  color: rgb(217, 70, 239);
}

.text-fuchsia-600 {
  color: rgb(192, 38, 211);
}

.text-fuchsia-700 {
  color: rgb(162, 28, 175);
}

.text-fuchsia-800 {
  color: rgb(134, 25, 143);
}

.text-fuchsia-900 {
  color: rgb(112, 26, 117);
}

.text-pink-50 {
  color: rgb(253, 242, 248);
}

.text-pink-100 {
  color: rgb(252, 231, 243);
}

.text-pink-200 {
  color: rgb(251, 207, 232);
}

.text-pink-300 {
  color: rgb(249, 168, 212);
}

.text-pink-400 {
  color: rgb(244, 114, 182);
}

.text-pink-500 {
  color: rgb(236, 72, 153);
}

.text-pink-600 {
  color: rgb(219, 39, 119);
}

.text-pink-700 {
  color: rgb(190, 24, 93);
}

.text-pink-800 {
  color: rgb(157, 23, 77);
}

.text-pink-900 {
  color: rgb(131, 24, 67);
}

.text-rose-50 {
  color: rgb(255, 241, 242);
}

.text-rose-100 {
  color: rgb(255, 228, 230);
}

.text-rose-200 {
  color: rgb(254, 205, 211);
}

.text-rose-300 {
  color: rgb(253, 164, 175);
}

.text-rose-400 {
  color: rgb(251, 113, 133);
}

.text-rose-500 {
  color: rgb(244, 63, 94);
}

.text-rose-600 {
  color: rgb(225, 29, 72);
}

.text-rose-700 {
  color: rgb(190, 18, 60);
}

.text-rose-800 {
  color: rgb(159, 18, 57);
}

.text-rose-900 {
  color: rgb(136, 19, 55);
}

.w-0 {
  width: 0px;
}

.w-px {
  width: 1px;
}

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}

.w-fit {
  width: fit-content;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-help {
  cursor: help;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-none {
  cursor: none;
}

.cursor-context-menu {
  cursor: context-menu;
}

.cursor-progress {
  cursor: progress;
}

.cursor-cell {
  cursor: cell;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-vertical-text {
  cursor: vertical-text;
}

.cursor-alias {
  cursor: alias;
}

.cursor-copy {
  cursor: copy;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-all-scroll {
  cursor: all-scroll;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-s-resize {
  cursor: s-resize;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-se-resize {
  cursor: se-resize;
}

.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.clearButton {
  display: block;
  width: 2rem;
  right: 0px;
  top: 0px;
  bottom: 0px;
  position: absolute;
  color: #a8a8a8;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
}

/*form generator*/