//
// Custom Variables
//
// Here you can add your own variables overrides
//
// The file is included before any other variable file,
// so you can override them because they all use !default
// --------------------------------------------------

// For example, changing the primary color
// $primary: #a48ad4;
// $font-size-root: 15px;
// $font-size-root: 16.5px;
// $font-size-base: .75rem !default;
/* font */
// body {
//     font-family: Inter;
// }
$font-family-sans-serif: "Inter", "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-size-base: .773rem !default;
